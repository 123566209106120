import { useEffect, useState } from 'react'
import useSWR from 'swr'
import {
  ITicketResponseList,
  ITicketResponseListRequest
} from '../../core/api_request_responses/ticket.models'
import { generateQueryString, toArray, toNumber } from '../../core/helpers'
import { TicketFilterResponse } from '../../core/models/ticket_filter.response'
import {
  createTicketFromTicketResponse,
  Ticket
} from '../../models/ticket.model'

export function useTickets(initial?: ITicketResponseListRequest) {
  const [filter, setFilter] = useState<ITicketResponseListRequest>(
    initial ?? new TicketFilterResponse()
  )
  const { data, mutate } = useSWR<ITicketResponseList>(
    generateQueryString('/ticket', filter),
    { refreshInterval: 1000 * 60 }
  )
  const [tickets, setTickets] = useState<Ticket[]>()

  useEffect(() => {
    if (data?.tickets) {
      setTickets(
        toArray(data?.tickets)
          .map((ticket) => createTicketFromTicketResponse(ticket))
          .sort((a, b) => {
            if (!a?.endDate) {
              return 1
            }
            if (!b?.endDate) {
              return -1
            }

            return a.endDate.valueOf() - b.endDate.valueOf()
          })
      )
    }
  }, [data])

  function changeTicket(ticket: Ticket) {
    setTickets((prev) =>
      toArray(prev).map((t) => (t.id === ticket.id ? ticket : t))
    )
  }

  return {
    tickets,
    mutate,
    setFilter,
    currentPage: toNumber(data?.currentPage, filter.page),
    currentCount: toNumber(data?.currentCount, 0),
    totalPage: toNumber(data?.totalPage, filter.page),
    totalCount: toNumber(data?.totalCount, 0),
    limit: toNumber(data?.limit, 50),
    changeTicket
  }
}
