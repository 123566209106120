import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { SWRConfig } from 'swr'
import GroupDetailPage from './pages/group/detail'
import ProfilePage from './pages/profile'
import ProfileDetailPage from './pages/profile/detail'
import ProjectDetailPage from './pages/project/detail'
import LoginPage from './pages/public/login'
import RecoveryPasswordPage from './pages/public/recovery-password'
import RecoveryPasswordCodePage from './pages/public/recovery-password/recovery-password-code'
import SignUpCodePage from './pages/public/sign-up/sign-up-code'
import reportWebVitals from './reportWebVitals'
import { get } from './utils/api'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect } from 'react'
import { sleep, toNumber } from './core/helpers'
import runOneSignal from './onesignal'
import AIPage from './pages/ai'
import LogoutPage from './pages/logout'
import PaymentPage from './pages/pay'
import PersonalAreaPage from './pages/personal_area'
import ProjectGroupsPage from './pages/project_groups'
import SignUpPublicPage from './pages/public/sign-up-public/sign-up-public'
import SignUpWithCodePage from './pages/public/sign-up-with-code/sign-up-with-code'
import StatisticPage from './pages/statistic'
import TicketsPage from './pages/tickets'
import theme from './theme'

const router = createBrowserRouter([
  {
    path: '/group/:id',
    element: <GroupDetailPage />
  },
  {
    path: '/profile/:id',
    element: <ProfileDetailPage />
  },
  {
    path: '/profile',
    element: <ProfilePage />
  },
  {
    path: '/project/:id',
    element: <ProjectDetailPage />
  },
  {
    path: '/public/recovery-password/:code',
    element: <RecoveryPasswordCodePage />
  },
  {
    path: '/public/recovery-password',
    element: <RecoveryPasswordPage />
  },
  {
    path: '/public/sign-up/:code',
    element: <SignUpCodePage />
  },
  {
    path: '/public/sign-up-with-ref/:code',
    element: <SignUpWithCodePage />
  },
  {
    path: '/public/sign-up-public',
    element: <SignUpPublicPage />
  },
  {
    path: '/public/login',
    element: <LoginPage />
  },
  {
    path: '/statistic/',
    element: <StatisticPage />
  },
  {
    path: '/logout',
    element: <LogoutPage />
  },
  {
    path: '/personal-area',
    element: <PersonalAreaPage />
  },
  {
    path: '/tickets',
    element: <TicketsPage />
  },
  {
    path: '/project-groups',
    element: <ProjectGroupsPage />
  },
  {
    path: '/',
    element: <ProjectGroupsPage />
  },
  {
    path: '/ai',
    element: <AIPage />
  },
  {
    path: '/pay/:code',
    element: <PaymentPage />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

async function fetcher<T>(resource: string): Promise<T | undefined> {
  if (resource === '/notification') {
    await sleep(1500)
  }
  return (await get<T>(resource))?.payload
}

function App() {
  useEffect(() => {
    runOneSignal(toNumber(window.localStorage.getItem('userId')))
  }, [])
  return (
    <GoogleOAuthProvider clientId="518826957106-r0av90fq7mud7qfi6fm7soh6ef7d4b42.apps.googleusercontent.com">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SWRConfig
          value={{
            fetcher
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </SWRConfig>
      </ThemeProvider>
    </GoogleOAuthProvider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
