import { AITicketQuestionResponse } from '../core/models/ai_ticket_question.response'
import { decodeHtml } from '../utils'

export class AITicketQuestion {
  id: number = 0
  tr: string = ''
  en: string = ''
}

export function createAITicketQuestionFromAIQuestionResponse(
  response: AITicketQuestionResponse
): AITicketQuestion {
  const instance = new AITicketQuestion()
  instance.id = response.id
  instance.tr = decodeHtml(response.tr)
  instance.en = decodeHtml(response.en)
  return instance
}
