import { useMemo, useState } from 'react'

import { Avatar } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import StatisticProjectSelect from '../../../components/statistic_project_select'
import { StatisticTimeSwitch } from '../../../components/statistic_time_switch'
import { useStatisticContext } from '../../../contexts/statistic'
import { IStatisticUserActivation } from '../../../core/api_request_responses/statistic.models'
import { toArray, toNumber, toString } from '../../../core/helpers'
import translate from '../../../hooks/use_localization'

interface Row {
  profilePicture?: string
  id: number
  name: string
  completedGroupRate: number
  progressGroupRate: number
  mediaRate: number
  commentRate: number
  createTicketRate: number
  total: number
}

const defaultActivation: IStatisticUserActivation = {
  doneRate: 0,
  rejectedRate: 0,
  inProgressRate: 0,
  feedbackRate: 0,
  mediaRate: 0,
  commentRate: 0,
  ticketCreateRate: 0,
  progressGroupRate: 0,
  completedGroupRate: 0,
  total: 0
}

export default function IndividualStatuses() {
  const { selectedTab, users, projects, mutate } = useStatisticContext()
  const [isTotal, setIsTotal] = useState(true)

  const [selectedProject, setSelectedProject] = useState<
    string | undefined | null
  >(translate('all'))

  async function onClickSet(props: { startDate: string; endDate: string }) {
    await mutate?.(props)
  }

  function toggleIsTotal() {
    setIsTotal((old) => !old)
  }

  const data: Row[] = useMemo(
    () =>
      toArray(users).map((user) => {
        let activation = user.activationTotal
        if (selectedProject && selectedProject !== translate('all')) {
          const target_project = toArray(projects).find(
            (project) => project?.name === selectedProject
          )

          activation = user.activations?.[toNumber(target_project?.id)] ?? {
            ...defaultActivation
          }
        }

        return {
          id: user.id,
          profilePicture: user?.profilePicture,
          name: user.name,
          completedGroupRate: activation.completedGroupRate,
          progressGroupRate: activation.progressGroupRate,
          mediaRate: activation.mediaRate,
          commentRate: activation.commentRate,
          createTicketRate: activation.ticketCreateRate,
          total: activation.total
        }
      }),
    [users, selectedProject, projects]
  )

  if (selectedTab !== 'individual' || !users || !projects) {
    return <></>
  }

  return (
    <>
      <StatisticTimeSwitch
        toggleIsTotal={toggleIsTotal}
        isTotal={isTotal}
        onClickSet={onClickSet}
      />
      <StatisticProjectSelect
        projects={toArray(projects)}
        selected_project={selectedProject}
        set_selected_project={setSelectedProject}
      />
      <TableView loading={!users} data={data} />
    </>
  )
}

function TableView({ loading, data }: { loading: boolean; data: Row[] }) {
  const { translate } = useStatisticContext()

  const columns: GridColDef[] = [
    {
      field: 'profilePicture',
      headerName: '',
      width: 36,
      minWidth: 36,
      renderCell: ({ row }) => (
        <Avatar
          src={toString((row as Row)?.profilePicture)}
          sx={{ width: 24, height: 24 }}
        />
      )
    },
    {
      field: 'name',
      headerName: translate('drawer_users'),
      width: 200
    },
    {
      field: 'completedGroupRate',
      type: 'number',
      headerName: translate('completed_group_rate'),
      width: 200
    },
    {
      field: 'progressGroupRate',
      type: 'number',
      headerName: translate('progress_group_rate'),
      width: 200
    },
    {
      field: 'mediaRate',
      type: 'number',
      headerName: translate('media_rate'),
      width: 200
    },
    {
      field: 'commentRate',
      type: 'number',
      headerName: translate('comment_rate'),
      width: 200
    },
    {
      field: 'createTicketRate',
      type: 'number',
      headerName: translate('create_ticket_rate'),
      width: 200
    },
    {
      field: 'total',
      headerName: translate('total_rate'),
      type: 'number',
      width: 200
    }
  ]

  return (
    <DataGrid
      autoHeight={true}
      loading={loading}
      rows={toArray(data)}
      columns={columns}
      pageSize={30}
      sx={{ mt: 3 }}
      rowHeight={40}
      rowsPerPageOptions={[30]}
      disableSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'total', sort: 'desc' }]
        }
      }}
    />
  )
}
