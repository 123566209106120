import { AddOutlined, DeleteOutline } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField
} from '@mui/material'
import { Box } from '@mui/system'
import { useMemo, useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import {
  ITicketAddTagRequest,
  ITicketDeleteTagRequest
} from '../../core/api_request_responses/ticket.models'
import { toArray, toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { Tag } from '../../models'
import { post, put } from '../../utils/api'
import DialogProvider from '../dialog_provider'
import TagCard from '../tag_card'
import TicketCollapsibleRow from './row'

export default function TicketTags() {
  const { ticket } = useTicketDetailContext()

  if (!ticket) {
    return null
  }
  const tagCount = toArray(ticket?.tags).length

  return (
    <TicketCollapsibleRow
      title={translate('tags')}
      badge={tagCount}
      AddComponent={() => <AddTagDialog />}
      DeleteComponent={() => (tagCount ? <DeleteTagDialog /> : <></>)}
      isDefaultOpen={!!tagCount}
    >
      <Stack direction={'row'} sx={{ gap: 2, flexWrap: 'wrap' }}>
        {toArray(ticket?.tags).map((tag) => (
          <TagCard tag={tag} key={tag.id} />
        ))}
      </Stack>
    </TicketCollapsibleRow>
  )
}

function AddTagDialog() {
  const { ticket, changeTicket } = useTicketDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [payload, setPayload] = useState<Partial<ITicketAddTagRequest>>({})

  const tagIds = toArray(ticket?.tags).map(({ id }) => id)
  const options = useMemo(
    () =>
      toArray(ticket?.project?.tags)
        .filter(({ id }) => !tagIds.includes(id))
        .map((tag) => ({ value: tag.id, label: tag.name })),
    [ticket]
  )

  const selectedTag = toArray(ticket?.project?.tags).find(
    (tag) => tag.id === payload?.tagId
  )

  function set_close() {
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.tagId) {
      return
    }

    await add_tag?.(payload as ITicketAddTagRequest)
    set_close()
  }

  function handleChangeTag(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      tagId: toNumber(event.target.value)
    })
  }

  async function add_tag(payload: ITicketAddTagRequest): Promise<boolean> {
    const targetTag = toArray(ticket?.project?.tags).find(
      (tag) => tag.id === payload.tagId
    )
    if (!targetTag || !changeTicket || !ticket) {
      return false
    }

    const oldList = toArray(ticket?.tags).slice(0)

    changeTicket({
      ...ticket,
      tags: toArray(ticket?.tags).concat(targetTag)
    })

    post<ITicketAddTagRequest, undefined>(
      `/ticket/${toNumber(ticket?.id)}/add-tag`,
      payload
    ).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({
          ...ticket,
          tags: oldList
        })
        alert('update_error')
      }
    })

    return true
  }

  if (!ticket) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={set_open}>
        <AddOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('add_tag')}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 3 }}
            fullWidth
            value={selectedTag?.id}
            label={translate('tag_name')}
            onChange={handleChangeTag}
            select={true}
          >
            {options.map((tag) => (
              <MenuItem key={tag.value} value={tag.value}>
                {tag.label}
              </MenuItem>
            ))}
          </TextField>

          <br />

          {selectedTag ? (
            <Box mt={3}>
              <TagCard tag={{ ...new Tag(), ...selectedTag }} />
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.tagId}
            onClick={save}
            variant={'contained'}
          >
            {translate('add')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function DeleteTagDialog() {
  const { ticket, changeTicket } = useTicketDetailContext()

  const [is_open, set_is_open] = useState(false)
  const [payload, setPayload] = useState<Partial<ITicketDeleteTagRequest>>({})

  async function delete_tag(
    payload: ITicketDeleteTagRequest
  ): Promise<boolean> {
    const targetTag = toArray(ticket?.tags).find(
      (tag) => tag.id === payload.tagId
    )
    if (!targetTag || !changeTicket || !ticket) {
      return false
    }

    const oldList = toArray(ticket?.tags).slice(0)
    const newList = toArray(ticket?.tags).filter(
      (tag) => tag.id !== payload.tagId
    )

    changeTicket({
      ...ticket,
      tags: newList
    })
    put<ITicketDeleteTagRequest, undefined>(
      `/ticket/${toNumber(ticket?.id)}/delete-tag`,
      payload
    ).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({
          ...ticket,
          tags: oldList
        })
        alert('update_error')
      }
    })

    return true
  }

  const options = useMemo(
    () =>
      toArray(ticket?.tags).map((tag) => ({
        value: tag.id,
        label: tag.name
      })),
    [ticket]
  )

  const selectedTag = toArray(ticket?.project?.tags).find(
    (tag) => tag.id === payload?.tagId
  )

  function set_close() {
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.tagId) {
      return
    }
    delete_tag?.(payload as ITicketAddTagRequest)
    set_close()
  }

  function handleChangeTag(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      tagId: toNumber(event.target.value)
    })
  }

  if (!ticket) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={set_open}>
        <DeleteOutline color={'error'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('remove_tag')}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 3 }}
            fullWidth
            value={selectedTag?.id}
            label={translate('name')}
            onChange={handleChangeTag}
            select={true}
          >
            {options.map((tag) => (
              <MenuItem key={tag.value} value={tag.value}>
                {tag.label}
              </MenuItem>
            ))}
          </TextField>

          <br />

          {selectedTag ? (
            <Box mt={3}>
              <TagCard tag={{ ...new Tag(), ...selectedTag }} />
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.tagId}
            onClick={save}
            variant={'contained'}
          >
            {translate('remove')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}
