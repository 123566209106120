// @ts-nocheck

export default function translate(_key: string | undefined | any): string {
  let locale = window.localStorage.getItem('lang') ?? 'en'

  if (locale !== 'tr' && locale !== 'en') {
    locale = 'tr'
  }

  const tr = {
    login_sign_in: 'Giriş yap',
    login_email: 'E-posta',
    login_password: 'Şifre',
    login_sign_in_button: 'Giriş yap',
    login_password_recovery: 'Şifremi unuttum',
    recovery_password_title: 'Şifremi unuttum',
    recovery_password_email: 'E-posta',
    recovery_password_submit: 'Gönder',
    recovery_password_go_login: 'Giriş yap',
    recovery_password_success: 'Şifreniz e-posta adresinize gönderildi.',
    recovery_password_unsuccess: 'E-posta adresinizi kontrol ediniz.',
    drawer_dashboard: 'Ana Sayfa',
    drawer_projects: 'Projeler',
    drawer_users: 'Kişiler',
    drawer_groups: 'Gruplar',
    drawer_statistic: 'İstatistik',
    drawer_layers: 'Katmanlar',
    invalid_email: 'Geçersiz e-posta adresi',
    invalid_password: 'Geçersiz şifre',
    is_required: 'Gerekli',
    invalid_email_or_password: 'Geçersiz e-posta adresi veya şifre',
    internal_error: ' Sunucu Hatası',
    password: 'Şifre',
    password_confirmation: 'Şifre tekrar',
    save: 'kaydet',
    password_confirmation_not_match: 'Şifre tekrarı eşleşmiyor',
    password_or_code_error: 'Şifre veya kod hatalı',
    invalid_code: 'Geçersiz veya süresi dolmuş kod',
    new_password_success: 'Şifreniz değiştirildi.',
    new_password_unsuccess: 'Şifreniz değiştirilemedi.',
    project_title: 'Proje',
    project_start_date: 'Başlangıç tarihi',
    project_end_date: 'Bitiş tarihi',
    project_description: 'Açıklama',
    row_action: 'İşlemler',
    user_first_name: 'Ad',
    user_last_name: 'Soyad',
    user_phone: 'Telefon',
    user_email: 'E-posta',
    nickname: 'Kullanıcı adı',
    group_name: 'Grup adı',
    drawer_accounts: 'Hesaplar',
    account_licences_id: 'Lisans ID',
    account_company_title: 'Şirket',
    account_company_address: 'Adres',
    account_company_country: 'Ülke',
    account_company_city: 'Şehir',
    account_tckn: 'TC Kimlik No',
    account_bill_day: 'Fatura günü',
    invalid_body: 'Geçersiz',
    user_password: 'Şifre',
    tab_detail: 'Detaylar',
    tab_group: 'Gruplar',
    tab_project: 'Projeler',
    tab_admin: 'Yönetici İslemleri',
    user_add_to_group: 'Kullanıcıyı Gruba Ekle',
    user_leave_group: 'Gruptan Çık',
    cancel: 'İptal',
    user_add_to_project: 'Projeye Ekle',
    user_leave_project: 'Projeden Çıkart',
    user_change_password: 'Şifreyi Değiştir',
    user_password_changed: 'Şifre değiştirildi.',
    email_invalid: 'Geçersiz e-posta adresi',
    email_exist: 'E-posta adresi zaten kullanılıyor',
    sign_up_success: 'Kayıt işlemi başarılı.',
    sign_up_title: 'Kayıt Ol',
    tab_user: 'Kişiler',
    group_manager_name: 'Yönetici Adı',
    group_owner_name: 'Grup Sahibinin Adı',
    group_add_user: 'Kullanıcı Ekle',
    create_hard_user: 'Direk kullanıcı olustur',
    tab_layer: 'Katmanlar',
    tab_data: 'Veri',
    layer_name: 'Katman Adı',
    statistic: 'İstatistik',
    close: 'Kapat',
    create_layer: 'Katman Oluştur',
    add_group_to_project: 'Projeye Ekle',
    logout: 'Çıkış',
    open: 'Açık',
    open_ticket: 'Açık Ticketlar',
    in_progress: 'Çalışılıyor',
    in_progress_ticket: 'Çalışılan Ticketlar',
    completed_closed: 'Tamamlandı/Kapalı',
    completed_closed_ticket: 'Tamamlandı/Kapalı Ticketlar',
    tickets_created_by_user: 'Kullanıcının Oluşturduğu Ticketlar',
    tickets_created_for_user: 'Kullanıcıya Atanan Ticketlar',
    tickets_created_for_group: 'Gruba atalı ticketlar',
    tickets_created_for_account: 'Account içerisindeki Ticketlar',
    all: 'Hepsi',
    layer: 'Katman',
    assigned_to_me: 'Bana Atalı',
    created_by_me: 'Benim Oluşturduğum',
    assign_both: 'Her ikiside',
    assigned_to: 'Atama',
    timeline_day: 'Bugün',
    timeline_week: 'Bu Hafta',
    timeline_month: 'Bu Ay',
    filters_expired: 'Süresi Dolanlar',
    timeline_all: 'Hepsi',
    time_filter: 'Zaman Filtresi',
    status_filter: 'Durum Filtresi',
    priority_filter: 'Öncelik Filtresi',
    filters_open: 'Açık',
    low: 'Düşük',
    normal: 'Normal',
    high: 'Yüksek',
    critical: 'Kritik',
    isCritical: 'Kritik mi?',
    filters_working_on: 'Çalışıyor',
    filters_completed: 'Tamamlandı',
    filters_feedback: 'Geri Bildirim',
    filters_rejected: 'Reddedildi',
    filters_all: 'Hepsi',
    show_closed_tickets: 'Kapalı Ticketları Göster',
    reset_filter: 'Filtreyi Sıfırla',
    tab_rollup: 'Roadmap',
    tab_project_leadership: 'Projelerin Durumu',
    tab_individual_leadership: 'Kişilerin Durumu',
    tab_forecast: 'Tıcket Zaman Grafiği',
    status_open: 'Açık',
    status_working_on: 'Çalışıyor',
    status_completed: 'Tamamlandı',
    status_closed: 'Kapalı',
    status_feedback: 'Geri bildirim',
    status_rejected: 'Reddedildi',
    success_rate: 'Tamamlanma Oranı',
    completed_rate: 'Zamanında Oranı',
    list_view: 'Liste Görünümü',
    pie_chart_view: 'Pasta Grafiği Görünümü',
    bar_chart_view: 'Bar Grafiği Görünümü',
    compilation_rate: 'Tamamlama Oranı',
    active_rate: 'Aktivasyon Değeri',
    project: 'Proje',
    user: 'Kişi',
    total: 'Toplam',
    group: 'Grup',
    tab_group_leadership: 'Grupların Durumu',
    tab_comments: 'Yorumlar',
    ticket_project: 'Proje',
    ticket_layer: 'Katman',
    ticket_title: 'Başlık',
    ticket_status: 'Durum',
    ticket_start_date: 'Başlangıç Tarihi',
    ticket_end_date: 'Bitiş Tarihi',
    ticket_description: 'Açıklama',
    ticket_id: 'ID',
    ticket_assignee: 'Atanan',
    ticket_owner_name: 'Oluşturan',
    ticket_priority: 'Öncelik',
    ticket_tags: 'Etiketler',
    ticket_medias: 'Medyalar',
    ticket_history: 'Gelişmeler',
    ticket_create_must_have_title: 'Ticket başlığı olmalıdır.',
    ticket_create_must_have_layer: 'Ticket katmanı olmalıdır.',
    ticket_keyboard_toast_select_project: 'Proje seçiniz.',
    ticket_create_must_be_assigned: 'Ticket atama yapılmalıdır.',
    ticket_create_must_be_end_date: 'Ticket bitiş tarihi olmalıdır.',
    ticket_create_must_be_start_date: 'Ticket başlangıç tarihi olmalıdır.',
    create_ticket: 'Ticket Oluştur',
    completed_group_rate: 'Durum (Tamamlandi/Kapali/Reddetmek)',
    progress_group_rate: 'Durum (Çalışıyor/Geri Bildirim)',
    media_rate: 'Medya Yükleme',
    comment_rate: 'Yorum Yapma',
    create_ticket_rate: 'Yeni Ticket Oluşturma',
    total_rate: 'Toplam Puan',
    RECORD_CANNOT_FIND: 'Kayıt Bulunamadı',
    RECORD_CANNOT_CREATE: 'Kayıt Oluşturulamadı',
    ALL_RECORDS_CANNOT_CREATE: 'Tüm Kayıtlar Oluşturulamadı',
    RECORD_CANNOT_UPDATE: 'Kayıt Güncellenemedi',
    INVALID_PASSWORD_OR_EMAIL: 'Geçersiz E-Posta veya Şifre',
    INVALID_TOKEN: 'Geçersiz Token',
    EMAIL_NOT_VALID: 'Geçersiz E-Posta',
    PASSWORD_NOT_VALID: 'Geçersiz Şifre',
    IS_REQUIRED: 'Zorunlu',
    EMAIL_IS_EXIST: 'E-Posta Zaten Kayıtlı',
    FILE_UPLOAD_ERROR: 'Dosya Yüklenemedi',
    FOLDER_CANNOT_CREATE: 'Klasör Oluşturulamadı',
    FILE_CANNOT_UPLOAD: 'Dosya Yüklenemedi',
    UNAUTHORIZED: 'Yetkisiz işlem',
    URL_NOT_VALID: 'Geçersiz URL',
    NOT_IMPLEMENTED: 'Henüz Eklenmedi',
    RECORD_IS_EXIST: 'Kayıt Zaten Kayıtlı',
    RECORD_IS_NOT_EXIST: 'Kayıt Bulunamadı',
    USER_CANNOT_ACCESS_THIS_RECORD: 'Bu Kayıda Erişim Yetkiniz Yok',
    USER_CANNOT_EDIT_THIS_RECORD: 'Bu Kaydı Düzenleme Yetkiniz Yok',
    GROUP_ADMIN_CANNOT_DELETE: 'Grup Adminini Silemezsiniz',
    PROJECT_ADMIN_CANNOT_DELETE: 'Proje Adminini Silemezsiniz',
    DATE_IS_NOT_VALID: 'Geçersiz Tarih',
    USER_CANNOT_CREATE_THIS_RECORD: 'Bu Kaydı Oluşturma Yetkiniz Yok',
    THIS_IS_PRIVATE_ARE: 'Bu Özel Alan',
    INVALID_BODY: 'Geçersiz İçerik',
    INVALID_ASSIGNED_TYPE: 'Geçersiz Atama Tipi',
    LAYER_CANNOT_FIND: 'Katman Bulunamadı',
    SYSTEM_ERROR: 'Sistem Hatası',
    ticket_check_items: 'Check List',
    ticket_comments: 'Yorumlar',
    custom: 'Özel',
    indexpage: 'Ana Sayfa',
    tickets: 'Ticketlar',
    personalArea: 'Kişisel Alan',
    projectGroups: 'Projelerim ve Gruplarım',
    createTicket: 'Ticket Oluştur',
    notifications: 'Bildirimler',
    unreaded: 'Okunmamış',
    mentions: 'Bahsedilmeler',
    ticketName: 'Ticket Adı',
    uploadAvatar: 'Avatar yükle',
    checkitem_title: 'CheckItem Düzenle veya Sil',
    mark_all_read: 'Hepsini Okundu işaretle',
    start_date: 'Başlangıç zamanı',
    end_date: 'Bitiş zamanı',
    update: 'Güncelle',
    until_today: 'Bugüne kadar',
    create: 'Oluştur',
    create_checkitem: 'CheckItem Oluştur',
    checkitem: 'Görev',
    invite_user: 'Kullanıcı davet et',
    firstname: 'İsim',
    lastname: 'Soyisim',
    email: 'E-mail',
    invite: 'Davet et',
    invite_success: 'Kullanıcı davet edildi',
    your_groups: 'Dahil Olduğun Gruplar',
    your_projects: 'Dahil Olduğun Projeler',
    leave_group: 'Gruptan Çık',
    leave_group_hint: 'Yöneticisi olduğunuz gruptan çıkamassınız.',
    create_group: 'Grup Oluştur',
    group_manager: 'Yönetici',
    manager: 'Yönetici',
    create_project: 'Proje Oluştur',
    project_detail: 'Proje Detayları',
    layers: 'Katmanlar',
    user_and_groups: 'Kullanıcı ve Gruplar',
    back: 'Geri',
    next: 'İleri',
    event_start_date: 'Etkinlik başlangıç tarihi',
    event_end_date: 'Etkinlik bitiş tarihi',
    project_name: 'Proje ismi',
    add: 'Ekle',
    joint_projects: 'Ortak Projeleriniz',
    joint_groups: 'Ortak Gruplarınız',
    group_edit: 'Grup Düzenle',
    add_user_to_group: 'Gruba kullanıcı ekle',
    remove_user: 'Kullanıcı çıkar',
    user_included_groups: 'Dahil olduğu gruplar',
    user_included_projects: 'Ortak projeleriniz',
    ticket_upload_success: 'Ticketlar başarıyla aktarıldı',
    ticket_upload_error1: 'Bazı kayıtlara erişilemedi, kullanıcılar',
    ticket_upload_error2: 'Gruplar',
    ticket_upload: 'Ticket aktarımı',
    example_file: 'Örnek dosyayı indir',
    select_upload_file: 'Aktarım dosyasını seç',
    update_project: 'Projeyi Güncelle',
    project_tags: 'Projenin Tagları',
    project_groups: 'Projenin Grupları',
    add_tag: 'Tag Ekle',
    edit_tag: 'Tag Düzenle',
    delete_tag: 'Tag sil',
    tag_name: 'Tag adı',
    tag_color: 'Tag rengi',
    add_layer: 'Layer ekle',
    edit_layer: 'Layer düzenle',
    add_group_project: 'Projeye Grup ekle',
    users: 'Kullanıcılar',
    add_user_to_project: 'Projeye kullanıcı ekle',
    done_total_tickets: 'Tamamlanan / Toplam ticket',
    action_rate: 'Aksiyon Yüzdesi',
    first_ticket: 'İlk ticket',
    last_ticket: 'Son ticket',
    event_date: 'Etkinlik Tarihi',
    today: 'Bugün',
    done: 'Tamamlanan',
    feedback: 'Geri bildirim',
    rejected: 'Reddedilen',
    sum: 'Toplam',
    completion_rate: 'Bu ticketların tamamlanma oranı ',
    all_projects: 'Bütün projeler',
    planned: 'Planlanan',
    actual: 'Gerçekleşen',
    user_group_select: 'Kişi/Grup Seçimi',
    table_view: 'Tablo görünümü',
    order: 'Sıra',
    status: 'Durum',
    _delete: 'Sil',
    assign_select: 'Atama',
    priortiy: 'Öncelik',
    time_select: 'Zaman',
    tag_select: 'Tag',
    submit: 'Uygula',
    reset: 'Sıfırla',
    no_ticket: 'Hiç ticket bulunamadı',
    name: 'isim',
    update_error: 'Güncelleme Hatası',
    ticket_delete_success: 'Ticket başarıyla silindi',
    details: 'Detaylar',
    comments: 'Yorumlar',
    createdBy: 'Oluşturan',
    remove_tag: 'Tag çıkar',
    remove: 'çıkar',
    files: 'Dosyalar',
    checkitems: 'Check Itemlar',
    add_checkitem: 'Check Item Oluştur',
    actions: 'Ticket Hareketleri',
    delete_ticket: 'Ticket Sil!',
    delete_ticket_1: 'Silmek istediğinize emin misiniz?',
    delete_ticket_2: 'Bu işlemin geri dönüşü yoktur!',
    tags: 'Etiketler',
    not_specified: 'Belirtilmedi',
    add_location: 'Konum Ekle',
    edit_location: 'Konum Düzenle',
    delete_location: 'Konum Sil',
    _new: 'Yeni',
    old: 'Eski',
    similar: 'Benzer',
    assignee_percent: 'Atama yüzdesi',
    assignee: 'Atama',
    assistant: 'Asistan',
    change_password_success: 'Şifre başarıyla değiştirildi',
    change_password: 'Şifre Değiştir',
    old_password: 'Eski Şifre',
    new_password: 'Yeni Şifre',
    confirm_password: 'Yeni Şifre Tekrar',
    link_file: 'Dosya Linkle',
    get_info: 'Bilgileri Getir',
    bar_chart: 'Bar Grafiği',
    line_chart: 'Çizgi Grafiği',
    assistant_assign_helper_text:
      'Benzer ticketlar incelendiğinde, daha önceden bu ticketın kimlere atandığı ve yüzdelik bilgisi yer almaktadır',
    assistant_done_rate: 'Zamanında Tamamlanma Oranı ',
    assistant_average_rate: 'Ticketın ortalama tamamlanma süresi',
    assistant_accepted: 'Başvurunuz alınmıştır',
    this_ticket_is_critical: 'Bu Ticket Kritik Olarak İşaretlenmiştir',
    what_is_the_risk_about_the_ticket: 'Bu Görevin Riskleri Nelerdir?',
    share: 'Paylaş',
    copy: 'Kopyala',
    copied: 'Kopyalandı',
    get_ref_code: 'Referans Kodu Ekle',
    firstName: 'İsim',
    lastName: 'Soyisim',
    template_success:
      'Bu görevin başarılı olması için en kritik faktörler nelerdir?',
    'roadMap.criticalTicketStatus': 'Kritik Ticket Sayısı / Tamamlanan',
    criticalTickets: 'Kritik Görevler',
    criticalDoneTickets: 'Tamamlanan Kritik Tickets',
    yes: 'Evet',
    no: 'Hayır',
    do_you_want_add_another_information:
      'Bu bilgiler dışında başka bilgi eklemek istediğiniz bilgileri yazıp, gönderiniz',
    send: 'Gönder',
    sign_up: 'Kayıt Ol',
    ai_limit_full: 'Asistan haklarınızı tükendi',
    ai_limit_request: 'Asistan hakkı talep etmek için lütfen başvurunuz.',
    request_accept: 'Başvurunuz alınmıştır',
    request_500_accept:
      'Tebrikler 🎉 Uygulamayı kullanan ilk 500 kişi içerisinde olduğunuz için Asistan hakkınız artırılmıştır.',
    projectDescription: 'Proje Açıklaması',
    mainTask: 'Ana Görev',
    talkingPoints: 'Konuşma Noktaları',
    customerName: 'Müşteri Adı',
    emailPurpose: 'E-posta Amaç',
    productService: 'Ürün Hizmeti',
    companyName: 'Şirket Adı',
    productServicesBenefits: 'Ürün Hizmetleri Avantajları',
    targetAudience: 'Hedef Kitle',
    topics: 'Konular',
    postingFrequency: 'Paylaşım Sıklığı',
    topic: 'Konu',
    questions: 'Sorular',
    campaignGoals: 'Kampanya Hedefleri',
    mainKeyword: 'Anahtar Kelime',
    contentGoal: 'İçerik Hedefi',
    secondaryKeyword: 'İkincil Anahtar Kelime',
    productDescription: 'Ürün Açıklaması',
    feedbackData: 'Geribildirim Verileri',
    businessRequirements: 'İş Gereksinimleri',
    systemInvolved: 'İlgili Sistemler',
    bestPractices: 'En İyi Uygulamalar',
    technicalDesign: 'Teknik Tasarım',
    outputCategory: 'Çıkış Kategorisi',
    testingType: 'Test Türü',
    yourContext: 'Sizin Bağlamınız',
    targetUser: 'Hedef Kullanıcı',
    feature: 'Özellik',
    product: 'Ürün',
    bugReportSource: 'Hata Bildirimi Kaynağı',
    bugReportData: 'Hata Bildirimi Verileri',
    role: 'Rol',
    jobsToBeDone: 'Yapılacak İşler',
    attributes: 'Özellikler',
    targetUsers: 'Hedef Kullanıcılar',
    emailToReplyTo: 'Yanıtlanacak E-posta',
    yourTalkingPoints: 'Sizin Konuşma Noktalarınız',
    uniqueSellingPoints: 'Benzersiz Satış Noktaları',
    promotionDetails: 'Promosyon Detayları',
    productServices: 'Ürün Hizmetleri',
    reasonsForCanceling: 'İptal Nedenleri',
    CTA: 'Çağrıya Yönlendirme (CTA)',
    incentive: 'Teşvik',
    goal: 'Hedef',
    desiredFormat: 'İstenen Format',
    systemsInvolved: 'İlgili Sistemler',
    view_as_pdf: 'PDF olarak görüntüle',
    select_ticket_for_linking: 'Linkleyeceğiniz Ticketı seçiniz',
    file_name: 'Dosya ismi',
    file_url: 'URL',
    file_select: 'Dosya Seçimi'
  }

  const en = {
    login_sign_in: 'Login',
    login_email: 'E-mail',
    login_password: 'Password',
    login_sign_in_button: 'Login',
    login_password_recovery: 'Recovery Password',
    recovery_password_title: 'Recovery Password',
    recovery_password_email: 'E-mail',
    recovery_password_submit: 'Submit',
    recovery_password_go_login: 'Login',
    recovery_password_success:
      'Your password has been sent to your e-mail address.',
    recovery_password_unsuccess: 'Please check your e-mail address.',
    drawer_dashboard: 'Home page',
    drawer_projects: 'Projects',
    drawer_users: 'Users',
    drawer_groups: 'Groups',
    drawer_statistic: 'Statistic',
    drawer_layers: 'Layers',
    invalid_email: 'Invalid email adress',
    invalid_password: 'Invalid password',
    is_required: 'Required',
    invalid_email_or_password: 'Invalid email or password',
    internal_error: ' Server error',
    password: 'Password',
    password_confirmation: 'Password confirmation',
    save: 'save',
    password_confirmation_not_match: 'Password repeats do not match',
    password_or_code_error: 'Password or code is incorrect',
    invalid_code: 'Invalid or expired code',
    new_password_success: 'Your password has been changed.',
    new_password_unsuccess: 'Your password could not be changed.',
    project_title: 'Project',
    project_start_date: 'Start date',
    project_end_date: 'End date',
    project_description: 'Description',
    row_action: 'Actions',
    user_first_name: 'Name',
    user_last_name: 'Lastname',
    user_phone: 'Phone',
    user_email: 'E-mail',
    nickname: 'Username',
    group_name: 'Group name',
    drawer_accounts: 'Accounts',
    account_licences_id: 'Licence ID',
    account_company_title: 'Company',
    account_company_address: 'Address',
    account_company_country: 'Country',
    account_company_city: 'City',
    account_tckn: 'ID No',
    account_bill_day: 'Bill day',
    invalid_body: 'Invalid',
    user_password: 'Password',
    tab_detail: 'Detail',
    tab_group: 'Groups',
    tab_project: 'Projects',
    tab_admin: 'Admin',
    user_add_to_group: 'Add User to Group',
    user_leave_group: 'Exit Group',
    cancel: 'Cancel',
    user_add_to_project: 'add to project',
    user_leave_project: 'Remove from Project',
    user_change_password: 'Change Password',
    user_password_changed: 'The password was changed.',
    email_invalid: 'Invalid e-mail address',
    email_exist: 'Email address is already in use',
    sign_up_success: 'Registration successful.',
    sign_up_title: 'Sign Up',
    tab_user: 'Users',
    group_manager_name: 'Manager Name',
    group_owner_name: 'Group Owner Name',
    group_add_user: 'Add User',
    create_hard_user: 'Create User',
    tab_layer: 'Layers',
    tab_data: 'Data',
    layer_name: 'Layer name',
    statistic: 'Statistic',
    close: 'Close',
    create_layer: 'Create Layer',
    add_group_to_project: 'Add to group',
    logout: 'Logout',
    open: 'Open',
    open_ticket: 'Open Tickets',
    in_progress: 'In Progress',
    in_progress_ticket: 'In Progress Tickets',
    tickets_created_by_user: 'User-Created Tickets',
    tickets_created_for_user: 'Tickets Assigned to User',
    tickets_created_for_group: 'Group-assigned tickets',
    tickets_created_for_account: 'Tickets in the Account',
    all: 'All',
    layer: 'Layer',
    assigned_to_me: 'Assigned to Me',
    created_by_me: 'Created By Me',
    assign_both: 'Both of them',
    assigned_to: 'Assignment',
    timeline_day: 'Today',
    timeline_week: 'This week',
    timeline_month: 'This month',
    filters_expired: 'Expired',
    timeline_all: 'All',
    time_filter: 'Time Filter',
    status_filter: 'Status Filter',
    priority_filter: 'Priority Filter',
    filters_open: 'Open',
    low: 'Low',
    normal: 'Normal',
    high: 'High',
    critical: 'Critical',
    isCritical: 'is Critical?',
    filters_working_on: 'working',
    filters_completed: 'Completed',
    filters_feedback: 'Feedback',
    filters_rejected: 'Rejected',
    filters_all: 'All',
    reset_filter: 'Reset Filter',
    tab_rollup: 'Roadmap',
    tab_project_leadership: 'Project Leadership',
    tab_individual_leadership: 'Individual Leadership',
    tab_forecast: 'Ticket Time Chart',
    status_open: 'Open',
    status_working_on: 'working',
    status_completed: 'Completed',
    status_feedback: 'Feedback',
    status_rejected: 'Rejected',
    success_rate: 'Completion rate',
    completed_rate: 'On Time Rate',
    list_view: 'List View',
    pie_chart_view: 'Pie Chart View',
    bar_chart_view: 'Bar Chart View',
    compilation_rate: 'Completion Rate',
    active_rate: 'Activation Rate',
    project: 'Project',
    user: 'User',
    total: 'Total',
    group: 'Group',
    tab_group_leadership: 'Status of Groups',
    tab_comments: 'Comments',
    ticket_project: 'Project',
    ticket_layer: 'Layer',
    ticket_title: 'Title',
    ticket_status: 'Status',
    ticket_start_date: 'Start Date',
    ticket_end_date: 'End Date',
    ticket_description: 'Description',
    ticket_id: 'ID',
    ticket_assignee: 'Assignee',
    ticket_owner_name: 'Created By',
    ticket_priority: 'Priority',
    ticket_tags: 'Tags',
    ticket_medias: 'Medias',
    ticket_history: 'Histories',
    ticket_create_must_have_title: 'The ticket should have a title',
    ticket_create_must_have_layer: 'The ticket should have a layer',
    ticket_keyboard_toast_select_project: 'Please, select a project.',
    ticket_create_must_be_assigned: 'Ticket assignment must be made.',
    ticket_create_must_be_end_date: 'The ticket must have an end date.',
    ticket_create_must_be_start_date: 'The ticket must have an start date.',
    create_ticket: 'Create ticket',
    completed_group_rate: 'Status (Done/Rejected)',
    progress_group_rate: 'Status (IN Progress/Feedback)',
    media_rate: 'Media rate',
    comment_rate: 'Comment rate',
    create_ticket_rate: 'Create ticket rate',
    total_rate: 'Total rate',
    RECORD_CANNOT_FIND: 'Record cannot find',
    RECORD_CANNOT_CREATE: 'Failed to Create Record',
    ALL_RECORDS_CANNOT_CREATE: 'Failed to Create All Records',
    RECORD_CANNOT_UPDATE: 'Failed to Update Record',
    INVALID_PASSWORD_OR_EMAIL: 'Invalid Email or Password',
    INVALID_TOKEN: 'Invalid Token',
    EMAIL_NOT_VALID: 'Invalid Email',
    PASSWORD_NOT_VALID: 'Invalid Password',
    IS_REQUIRED: 'necessary',
    EMAIL_IS_EXIST: 'Email Already Registered',
    FILE_UPLOAD_ERROR: 'File Could Not Be Uploaded',
    FOLDER_CANNOT_CREATE: 'Failed to Create Folder',
    FILE_CANNOT_UPLOAD: 'File Could Not Be Uploaded',
    UNAUTHORIZED: 'Unauthorized operation',
    URL_NOT_VALID: 'Invalid URL',
    NOT_IMPLEMENTED: 'Not Added Yet',
    RECORD_IS_EXIST: 'Record is exist',
    RECORD_IS_NOT_EXIST: 'No Records Found',
    USER_CANNOT_ACCESS_THIS_RECORD:
      'You Are Not Authorized To Access This Recording',
    USER_CANNOT_EDIT_THIS_RECORD:
      'You Are Not Authorized To Edit This Recording',
    GROUP_ADMIN_CANNOT_DELETE: 'You Cant Delete Group Admin',
    PROJECT_ADMIN_CANNOT_DELETE: 'You Cannot Delete Project Admin',
    DATE_IS_NOT_VALID: 'Invalid Date',
    USER_CANNOT_CREATE_THIS_RECORD:
      'You Are Not Authorized To Create This Record',
    THIS_IS_PRIVATE_ARE: 'This Private Area',
    INVALID_BODY: 'Invalid Body',
    INVALID_ASSIGNED_TYPE: 'Invalid Assignment Type',
    LAYER_CANNOT_FIND: 'Layer Not Found',
    SYSTEM_ERROR: 'System failure',
    ticket_check_items: 'Check List',
    ticket_comments: 'Comments',
    custom: 'Custom',
    indexpage: 'Home',
    tickets: 'Tickets',
    personalArea: 'Personal Area',
    projectGroups: 'Project And Groups',
    createTicket: 'Create Ticket',
    notifications: 'Notifications',
    unreaded: 'Unread',
    mentions: 'Mentions',
    ticketName: 'Name',
    uploadAvatar: 'Upload Avatar',
    checkitem_title: 'CheckItem Edit',
    mark_all_read: 'Mark as all read',
    start_date: 'Start date',
    end_date: 'End date',
    update: 'Update',
    until_today: 'Until Today',
    create: 'Create',
    create_checkitem: 'Create CheckItem',
    checkitem: 'CheckItem',
    invite_user: 'Invite User',
    firstname: 'First name',
    lastname: 'Lastname',
    email: 'E-mail',
    invite: 'Invite',
    invite_success: 'User successfully invited',
    your_groups: 'Your Groups',
    your_projects: 'Your Projects',
    leave_group: 'Leave Group',
    leave_group_hint: 'You cannot leave the group you are admin.',
    create_group: 'Create Group',
    group_manager: 'Manager',
    manager: 'Manager',
    create_project: 'Create Project',
    project_detail: 'Project Detail',
    layers: 'Layers',
    user_and_groups: 'User and Groups',
    back: 'Back',
    next: 'Next',
    event_start_date: 'Event start date',
    event_end_date: 'Event end date',
    project_name: 'Project name',
    add: 'Add',
    joint_projects: 'joint projects',
    joint_groups: 'joint groups',
    group_edit: 'Group Edit',
    add_user_to_group: 'Add User To Group',
    remove_user: 'Remove User',
    user_included_groups: 'User Included Groups',
    user_included_projects: 'User Included Projects',
    ticket_upload_success: 'Tickets transferred successfully',
    ticket_upload_error1: 'Some records could not be accessed, users',
    ticket_upload_error2: 'groups',
    ticket_upload: 'Tickets Upload',
    example_file: 'Example file',
    select_upload_file: 'Select the upload file',
    update_project: 'Edit project',
    project_tags: 'Project Tags',
    project_groups: 'Project Groups',
    add_tag: 'Add Tag',
    edit_tag: 'Edit Tag',
    delete_tag: 'Delete Tag',
    tag_name: 'Tag name',
    tag_color: 'Tag color',
    add_layer: 'Add Layer',
    edit_layer: 'Edit Layer',
    add_group_project: 'Add Group to Project',
    users: 'Users',
    add_user_to_project: 'Add User to Project',
    done_total_tickets: 'Done / Total tickets',
    action_rate: 'Action Rate',
    first_ticket: 'First Ticket',
    last_ticket: 'Last Ticket',
    event_date: 'Event Date',
    today: 'Today',
    done: 'Done',
    feedback: 'Feedback',
    rejected: 'Rejected',
    sum: 'Total',
    completion_rate: 'Completion rate of these tickets ',
    all_projects: 'All projects',
    planned: 'Planned',
    actual: 'Actual',
    user_group_select: 'User/Group Select',
    table_view: 'Table view',
    order: 'Order',
    status: 'Status',
    _delete: 'Delete',
    assign_select: 'Assign',
    priortiy: 'Priority',
    time_select: 'Time',
    tag_select: 'Tags',
    submit: 'Submit',
    reset: 'Reset',
    no_ticket: 'No tickets found',
    name: 'Name',
    update_error: 'Update Error',
    ticket_delete_success: 'ticket_delete_success',
    details: 'Details',
    comments: 'Comments',
    createdBy: 'Created By',
    remove_tag: 'Remove Tag',
    remove: 'Remove',
    files: 'Files',
    checkitems: 'Check Items',
    add_checkitem: 'Add check item',
    actions: 'Ticket actions',
    delete_ticket: 'Delete Ticket!',
    delete_ticket_1: 'Are you sure you want to delete?',
    delete_ticket_2: 'This action is irreversible!',
    tags: 'Tags',
    not_specified: 'Not specified',
    add_location: 'Add Location',
    edit_location: 'Edit Location',
    delete_location: 'Delete Location',
    _new: 'New',
    old: 'Old',
    similar: 'Similar',
    assignee_percent: 'Atama yüzdesi',
    assignee: 'Atama',
    assistant: 'Assistant',
    change_password_success: 'Change Password Success!',
    change_password: 'Change Password',
    old_password: 'Old Password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    link_file: 'Link File',
    get_info: 'Get Info',
    bar_chart: 'Bar Chart',
    line_chart: 'Line Chart',
    assistant_assign_helper_text:
      'When similar tickets are examined, who has previously been assigned to this ticket and the percentage of percentage are included.',
    assistant_done_rate: 'Timely completion rate',
    assistant_average_rate: 'The average completion time of the ticket',
    assistant_accepted: 'Your application has been received',
    this_ticket_is_critical: 'This Ticket Has Been Marked as Critical',
    what_is_the_risk_about_the_ticket: 'What Are the Risks of This Ticket?',
    share: 'Share',
    copy: 'Copy',
    copied: 'Copied',
    get_ref_code: 'Add Ref Code',
    firstName: 'First Name',
    lastName: 'Last Name',
    template_success:
      'Bu görevin başarılı olması için en kritik faktörler nelerdir?',
    'roadMap.criticalTicketStatus': 'Number of Critical Tickets / Completed',
    criticalTickets: 'Critical Tickets',
    criticalDoneTickets: 'Done Critical Tickets',
    yes: 'Yes',
    no: 'No',
    do_you_want_add_another_information:
      'Enter any additional information related to this task',
    send: 'Send',
    sign_up: 'Sign Up',
    ai_limit_full: 'Your assistant tokens have been exhausted',
    ai_limit_request: 'Please apply to request assistant tokens',
    request_accept: 'Your application has been received',
    request_500_accept:
      'Congratulations 🎉 Your Assistant privileges have been increased because you are among the first 500 people to use the application.',
    projectDescription: 'Project Description',
    mainTask: 'Main Task',
    talkingPoints: 'Talking Points',
    customerName: 'Customer Name',
    emailPurpose: 'Email Purpose',
    productService: 'Product Service',
    companyName: 'Company Name',
    productServicesBenefits: 'Product Services Benefits',
    targetAudience: 'Target Audience',
    topics: 'Topics',
    postingFrequency: 'Posting Frequency',
    topic: 'Topic',
    questions: 'Questions',
    campaignGoals: 'Campaign Goals',
    mainKeyword: 'Main Keyword',
    contentGoal: 'Content Goal',
    secondaryKeyword: 'Secondary Keyword',
    productDescription: 'Product Description',
    feedbackData: 'Feedback Data',
    businessRequirements: 'Business Requirements',
    systemInvolved: 'System Involved',
    bestPractices: 'Best Practices',
    technicalDesign: 'Technical Design',
    outputCategory: 'Output Category',
    testingType: 'Testing Type',
    yourContext: 'Your Context',
    targetUser: 'Target User',
    feature: 'Feature',
    product: 'Product',
    bugReportSource: 'Bug Report Source',
    bugReportData: 'Bug Report Data',
    role: 'Role',
    jobsToBeDone: 'Jobs to be Done',
    attributes: 'Attributes',
    targetUsers: 'Target Users',
    emailToReplyTo: 'Email to Reply To',
    yourTalkingPoints: 'Your Talking Points',
    uniqueSellingPoints: 'Unique Selling Points',
    promotionDetails: 'Promotion Details',
    productServices: 'Product Services',
    reasonsForCanceling: 'Reasons for Canceling',
    CTA: 'Call to Action (CTA)',
    incentive: 'Incentive',
    goal: 'Goal',
    desiredFormat: 'Desired Format',
    systemsInvolved: 'Systems Involved',
    view_as_pdf: 'View as PDF',
    select_ticket_for_linking: 'Select Ticket For Linking',
    file_name: 'File name',
    file_url: 'File URL',
    file_select: 'File Select'
  }

  if (typeof _key === 'string') {
    if (locale === 'tr') {
      return tr[_key]
    }
    return en[_key]
  }

  if (locale === 'tr') {
    return _key?.tr ?? ''
  }

  return _key?.en ?? ''
}
