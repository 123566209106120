import { DeleteOutline, UploadFileOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import { useRef } from 'react'
import { useCreateTicketContext } from '../../contexts/create_ticket'
import { toArray } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import TicketCollapsibleRow from '../ticket_detail/row'

export default function NewTicketFileList() {
  const { files, setFiles } = useCreateTicketContext()

  function deleteFile(index: number) {
    setFiles?.((prev) => prev.filter((_, i) => i !== index))
  }

  return (
    <TicketCollapsibleRow
      title={translate('files')}
      badge={toArray(files).length}
      AddComponent={() => (
        <UploadFileDialog
          onFileAdd={(newFile) =>
            setFiles?.((prev) => [...toArray(files), newFile])
          }
        />
      )}
    >
      <ImageList cols={3} sx={{ width: 550 }}>
        {toArray(files).map((item, index) => (
          <ImageListItem key={index}>
            <img
              src={
                mimeTypeIsImage(item.type)
                  ? `${URL.createObjectURL(item)}`
                  : 'document.png'
              }
              srcSet={
                mimeTypeIsImage(item.type)
                  ? `${URL.createObjectURL(item)}`
                  : 'document.png'
              }
              alt={item.name}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.name}
              actionIcon={
                <DeleteOutline
                  color="error"
                  onClick={() => deleteFile(index)}
                />
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </TicketCollapsibleRow>
  )
}

function UploadFileDialog({ onFileAdd }: { onFileAdd: (file: File) => void }) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null)

  const onClickHandler = () => {
    fileInputRef.current?.click()
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return
    }

    onFileAdd(event.target.files[0])
  }

  return (
    <form ref={formRef}>
      <LoadingButton sx={{ alignSelf: 'center' }} onClick={onClickHandler}>
        {<UploadFileOutlined />}
      </LoadingButton>
      <input
        multiple={false}
        name={'image.jpeg'}
        onChange={onChangeHandler}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />
    </form>
  )
}

function mimeTypeIsImage(mimeType: string): boolean {
  return mimeType.startsWith('image/')
}
