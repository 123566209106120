import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketChangeDescriptionRequest } from '../../core/api_request_responses/ticket.models'
import translate from '../../hooks/use_localization'
import { put } from '../../utils/api'

export default function TicketDescription() {
  const { ticket, changeTicket } = useTicketDetailContext()
  const [text, setText] = useState(ticket?.description)

  useEffect(() => {
    if (ticket) {
      setText(ticket?.description)
    }
  }, [ticket])

  async function change(newValue?: string) {
    if (!ticket || !changeTicket) {
      return
    }
    if (newValue?.trim() === ticket?.description?.trim()) {
      return
    }
    const oldValue = ticket?.description?.trim()
    changeTicket({ ...ticket, description: newValue?.trim() })
    put<ITicketChangeDescriptionRequest>(
      `/ticket/${ticket.id}/change-description`,
      { description: newValue?.trim() }
    ).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({ ...ticket, description: oldValue })
        alert('update_error')
      }
    })
  }

  if (!ticket) {
    return null
  }

  return (
    <TextField
      sx={{ mt: 1 }}
      label={translate('ticket_description')}
      onChange={(event) => setText(event.target.value)}
      onBlur={async (e) => await change(e.target.value)}
      value={text}
      multiline={true}
      fullWidth={true}
      variant="standard"
    />
  )
}
