import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { useTicketsContext } from '../../contexts/tickets'
import { toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { put } from '../../utils/api'
import DialogProvider from '../dialog_provider'
import If from '../if'

export default function DeleteTicket() {
  const { selectedTicketId, handle_click_title, mutate } = useTicketsContext()

  async function deleteTicket(): Promise<string | undefined> {
    const response = await put(`/ticket/${toNumber(selectedTicketId)}/delete`, {
      temp: 'temp'
    })
    await mutate?.()
    if (response?.statusCode === 200) {
      alert('ticket_delete_success')
      handle_click_title?.(undefined)
    }

    return translate(response?.errorCode)
  }

  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const apiError = useRef<string | undefined>()

  function set_close() {
    if (loading) {
      return
    }
    apiError.current = undefined
    set_is_open(false)
  }

  function set_open() {
    if (!selectedTicketId) {
      return
    }

    set_is_open(true)
  }

  async function save() {
    setLoading(true)
    const response = await deleteTicket?.()
    if (!!response) {
      apiError.current = response
    } else {
      setLoading(false)
      set_close()
    }
  }

  if (!selectedTicketId) {
    return <></>
  }

  return (
    <>
      <Button onClick={set_open} color="error">
        {translate('delete_ticket')}
      </Button>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogContent>
          <If condition={!!apiError.current}>
            <Typography>{apiError.current}</Typography>
          </If>
          <Typography>{translate('delete_ticket_1')}</Typography>
          <Typography>{translate('delete_ticket_2')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            loading={loading}
            onClick={save}
            variant={'contained'}
            color="error"
          >
            {translate('delete_ticket')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}
