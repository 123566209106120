import { createContext, useContext, useEffect, useState } from 'react'
import {
  IAIQuestionTemplateRequest,
  IAIQuestionTemplateResponse,
  IAIResponse
} from '../core/api_request_responses/ai.models'
import { ERROR_CODES } from '../core/error_codes'
import { toArray } from '../core/helpers'
import { AIAnswerResponse } from '../core/models/ai_answer.response'
import { File } from '../models'
import {
  AIAnswer,
  createAIAnswerFromAIAnswerResponse
} from '../models/ai_answer.model'
import { createFileFromFileResponse } from '../models/file.model'
import { get, post } from '../utils/api'

const AI_TITLES = [
  {
    title: {
      tr: 'Asistan Ayarları',
      en: 'Assistant settings'
    },
    functions: [
      {
        name: {
          tr: 'Dosya Yönetimi',
          en: 'File Management'
        },
        code: 'settings_file_management'
      }
    ]
  },
  {
    title: {
      tr: 'Proje Yönetimi',
      en: 'Project Management'
    },
    functions: [
      {
        name: {
          tr: 'Kapsam Belgesi Oluşturun',
          en: 'Create Scope Document'
        },
        code: 'project_create_scope_document'
      },
      {
        name: {
          tr: 'Alt Görevler Oluşturun',
          en: 'Create Subtasks'
        },
        code: 'project_create_subtasks'
      }
    ]
  },
  {
    title: {
      tr: 'Satış',
      en: 'Sales'
    },
    functions: [
      {
        name: {
          tr: 'Asansör Konuşması Oluşturun',
          en: 'Create Elevator Pitch'
        },
        code: 'sales_create_elevator_pitch'
      },
      {
        name: {
          tr: 'Müşteri E-postası Yazın',
          en: 'Write Customer Email'
        },
        code: 'sales_write_customer_email'
      }
      // Diğer satış işlevlerini ekleyebilirsiniz
    ]
  },
  {
    title: {
      tr: 'Sosyal Medya Yönetimi',
      en: 'Social Media Management'
    },
    functions: [
      {
        name: {
          tr: 'İçerik Takvimi Oluşturun',
          en: 'Create Content Calendar'
        },
        code: 'social_create_content_calendar'
      },
      {
        name: {
          tr: 'Konu Başlığı Yazın',
          en: 'Write a Thread'
        },
        code: 'social_write_thread'
      }
      // Diğer sosyal medya yönetimi işlevlerini ekleyebilirsiniz
    ]
  },
  {
    title: {
      tr: 'SEO (Arama Motoru Optimizasyonu)',
      en: 'SEO (Search Engine Optimization)'
    },
    functions: [
      {
        name: {
          tr: 'Blog Gönderisi Oluşturun',
          en: 'Create Blog Post'
        },
        code: 'seo_blog_post'
      },
      {
        name: {
          tr: 'FAQ Şeması Oluşturun',
          en: 'Generate FAQ Schema'
        },
        code: 'seo_generate_faq_schema'
      }
      // Diğer SEO işlevlerini ekleyebilirsiniz
    ]
  },
  // {
  //   title: 'AI Document',
  //   functions: [
  //     {
  //       name: 'Summarize A Document',
  //       code: 'document_summarize_a_document'
  //     },
  //     {
  //       name: 'Analyze A Document',
  //       code: 'document_analyze_a_document'
  //     }
  //   ]
  // },
  {
    title: {
      tr: 'Pazarlama',
      en: 'Marketing'
    },
    functions: [
      {
        name: {
          tr: 'Kampanya Fikirleri Oluşturun',
          en: 'Create Campaign Ideas'
        },
        code: 'marketing_create_campaign_ideas'
      },
      {
        name: {
          tr: 'SEO Makale Taslağı Yazın',
          en: 'Write SEO Article Brief'
        },
        code: 'marketing_write_seo_article_brief'
      },
      {
        name: {
          tr: 'Ürün Geri Bildirimini Özetleyin',
          en: 'Summarize Product Feedback'
        },
        code: 'marketing_summarize_product_feedback'
      }
      // Diğer pazarlama işlevlerini ekleyebilirsiniz
    ]
  },
  {
    title: {
      tr: 'Mühendislik',
      en: 'Engineering'
    },
    functions: [
      {
        name: {
          tr: 'Teknik Şartname Belgesi Yazın',
          en: 'Write Technical Specification Document'
        },
        code: 'engineering_write_technical_specification_document'
      },
      {
        name: {
          tr: 'Test Planı Oluşturun',
          en: 'Create Test Plan'
        },
        code: 'engineering_create_test_plan'
      },
      {
        name: {
          tr: 'Hata Raporu Oluşturun',
          en: 'Bug Report'
        },
        code: 'engineering_bug_report'
      }
      // Diğer mühendislik işlevlerini ekleyebilirsiniz
    ]
  },
  {
    title: {
      tr: 'Ürün Yönetimi',
      en: 'Product Management'
    },
    functions: [
      {
        name: {
          tr: 'Kullanıcı Hikayesi Oluşturun',
          en: 'Generate User Story'
        },
        code: 'product_generate_user_story'
      },
      {
        name: {
          tr: 'Pazarlama Planı Oluşturun',
          en: 'Create Go-to-Market Plan'
        },
        code: 'product_create_go_to_market_plan'
      },
      {
        name: {
          tr: 'Hataları Önceliklendirin',
          en: 'Prioritize Bugs'
        },
        code: 'product_prioritize_bugs'
      }
      // Diğer ürün yönetimi işlevlerini ekleyebilirsiniz
    ]
  },
  {
    title: {
      tr: 'Tasarım',
      en: 'Design'
    },
    functions: [
      {
        name: {
          tr: 'Tasarım Persona Oluşturun',
          en: 'Create a Design Persona'
        },
        code: 'design_create_design_persona'
      },
      {
        name: {
          tr: 'Kullanıcı Yolculuğunu Belirleyin',
          en: 'Outline a User Journey'
        },
        code: 'design_outline_a_user_journey'
      }
      // Diğer tasarım işlevlerini ekleyebilirsiniz
    ]
  },
  {
    title: {
      tr: 'E-posta Yönetimi',
      en: 'Email Management'
    },
    functions: [
      {
        name: {
          tr: 'E-posta Yazın',
          en: 'Write an Email'
        },
        code: 'email_write_an_email'
      },
      {
        name: {
          tr: 'E-postaya Yanıt Verin',
          en: 'Reply to Email'
        },
        code: 'email_reply_to_email'
      },
      {
        name: {
          tr: 'Promosyon E-postası Yazın',
          en: 'Write Promotional Email'
        },
        code: 'email_write_promotional_email'
      },
      {
        name: {
          tr: 'Yeniden Etkinleştirme E-postası Yazın',
          en: 'Write Reactivation Email'
        },
        code: 'email_write_reactivation_email'
      },
      {
        name: {
          tr: 'Teşekkür E-postası Yazın',
          en: 'Thank You Email'
        },
        code: 'email_thank_you_email'
      },
      {
        name: {
          tr: 'Terkedilmiş Sepet E-postası Yazın',
          en: 'Write Abandoned Cart Email'
        },
        code: 'email_write_abandoned_cart_email'
      },
      {
        name: {
          tr: 'Hoş Geldiniz E-postası Yazın',
          en: 'Write Welcome Email'
        },
        code: 'email_write_welcome_email'
      },
      {
        name: {
          tr: 'Eğitim Haber Bülteni Yazın',
          en: 'Write Educational Newsletter'
        },
        code: 'email_write_educational_newsletter'
      }
      // Diğer e-posta yönetimi işlevlerini ekleyebilirsiniz
    ]
  },
  {
    title: {
      tr: 'Bilgi Teknolojileri',
      en: 'Information Technology'
    },
    functions: [
      {
        name: {
          tr: 'Test Planı Oluşturun',
          en: 'Create Test Plan'
        },
        code: 'it_create_test_plan'
      },
      {
        name: {
          tr: 'Proje Planı Oluşturun',
          en: 'Create Project Plan'
        },
        code: 'it_create_project_plan'
      }
      // Diğer bilgi teknolojileri işlevlerini ekleyebilirsiniz
    ]
  }
]

interface AIContextProps {
  getAI?: () => Promise<void>
  files?: File[]
  sendQuestionTemplate?: ({
    questionTemplate,
    payload
  }: {
    questionTemplate: string
    payload: Object
  }) => Promise<void>
  AI_TITLES: typeof AI_TITLES
  selectedFunctionCode?: string
  setSelectedFunctionCode?: React.Dispatch<React.SetStateAction<string>>
  isLimited?: boolean
  setIsLimited?: React.Dispatch<React.SetStateAction<boolean>>
  history?: AIAnswer[]
  loading?: boolean
  selectedFiles?: number[]
  setSelectedFiles?: React.Dispatch<React.SetStateAction<number[] | undefined>>
}

const defaultProps: AIContextProps = { AI_TITLES }

export const AIContext = createContext<AIContextProps>(defaultProps)

export function AIContextProvider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}): JSX.Element {
  const [isLimited, setIsLimited] = useState(false)
  const [selectedFunctionCode, setSelectedFunctionCode] =
    useState<string>('select')

  const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState<AIAnswer[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [selectedFiles, setSelectedFiles] = useState<number[] | undefined>(
    undefined
  )

  async function getAI() {
    setLoading(true)
    const response = await get<IAIResponse>('/ai')
    if (response.statusCode === 200 && response?.payload) {
      setHistory(
        toArray(response?.payload?.answers).map(
          createAIAnswerFromAIAnswerResponse
        )
      )
      setFiles(
        toArray(response?.payload?.files).map(createFileFromFileResponse)
      )
      if (!Array.isArray(selectedFiles)) {
        setSelectedFiles(toArray(response?.payload?.files).map(({ id }) => id))
      }
    }
    setLoading(false)
  }

  async function sendQuestionTemplate({
    questionTemplate,
    payload
  }: {
    questionTemplate: string
    payload: Object
  }): Promise<void> {
    setLoading(true)
    const response = await post<
      IAIQuestionTemplateRequest,
      IAIQuestionTemplateResponse
    >('/ai/template', {
      questionTemplate,
      payload: { ...payload, fileIds: toArray(selectedFiles) }
    })
    if (response.statusCode === 200 && !!response?.payload?.answer) {
      setHistory((prev) => [
        ...toArray(prev),
        createAIAnswerFromAIAnswerResponse(
          response?.payload?.answer ?? new AIAnswerResponse()
        )
      ])
      setLoading(false)
    }
    if (
      response.statusCode === 400 &&
      response?.errorCode === ERROR_CODES.AI_LIMIT
    ) {
      setIsLimited(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    getAI()
  }, [])
  return (
    <AIContext.Provider
      value={{
        ...defaultProps,
        getAI,
        setSelectedFunctionCode,
        selectedFunctionCode,
        sendQuestionTemplate,
        isLimited,
        setIsLimited,
        history,
        loading,
        files,
        setSelectedFiles,
        selectedFiles
      }}
    >
      {children}
    </AIContext.Provider>
  )
}

export function useAIContext(): AIContextProps {
  const context = useContext(AIContext)
  if (!context) {
    return defaultProps
  }
  return context
}
