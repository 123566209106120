import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import { Collapse, IconButton, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import Card from './card'
import If from './if'

const cardHeight = 130

export default function CollapsibleRow({
  title,
  badge,
  children,
  isDefaultOpen,
  DeleteComponent,
  AddComponent,
  EditComponent,
  onOpen
}: {
  title: string
  badge?: number
  children: JSX.Element | JSX.Element[]
  isDefaultOpen?: boolean
  DeleteComponent?: () => JSX.Element
  AddComponent?: () => JSX.Element
  EditComponent?: () => JSX.Element
  onOpen?: () => Promise<void> | void
}) {
  const [isOpen, setIsOpen] = useState(!!isDefaultOpen)

  function toggle() {
    if (!isOpen && onOpen) {
      onOpen()
    }
    setIsOpen((prev) => !prev)
  }

  return (
    <>
      <Collapse in={isOpen} collapsedSize={cardHeight}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Box display={'flex'} gap={1} onClick={toggle} flexGrow={1}>
            <Typography variant={'h5'}>{title}</Typography>
            <If condition={badge}>
              <Typography variant={'h5'}>({badge})</Typography>
            </If>
          </Box>
          <Stack direction={'row'} gap={2}>
            {DeleteComponent ? <DeleteComponent /> : null}
            {EditComponent ? <EditComponent /> : null}
            {AddComponent ? <AddComponent /> : null}
            <IconButton onClick={toggle}>
              {!isOpen ? (
                <ExpandMoreOutlined fontSize="large" />
              ) : (
                <ExpandLessOutlined fontSize="large" />
              )}
            </IconButton>
          </Stack>
        </Card>
        <Box sx={{ mt: 1 }}>{children}</Box>
      </Collapse>
    </>
  )
}
