import { CheckItemResponse } from '../core/models/check_item.response'
import { decodeHtml } from '../utils'
import { createUserFromUserResponse, User } from './user.model'

export class CheckItem {
  id: number = 0
  name: string = ''
  isDone: boolean = false
  createdBy: User = new User()
}

export function createCheckItemFromCheckItemResponse(
  response: CheckItemResponse
): CheckItem {
  const instance = new CheckItem()
  instance.id = response.id
  instance.name = decodeHtml(response.name)
  instance.isDone = response.isDone
  instance.createdBy = createUserFromUserResponse(response.createdBy)
  return instance
}
