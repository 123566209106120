import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AISeo() {
  return (
    <>
      <SEOBlogPost />
      <GenerateFAQSchema />
    </>
  )
}

function SEOBlogPost() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    topic: '',
    targetAudience: ''
  })

  const isDisabled = !payload?.topic || !payload.targetAudience || !!loading

  async function send() {
    await sendQuestionTemplate?.({
      questionTemplate: 'seo_blog_post',
      payload
    })

    setPayload({
      topic: '',
      targetAudience: ''
    })
  }

  return (
    <AIFunctionCard code="seo_blog_post" onSend={send} isDisabled={isDisabled}>
      <TextField
        disabled={loading}
        value={payload.topic}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            topic: event.target.value
          }))
        }
        placeholder={translate('topic')}
      />
      <TextField
        disabled={loading}
        multiline
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
    </AIFunctionCard>
  )
}

function GenerateFAQSchema() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    questions: ''
  })

  const isDisabled = !payload?.questions

  async function send() {
    await sendQuestionTemplate?.({
      questionTemplate: 'seo_generate_faq_schema',
      payload
    })

    setPayload({
      questions: ''
    })
  }

  return (
    <AIFunctionCard
      code="seo_generate_faq_schema"
      onSend={send}
      isDisabled={isDisabled}
    >
      <TextField
        disabled={loading}
        multiline
        value={payload.questions}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            questions: event.target.value
          }))
        }
        placeholder={translate('questions')}
      />
    </AIFunctionCard>
  )
}
