import {
  ArrowBack,
  DeleteForeverOutlined,
  UploadFileOutlined
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useRef, useState } from 'react'
import { useAIContext } from '../../contexts/ai'
import { IAIFileUploadResponse } from '../../core/api_request_responses/ai.models'
import { toArray } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { get, postFormData } from '../../utils/api'

export default function AISettings() {
  return (
    <>
      <ManageFiles />
    </>
  )
}

function ManageFiles() {
  const { files, selectedFunctionCode, setSelectedFunctionCode, getAI } =
    useAIContext()
  const [loadingDelete, setLoadingDelete] = useState(0)

  if (selectedFunctionCode !== 'settings_file_management') {
    return <></>
  }

  async function onDelete(fileId: number) {
    setLoadingDelete(fileId)
    await get(`/ai/delete-file/${fileId}`)
    await getAI?.()
    setLoadingDelete(0)
  }

  return (
    <>
      <IconButton onClick={() => setSelectedFunctionCode?.('select')}>
        <ArrowBack />
      </IconButton>
      <br />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('file_name')}</TableCell>
              <TableCell>{translate('file_url')}</TableCell>
              <TableCell>
                <UploadFileButton />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toArray(files).map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.filename}
                </TableCell>
                <TableCell>
                  <Link href={row.url} target={'_blank'}>
                    {row.url}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <LoadingButton
                    onClick={async () => await onDelete(row.id)}
                    loading={row.id === loadingDelete}
                    disabled={!!loadingDelete}
                  >
                    {row.id === loadingDelete ? (
                      <></>
                    ) : (
                      <DeleteForeverOutlined color="error" />
                    )}
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

function UploadFileButton() {
  const { getAI } = useAIContext()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null)
  const [loading, set_loading] = useState(false)

  async function send_file(
    formData: FormData
  ): Promise<IAIFileUploadResponse | undefined> {
    const response = await postFormData('/ai/upload-file', formData)

    if (response.statusCode !== 200) {
      return undefined
    }

    return response?.payload as IAIFileUploadResponse
  }

  const onClickHandler = () => {
    fileInputRef.current?.click()
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length || !send_file || loading) {
      return
    }

    const formData = new FormData()

    Array.from(event.target.files).forEach((file) => {
      formData.append('file', file)
    })

    set_loading(true)
    send_file(formData)
      .then((response) => {
        formRef.current?.reset()
        getAI?.().finally(() => set_loading(false))
      })
      .finally(() => {})
  }

  return (
    <form ref={formRef}>
      <LoadingButton
        sx={{ alignSelf: 'center' }}
        loading={loading}
        onClick={onClickHandler}
      >
        {loading ? <></> : <UploadFileOutlined color="success" />}
      </LoadingButton>
      <input
        multiple={false}
        disabled={loading}
        name={'file.txt'}
        onChange={onChangeHandler}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        accept=".docx, .pdf, .txt, .xlsx, .xls"
      />
    </form>
  )
}
