import { GroupOutlined } from '@mui/icons-material'
import { Avatar, MenuItem, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useMemo } from 'react'
import { toArray, toNumber, toString } from '../core/helpers'
import translate from '../hooks/use_localization'
import { Group, User } from '../models'

export default function AssigneeSelect({
  users,
  groups,
  selectedType,
  selectedValue,
  onListItemClick,
  disabled
}: {
  users: User[]
  groups: Group[]
  selectedValue?: number
  selectedType?: 'group' | 'user'
  onListItemClick: ({
    assignedId,
    assignedType
  }: {
    assignedId: number
    assignedType: 'group' | 'user'
  }) => void
  disabled?: boolean
}) {
  const selected_value = `${toString(selectedType)}_${toString(selectedValue)}`
  const assignList: {
    isGroup: boolean
    url?: string
    id: string
    name: string
  }[] = useMemo(() => {
    const userList = toArray(users).map((user) => ({
      isGroup: false,
      url: user?.profilePicture,
      id: `user_${user.id}`,
      name: user.name
    }))

    const groupList = toArray(groups).map((group) => ({
      isGroup: true,
      id: `group_${group.id}`,
      name: group.name
    }))

    return [...userList, ...groupList]
  }, [groups, users])

  function handleChangeAssigned(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    const assignedType = value.startsWith('group_') ? 'group' : 'user'
    const id = toNumber(value.replace('group_', '').replace('user_', ''))
    const assignee = assignList.find((assign) => assign.id === value)
    if (!assignee) {
      return
    }

    onListItemClick({
      assignedType,
      assignedId: id
    })
  }

  return (
    <TextField
      required={true}
      disabled={disabled}
      fullWidth
      onChange={handleChangeAssigned}
      label={translate('ticket_assignee')}
      value={selected_value}
      select={true}
    >
      {toArray(assignList).map((assign) => (
        <MenuItem key={assign.id} value={assign.id}>
          <Stack direction={'row'} alignItems={'center'}>
            {assign.isGroup ? (
              <GroupOutlined />
            ) : (
              <Avatar
                alt={toString(assign.name)}
                src={toString(assign.url)}
                sx={{ width: 24, height: 24 }}
              />
            )}
            <Typography sx={{ ml: 1 }}>{assign.name}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  )
}
