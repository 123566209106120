import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketChangeEndDateRequest } from '../../core/api_request_responses/ticket.models'
import {
  convertDateStringToISOString,
  convertISO8601StringToDate
} from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { put } from '../../utils/api'
import moment from '../../utils/moment'

export default function TicketEndDate() {
  const { ticket, changeTicket } = useTicketDetailContext()
  const [value, setValue] = useState(
    ticket?.endDate ? moment(ticket?.endDate).format('YYYY-MM-DD') : undefined
  )

  useEffect(() => {
    if (ticket) {
      setValue(
        ticket?.endDate
          ? moment(ticket.endDate).format('YYYY-MM-DD')
          : undefined
      )
    }
  }, [ticket])

  async function change(newValue: string) {
    if (!ticket || !changeTicket) {
      return
    }
    const rawOldValue = ticket?.endDate
    const oldValue = ticket?.endDate
      ? moment(ticket.endDate).format('YYYY-MM-DD')
      : undefined
    const formattedNewValue =
      newValue && newValue.length > 0
        ? convertDateStringToISOString(newValue)
        : undefined

    if (oldValue === newValue) {
      return
    }
    changeTicket({
      ...ticket,
      endDate: formattedNewValue
        ? convertISO8601StringToDate(formattedNewValue)
        : undefined
    })
    put<ITicketChangeEndDateRequest>(`/ticket/${ticket.id}/change-end-date`, {
      endDate: formattedNewValue
    }).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({ ...ticket, endDate: rawOldValue })
        alert('update_error')
      }
    })
  }

  if (!ticket) {
    return null
  }

  return (
    <TextField
      label={translate('end_date')}
      type="date"
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onBlur={async (event) => await change(event.target.value)}
      fullWidth={true}
      InputLabelProps={{
        shrink: true
      }}
    />
  )
}
