import { Paper, PaperProps } from '@mui/material'

export default function Card(props: PaperProps) {
  return (
    <Paper
      sx={{
        p: 3,
        width: '100%',
        mt: 3,
        maxWidth: {
          xs: 285,
          sm: '100%'
        },
        ...props?.sx
      }}
    >
      {props.children}
    </Paper>
  )
}
