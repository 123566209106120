import { useEffect, useState } from 'react'
import useSWR from 'swr'
import {
  ITicketRequest,
  ITicketResponse
} from '../../core/api_request_responses/ticket.models'
import { generateQueryString } from '../../core/helpers'
import {
  Ticket,
  createTicketFromTicketResponse
} from '../../models/ticket.model'

export function useTicket(id: number, props?: ITicketRequest) {
  const { data, mutate } = useSWR<ITicketResponse>(
    generateQueryString(`/ticket/${id}`, { ...props, ticketId: id })
  )
  const [ticket, setTicket] = useState<Ticket>()

  useEffect(() => {
    if (data?.ticket) {
      setTicket(createTicketFromTicketResponse(data.ticket))
    }
  }, [data])

  return {
    ticket,
    changeTicket: setTicket,
    mutate
  }
}
