import { FavoriteBorder, Send } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Button,
  Card,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import Lottie from 'react-lottie'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import {
  ITicketGetAIResponse,
  ITicketPostAIAnswerReaction,
  ITicketPostAIRequest
} from '../../core/api_request_responses/ticket.models'
import { toArray, toNumber } from '../../core/helpers'
import { AITicketQuestionResponse } from '../../core/models/ai_ticket_question.response'
import translate from '../../hooks/use_localization'
import likeData from '../../lotties/67021-love-animation-with-particle.json'
import loadingData from '../../lotties/97930-loading.json'
import workingAnimationData from '../../lotties/lf20_urp7yv2d.json'

import moment from 'moment'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { ERROR_CODES } from '../../core/error_codes'
import {
  AITicketAnswer,
  createAITicketAnswerFromAIAnswerResponse
} from '../../models/ai_ticket_answer.model'
import { get, post } from '../../utils/api'
import AILimitModal from '../ai_limit_modal'

export default function Assistant() {
  const { ticket } = useTicketDetailContext()
  const [response, setResponse] = useState<ITicketGetAIResponse>()
  const [loading, setLoading] = useState(false)
  const selectedTextRef = useRef<undefined | string>()
  const [additionalInfo, setAdditionalInfo] = useState<string>()
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(false)
  const [isLimited, setIsLimited] = useState(false)

  useEffect(() => {
    if (!response) {
      get<ITicketGetAIResponse>(`/ticket/${toNumber(ticket?.id)}/ai`).then(
        (res) => {
          if (res && res.statusCode === 200 && res?.payload) {
            setResponse(res.payload)
          }
        }
      )
    }
  }, [ticket, response])

  async function onSendQuestion(selected: AITicketQuestionResponse) {
    if (loading || !ticket) {
      return
    }

    if (!isAdditionalInfoOpen) {
      selectedTextRef.current = translate(selected)
      setIsAdditionalInfoOpen(true)
      return
    }

    setLoading(true)
    const updateResponse = await post<ITicketPostAIRequest, undefined>(
      `/ticket/${toNumber(ticket?.id)}/ai`,
      {
        questionId: selected.id,
        additionalInfo
      }
    )
    if (updateResponse.statusCode === 200) {
      setIsAdditionalInfoOpen(false)
      setAdditionalInfo(undefined)
      const getResponse = await get<ITicketGetAIResponse>(
        `/ticket/${toNumber(ticket?.id)}/ai`
      )
      if (getResponse.statusCode === 200 && getResponse?.payload) {
        setResponse(getResponse.payload)
      }
    }

    if (
      updateResponse.statusCode === 400 &&
      updateResponse?.errorCode === ERROR_CODES.AI_LIMIT
    ) {
      setIsAdditionalInfoOpen(false)
      setAdditionalInfo(undefined)
      setIsLimited(true)
    }

    setLoading(false)
  }

  if (!ticket || !response) {
    return <WorkingSVG />
  }

  const ticketMarkdownText = `**Ticket ${translate('ticket_title')}**: ${
    ticket?.name
  }.\n\n**${translate('ticket_project')}**: ${
    ticket?.project.name
  }.\n\n**${translate('ticket_layer')}**: ${
    ticket?.layer.name
  }.\n\n**${translate('ticket_start_date')}**: ${
    moment(ticket?.startDate).format('DD-MM-YYYY') ?? translate('not_specified')
  }.\n\n**${translate('ticket_end_date')}**: ${
    moment(ticket?.endDate).format('DD-MM-YYYY') ?? translate('not_specified')
  }.\n\n**${translate('isCritical')}**: ${
    ticket?.priority.code === 'critical' ? translate('yes') : translate('no')
  }.\n\n**${translate('ticket_description')}**: ${
    ticket?.description ? ticket?.description : translate('not_specified')
  }.\n\n${translate('do_you_want_add_another_information')}`

  return (
    <>
      <Box
        sx={{
          maxHeight: '70vh',
          minHeight: '70vh',
          height: '70vh',
          overflow: 'scroll'
        }}
      >
        <ChatList
          answers={toArray(response?.answers).map((answer) =>
            createAITicketAnswerFromAIAnswerResponse(answer)
          )}
          inputText={ticketMarkdownText}
          isAdditionalInfoOpen={isAdditionalInfoOpen}
          loading={loading}
          selectedQuestionText={selectedTextRef.current}
        />
      </Box>
      <InputArea
        questions={response?.questions}
        onSendQuestion={onSendQuestion}
        loading={loading}
        additionalInfo={additionalInfo}
        setAdditionalInfo={setAdditionalInfo}
        isAdditionalInfoOpen={isAdditionalInfoOpen}
      />
      <AILimitModal
        open={isLimited}
        setClose={() => setIsLimited(false)}
        code={'ticket'}
        ticketId={ticket?.id}
      />
    </>
  )
}

function WorkingSVG() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: workingAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return <Lottie options={defaultOptions} height={400} width={400} />
}

function LoadingSVG() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return <Lottie options={defaultOptions} height={50} width={100} />
}

function LikeSVG() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: likeData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return <Lottie options={defaultOptions} height={48} width={48} />
}

function InputArea({
  questions,
  onSendQuestion,
  loading,
  additionalInfo,
  setAdditionalInfo,
  isAdditionalInfoOpen
}: {
  questions?: AITicketQuestionResponse[]
  onSendQuestion: (selected: AITicketQuestionResponse) => void
  loading: boolean
  additionalInfo?: string
  setAdditionalInfo: React.Dispatch<React.SetStateAction<string | undefined>>
  isAdditionalInfoOpen: boolean
}) {
  const [selectedOption, setSelectedOption] = useState<number>()

  function handle_change_value(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setSelectedOption(toNumber(event.target.value))
  }

  function handle_click_send() {
    if (!selectedOption || !questions) {
      return
    }
    const selected = toArray(questions).find(
      (question) => question.id === selectedOption
    )
    selected && onSendQuestion(selected)
  }

  if (isAdditionalInfoOpen) {
    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{
          height: '5vh',
          background: 'white',
          mt: 2
        }}
      >
        <TextField
          disabled={loading || !questions}
          value={additionalInfo}
          onChange={(event) => setAdditionalInfo(event.target.value)}
          fullWidth
          sx={{
            background: 'white',
            textAlign: 'bottom'
          }}
        />
        <LoadingButton onClick={handle_click_send} loading={loading}>
          {loading ? <></> : <Send />}
        </LoadingButton>
      </Stack>
    )
  }

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      sx={{
        height: '5vh',
        background: 'white',
        mt: 2
      }}
    >
      <TextField
        disabled={loading || !questions}
        select={true}
        value={selectedOption}
        onChange={handle_change_value}
        fullWidth
        sx={{
          background: 'white',
          textAlign: 'bottom'
        }}
      >
        {toArray(questions).map((question) => (
          <MenuItem key={question.id} value={question.id}>
            {translate(question)}
          </MenuItem>
        ))}
      </TextField>
      <LoadingButton
        disabled={loading || !questions || !selectedOption}
        onClick={handle_click_send}
        loading={loading}
      >
        {loading ? <></> : <Send />}
      </LoadingButton>
    </Stack>
  )
}

function ChatList({
  answers,
  loading,
  selectedQuestionText,
  inputText,
  isAdditionalInfoOpen
}: {
  answers?: AITicketAnswer[]
  loading: boolean
  selectedQuestionText?: string
  inputText?: string
  isAdditionalInfoOpen: boolean
}) {
  useEffect(() => {
    if (!loading && answers && !isAdditionalInfoOpen) {
      const element = document.getElementById(
        `${Math.max(...toArray(answers).map(({ id }) => id))}`
      )
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }

    if (loading) {
      const element = document.getElementById('loadingCard')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
  }, [answers, loading])

  return (
    <Box sx={{ mt: 2 }}>
      {toArray(answers)
        .sort((a, b) => a.id - b.id)
        .map((answer: AITicketAnswer) => {
          return (
            <Stack key={answer.id} id={`${answer.id}`} gap={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  mt: 2
                }}
              >
                <QuestionCard
                  text={translate(answer.question)}
                  img={answer.createdBy?.profilePicture}
                  time={answer.createdAt?.toLocaleString()}
                />
              </Box>

              <Box>
                <AnswerCard
                  text={translate(answer)}
                  isLiked={answer.liked}
                  like={answer.like}
                  answerId={answer.id}
                  ticketId={answer.ticket.id}
                />
              </Box>
            </Stack>
          )
        })}

      <Box sx={{ py: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse'
          }}
        >
          {isAdditionalInfoOpen && selectedQuestionText ? (
            <LoadingQuestionCard
              text={selectedQuestionText}
              img={localStorage.getItem('selectedQuestionText')}
            />
          ) : (
            <></>
          )}
        </Box>
        {isAdditionalInfoOpen && selectedQuestionText && inputText ? (
          <Card
            sx={{
              maxWidth: '90%',
              background: '#00A67E',
              display: 'flex',
              flexDirection: 'column',
              p: 2,
              mt: 2
            }}
          >
            <Box
              sx={{
                display: 'flex'
              }}
            >
              <Box
                sx={{
                  textAlign: 'left',
                  background: '#00A67E',
                  color: 'white'
                }}
              >
                <ReactMarkdown>{inputText}</ReactMarkdown>
              </Box>
            </Box>
          </Card>
        ) : (
          <></>
        )}

        {loading && selectedQuestionText ? (
          <LoadingQuestionCard
            text={selectedQuestionText}
            img={localStorage.getItem('selectedQuestionText')}
          />
        ) : (
          <></>
        )}
      </Box>
      <Box id="loadingCard">{loading ? <LoadingAnswerCard /> : <></>}</Box>
    </Box>
  )
}

function QuestionCard({
  text,
  img,
  time
}: {
  text: string
  img?: string
  time?: string
}) {
  return (
    <Card
      sx={{
        maxWidth: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            textAlign: 'right',
            maxWidth: '90%'
          }}
        >
          {text}
        </Box>
        <Avatar src={img} sx={{ width: 32, height: 32, ml: 4 }} />
      </Box>
      <Typography variant={'caption'}>{time}</Typography>
    </Card>
  )
}

function AnswerCard({
  text,
  isLiked,
  like,
  ticketId,
  answerId
}: {
  text: string
  isLiked: boolean
  like: number
  ticketId: number
  answerId: number
}) {
  const [isLocaleLiked, setIsLocaleLiked] = useState(isLiked)

  useEffect(() => {
    setIsLocaleLiked(isLiked)
  }, [isLiked])

  async function onClickLikeButton() {
    const isLiked = isLocaleLiked
    const newIsLiked = !isLiked
    setIsLocaleLiked(newIsLiked)
    post<ITicketPostAIAnswerReaction, undefined>(
      `/ticket/${ticketId}/ai/reaction`,
      {
        isLike: newIsLiked,
        answerId
      }
    ).then((response) => {
      if (response.statusCode !== 200) {
        setIsLocaleLiked(isLiked)
      }
    })
  }

  return (
    <Card
      sx={{
        maxWidth: '90%',
        background: '#00A67E',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          minHeight: 64
        }}
      >
        <Box></Box>
        <Button onClick={onClickLikeButton}>
          {isLocaleLiked ? <LikeSVG /> : <FavoriteBorder color="error" />}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            textAlign: 'left',
            background: '#00A67E',
            maxWidth: '90%',
            color: 'white'
          }}
        >
          <ReactMarkdown>{text}</ReactMarkdown>
        </Box>
      </Box>
    </Card>
  )
}

function LoadingQuestionCard({
  text,
  img
}: {
  text: string
  img?: string | null
}) {
  return (
    <Card
      sx={{
        maxWidth: '90%',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            textAlign: 'right',
            background: 'white',
            maxWidth: '90%'
          }}
        >
          {text}
        </Box>
        <Avatar src={img ?? undefined} sx={{ width: 32, height: 32, ml: 4 }} />
      </Box>
      <Typography variant={'caption'}>{new Date().toLocaleString()}</Typography>
    </Card>
  )
}

function LoadingAnswerCard() {
  const color = '#00A67E'
  return (
    <Card
      sx={{
        mt: 2,
        maxWidth: 100,
        background: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <LoadingSVG />
      </Box>
    </Card>
  )
}
