import { AddLinkOutlined, CopyAllOutlined, Done } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketGetReferenceResponse } from '../../core/api_request_responses/ticket.models'
import { generateQueryString, toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { get } from '../../utils/api'

export default function TicketShare() {
  const { ticket } = useTicketDetailContext()
  const [isCopied, setIsCopied] = useState(false)
  const [refCode, setRefCode] = useState<string | undefined>()
  const url = generateQueryString('http://app.theticketapp.co/tickets', {
    refCode,
    ticketId: toNumber(ticket?.id)
  })

  const [isOpen, setIsOpen] = useState(false)

  function setClose() {
    setIsCopied(false)
    setIsOpen(false)
    setRefCode(undefined)
  }

  function setOpen() {
    setIsOpen(true)
  }

  async function copy() {
    if (!ticket || !url) {
      return
    }

    navigator.clipboard.writeText(url)
    setIsCopied(true)
  }

  async function getRefCode() {
    const response = await get<ITicketGetReferenceResponse>(
      `/ticket/${toNumber(ticket?.id)}/reference`
    )
    if (response?.payload && response.statusCode === 200) {
      setRefCode(response.payload.referenceCode)
    }
  }

  if (!ticket) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={setOpen}>
        <AddLinkOutlined />
        <Typography>{translate('share')}</Typography>
      </IconButton>
      <Dialog open={isOpen} onClose={setClose} fullWidth={true}>
        <DialogTitle>{translate('share')}</DialogTitle>
        <DialogContent>
          <Box display={'flex'}>
            <TextField value={url} disabled={true} fullWidth={true} />
            <CopyButton isCopied={isCopied} onClick={copy} />
            <RefCodeButton onClick={getRefCode} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

function CopyButton({
  isCopied,
  onClick
}: {
  isCopied: boolean
  onClick: () => void
}) {
  if (isCopied) {
    return (
      <IconButton color="success" onClick={onClick}>
        <Done />
        <Typography>{translate('copied')}</Typography>
      </IconButton>
    )
  }
  return (
    <IconButton onClick={onClick}>
      <CopyAllOutlined />
      <Typography>{translate('copy')}</Typography>
    </IconButton>
  )
}

function RefCodeButton({ onClick }: { onClick: () => Promise<void> }) {
  const [loading, setLoading] = useState(false)
  async function handleClickButton() {
    setLoading(true)
    await onClick()
    setLoading(false)
  }

  return (
    <LoadingButton onClick={handleClickButton} loading={loading}>
      {translate('get_ref_code')}
    </LoadingButton>
  )
}
