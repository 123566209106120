import { PeopleOutline } from '@mui/icons-material'
import { Avatar, Button, Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import translate from '../hooks/use_localization'
import { Group } from '../models'

export default function GroupCard({ group }: { group: Group }) {
  return (
    <Card
      sx={{
        p: 1,
        width: 280,
        maxWidth: 280,
        minWidth: 280
      }}
    >
      <Button
        sx={{ p: 0, pt: 1, alignItems: 'center' }}
        variant={'text'}
        href={`/group/${group.id}`}
      >
        <Avatar sx={{ width: 32, height: 32, mr: 1 }}>
          <PeopleOutline />
        </Avatar>
        <Typography fontWeight={'bold'}>{group.name}</Typography>
      </Button>

      <Stack
        direction={'row'}
        alignItems={'flex-end'}
        mt={3}
        justifyContent={'space-between'}
      >
        <Typography variant={'caption'}>{translate('group')}</Typography>
        <Button
          variant={'text'}
          sx={{ p: 0 }}
          href={`/profile/${group.manager.id}`}
        >
          <Avatar
            alt={group.manager.name}
            src={group.manager?.profilePicture}
            sx={{ width: 32, height: 32, mr: 1 }}
          />
          <Typography component={'u'}>{group.manager.name}</Typography>
        </Button>
      </Stack>
    </Card>
  )
}
