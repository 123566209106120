import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import translate from '../hooks/use_localization'

export default function ViewSelect({
  view,
  setView
}: {
  view: 'table' | 'bar' | 'pie'
  setView: (view: 'table' | 'bar' | 'pie') => void
}) {
  return (
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      onChange={(e) =>
        setView((e.target as HTMLInputElement)?.value as 'table')
      }
      value={view}
    >
      <FormControlLabel
        value="table"
        control={<Radio />}
        label={translate('list_view')}
      />
      <FormControlLabel
        value="bar"
        control={<Radio />}
        label={translate('bar_chart_view')}
      />
      <FormControlLabel
        value="pie"
        control={<Radio />}
        label={translate('pie_chart_view')}
      />
    </RadioGroup>
  )
}
