import { useMemo, useState } from 'react'

import { Stack, Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import AutoCompleteSelect from '../../../components/auto_complete_select'
import { useStatisticContext } from '../../../contexts/statistic'
import { toArray, toNumber } from '../../../core/helpers'
import translate from '../../../hooks/use_localization'
import { Group, Project, User } from '../../../models'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
)

const week_list_in_year: [Date, Date][] = [] // [start,date, end_date]
const first_date = new Date(new Date().getFullYear(), 0, 1)
for (let i = 1; i <= 52; i++) {
  const start = new Date(first_date)
  const end = new Date(first_date)
  end.setDate(end.getDate() + i * 7 - 1)
  start.setDate(start.getDate() + (i - 1) * 7)
  week_list_in_year.push([start, end])
}

const labels = week_list_in_year.map(
  ([_, end]) => `${end.getDate()}/${end.getMonth() + 1}`
)

export default function Forecast() {
  const { selectedTab, forecast, projects, users, groups } =
    useStatisticContext()

  const [selectedProject, setSelectedProject] = useState<Project>()
  const [isLine, setIsLine] = useState(true)

  const [selectedTarget, setSelectedTarget] = useState<
    { type: 'user' | 'group'; id: number; name: string } | undefined
  >()

  const plannedData: number[] = useMemo(() => {
    if (!forecast) {
      return []
    }
    const labelMap = new Map<string, number>([])

    for (const label of labels) {
      const old = toNumber(labelMap.get(label))
      let total = 0
      for (const user of forecast.users) {
        if (
          selectedTarget &&
          selectedTarget.type !== 'user' &&
          selectedTarget.id !== user.id
        ) {
          continue
        }

        const project = selectedProject
        if (project) {
          total += toNumber(user.activation[project.id][label])
          continue
        }
        const values = Object.values(user.activation)
        for (const value of values) {
          total += value[label]
        }
      }
      for (const group of forecast.groups) {
        if (
          selectedTarget &&
          selectedTarget.type !== 'group' &&
          selectedTarget.id !== group.id
        ) {
          continue
        }
        const project = selectedProject
        if (project) {
          total += toNumber(group.activation[project.id][label])
          continue
        }
        const values = Object.values(group.activation)
        for (const value of values) {
          total += value[label]
        }
      }

      labelMap.set(label, old + total)
    }

    return Array.from(labelMap.values())
  }, [forecast, selectedProject, selectedTarget])

  const actualData: number[] = useMemo(() => {
    if (!forecast) {
      return []
    }
    const labelMap = new Map<string, number>([])

    for (const label of labels) {
      const old = toNumber(labelMap.get(label))
      let total = 0
      for (const user of forecast.users) {
        if (
          selectedTarget &&
          selectedTarget.type !== 'user' &&
          selectedTarget.id !== user.id
        ) {
          continue
        }
        const project = selectedProject
        if (project) {
          total += toNumber(user.realized[project.id][label])
          continue
        }
        const values = Object.values(user.realized)
        for (const value of values) {
          total += value[label]
        }
      }

      for (const group of forecast.groups) {
        if (
          selectedTarget &&
          selectedTarget.type !== 'group' &&
          selectedTarget.id !== group.id
        ) {
          continue
        }
        const project = selectedProject
        if (project) {
          total += toNumber(group.realized[project.id][label])
          continue
        }
        const values = Object.values(group.realized)
        for (const value of values) {
          total += value[label]
        }
      }

      labelMap.set(label, old + total)
    }

    return Array.from(labelMap.values())
  }, [forecast, selectedTarget])

  if (selectedTab !== 'forecast' || !forecast) {
    return <></>
  }

  const data = {
    labels,
    datasets: [
      {
        label: translate('planned'),
        data: plannedData,
        borderColor: 'rgba(211, 211, 211, 1)',
        backgroundColor: 'rgba(211, 211, 211, 0.5)'
      },
      {
        label: translate('actual'),
        data: actualData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', columnGap: 3 }}>
        <Box sx={{ width: '30%' }}>
          <ProjectSelect
            value={selectedProject?.name}
            onChangeProject={(project) => setSelectedProject(project)}
            projects={toArray(projects)}
          />
        </Box>
        <Box sx={{ width: '30%' }}>
          <AssignSelect
            users={toArray(users)}
            groups={toArray(groups)}
            value={selectedTarget?.name}
            onChangeAssign={(value) => setSelectedTarget(value)}
          />
        </Box>
        <Box sx={{ width: '30%', alignSelf: 'flex-end' }}>
          <Stack direction={'row-reverse'} gap={1} alignItems={'center'} p={1}>
            <Typography>{translate('line_chart')}</Typography>
            <Switch
              color={'primary'}
              checked={isLine}
              onChange={() => setIsLine((prev) => !prev)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>{translate('bar_chart')}</Typography>
          </Stack>
        </Box>
      </Box>
      <br />
      {isLine ? <Line data={data} /> : <Bar data={data} />}
    </Box>
  )
}

function AssignSelect({
  value,
  onChangeAssign,
  users,
  groups
}: {
  value?: string
  onChangeAssign: (value?: {
    type: 'user' | 'group'
    id: number
    name: string
  }) => void
  users: User[]
  groups: Group[]
}) {
  return (
    <AutoCompleteSelect
      value={value}
      options={[
        ...toArray(users).map(({ name }) => name),
        ...toArray(groups).map((group) => group.name)
      ]}
      onChange={(selected) => {
        if (!selected) {
          return onChangeAssign(undefined)
        }
        const selectedUser = users.find(({ name }) => name === selected)
        const selectedGroup = groups.find(({ name }) => name === selected)

        if (selectedUser) {
          onChangeAssign({ type: 'user', id: selectedUser.id, name: selected })
        } else if (selectedGroup) {
          onChangeAssign({
            type: 'group',
            id: selectedGroup.id,
            name: selected
          })
        }
      }}
      placeholder={translate('user_group_select')}
    />
  )
}

function ProjectSelect({
  value,
  onChangeProject,
  projects
}: {
  value?: string
  onChangeProject: (value?: Project) => void
  projects: Project[]
}) {
  return (
    <AutoCompleteSelect
      value={value}
      options={toArray(projects).map(({ name }) => name)}
      onChange={(selected) => {
        const selected_project = toArray(projects).find(
          (project) => project.name === selected
        )
        onChangeProject(selected_project)
      }}
      placeholder={translate('project')}
    />
  )
}
