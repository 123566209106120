import { MenuItem, TextField } from '@mui/material'
import { useMemo } from 'react'
import { useTicketsContext } from '../../contexts/tickets'
import { toArray } from '../../core/helpers'
import { assignFilterOptions } from '../../core/models/ticket_filter.response'
import AssigneeSelect from '../assignee_select'
import If from '../if'

export default function FilterAssignSelect() {
  const { ticketFilter, setTicketFilter, translate, tickets, users, groups } =
    useTicketsContext()

  function handleChangeType(assignFilter: assignFilterOptions) {
    setTicketFilter?.((prev) => ({
      ...prev,
      assignFilter
    }))
  }

  const typeOptions: { value: assignFilterOptions; label: string }[] = useMemo(
    () => [
      {
        value: 'tome',
        label: translate?.('assigned_to_me') ?? ''
      },
      {
        value: 'byme',
        label: translate?.('created_by_me') ?? ''
      },
      {
        value: 'assignboth',
        label: translate?.('assign_both') ?? ''
      },
      {
        value: 'custom',
        label: translate?.('custom') ?? ''
      },
      {
        value: 'all',
        label: translate?.('all') ?? ''
      }
    ],
    []
  )

  if (!translate || !tickets) {
    return <></>
  }

  return (
    <>
      <TextField
        select={true}
        fullWidth={true}
        value={ticketFilter?.assignFilter ?? 'all'}
        label={translate('assign_select')}
        onChange={(e) =>
          handleChangeType(e.target.value as assignFilterOptions)
        }
      >
        {typeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <If condition={ticketFilter?.assignFilter === 'custom'}>
        <AssigneeSelect
          users={toArray(users)}
          groups={toArray(groups)}
          selectedType={ticketFilter?.customAssignType}
          selectedValue={ticketFilter?.customAssignId}
          onListItemClick={(item) => {
            setTicketFilter?.((prev) => ({
              ...prev,
              customAssignType: item.assignedType,
              customAssignId: item.assignedId
            }))
          }}
        />
      </If>
    </>
  )
}
