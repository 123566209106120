import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AIIt() {
  return (
    <>
      <CreateTestPlan />
      <CreateProjectPlan />
    </>
  )
}

function CreateTestPlan() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    businessRequirements: '',
    systemsInvolved: '',
    technicalDesign: '',
    outputCategory: '',
    testingType: ''
  })

  const isDisabled =
    !payload.businessRequirements ||
    !payload.systemsInvolved ||
    !payload.technicalDesign ||
    !payload.outputCategory ||
    !payload.testingType ||
    !!loading

  const functionCode = 'it_create_test_plan'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      businessRequirements: '',
      systemsInvolved: '',
      technicalDesign: '',
      outputCategory: '',
      testingType: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.businessRequirements}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            businessRequirements: event.target.value
          }))
        }
        placeholder={translate('businessRequirements')}
      />
      <TextField
        disabled={loading}
        value={payload.systemsInvolved}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            systemsInvolved: event.target.value
          }))
        }
        placeholder={translate('systemsInvolved')}
      />
      <TextField
        disabled={loading}
        value={payload.technicalDesign}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            technicalDesign: event.target.value
          }))
        }
        placeholder={translate('technicalDesign')}
      />
      <TextField
        disabled={loading}
        value={payload.outputCategory}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            outputCategory: event.target.value
          }))
        }
        placeholder={translate('outputCategory')}
      />
      <TextField
        disabled={loading}
        value={payload.testingType}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            testingType: event.target.value
          }))
        }
        placeholder={translate('testingType')}
      />
    </AIFunctionCard>
  )
}

function CreateProjectPlan() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    targetAudience: '',
    topic: '',
    businessRequirements: ''
  })
  const isDisabled =
    !payload.targetAudience ||
    !payload.topic ||
    !payload.businessRequirements ||
    !!loading

  const functionCode = 'it_create_project_plan'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      targetAudience: '',
      topic: '',
      businessRequirements: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
      <TextField
        disabled={loading}
        value={payload.topic}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            topic: event.target.value
          }))
        }
        placeholder={translate('topic')}
      />
      <TextField
        disabled={loading}
        value={payload.businessRequirements}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            businessRequirements: event.target.value
          }))
        }
        placeholder={translate('businessRequirements')}
      />
    </AIFunctionCard>
  )
}
