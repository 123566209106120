// @flow
import { Box } from '@mui/material'
import { useMemo } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { usePersonalAreaContext } from '../../contexts/personal_area'
import { toArray, toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { CheckItem } from '../../models'
import { put } from '../../utils/api'
import Column from './sub_components/column'

export interface ColumnProps {
  id: number
  title: string
  color: string
  index: number
  items: CheckItem[]
  code: string
}

export default function CheckItemKanban() {
  const { checkItems, view, statuses, changeCheckItem } =
    usePersonalAreaContext()
  const columns: ColumnProps[] = useMemo(() => {
    const openStatus = statuses[0]
    const doneStatus = statuses[1]
    const result: ColumnProps[] = [
      {
        id: openStatus.id,
        title: translate(openStatus),
        color: openStatus.color,
        index: 0,
        items: [],
        code: openStatus.code
      },
      {
        id: doneStatus.id,
        title: translate(doneStatus),
        color: doneStatus.color,
        index: 1,
        items: [],
        code: doneStatus.code
      }
    ]

    const openItems: CheckItem[] = []
    const doneItems: CheckItem[] = []

    for (const item of toArray(checkItems)) {
      if (item.isDone) {
        doneItems.push(item)
      } else {
        openItems.push(item)
      }
    }

    result[0].items = openItems
    result[1].items = doneItems

    return result
  }, [checkItems])

  const onDragEnd = (result: DropResult) => {
    // dropped nowhere
    if (!result.destination || result.destination.droppableId === 'board') {
      return
    }

    const source = result.source
    const destination = result.destination

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      return
    }

    const checkItemId = toNumber(result.draggableId)
    const newStatusId = toNumber(destination.droppableId)
    const targetItem: CheckItem | undefined = toArray(checkItems).find(
      ({ id }) => id === checkItemId
    )

    if (!targetItem) {
      return
    }

    const newIsDone = !targetItem.isDone

    changeCheckItem?.({ ...targetItem, isDone: newIsDone })
    put(`/check-item/${targetItem.id}/toggle`, {
      temp: 'temp'
    }).then((response) => {
      if (response.statusCode !== 200) {
        changeCheckItem?.(targetItem)
        alert(translate('update_error'))
      }
    })
  }

  if (view !== 'kanban' || !checkItems) {
    return null
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="board" type="COLUMN" direction="horizontal">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                display: 'inline-flex',
                columnGap: 3
              }}
            >
              {columns.map((column, index) => (
                <Column {...column} key={column.id} index={index} />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}
