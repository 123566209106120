import { useContext } from 'react'
import { TicketCardContext } from '../../../contexts/ticket/card'

export default function useTicketCardContext() {
  const context = useContext(TicketCardContext)
  if (!context) {
    return {}
  }
  return context
}
