import { Divider, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useCreateProjectContext } from '../../contexts/create_project'
import { toArray } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import AssigneeSelect from '../assignee_select'

export default function UserGroupStep() {
  const { activeStep, setPayload, payload, users, groups, loading } =
    useCreateProjectContext()
  if (activeStep !== 3) {
    return null
  }

  return (
    <Box>
      <Stack>
        <AssigneeSelect
          disabled={loading}
          users={toArray(users).filter(
            ({ id }) =>
              !toArray(payload?.users)
                .map(({ userId }) => userId)
                .includes(id)
          )}
          groups={toArray(groups).filter(
            ({ id }) =>
              !toArray(payload?.groups)
                .map(({ groupId }) => groupId)
                .includes(id)
          )}
          onListItemClick={({ assignedId, assignedType }) => {
            if (assignedType === 'user') {
              setPayload?.((prev) => ({
                ...prev,
                users: toArray(prev?.users).concat({ userId: assignedId })
              }))
            } else {
              setPayload?.((prev) => ({
                ...prev,
                groups: toArray(prev?.groups).concat({ groupId: assignedId })
              }))
            }
          }}
        />
      </Stack>
      <Box sx={{ my: 1 }}>
        <Divider />
      </Box>
      <Typography>{translate('user_and_groups')}</Typography>
      <Box sx={{ my: 1 }}>
        <Divider />
      </Box>
      <Stack gap={2}>
        {toArray(payload?.users)
          .map((user) => {
            const targetUser = toArray(users).find(
              ({ id }) => id === user.userId
            )
            if (targetUser) {
              return (
                <Typography key={targetUser.id}>{targetUser.name}</Typography>
              )
            }
            return undefined
          })
          .filter(Boolean)}

        {toArray(payload?.groups)
          .map((group) => {
            const targetGroup = toArray(groups).find(
              ({ id }) => id === group.groupId
            )
            if (targetGroup) {
              return (
                <Typography key={targetGroup.id}>{targetGroup.name}</Typography>
              )
            }
            return undefined
          })
          .filter(Boolean)}
      </Stack>
    </Box>
  )
}
