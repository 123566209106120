import { Box } from '@mui/system'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { toArray } from '../../../core/helpers'
import { CheckItem } from '../../../models'
import { grid } from './column'
import CheckItemContainer from './item'

const scrollContainerHeight = '70vh'

export default function ItemList(props: {
  listId: string
  listType: string
  items: CheckItem[]
}) {
  const { listId, listType, items } = props

  return (
    <Droppable droppableId={listId} type={listType}>
      {(dropProvided) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            userSelect: 'none',
            width: 300
          }}
          {...dropProvided.droppableProps}
        >
          <Box
            sx={{
              overflowX: 'hidden',
              overflowY: 'auto',
              height: scrollContainerHeight
            }}
          >
            <Box
              ref={dropProvided.innerRef}
              sx={{ height: scrollContainerHeight, paddingBottom: `${grid}px` }}
            >
              {toArray(items).map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`${item.id}`}
                  index={index}
                >
                  {(dragProvided, dragSnapshot) => (
                    <CheckItemContainer
                      key={item.id}
                      item={item}
                      index={index}
                      isDragging={dragSnapshot.isDragging}
                      provided={dragProvided}
                    />
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </Box>
          </Box>
        </Box>
      )}
    </Droppable>
  )
}
