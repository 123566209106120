import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import If from '../components/if'
import translate from '../hooks/use_localization'

interface LoginFormProps {
  handle_submit: (email: string) => void
  loading: boolean
  api_message?: {
    error?: boolean
    message?: string
  }
}

interface IFormInput {
  password: string
  password_confirmation: string
}

export default function NewPasswordForm({
  handle_submit,
  loading,
  api_message
}: LoginFormProps) {
  const schema = useMemo(
    () =>
      yup
        .object({
          password: yup.string().required(translate('is_required')),
          password_confirmation: yup
            .string()
            .oneOf(
              [yup.ref('password'), null],
              translate('password_confirmation_not_match')
            )
        })
        .required(),
    [translate]
  )

  const { handleSubmit, control } = useForm<IFormInput>({
    resolver: yupResolver(schema)
  })

  function handle_click_save_password() {
    handleSubmit((form) => handle_submit(form.password))()
  }

  return (
    <>
      <If condition={!!api_message}>
        <Typography color={api_message?.error ? 'error' : 'success'}>
          {api_message?.message}
        </Typography>
      </If>
      <Controller
        name={'password'}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <TextField
            disabled={loading}
            type="password"
            autoComplete="new-password"
            fullWidth
            required
            onChange={onChange}
            value={value}
            label={translate('password')}
            error={!!errors.password}
          />
        )}
      />
      <Controller
        name={'password_confirmation'}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <TextField
            disabled={loading}
            type="password"
            autoComplete="new-password"
            fullWidth
            required
            onChange={onChange}
            value={value}
            label={translate('password_confirmation')}
            error={!!errors.password_confirmation}
          />
        )}
      />

      <LoadingButton
        loading={loading}
        onClick={handle_click_save_password}
        fullWidth
        variant="contained"
      >
        {translate('recovery_password_submit')}
      </LoadingButton>
    </>
  )
}
