import { CircularProgress } from '@mui/material'
import * as React from 'react'
import { createContext, useContext, useEffect } from 'react'
import translate from '../hooks/use_localization'

import {
  IProjectAddGroupRequest,
  IProjectAddLayerRequest,
  IProjectAddUserRequest,
  IProjectUpdateLayerRequest,
  IProjectUpdateRequest
} from '../core/api_request_responses/project.models'
import {
  ITagCreateRequest,
  ITagUpdateRequest
} from '../core/api_request_responses/tag.models'
import useMe from '../hooks/detail_hooks/use_me'
import { useProject } from '../hooks/detail_hooks/use_project'
import useGroups from '../hooks/list_hooks/use_groups'
import { useUsers } from '../hooks/list_hooks/use_users'
import { Group, Project, User } from '../models'
import { post, put } from '../utils/api'

interface ProjectDetailContextProps {
  isManager?: boolean
  project?: Project
  groups?: Group[]
  users?: User[]
  translate: (key: string) => string
  updateProject?: (payload: IProjectUpdateRequest) => Promise<void>
  create_layer?: (payload: IProjectAddLayerRequest) => Promise<boolean>
  update_layer?: (payload: IProjectUpdateLayerRequest) => Promise<boolean>
  add_user_to_project?: (payload: IProjectAddUserRequest) => Promise<void>
  add_group_to_project?: (payload: IProjectAddGroupRequest) => Promise<void>
  sendImage?: (formData: FormData) => Promise<boolean>
  create_tag?: (payload: ITagCreateRequest) => Promise<boolean>
  update_tag?: (tagId: number, payload: ITagUpdateRequest) => Promise<boolean>
}

export const ProjectDetailContext = createContext<ProjectDetailContextProps>({
  translate
})

export function ProjectDetailContextProvider({
  id,
  children
}: {
  id: number
  children: JSX.Element | JSX.Element[]
}) {
  const [isManager, setIsManager] = React.useState(false)
  const { user } = useMe()
  const { project, mutate } = useProject(id, {
    groups: 'true',
    users: 'true',
    tags: 'true'
  })

  const { users } = useUsers()
  const { groups } = useGroups()

  useEffect(() => {
    if (project && user) {
      setIsManager(project.manager.id === user.id)
    }
  }, [project, user])

  async function create_layer(
    payload: IProjectAddLayerRequest
  ): Promise<boolean> {
    if (!payload.name) {
      return false
    }
    const response = await post<IProjectAddLayerRequest, void>(
      `/project/${id}/add-layer`,
      payload
    )
    if (response.statusCode === 200) {
      await mutate()
      return true
    }
    return false
  }

  async function update_layer(
    payload: IProjectUpdateLayerRequest
  ): Promise<boolean> {
    if (!payload.name || !payload.layerId) {
      return false
    }
    const response = await put<IProjectUpdateLayerRequest, void>(
      `/project/${id}/update-layer`,
      payload
    )
    if (response.statusCode === 200) {
      await mutate()
      return true
    }
    return false
  }

  async function create_tag(payload: ITagCreateRequest): Promise<boolean> {
    if (!payload.name || !payload.color) {
      return false
    }
    payload.projectId = id
    const response = await post<ITagCreateRequest, void>('/tag', payload)
    if (response.statusCode === 200) {
      await mutate()
      return true
    }
    return false
  }

  async function update_tag(
    tagId: number,
    payload: ITagUpdateRequest
  ): Promise<boolean> {
    if (!payload.name || !payload.color) {
      return false
    }
    const response = await put<ITagUpdateRequest, void>(
      `/tag/${tagId}`,
      payload
    )
    if (response.statusCode === 200) {
      await mutate()
      return true
    }
    return false
  }

  async function add_user_to_project(
    payload: IProjectAddUserRequest
  ): Promise<void> {
    await post<IProjectAddUserRequest, void>(`/project/${id}/add-user`, payload)
    await mutate()
  }

  async function add_group_to_project(
    payload: IProjectAddGroupRequest
  ): Promise<void> {
    await post<IProjectAddGroupRequest, void>(
      `/project/${id}/add-group`,
      payload
    )
    await mutate()
  }

  async function updateProject(payload: IProjectUpdateRequest): Promise<void> {
    await put<IProjectUpdateRequest, void>(`/project/${id}`, payload)
    await mutate()
  }

  return (
    <ProjectDetailContext.Provider
      value={{
        isManager,
        project,
        groups,
        users,
        translate,
        update_layer,
        create_layer,
        add_user_to_project,
        add_group_to_project,
        updateProject,
        create_tag,
        update_tag
      }}
    >
      {project ? children : <CircularProgress />}
    </ProjectDetailContext.Provider>
  )
}

export function useProjectDetailContext() {
  const context = useContext(ProjectDetailContext)
  if (!context) {
    return { translate }
  }
  return context
}
