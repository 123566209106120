import { Autocomplete, Chip, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useMemo } from 'react'
import { checkedIcon, icon } from '.'
import { useTicketsContext } from '../../contexts/tickets'
import { toArray } from '../../core/helpers'

export default function FilterLayerSelect() {
  const { projects, ticketFilter, setTicketFilter, translate, tickets } =
    useTicketsContext()

  const layer_options: { value: number; label: string }[] = useMemo(() => {
    const result = [
      {
        value: 0,
        label: translate?.('all') ?? ''
      }
    ]

    const target_projects = toArray(projects).filter((p) =>
      ticketFilter?.projectIds.includes(p.id)
    )

    for (const project of target_projects) {
      for (const layer of project.layers) {
        result.push({
          value: layer.id,
          label: `${project.name} / ${layer.name}`
        })
      }
    }

    return result
  }, [ticketFilter, projects])

  function isChecked(value: number): boolean {
    return toArray(ticketFilter?.layerIds).includes(value)
  }

  const selectedOptions = toArray(
    layer_options.filter((option) => isChecked(option.value))
  )

  if (!translate || !tickets) {
    return <></>
  }

  return (
    <Autocomplete
      multiple
      autoComplete={true}
      limitTags={2}
      fullWidth={true}
      disableCloseOnSelect
      options={layer_options}
      getOptionLabel={(option) => option.label}
      size={'small'}
      renderTags={(value, getTagProps) => {
        return value.map((v, index) => (
          <Chip
            variant="outlined"
            label={v.label}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }}
      onChange={(a, b) => {
        if (b) {
          if (
            toArray(ticketFilter?.projectIds).length === 1 &&
            toArray(ticketFilter?.projectIds)[0] === 0
          ) {
            return
          }

          if (b.length === 0) {
            setTicketFilter?.((prev) => ({
              ...prev,
              layerIds: [0]
            }))
            return
          }

          setTicketFilter?.((prev) => ({
            ...prev,
            layerIds: b.map((v) => v.value).filter(Boolean)
          }))
        }
      }}
      value={selectedOptions}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={translate('layer')} fullWidth={true} />
      )}
    />
  )
}
