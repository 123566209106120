import { toArray } from '../core/helpers'
import { UserResponse } from '../core/models/user.response'
import { decodeHtml } from '../utils'
import { createGroupFromGroupResponse, Group } from './group.model'
import { createProjectFromProjectResponse, Project } from './project.model'

export class User {
  id: number = 0
  name: string = ''
  firstName: string = ''
  lastName: string = ''
  email: string = ''
  profilePicture?: string
  groups?: Group[]
  projects?: Project[]
}

export function createUserFromUserResponse(userResponse: UserResponse): User {
  const instance = new User()
  instance.id = userResponse.id
  instance.name = decodeHtml(userResponse.name)
  instance.firstName = decodeHtml(userResponse.firstName)
  instance.lastName = decodeHtml(userResponse.lastName)
  instance.email = userResponse.email
  instance.profilePicture = userResponse?.profilePicture
  instance.groups = toArray(userResponse?.groups).map((group) =>
    createGroupFromGroupResponse(group)
  )
  instance.projects = toArray(userResponse?.projects).map((project) =>
    createProjectFromProjectResponse(project)
  )
  return instance
}
