import useSWR from 'swr'
import { IPriorityListResponse } from '../../core/api_request_responses/priority.models'
import { generateQueryString, toArray } from '../../core/helpers'
import { PriorityResponse } from '../../core/models/priority.response'
import { createPriorityFromPriorityResponse } from '../../models/priority.model'

export default function usePriorities() {
  const { data, mutate } = useSWR<IPriorityListResponse>(
    generateQueryString('/priority'),
    { refreshInterval: 0 }
  )
  return {
    priorities: data?.priorities
      ? toArray(data?.priorities).map((priority: PriorityResponse) =>
          createPriorityFromPriorityResponse(priority)
        )
      : undefined,
    mutate
  }
}
