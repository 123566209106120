import { AccountTreeOutlined } from '@mui/icons-material'
import { Avatar, Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import translate from '../hooks/use_localization'
import { Layer } from '../models'

export default function LayerCard({ layer }: { layer: Layer }) {
  return (
    <Card
      sx={{
        p: 1,
        width: 280,
        maxWidth: 280,
        minWidth: 280
      }}
    >
      <Stack direction={'row'} sx={{ p: 0, pt: 1, alignItems: 'center' }}>
        <Avatar sx={{ width: 32, height: 32, mr: 1 }}>
          <AccountTreeOutlined />
        </Avatar>
        <Typography fontWeight={'bold'}>{layer.name}</Typography>
      </Stack>

      <Stack
        direction={'row'}
        alignItems={'flex-end'}
        mt={3}
        justifyContent={'space-between'}
      >
        <Typography variant={'caption'}>{translate('layer')}</Typography>
      </Stack>
    </Card>
  )
}
