import { convertISO8601StringToDate } from '../core/helpers'
import { TicketActionResponse } from '../core/models/ticket_action.response'
import { decodeHtml } from '../utils'
import { createUserFromUserResponse, User } from './user.model'

export class TicketAction {
  id: number = 0
  createdAt: Date = new Date()
  createdBy: User = new User()
  tr: string = ''
  en: string = ''
}

export function createTicketActionFromTicketActionResponse(
  response: TicketActionResponse
): TicketAction {
  const instance = new TicketAction()
  instance.id = response.id
  instance.createdBy = createUserFromUserResponse(response.createdBy)
  instance.createdAt =
    convertISO8601StringToDate(response.createdAt) ?? new Date()
  instance.tr = decodeHtml(response.tr)
  instance.en = decodeHtml(response.en)
  return instance
}
