import useSWR from 'swr'

import {
  IGroupListRequest,
  IGroupListResponse
} from '../../core/api_request_responses/group.models'
import { generateQueryString, toArray } from '../../core/helpers'
import { GroupResponse } from '../../core/models/group.response'
import { createGroupFromGroupResponse } from '../../models/group.model'

export default function useGroups(props?: IGroupListRequest) {
  const { data, mutate } = useSWR<IGroupListResponse>(
    generateQueryString('/group', props)
  )
  return {
    groups: data?.groups
      ? toArray(data?.groups).map((group: GroupResponse) =>
          createGroupFromGroupResponse(group)
        )
      : undefined,
    mutate
  }
}
