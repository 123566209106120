import * as React from 'react'
import { useEffect, useState } from 'react'

import { AddOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem
} from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'
import Card from '../../components/card'
import CollapsibleRow from '../../components/collapsible_row'
import DialogProvider from '../../components/dialog_provider'
import ProjectCard from '../../components/project_card'
import UserCard from '../../components/user_card'
import {
  GroupDetailContextProvider,
  useGroupDetailContext
} from '../../contexts/group_detail'
import { IGroupUpdateRequest } from '../../core/api_request_responses/group.models'
import { toArray, toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import Layout from '../../layouts/page_layout'
import { Project, User } from '../../models'

export default function GroupDetailPage() {
  const { id } = useParams<{ id: string }>()

  return (
    <GroupDetailContextProvider id={toNumber(id)}>
      <Layout>
        <GroupInfo />
        <GroupProjects />
        <GroupUsers />
      </Layout>
    </GroupDetailContextProvider>
  )
}

function GroupInfo() {
  const { group } = useGroupDetailContext()
  if (!group) {
    return <></>
  }
  return (
    <Card>
      <Typography>
        <b>{translate('group_name')}</b> : {group.name}
      </Typography>
      <br />
      <Typography>
        <b>{translate('manager')}</b> : {group.manager.name}
      </Typography>
      <EditGroupDialog />
    </Card>
  )
}

function GroupProjects() {
  const { group } = useGroupDetailContext()
  if (!group) {
    return <></>
  }

  return (
    <CollapsibleRow
      title={translate('joint_projects')}
      badge={toArray(group.projects).length}
    >
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {toArray(group.projects).map((project: Project) => (
          <ProjectCard project={project} key={project.id} />
        ))}
      </Box>
    </CollapsibleRow>
  )
}

function GroupUsers() {
  const { group } = useGroupDetailContext()
  if (!group) {
    return <></>
  }

  return (
    <CollapsibleRow
      title={translate('users')}
      AddComponent={() => <AddUserDialog />}
      DeleteComponent={() => <DeleteUserDialog />}
      badge={toArray(group?.users).length}
    >
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {toArray(group.users).map((user: User) => (
          <UserCard
            user={user}
            key={user.id}
            additionalText={
              user.id === group.manager.id ? translate('manager') : undefined
            }
          />
        ))}
      </Box>
    </CollapsibleRow>
  )
}

function EditGroupDialog() {
  const { group, isManager, update } = useGroupDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<IGroupUpdateRequest>>()

  useEffect(() => {
    if (group) {
      setPayload({
        managerId: group?.manager.id,
        name: group.name
      })
    }
  }, [group])

  function set_close() {
    if (loading) {
      return
    }
    setPayload(undefined)
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  function handleChangeManager(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      managerId: toNumber(event.target.value)
    })
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      name: event.target.value
    })
  }

  async function save() {
    if (
      !payload?.name ||
      payload.name.trim().length === 0 ||
      !payload.managerId
    ) {
      return
    }
    setLoading(true)
    await update?.(payload as IGroupUpdateRequest)
    set_close()
    setLoading(false)
  }

  if (!group || !isManager) {
    return <></>
  }

  return (
    <>
      <IconButton onClick={set_open}>
        <EditOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('group_edit')}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 3 }}
            fullWidth
            value={payload?.managerId}
            select
            label={translate('manager')}
            onChange={handleChangeManager}
          >
            {toArray(group?.users).map((user: User) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            sx={{ mt: 3 }}
            fullWidth
            value={payload?.name}
            label={translate('group_name')}
            onChange={handleChangeName}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.managerId || !payload?.name}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('save')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function AddUserDialog() {
  const { group, users, addUser, isManager } = useGroupDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<number>(0)

  function set_close() {
    if (loading) {
      return
    }
    setSelectedUserId(0)
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function add_user() {
    setLoading(true)
    await addUser?.(selectedUserId)
    set_close()
    setLoading(false)
  }

  if (!group || !isManager) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={set_open}>
        <AddOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('add_user_to_group')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={selectedUserId}
            select
            onChange={(event) =>
              setSelectedUserId(toNumber(event.target.value))
            }
          >
            {toArray(users).map((user: User) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!selectedUserId}
            loading={loading}
            onClick={add_user}
            variant={'contained'}
          >
            {translate('add')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function DeleteUserDialog() {
  const { group, deleteUser, isManager } = useGroupDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<number>(0)

  function set_close() {
    if (loading) {
      return
    }
    setSelectedUserId(0)
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function delete_user() {
    setLoading(true)
    await deleteUser?.(selectedUserId)
    set_close()
    setLoading(false)
  }

  if (!group || !isManager) {
    return <></>
  }
  const managerId = group.manager.id

  return (
    <>
      <IconButton onClick={set_open}>
        <DeleteOutline color={'error'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('remove_user')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={selectedUserId}
            select
            onChange={(event) =>
              setSelectedUserId(toNumber(event.target.value))
            }
          >
            {toArray(group?.users)
              .filter((user) => user.id !== managerId)
              .map((user: User) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            Kapat
          </Button>
          <LoadingButton
            disabled={!selectedUserId}
            loading={loading}
            onClick={delete_user}
            variant={'contained'}
          >
            Gruptan Çıkar
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}
