import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { toArray, toNumber } from '../core/helpers'
import translate from '../hooks/use_localization'
import { Priority } from '../models'

export default function PrioritySelect({
  priorities,
  value,
  onListItemClick,
  disabled
}: {
  priorities: Priority[]
  value?: number
  onListItemClick: (priorityId: number) => void
  disabled?: boolean
}) {
  const criticalId = toNumber(
    toArray(priorities).find((p) => p.code === 'critical')?.id,
    2
  )
  const normalId = toNumber(
    toArray(priorities).find((p) => p.code === 'normal')?.id,
    1
  )
  const isCritical = value === criticalId

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={isCritical}
            onClick={() => onListItemClick(isCritical ? normalId : criticalId)}
          />
        }
        label={translate('critical')}
      />
    </FormGroup>
  )
}
