import { MenuItem, TextField } from '@mui/material'
import { useMemo } from 'react'
import { useTicketsContext } from '../../contexts/tickets'
import { timeFilterOptions } from '../../core/models/ticket_filter.response'
import If from '../if'

export default function FilterTimeSelect() {
  const { ticketFilter, setTicketFilter, translate, tickets } =
    useTicketsContext()

  function handleChangeType(timeFilter: timeFilterOptions) {
    setTicketFilter?.((prev) => ({
      ...prev,
      timeFilter
    }))
  }

  const typeOptions: { value: timeFilterOptions; label: string }[] = useMemo(
    () => [
      {
        value: 'today',
        label: translate?.('timeline_day') ?? ''
      },
      {
        value: 'week',
        label: translate?.('timeline_week') ?? ''
      },
      {
        value: 'month',
        label: translate?.('timeline_month') ?? ''
      },
      {
        value: 'expired',
        label: translate?.('filters_expired') ?? ''
      },
      {
        value: 'custom',
        label: translate?.('custom') ?? ''
      },
      {
        value: 'all',
        label: translate?.('timeline_all') ?? ''
      }
    ],
    []
  )

  if (!translate || !tickets) {
    return <></>
  }

  return (
    <>
      <TextField
        select={true}
        fullWidth={true}
        value={ticketFilter?.timeFilter ?? 'all'}
        label={translate('time_select')}
        onChange={(e) => handleChangeType(e.target.value as timeFilterOptions)}
      >
        {typeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <If condition={ticketFilter?.timeFilter === 'custom'}>
        <TextField
          label={translate('start_date')}
          type="date"
          value={ticketFilter?.customStartDate}
          onChange={(event) => {
            setTicketFilter?.((prev) => ({
              ...prev,
              customStartDate: event.target.value
            }))
          }}
          fullWidth={true}
          InputLabelProps={{
            shrink: true
          }}
        />
      </If>
      <If condition={ticketFilter?.timeFilter === 'custom'}>
        <TextField
          label={translate('end_date')}
          type="date"
          value={ticketFilter?.customEndDate}
          onChange={(event) => {
            setTicketFilter?.((prev) => ({
              ...prev,
              customEndDate: event.target.value
            }))
          }}
          fullWidth={true}
          InputLabelProps={{
            shrink: true
          }}
        />
      </If>
    </>
  )
}
