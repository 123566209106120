import { TicketFileResponse } from '../core/models/ticket_file.response'
import { decodeHtml } from '../utils'

export class TicketFile {
  id: number = 0
  mimeType: string = ''
  filename: string = ''
  url: string = ''
  isAIAnswer: boolean = false
}

export function createTicketFileFromTicketFileResponse(
  response: TicketFileResponse
): TicketFile {
  const instance = new TicketFile()
  instance.id = response.id
  instance.mimeType = response.mimeType
  instance.filename = decodeHtml(response.filename)
  instance.url = response.url
  instance.isAIAnswer = response.isAIAnswer
  return instance
}
