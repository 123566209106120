import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import { Card, Collapse, IconButton, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import If from '../if'

const cardHeight = 50

export default function TicketCollapsibleRow({
  title,
  badge,
  children,
  isDefaultOpen,
  DeleteComponent,
  AddComponent,
  EditComponent
}: {
  title: string
  badge?: number
  children: JSX.Element | JSX.Element[]
  isDefaultOpen?: boolean
  DeleteComponent?: () => JSX.Element
  AddComponent?: () => JSX.Element
  EditComponent?: () => JSX.Element
}) {
  const [isOpen, setIsOpen] = useState(!!isDefaultOpen)

  function toggle() {
    setIsOpen((prev) => !prev)
  }

  return (
    <Collapse in={isOpen} collapsedSize={cardHeight}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2
        }}
      >
        <Box display={'flex'} gap={1} onClick={toggle} flexGrow={1}>
          <Typography variant="body2" fontWeight={'bold'}>
            {title}
          </Typography>
          <If condition={badge}>
            <Typography variant="body2" fontWeight={'bold'}>
              ({badge})
            </Typography>
          </If>
        </Box>
        <Stack direction={'row'} gap={2}>
          {DeleteComponent ? <DeleteComponent /> : null}
          {EditComponent ? <EditComponent /> : null}
          {AddComponent ? <AddComponent /> : null}
          <IconButton onClick={toggle}>
            {!isOpen ? (
              <ExpandMoreOutlined fontSize="medium" />
            ) : (
              <ExpandLessOutlined fontSize="medium" />
            )}
          </IconButton>
        </Stack>
      </Card>
      <Box sx={{ mt: 2 }}>{children}</Box>
    </Collapse>
  )
}
