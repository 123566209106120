import { LinkRounded, UploadFileOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField
} from '@mui/material'
import { Stack } from '@mui/system'
import { useRef, useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { useTicketsContext } from '../../contexts/tickets'
import {
  ITicketDeleteFileRequest,
  ITicketFileUploadResponse,
  ITicketLinkFileRequest,
  ITicketLinkFileResponse
} from '../../core/api_request_responses/ticket.models'
import { toArray, toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { TicketFile } from '../../models'
import { post, postFormData, put } from '../../utils/api'
import DialogProvider from '../dialog_provider'
import TicketCollapsibleRow from './row'

export default function TicketFileList() {
  const { ticket, changeTicket } = useTicketDetailContext()
  const [selectedItem, setSelectedItem] = useState<TicketFile | undefined>()

  function setClose() {
    setSelectedItem(undefined)
  }

  async function deleteFile(id?: number) {
    if (!id || !changeTicket || !ticket) {
      return
    }
    const oldList = toArray(ticket?.files).slice(0)
    const newList = toArray(ticket?.files).filter((file) => file.id !== id)
    changeTicket({ ...ticket, files: newList })
    put<ITicketDeleteFileRequest>(
      `/ticket/${toNumber(ticket?.id)}/delete-file`,
      { fileId: id }
    ).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({ ...ticket, files: oldList })
        alert('update_error')
      }
    })
    setClose()
  }

  return (
    <TicketCollapsibleRow
      title={translate('files')}
      badge={toArray(ticket?.files).length}
      AddComponent={() => (
        <UploadFileDialog
          onUploadEnd={async (response) => {
            if (response && ticket && changeTicket) {
              const newFile = new TicketFile()
              newFile.id = response.file.id
              newFile.mimeType = response.file.mimeType
              newFile.url = response.file.url
              newFile.filename = response.file.filename

              changeTicket({
                ...ticket,
                files: toArray(ticket?.files).concat(newFile)
              })
            } else {
              alert('update_error')
            }
          }}
        />
      )}
      EditComponent={() => (
        <LinkFileDialog
          onUploadEnd={async (response) => {
            if (response && ticket && changeTicket) {
              const newFile = new TicketFile()
              newFile.id = response.file.id
              newFile.mimeType = response.file.mimeType
              newFile.url = response.file.url
              newFile.filename = response.file.filename

              changeTicket({
                ...ticket,
                files: toArray(ticket?.files).concat(newFile)
              })
            } else {
              alert('update_error')
            }
          }}
        />
      )}
    >
      <ImageList cols={3} sx={{ width: 550 }}>
        {toArray(ticket?.files).map((item) => (
          <ImageListItem key={item.id} onClick={() => setSelectedItem(item)}>
            <img
              src={
                mimeTypeIsImage(item.mimeType)
                  ? `${item.url}`
                  : getFileIcon(item)
              }
              srcSet={
                mimeTypeIsImage(item.mimeType)
                  ? `${item.url}`
                  : getFileIcon(item)
              }
              alt={item.filename}
              loading="lazy"
            />
            <ImageListItemBar title={item.filename} />
          </ImageListItem>
        ))}
      </ImageList>
      <DialogProvider open={!!selectedItem} set_close={setClose}>
        <DialogTitle>{selectedItem?.filename ?? ''}</DialogTitle>
        {mimeTypeIsImage(selectedItem?.mimeType ?? '') ? (
          <img
            src={`${selectedItem?.url ?? ''}`}
            srcSet={`${selectedItem?.url ?? ''}`}
            alt={selectedItem?.filename}
            loading="lazy"
          />
        ) : (
          <Button href={selectedItem?.url ?? ''} target="_blank">
            <img
              src={getFileIcon(selectedItem)}
              srcSet={getFileIcon(selectedItem)}
              alt={selectedItem?.filename}
              loading="lazy"
            />
          </Button>
        )}

        <DialogActions>
          <LoadingButton
            color={'error'}
            onClick={async () => await deleteFile(selectedItem?.id)}
          >
            {translate('_delete')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </TicketCollapsibleRow>
  )
}

function UploadFileDialog({
  onUploadEnd
}: {
  onUploadEnd: (file?: ITicketFileUploadResponse) => Promise<void>
}) {
  const { selectedTicketId } = useTicketsContext()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null)
  const [loading, set_loading] = useState(false)

  async function send_file(
    formData: FormData
  ): Promise<ITicketFileUploadResponse | undefined> {
    const response = await postFormData(
      `/ticket/${toNumber(selectedTicketId)}/upload-file`,
      formData
    )

    if (response.statusCode !== 200) {
      return undefined
    }

    return response?.payload as ITicketFileUploadResponse
  }

  const onClickHandler = () => {
    fileInputRef.current?.click()
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length || !send_file || loading) {
      return
    }

    const formData = new FormData()

    Array.from(event.target.files).forEach((file) => {
      formData.append('file', file)
    })

    set_loading(true)
    send_file(formData)
      .then((response) => {
        formRef.current?.reset()
        onUploadEnd(response).finally(() => set_loading(false))
      })
      .finally(() => {})
  }

  return (
    <form ref={formRef}>
      <LoadingButton
        sx={{ alignSelf: 'center' }}
        loading={loading}
        onClick={onClickHandler}
      >
        {!loading ? <UploadFileOutlined /> : <></>}
      </LoadingButton>
      <input
        multiple={false}
        disabled={loading}
        name={'image.jpeg'}
        onChange={onChangeHandler}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />
    </form>
  )
}

function LinkFileDialog({
  onUploadEnd
}: {
  onUploadEnd: (file?: ITicketLinkFileResponse) => Promise<void>
}) {
  const { selectedTicketId } = useTicketsContext()
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [payload, setPayload] = useState<ITicketLinkFileRequest>({
    url: '',
    mimeType: 'application/pdf',
    filename: ''
  })

  function setClose() {
    if (loading) {
      return
    }
    setPayload({
      url: '',
      mimeType: 'application/pdf',
      filename: ''
    })
    setIsOpen(false)
  }

  function setOpen() {
    setIsOpen(true)
  }

  async function save() {
    setLoading(true)
    const response = await post<
      ITicketLinkFileRequest,
      ITicketLinkFileResponse
    >(`/ticket/${toNumber(selectedTicketId)}/link-file`, payload)
    if (response) {
      await onUploadEnd(response.payload)
    }

    setLoading(false)
  }

  return (
    <>
      <LoadingButton
        sx={{ alignSelf: 'center' }}
        loading={loading}
        onClick={setOpen}
      >
        {!loading ? <LinkRounded /> : <></>}
      </LoadingButton>
      <Dialog onClose={setClose} open={isOpen} fullWidth>
        <DialogTitle>{translate('link_file')}</DialogTitle>
        <DialogContent>
          <Stack rowGap={2} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              type={'url'}
              label={'URL'}
              value={payload.url}
              disabled={loading}
              onChange={(event) =>
                setPayload((prev) => ({ ...prev, url: event.target.value }))
              }
            />
            <TextField
              fullWidth
              label={translate('name')}
              value={payload.filename}
              disabled={loading}
              onChange={(event) =>
                setPayload((prev) => ({
                  ...prev,
                  filename: event.target.value
                }))
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={save} loading={loading} variant="contained">
            {translate('save')}
          </LoadingButton>

          <LoadingButton onClick={setClose} disabled={loading}>
            {translate('close')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

function mimeTypeIsImage(mimeType: string): boolean {
  return mimeType.startsWith('image/')
}

function getFileIcon(file: TicketFile | undefined): string {
  return file?.isAIAnswer ? 'ai_document.png' : 'document.png'
}
