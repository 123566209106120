import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AIDesign() {
  return (
    <>
      <CreateDesignPersona />
      <OutlineUserJourney />
    </>
  )
}

function CreateDesignPersona() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    role: '',
    jobsToBeDone: '',
    attributes: ''
  })
  const isDisabled =
    !payload.role || !payload.jobsToBeDone || !payload.attributes || !!loading

  const functionCode = 'design_create_design_persona'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      role: '',
      jobsToBeDone: '',
      attributes: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.role}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            role: event.target.value
          }))
        }
        placeholder={translate('role')}
      />
      <TextField
        disabled={loading}
        value={payload.jobsToBeDone}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            jobsToBeDone: event.target.value
          }))
        }
        placeholder={translate('jobsToBeDone')}
      />
      <TextField
        disabled={loading}
        value={payload.attributes}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            attributes: event.target.value
          }))
        }
        placeholder={translate('attributes')}
      />
    </AIFunctionCard>
  )
}

function OutlineUserJourney() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    feature: '',
    targetUsers: ''
  })
  const isDisabled = !payload.feature || !payload.targetUsers || !!loading

  const functionCode = 'design_outline_a_user_journey'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      feature: '',
      targetUsers: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.feature}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            feature: event.target.value
          }))
        }
        placeholder={translate('feature')}
      />
      <TextField
        disabled={loading}
        value={payload.targetUsers}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetUsers: event.target.value
          }))
        }
        placeholder={translate('targetUsers')}
      />
    </AIFunctionCard>
  )
}
