import { convertISO8601StringToDate, toArray } from '../core/helpers'
import { GroupResponse } from '../core/models/group.response'
import { TicketResponse } from '../core/models/ticket.response'
import { UserResponse } from '../core/models/user.response'
import { decodeHtml } from '../utils'
import {
  CheckItem,
  createCheckItemFromCheckItemResponse
} from './check_item.model'
import { Comment, createCommentFromCommentResponse } from './comment.model'
import { createGroupFromGroupResponse, Group } from './group.model'
import { createLayerFromLayerResponse, Layer } from './layer.model'
import { createPriorityFromPriorityResponse, Priority } from './priority.model'
import { createProjectFromProjectResponse, Project } from './project.model'
import { createStatusFromStatusResponse, Status } from './status.model'
import { createTagFromTagResponse, Tag } from './tag.model'
import {
  createTicketActionFromTicketActionResponse,
  TicketAction
} from './ticket_action.model'
import {
  createTicketFileFromTicketFileResponse,
  TicketFile
} from './ticket_file.model'
import { createUserFromUserResponse, User } from './user.model'

export class Ticket {
  id: number = 0
  createdBy: User = new User()

  name: string = ''
  status: Status = new Status()
  priority: Priority = new Priority()
  assigned: User | Group = new Group()
  assignedType: 'user' | 'group' = 'group'
  project: Project = new Project()
  layer: Layer = new Layer()
  tags: Tag[] = []
  checkItems: CheckItem[] = []
  description?: string
  startDate?: Date
  endDate?: Date
  long?: number
  lat?: number

  files?: TicketFile[]
  comments?: Comment[]
  actions?: TicketAction[]
}

export function createTicketFromTicketResponse(
  ticketResponse: TicketResponse
): Ticket {
  const instance = new Ticket()
  instance.id = ticketResponse.id
  instance.createdBy = createUserFromUserResponse(ticketResponse.createdBy)
  instance.name = decodeHtml(ticketResponse.name)
  instance.status = createStatusFromStatusResponse(ticketResponse.status)
  instance.priority = createPriorityFromPriorityResponse(
    ticketResponse.priority
  )
  instance.assigned =
    ticketResponse?.assignedType === 'user'
      ? createUserFromUserResponse(ticketResponse.assigned as UserResponse)
      : createGroupFromGroupResponse(ticketResponse.assigned as GroupResponse)
  instance.assignedType = ticketResponse.assignedType
  instance.project = createProjectFromProjectResponse(ticketResponse.project)
  instance.layer = createLayerFromLayerResponse(ticketResponse.layer)
  instance.tags = toArray(ticketResponse?.tags).map((tag) =>
    createTagFromTagResponse(tag)
  )

  instance.description = ticketResponse?.description
    ? decodeHtml(ticketResponse.description)
    : undefined
  instance.startDate = ticketResponse?.startDate
    ? convertISO8601StringToDate(ticketResponse.startDate)
    : undefined
  instance.endDate = ticketResponse?.endDate
    ? convertISO8601StringToDate(ticketResponse.endDate)
    : undefined
  instance.checkItems = ticketResponse.checkItems?.map(
    createCheckItemFromCheckItemResponse
  )
  instance.files = ticketResponse?.files
    ? ticketResponse?.files?.map(createTicketFileFromTicketFileResponse)
    : undefined
  instance.comments = ticketResponse?.comments
    ? ticketResponse?.comments?.map(createCommentFromCommentResponse)
    : undefined
  instance.actions = ticketResponse?.actions
    ? ticketResponse?.actions?.map(createTicketActionFromTicketActionResponse)
    : undefined

  instance.lat = ticketResponse?.lat
  instance.long = ticketResponse?.long
  return instance
}
