import AILimitModal from '../../components/ai_limit_modal'
import { useAIContext } from '../../contexts/ai'

export default function AILimit() {
  const { isLimited, setIsLimited, selectedFunctionCode } = useAIContext()

  return (
    <>
      <AILimitModal
        open={!!isLimited}
        setClose={() => setIsLimited?.(false)}
        code={selectedFunctionCode ?? 'assistant'}
      />
    </>
  )
}
