import { Delete, EmojiEmotionsOutlined, Send } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  CircularProgress,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import EmojiPicker from 'emoji-picker-react'
import { useEffect, useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import {
  ITicketAddCommentRequest,
  ITicketDeleteCommentRequest
} from '../../core/api_request_responses/ticket.models'
import { toArray, toNumber } from '../../core/helpers'
import { CreateResponse } from '../../core/models/api.response'
import useMe from '../../hooks/detail_hooks/use_me'
import { Comment, Ticket, User } from '../../models'
import { post, put } from '../../utils/api'

export default function TicketComment() {
  const { ticket, changeTicket } = useTicketDetailContext()

  const { user } = useMe()

  if (!ticket) {
    return null
  }

  return (
    <>
      <Box
        sx={{
          maxHeight: '70vh',
          minHeight: '70vh',
          height: '70vh',
          overflow: 'scroll'
        }}
      >
        <ChatList
          ticket={ticket}
          user={user}
          onDelete={(commentId: number) => {
            if (!changeTicket || !ticket) {
              return
            }
            const newList = toArray(ticket?.comments).filter(
              ({ id }) => id !== commentId
            )
            changeTicket({ ...ticket, comments: newList })
          }}
        />
      </Box>
      <InputArea
        ticket={ticket}
        user={user}
        onAdd={(comment: Comment) => {
          if (!changeTicket || !ticket) {
            return
          }
          const newList = toArray(ticket?.comments).concat(comment)
          changeTicket({ ...ticket, comments: newList })
        }}
      />
    </>
  )
}

function ChatList({
  ticket,
  user,
  onDelete
}: {
  ticket?: Ticket
  user?: User
  onDelete: (commentId: number) => void
}) {
  const [loading, set_loading] = useState(0)

  useEffect(() => {
    if (ticket?.comments) {
      const element = document.getElementById(
        `${Math.max(...toArray(ticket.comments).map(({ id }) => id))}`
      )
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
  }, [ticket?.comments])

  function handle_click_delete_comment(comment: Comment) {
    return async () => {
      set_loading(comment.id)
      const response = await put<ITicketDeleteCommentRequest, undefined>(
        `/ticket/${toNumber(ticket?.id)}/delete-comment`,
        {
          commentId: comment.id
        }
      )
      if (response.statusCode === 200) {
        onDelete?.(comment.id)
      }

      set_loading(0)
    }
  }

  if (!ticket) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      {toArray(ticket?.comments)
        .sort((a, b) => a.id - b.id)
        .map((comment: Comment) => {
          return (
            <Box key={comment.id} id={`${comment.id}`}>
              <Stack gap={1}>
                <Typography fontWeight={'bold'}>
                  {comment?.createdBy?.name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                    flexDirection: 'space-between'
                  }}
                  alignItems={'center'}
                >
                  <Typography>{sanitizer(comment?.text)}</Typography>
                  {comment?.createdBy.id === user?.id ? (
                    <LoadingButton
                      onClick={handle_click_delete_comment(comment)}
                      loading={loading === comment.id}
                    >
                      {loading === comment.id ? (
                        <></>
                      ) : (
                        <Delete color={'error'} />
                      )}
                    </LoadingButton>
                  ) : (
                    <></>
                  )}
                </Box>
                <Typography variant={'caption'}>
                  {comment.createdAt?.toLocaleString()}
                </Typography>
              </Stack>
              <hr />
            </Box>
          )
        })}
    </Box>
  )
}

function InputArea({
  ticket,
  onAdd,
  user
}: {
  ticket?: Ticket
  onAdd: (comment: Comment) => void
  user?: User
}) {
  const [text, set_text] = useState('')
  const [loading, set_loading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  function handle_change_text(event: React.ChangeEvent<HTMLTextAreaElement>) {
    set_text(event.target.value)
  }

  async function handle_click_add_comment() {
    set_loading(true)
    const response = await post<ITicketAddCommentRequest, CreateResponse>(
      `/ticket/${toNumber(ticket?.id)}/add-comment`,
      {
        text
      }
    )
    if (response.statusCode === 200 && response?.payload?.id && user) {
      const newComment = new Comment()
      newComment.id = response.payload.id
      newComment.createdBy = user
      newComment.text = text
      onAdd(newComment)
    }
    setAnchorEl(null)
    set_loading(false)
    set_text('')
    document.getElementById('commentInput')?.focus({ preventScroll: true })
  }

  function keyPress(e: any) {
    if (e.keyCode === 13 && toNumber(text?.trim()?.length) > 0) {
      void handle_click_add_comment().then()
    }
  }

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      sx={{
        height: '5vh',
        background: 'white'
      }}
    >
      <TextField
        id="commentInput"
        value={sanitizer(text)}
        multiline={true}
        onKeyDown={keyPress}
        onChange={handle_change_text}
        fullWidth
        sx={{
          background: 'white',
          textAlign: 'bottom'
        }}
      />
      <IconButton onClick={handleClick} disabled={loading || !ticket}>
        <EmojiEmotionsOutlined />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <EmojiPicker
          skinTonesDisabled={true}
          onEmojiClick={(emoji) => {
            // const code = `\u{${emoji.unifiedWithoutSkinTone.toUpperCase()}}`
            // const emojiCode = base + emoji.unifiedWithoutSkinTone.toUpperCase() + '}'
            // set_text(
            //   (prev) =>
            //     `${prev} ${String.fromCodePoint(
            //       parseInt(emoji.unifiedWithoutSkinTone, 16)
            //     )}`
            // )
            set_text(
              (prev) =>
                `${prev} U+${emoji.unifiedWithoutSkinTone.toUpperCase()}`
            )
            setAnchorEl(null)
          }}
        />
      </Popover>

      <LoadingButton
        disabled={toNumber(text?.trim()?.length) === 0 || !ticket}
        onClick={handle_click_add_comment}
        loading={loading}
      >
        {loading ? <></> : <Send />}
      </LoadingButton>
    </Stack>
  )
}

function sanitizer(text: string): string {
  return text
    .split(' ')
    .map((current) => {
      if (current.startsWith('U+')) {
        return String.fromCodePoint(parseInt(current.slice(2), 16))
      }
      return current
    })
    .join(' ')
}
