// @flow
import { Box } from '@mui/material'
import { useMemo } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTicketsContext } from '../../contexts/tickets'
import {
  ITicketChangeAssigneeRequest,
  ITicketChangeStatusRequest
} from '../../core/api_request_responses/ticket.models'
import { toArray, toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { Group, Ticket, User } from '../../models'
import { put } from '../../utils/api'
import Column from './sub_components/column'

export interface ColumnProps {
  id: number
  title: string
  color: string
  index: number
  items: Ticket[]
  code: string
  onChangeAssignee: (
    ticket: Ticket,
    newAssignedType: 'user' | 'group',
    newAssigned: User | Group
  ) => void
  handle_click_title?: (ticketId?: number | undefined) => void
}

export default function Kanban() {
  const {
    tickets,
    ticketFilter,
    statuses,
    changeTicket,
    view,
    handle_click_title
  } = useTicketsContext()
  const columns: ColumnProps[] = useMemo(() => {
    const result: ColumnProps[] = []
    const itemMap = new Map<number, Ticket[]>([])

    for (const ticket of toArray(tickets)) {
      const old = toArray(itemMap.get(ticket.status.id))
      itemMap.set(ticket.status.id, old.concat(ticket))
    }

    for (const status of toArray(statuses)) {
      result.push({
        id: status.id,
        title: translate(status),
        color: status.color,
        items: toArray(itemMap.get(status.id)),
        index: toNumber(toArray(statuses).indexOf(status)),
        code: status.code,
        onChangeAssignee(ticket, newAssignedType, newAssigned) {
          if (!changeTicket) {
            return
          }
          const oldAssigned = ticket.assigned
          const oldAssignedType = ticket.assignedType
          changeTicket({
            ...ticket,
            assignedType: newAssignedType,
            assigned: newAssigned
          })
          put<ITicketChangeAssigneeRequest, undefined>(
            `/ticket/${ticket?.id}/change-assignee`,
            {
              assignedType: newAssignedType,
              assignedId: newAssigned.id
            }
          ).then((response) => {
            if (response.statusCode !== 200) {
              changeTicket({
                ...ticket,
                assignedType: oldAssignedType,
                assigned: oldAssigned
              })
              alert('update_error')
            }
          })
        }
      })
    }
    return result
  }, [ticketFilter, tickets, statuses])

  const onDragEnd = (result: DropResult) => {
    // dropped nowhere
    if (!result.destination || result.destination.droppableId === 'board') {
      return
    }

    const source = result.source
    const destination = result.destination

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      return
    }

    const ticketId = toNumber(result.draggableId)
    const newStatusId = toNumber(destination.droppableId)
    const targetTicket: Ticket | undefined = toArray(tickets).find(
      ({ id }) => id === ticketId
    )

    const oldStatus = targetTicket?.status
    const newStatus = toArray(statuses).find(({ id }) => id === newStatusId)
    if (!oldStatus || !newStatus || !targetTicket || !changeTicket) {
      return
    }

    targetTicket.status = newStatus
    changeTicket(targetTicket)
    put<ITicketChangeStatusRequest, undefined>(
      `/ticket/${ticketId}/change-status`,
      {
        statusId: toNumber(newStatus.id)
      }
    ).then((response) => {
      if (response.statusCode !== 200) {
        targetTicket.status = oldStatus
        changeTicket(targetTicket)
        alert(translate('update_error'))
      }
    })
  }

  if (view !== 'kanban') {
    return null
  }

  return (
    <Box sx={{ ml: 3 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="board" type="COLUMN" direction="horizontal">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                minWidth: '100vw',
                display: 'inline-flex',
                columnGap: 3
              }}
            >
              {columns.map((column, index) => (
                <Column
                  {...column}
                  key={column.id}
                  index={index}
                  handle_click_title={handle_click_title}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}
