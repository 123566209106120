import { CircularProgress } from '@mui/material'
import { createContext, useContext } from 'react'
import { useUser } from '../../hooks/detail_hooks/use_user'
import translate from '../../hooks/use_localization'
import { User } from '../../models'

interface ProfileUserContextProps {
  user?: User
  translate?: (key: string) => string
}

export const ProfileUserContext = createContext<ProfileUserContextProps>({
  translate
})

export function ProfileUserContextProvider({
  id,
  children
}: {
  id: number
  children: JSX.Element | JSX.Element[]
}) {
  const { user } = useUser(id, { projects: 'true', groups: 'true' })

  return (
    <ProfileUserContext.Provider
      value={{
        user,
        translate
      }}
    >
      {user ? children : <CircularProgress />}
    </ProfileUserContext.Provider>
  )
}

export function useProfileUserContext() {
  const context = useContext(ProfileUserContext)
  if (!context) {
    return { translate }
  }
  return context
}
