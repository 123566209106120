import { convertISO8601StringToDate, toArray } from '../core/helpers'
import { ProjectResponse } from '../core/models/project.response'
import { decodeHtml } from '../utils'
import { Layer, createLayerFromLayerResponse } from './layer.model'
import { Tag, createTagFromTagResponse } from './tag.model'
import { User, createUserFromUserResponse } from './user.model'

export class Project {
  id: number = 0
  name: string = ''
  manager: User = new User()
  isPrivate: boolean = false
  layers: Layer[] = []
  createdAt: Date = new Date()
  startDate?: Date
  endDate?: Date
  eventStartDate?: Date
  eventEndDate?: Date
  tags?: Tag[]
}

export function createProjectFromProjectResponse(
  projectResponse: ProjectResponse
): Project {
  const instance = new Project()
  instance.id = projectResponse.id
  instance.name = decodeHtml(projectResponse.name)
  instance.manager = createUserFromUserResponse(projectResponse.manager)
  instance.isPrivate = !!projectResponse.isPrivate
  instance.layers = toArray(projectResponse?.layers).map((layer) =>
    createLayerFromLayerResponse(layer)
  )
  instance.createdAt =
    convertISO8601StringToDate(projectResponse.createdAt) ?? new Date()
  instance.startDate = projectResponse?.startDate
    ? convertISO8601StringToDate(projectResponse.startDate)
    : undefined
  instance.endDate = projectResponse?.endDate
    ? convertISO8601StringToDate(projectResponse.endDate)
    : undefined
  instance.eventStartDate = projectResponse?.eventStartDate
    ? convertISO8601StringToDate(projectResponse.eventStartDate)
    : undefined
  instance.eventEndDate = projectResponse?.eventEndDate
    ? convertISO8601StringToDate(projectResponse.eventEndDate)
    : undefined

  instance.tags = toArray(projectResponse?.tags).map((tag) =>
    createTagFromTagResponse(tag)
  )

  return instance
}
