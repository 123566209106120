import { convertISO8601StringToDate } from '../core/helpers'
import { AIAnswerResponse } from '../core/models/ai_answer.response'
import { decodeHtml } from '../utils'

export class AIAnswer {
  id: number = 0
  tr: string = ''
  en: string = ''
  createdAt: Date = new Date()
  functionCode: string = ''
  url: {
    tr: string
    en: string
  } = {
    tr: '',
    en: ''
  }
}

export function createAIAnswerFromAIAnswerResponse(
  response: AIAnswerResponse
): AIAnswer {
  const instance = new AIAnswer()
  instance.id = response.id
  instance.tr = decodeHtml(response.tr)
  instance.en = decodeHtml(response.en)
  instance.createdAt =
    convertISO8601StringToDate(response.createdAt) ?? new Date()
  instance.functionCode = response.functionCode
  instance.url = response.url
  return instance
}
