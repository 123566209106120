export type assignFilterOptions =
  | 'tome'
  | 'byme'
  | 'assignboth'
  | 'custom'
  | 'all'
export type timeFilterOptions =
  | 'today'
  | 'week'
  | 'month'
  | 'expired'
  | 'custom'
  | 'all'
export type statusFilterOptions =
  | 'open'
  | 'inprogress'
  | 'completed'
  | 'feedback'
  | 'rejected'
export type priorityFilterOptions = 'normal' | 'critical'

export const assignFilters: assignFilterOptions[] = [
  'tome',
  'byme',
  'assignboth',
  'custom',
  'all'
]

export const timeFilters: timeFilterOptions[] = [
  'today',
  'week',
  'month',
  'expired',
  'custom',
  'all'
]

export const statusFilters: statusFilterOptions[] = [
  'open',
  'inprogress',
  'completed',
  'feedback',
  'rejected'
]

export const priorityFilters: priorityFilterOptions[] = ['normal', 'critical']

export class TicketFilterResponse {
  projectIds: number[] = [0]
  layerIds: number[] = [0]
  tagIds: number[] = [0]

  assignFilter: assignFilterOptions = 'assignboth'
  customAssignType?: 'group' | 'user'
  customAssignId?: number

  timeFilter: timeFilterOptions = 'all'
  customStartDate?: string
  customEndDate?: string

  statusFilters: statusFilterOptions[] = [
    'open',
    'inprogress',
    'feedback',
    'rejected'
  ]

  priorityFilters: priorityFilterOptions[] = priorityFilters
  page: number = 1
}
