import { Autocomplete, Chip, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useMemo } from 'react'
import { checkedIcon, icon } from '.'
import { useTicketsContext } from '../../contexts/tickets'
import { toArray, toIdMap } from '../../core/helpers'
import { Tag } from '../../models'
import TagCard from '../tag_card'

export default function FilterTagSelect() {
  const { tags, projects, ticketFilter, setTicketFilter, translate, tickets } =
    useTicketsContext()

  const tag_options: { value: number; label: string; color: string }[] =
    useMemo(() => {
      const result = [
        {
          value: 0,
          label: translate?.('all') ?? '',
          color: 'white'
        }
      ]
      if (!projects || !tags) {
        return result
      }

      const selectedProjects = toArray(ticketFilter?.projectIds)

      const projectIdMap = toIdMap(projects)

      if (selectedProjects.length === 1 && selectedProjects[0] === 0) {
        return result.concat(
          toArray(tags).map((tag) => ({
            value: tag.id,
            label: `${tag.name}/${projectIdMap.get(tag.projectId)?.name ?? ''}`,
            color: tag.color
          }))
        )
      }

      return result.concat(
        tags
          .filter(({ projectId }) => selectedProjects.includes(projectId))
          .map((tag) => ({
            value: tag.id,
            label: `${tag.name}/${projectIdMap.get(tag.projectId)?.name ?? ''}`,
            color: tag.color
          }))
      )
    }, [ticketFilter, projects, tags])

  function isChecked(value: number): boolean {
    return toArray(ticketFilter?.tagIds).includes(value)
  }

  const selectedOptions = toArray(
    tag_options.filter((option) => isChecked(option.value))
  )

  if (!translate || !tickets) {
    return <></>
  }

  return (
    <Autocomplete
      multiple
      autoComplete={true}
      limitTags={2}
      fullWidth={true}
      disableCloseOnSelect
      options={tag_options}
      getOptionLabel={(option) => option.label}
      size={'small'}
      renderTags={(value, getTagProps) => {
        return value.map((v, index) => (
          <Chip
            sx={{
              backgroundColor: v.color,
              color: v.value === 0 ? 'inherit' : 'white'
            }}
            variant="outlined"
            label={v.label}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }}
      onChange={(a, b) => {
        if (b) {
          if (b.length === 0) {
            setTicketFilter?.((prev) => ({
              ...prev,
              tagIds: [0]
            }))
            return
          }

          setTicketFilter?.((prev) => ({
            ...prev,
            tagIds: b.map((v) => v.value).filter(Boolean)
          }))
        }
      }}
      value={selectedOptions}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            sx={{
              marginRight: 8
            }}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
          />
          {option.value === 0 ? (
            option.label
          ) : (
            <TagCard
              tag={{ ...new Tag(), name: option.label, color: option.color }}
            />
          )}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={translate('tag_select')}
          fullWidth={true}
        />
      )}
    />
  )
}
