import { Box, Divider, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { useTicketsContext } from '../../contexts/tickets'
import translate from '../../hooks/use_localization'
import TicketActions from './actions'
import TicketAssignee from './assignee'
import TicketAssistant from './assistant'
import TicketCheckList from './check_list'
import TicketComment from './comment'
import TicketCoordinates from './coordiantes'
import TicketCoordinatesAdd from './coordiantes_add'
import DeleteTicket from './delete'
import TicketDescription from './description'
import TicketEndDate from './end_date'
import TicketFileList from './file_list'
import TicketLayer from './layer'
import TicketOwner from './owner'
import TicketPriority from './priorities'
import TicketProject from './project'
import TicketShare from './share_ticket'
import TicketStartDate from './start_date'
import TicketStatus from './status'
import TicketTags from './tags'
import TicketName from './title'

export default function TicketDetail() {
  const { selectedTicketId, handle_click_title } = useTicketsContext()
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  function setClose() {
    handle_click_title?.(undefined)
    setValue(0)
  }
  if (!selectedTicketId) {
    return null
  }

  return (
    <>
      <Box
        onClick={setClose}
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 98,
          left: 0,
          top: 0,
          background: 'black',
          opacity: 0.3
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '800px',
          minHeight: '100%',
          background: 'white',
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,
          maxHeight: '100vh',
          overflow: 'scroll',
          zIndex: 99,
          right: 0,
          top: 0,
          p: 4,
          pt: 12
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab label={translate('details')} sx={{ width: '33.3%' }} />
          <Tab label={translate('comments')} sx={{ width: '33.3%' }} />
          <Tab label={translate('assistant')} sx={{ width: '33.3%' }} />
        </Tabs>
        {value === 0 ? <DetailArea /> : <></>}
        {value === 1 ? <TicketComment /> : <></>}
        {value === 2 ? <TicketAssistant /> : <></>}
      </Box>
    </>
  )
}

function DetailArea() {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flex: 2,
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          mt: 2
        }}
      >
        <TicketCoordinates />
        <Box
          sx={{
            display: 'flex',
            flex: 1
          }}
        >
          <TicketProject />
        </Box>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <TicketLayer />
        </Box>
      </Box>
      <Box sx={{ my: 1 }}>
        <Divider />
      </Box>
      <TicketName />
      <Box sx={{ my: 1 }} />
      <TicketDescription />
      <Box
        sx={{
          display: 'flex',
          flex: 2,
          alignItems: 'center',
          flexWrap: 'wrap',
          my: 2,
          columnGap: 4
        }}
      >
        <Box sx={{ display: 'flex', flex: 1 }}>
          <TicketStatus />
        </Box>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <TicketPriority />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 2,
          alignItems: 'center',
          flexWrap: 'wrap',
          my: 2,
          columnGap: 4,
          rowGap: 2
        }}
      >
        <Box sx={{ display: 'flex', flex: 1 }}>
          <TicketAssignee />
        </Box>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <TicketOwner />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 2,
          alignItems: 'center',
          flexWrap: 'wrap',
          my: 2,
          columnGap: 4,
          rowGap: 2
        }}
      >
        <Box sx={{ display: 'flex', flex: 1 }}>
          <TicketStartDate />
        </Box>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <TicketEndDate />
        </Box>
      </Box>
      <Box sx={{ my: 1 }} />
      <TicketTags />
      <Box sx={{ my: 1 }} />
      <TicketFileList />
      <Box sx={{ my: 1 }} />
      <TicketCheckList />
      <Box sx={{ my: 1 }} />
      <TicketActions />
      <Box sx={{ my: 1 }}>
        <Divider />
      </Box>
      <DeleteTicket />
      <TicketCoordinatesAdd />
      <TicketShare />
    </>
  )
}
