export enum ERROR_CODES {
  RECORD_CANNOT_FIND = 'RECORD_CANNOT_FIND',
  RECORD_CANNOT_CREATE = 'RECORD_CANNOT_CREATE',
  ALL_RECORDS_CANNOT_CREATE = 'ALL_RECORDS_CANNOT_CREATE',
  RECORD_CANNOT_UPDATE = 'RECORD_CANNOT_UPDATE',
  INVALID_PASSWORD_OR_EMAIL = 'INVALID_PASSWORD_OR_EMAIL',
  INVALID_TOKEN = 'INVALID_TOKEN',
  EMAIL_NOT_VALID = 'EMAIL_NOT_VALID',
  PASSWORD_NOT_VALID = 'PASSWORD_NOT_VALID',
  IS_REQUIRED = 'IS_REQUIRED',
  HEX_COLOR_IS_REQUIRED = 'HEX_COLOR_IS_REQUIRED',
  EMAIL_IS_EXIST = 'EMAIL_IS_EXIST',
  FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR',
  FOLDER_CANNOT_CREATE = 'FOLDER_CANNOT_CREATE',
  FILE_CANNOT_UPLOAD = 'FILE_CANNOT_UPLOAD',
  UNAUTHORIZED = 'UNAUTHORIZED',
  URL_NOT_VALID = 'URL_NOT_VALID',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  RECORD_IS_EXIST = 'RECORD_IS_EXIST',
  RECORD_IS_NOT_EXIST = 'RECORD_IS_NOT_EXIST',
  USER_CANNOT_ACCESS_THIS_RECORD = 'USER_CANNOT_ACCESS_THIS_RECORD',
  USER_CANNOT_EDIT_THIS_RECORD = 'USER_CANNOT_EDIT_THIS_RECORD',
  GROUP_ADMIN_CANNOT_DELETE = 'GROUP_ADMIN_CANNOT_DELETE',
  PROJECT_ADMIN_CANNOT_DELETE = 'PROJECT_ADMIN_CANNOT_DELETE',
  DATE_IS_NOT_VALID = 'DATE_IS_NOT_VALID',
  USER_CANNOT_CREATE_THIS_RECORD = 'USER_CANNOT_CREATE_THIS_RECORD',
  THIS_IS_PRIVATE_ARE = 'THIS_IS_PRIVATE_ARE',
  INVALID_BODY = 'INVALID_BODY',
  INVALID_MIME = 'INVALID_MIME',
  INVALID_ASSIGNED_TYPE = 'INVALID_ASSIGNED_TYPE',
  LAYER_CANNOT_FIND = 'LAYER_CANNOT_FIND',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  INVALID_PROJECT_FILE = 'INVALID_PROJECT_FILE',
  PROJECT_FILE_INVALID_LAYER = 'PROJECT_FILE_INVALID_LAYER',
  PROJECT_FILE_INVALID_NAME = 'PROJECT_FILE_INVALID_NAME',
  PROJECT_FILE_INVALID_PRIORITY = 'PROJECT_FILE_INVALID_PRIORITY',
  PROJECT_FILE_INVALID_ASSIGN = 'PROJECT_FILE_INVALID_ASSIGN',
  PROJECT_FILE_INVALID_START_DATE = 'PROJECT_FILE_INVALID_START_DATE',
  PROJECT_FILE_INVALID_END_DATE = 'PROJECT_FILE_INVALID_END_DATE',
  AI_LIMIT = 'AI_LIMIT'
}
