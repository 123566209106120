import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

interface AutoCompleteSelectProps {
  value?: string | null
  options: string[]
  onChange: (value?: string | null) => void
  placeholder: string
}

export default function AutoCompleteSelect({
  options,
  value,
  onChange,
  placeholder
}: AutoCompleteSelectProps) {
  return (
    <Autocomplete
      value={value}
      onChange={(event: any, newValue: string | null) => {
        onChange(newValue)
      }}
      options={options}
      renderInput={(params) => (
        <TextField {...params} fullWidth placeholder={placeholder} />
      )}
    />
  )
}
