export class AIAnswerResponse {
  id: number = 0
  tr: string = ''
  en: string = ''
  createdAt: string = ''
  functionCode: string = ''
  url: {
    tr: string
    en: string
  } = {
    tr: '',
    en: ''
  }
}
