import { Button, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { GoogleLogin } from '@react-oauth/google'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  IPublicLoginRequest,
  IPublicLoginResponse
} from '../../core/api_request_responses/public.models'
import { toNumber } from '../../core/helpers'
import LoginForm from '../../forms/login'
import translate from '../../hooks/use_localization'
import useQuery from '../../hooks/use_query'
import AuthLayout from '../../layouts/auth_layout'
import runOneSignal from '../../onesignal'
import { post } from '../../utils/api'

export default function LoginPage() {
  const navigate = useNavigate()
  const [loading, set_loading] = useState(false)
  const api_error = useRef<string | undefined>()

  const query = useQuery()

  async function handle_submit(email: string, password: string) {
    set_loading(true)
    const response = await post<IPublicLoginRequest, IPublicLoginResponse>(
      '/public/login',
      { email, password, device: 'web' }
    )
    if (response.statusCode === 200 && !!response?.payload) {
      await runOneSignal(response?.payload?.user.id)

      api_error.current = undefined
      set_loading(false)
      window.localStorage.setItem('userId', `${response?.payload?.user.id}`)
      window.localStorage.setItem('name', response?.payload?.user.name)
      window.localStorage.setItem(
        'photoUrl',
        response?.payload?.user?.profilePicture ?? ''
      )
      const ticketId = toNumber(query.get('ticketId'))
      if (ticketId) {
        navigate(`/tickets?ticketId=${ticketId}`)
      } else {
        navigate('/')
      }
      return
    }
    api_error.current = translate(response?.errorCode)
    set_loading(false)
  }

  function handle_click_password_recovery() {
    navigate('/public/recovery-password')
  }
  function handle_click_sign_up() {
    navigate('/public/sign-up-public')
  }

  return (
    <AuthLayout>
      <Stack gap={3}>
        <Typography component="h1" variant="h5">
          {translate('login_sign_in')}
        </Typography>
        <LoginForm
          loading={loading}
          handle_submit={handle_submit}
          api_error={api_error?.current}
        />
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            console.log(credentialResponse)
          }}
          onError={() => {
            console.log('Login Failed')
          }}
        />
        <Button
          disabled={loading}
          onClick={handle_click_password_recovery}
          fullWidth
          variant="outlined"
        >
          {translate('login_password_recovery')}
        </Button>
        <Button
          disabled={loading}
          onClick={handle_click_sign_up}
          fullWidth
          variant="outlined"
        >
          {translate('sign_up')}
        </Button>
      </Stack>
    </AuthLayout>
  )
}
