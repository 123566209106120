import * as React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import { IGroupListResponse } from '../core/api_request_responses/group.models'
import { IUserListResponse } from '../core/api_request_responses/user.models'
import { generateQueryString, toArray, toNumber } from '../core/helpers'
import {
  TicketFilterResponse,
  assignFilterOptions,
  assignFilters,
  priorityFilterOptions,
  priorityFilters,
  statusFilterOptions,
  statusFilters,
  timeFilterOptions,
  timeFilters
} from '../core/models/ticket_filter.response'
import usePriorities from '../hooks/list_hooks/use_priorities'
import { useProjects } from '../hooks/list_hooks/use_projects'
import useStatuses from '../hooks/list_hooks/use_statuses'
import useTags from '../hooks/list_hooks/use_tags'
import { useTickets } from '../hooks/list_hooks/use_tickets'
import translate from '../hooks/use_localization'
import useQuery from '../hooks/use_query'
import { Group, Priority, Project, Status, Tag, Ticket, User } from '../models'
import { createGroupFromGroupResponse } from '../models/group.model'
import { createUserFromUserResponse } from '../models/user.model'

interface TicketsContextProps {
  tickets?: Ticket[]
  statuses?: Status[]
  projects?: Project[]
  priorities?: Priority[]
  ticketFilter?: TicketFilterResponse
  setTicketFilter?: React.Dispatch<React.SetStateAction<TicketFilterResponse>>
  saveFilter?: () => void
  resetFilter?: () => void
  translate: (key?: string) => string
  users?: User[]
  groups?: Group[]
  currentPage?: number
  totalPage?: number
  totalCount?: number
  limit?: number
  changeTicket?: (ticket: Ticket) => void
  view?: 'list' | 'kanban'
  setView?: (view: 'list' | 'kanban') => void
  tags?: Tag[]
  selectedTicketId?: number | undefined
  handle_click_title?: (selectedTicketId?: number) => void
  mutate?: () => Promise<void>
  refCode?: string
}

export const TicketsContext = createContext<TicketsContextProps>({
  translate
})

export function TicketsContextProvider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}): JSX.Element {
  const query = useQuery()
  const [ticketFilter, setTicketFilter] = useState(new TicketFilterResponse())
  const [selectedTicketId, setSelectedTicketId] = useState<number | undefined>()
  const [refCode, setRefCode] = useState<string | undefined>()
  const [view, setView] = useState<'list' | 'kanban'>('list')
  const {
    tickets,
    setFilter,
    currentPage,
    totalPage,
    totalCount,
    limit,
    changeTicket
  } = useTickets(ticketFilter)

  const { statuses } = useStatuses()
  const { projects } = useProjects()
  const { priorities } = usePriorities()
  const navigate = useNavigate()

  const { data: usersData } = useSWR<IUserListResponse>('/user', {
    refreshInterval: 0
  })
  const { data: groupsData } = useSWR<IGroupListResponse>('/group', {
    refreshInterval: 0
  })

  const { tags } = useTags()

  useEffect(() => {
    const filter = new TicketFilterResponse()
    const selectedProjects = query.getAll('projectIds')
    const selectedLayers = query.getAll('layerIds')
    const selectedTags = query.getAll('tagIds')
    const view = query.get('view') as string
    filter.page = toNumber(query.get('page') as string | undefined, 1)

    if (selectedProjects.length > 0) {
      filter.projectIds = selectedProjects.map((id) => toNumber(id))
    }

    if (selectedLayers.length > 0) {
      filter.layerIds = selectedLayers.map((id) => toNumber(id))
    }

    if (selectedLayers.length > 0) {
      filter.tagIds = selectedTags.map((id) => toNumber(id))
    }

    const assignFilter = query.get('assignFilter')
    if (assignFilters.includes(assignFilter as assignFilterOptions)) {
      filter.assignFilter = assignFilter as assignFilterOptions
    }

    const timeFilter = query.get('timeFilter')
    if (timeFilters.includes(timeFilter as timeFilterOptions)) {
      filter.timeFilter = timeFilter as timeFilterOptions
    }

    const selectedStatuses = query
      .getAll('statusFilters')
      .filter((value) => statusFilters.includes(value as statusFilterOptions))
    if (selectedStatuses.length > 0) {
      filter.statusFilters = (selectedStatuses as statusFilterOptions[]).map(
        (value) => value
      )
    }

    const selectedPriorities = query
      .getAll('priorityFilters')
      .filter((value) =>
        priorityFilters.includes(value as priorityFilterOptions)
      )

    if (selectedPriorities.length > 0) {
      filter.priorityFilters = (
        selectedPriorities as priorityFilterOptions[]
      ).map((value) => value)
    }

    if (filter.assignFilter === 'custom') {
      const assignType = query.get('customAssignType')
      if (assignType === 'group' || assignType === 'user') {
        filter.customAssignType = assignType
      }
      const assignId = query.get('customAssignId')
      if (!!assignId) {
        filter.customAssignId = toNumber(assignId)
      }
    }

    if (filter.timeFilter === 'custom') {
      filter.customStartDate = query.get('customStartDate') ?? undefined
      filter.customEndDate = query.get('customEndDate') ?? undefined
    }

    setTicketFilter(filter)
    setFilter(filter)

    if (['kanban', 'list'].includes(view)) {
      setView(view as 'kanban' | 'list')
    } else {
      view !== 'list' && setView('list')
    }
  }, [query])

  useEffect(() => {
    const targetTicketId = toNumber(query.get('ticketId') as string)
    const refCode = query.get('refCode') as string

    if (targetTicketId) {
      setSelectedTicketId(targetTicketId)
      refCode && setRefCode(refCode)
    }
  }, [query, tickets])

  function saveFilter(): void {
    const newUrl = generateQueryString('/tickets', { ...ticketFilter, view })
    if (newUrl) {
      navigate(newUrl)
    }
  }

  function resetFilter(): void {
    navigate('/tickets')
  }

  function handle_click_title(selectedTicketId?: number) {
    setRefCode(undefined)
    setSelectedTicketId(selectedTicketId)
  }

  return (
    <TicketsContext.Provider
      value={{
        tickets,
        statuses,
        projects,
        priorities,
        ticketFilter,
        setTicketFilter,
        translate,
        resetFilter,
        saveFilter,
        currentPage,
        totalPage,
        totalCount,
        limit,
        changeTicket,
        view,
        setView,
        tags,
        selectedTicketId,
        refCode,
        handle_click_title,
        users: usersData
          ? toArray(usersData?.users).map((u) => createUserFromUserResponse(u))
          : undefined,
        groups: groupsData
          ? toArray(groupsData?.groups).map((g) =>
              createGroupFromGroupResponse(g)
            )
          : undefined
      }}
    >
      {children}
    </TicketsContext.Provider>
  )
}

export function useTicketsContext(): TicketsContextProps {
  const context = useContext(TicketsContext)
  if (!context) {
    return {
      translate
    }
  }
  return context
}
