import { ArticleOutlined } from '@mui/icons-material'
import { Avatar, Button, Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import translate from '../hooks/use_localization'
import { Project } from '../models'

export default function ProjectCard({ project }: { project: Project }) {
  return (
    <Card
      sx={{
        p: 1,
        width: 280,
        maxWidth: 280,
        minWidth: 280
      }}
    >
      <Button
        sx={{ p: 0, pt: 1, alignItems: 'center' }}
        variant={'text'}
        href={`/project/${project.id}`}
      >
        <Avatar sx={{ width: 32, height: 32, mr: 1 }}>
          <ArticleOutlined />
        </Avatar>
        <Typography fontWeight={'bold'}>{project.name}</Typography>
      </Button>
      <Stack
        direction={'row'}
        alignItems={'flex-end'}
        mt={3}
        justifyContent={'space-between'}
      >
        <Typography variant={'caption'}>{translate('project')}</Typography>
        <Button
          variant={'text'}
          sx={{ p: 0 }}
          href={`/profile/${project.manager.id}`}
        >
          <Avatar
            alt={project.manager.name}
            src={project.manager?.profilePicture}
            sx={{ width: 32, height: 32, mr: 1 }}
          />
          <Typography component={'u'}>{project.manager.name}</Typography>
        </Button>
      </Stack>
    </Card>
  )
}
