import { toArray } from '../core/helpers'
import { GroupResponse } from '../core/models/group.response'
import { decodeHtml } from '../utils'
import { createProjectFromProjectResponse, Project } from './project.model'
import { createUserFromUserResponse, User } from './user.model'

export class Group {
  id: number = 0
  name: string = ''
  manager: User = new User()
  users?: User[]
  projects?: Project[]
}

export function createGroupFromGroupResponse(
  groupResponse: GroupResponse
): Group {
  const instance = new Group()
  instance.id = groupResponse.id
  instance.name = decodeHtml(groupResponse.name)
  instance.manager = createUserFromUserResponse(groupResponse.manager)
  instance.users = toArray(groupResponse?.users).map((user) =>
    createUserFromUserResponse(user)
  )
  instance.projects = toArray(groupResponse?.projects).map((project) =>
    createProjectFromProjectResponse(project)
  )
  return instance
}
