import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import If from '../components/if'
import translate from '../hooks/use_localization'

interface LoginFormProps {
  handle_submit: (email: string, password: string) => void
  loading: boolean
  api_error?: string
}

interface IFormInput {
  email: string
  password: string
}

export default function LoginForm({
  handle_submit,
  loading,
  api_error
}: LoginFormProps) {
  const schema = useMemo(
    () =>
      yup
        .object({
          email: yup
            .string()
            .email(translate('invalid_email'))
            .required(translate('is_required')),
          password: yup.string().required(translate('is_required'))
        })
        .required(),
    [translate]
  )

  const { handleSubmit, control } = useForm<IFormInput>({
    resolver: yupResolver(schema)
  })

  function handle_click_login() {
    handleSubmit((form) => handle_submit(form.email, form.password))()
  }

  return (
    <>
      <If condition={!!api_error}>
        <Typography color={'error'}>{api_error}</Typography>
      </If>
      <Controller
        name={'email'}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <TextField
            disabled={loading}
            fullWidth
            required
            onChange={onChange}
            value={value}
            label={translate('login_email')}
            error={!!errors.email}
          />
        )}
      />
      <Controller
        name={'password'}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <TextField
            disabled={loading}
            fullWidth
            required
            onSubmit={handle_click_login}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handle_click_login()
              }
            }}
            autoComplete="current-password"
            type="password"
            onChange={onChange}
            value={value}
            label={translate('login_password')}
            error={!!errors.password}
          />
        )}
      />

      <LoadingButton
        loading={loading}
        onClick={handle_click_login}
        fullWidth
        variant="contained"
      >
        {translate('login_sign_in_button')}
      </LoadingButton>
    </>
  )
}
