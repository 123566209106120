import { createContext, useContext } from 'react'
import { useTicket } from '../hooks/detail_hooks/use_ticket'
import { Ticket } from '../models'

interface TicketDetailContextProps {
  onClose?: () => void
  ticket?: Ticket
  changeTicket?: (ticket: Ticket) => void
}

export const TicketDetailContext = createContext<TicketDetailContextProps>({})

export function TicketDetailContextProvider({
  children,
  ticketId,
  onClose,
  onChangeTicket,
  refCode
}: {
  children: JSX.Element | JSX.Element[]
  ticketId: number
  onClose: () => void
  onChangeTicket: (ticket: Ticket) => void
  refCode?: string
}): JSX.Element {
  const { ticket, changeTicket: changeTicketState } = useTicket(ticketId, {
    checkItems: 'true',
    files: 'true',
    comments: 'true',
    actions: 'true',
    refCode
  })

  function changeTicket(ticket: Ticket) {
    onChangeTicket(ticket)
    changeTicketState(ticket)
  }

  return (
    <TicketDetailContext.Provider
      value={{
        ticket,
        changeTicket,
        onClose
      }}
    >
      {children}
    </TicketDetailContext.Provider>
  )
}

export function useTicketDetailContext(): TicketDetailContextProps {
  const context = useContext(TicketDetailContext)
  if (!context) {
    return {}
  }
  return context
}
