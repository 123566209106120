import { StatisticContextProvider } from '../../contexts/statistic'
import Forecast from './tabs/forecast'
import GroupStatuses from './tabs/group'
import IndividualStatuses from './tabs/individual'
import ProjectStatuses from './tabs/project'

import RoadMap from './tabs/roadmap'

export default function StatisticPage() {
  return (
    <StatisticContextProvider>
      <RoadMap />
      <ProjectStatuses />
      <IndividualStatuses />
      <GroupStatuses />
      <Forecast />
    </StatisticContextProvider>
  )
}
