import { AddOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepLabel,
  Stepper
} from '@mui/material'
import { Box } from '@mui/system'
import {
  CreateProjectContextProvider,
  useCreateProjectContext
} from '../../contexts/create_project'
import { toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import LayerStep from './layer_step'
import ProjectStep from './project_step'
import UserGroupStep from './user_group_step'

export default function CreateProjectDialog({
  mutate
}: {
  mutate: () => Promise<void>
}) {
  return (
    <CreateProjectContextProvider mutate={mutate}>
      <AddButton />
      <Content />
    </CreateProjectContextProvider>
  )
}

const steps = [
  translate('project_detail'),
  translate('layers'),
  translate('user_and_groups')
]

function AddButton() {
  const { setOpen } = useCreateProjectContext()

  return (
    <IconButton onClick={setOpen}>
      <AddOutlined color={'success'} />
    </IconButton>
  )
}

function Content() {
  const { isOpen } = useCreateProjectContext()

  return (
    <Dialog open={!!isOpen} onClose={() => {}}>
      <DialogTitle>{translate('create_project')}</DialogTitle>
      <DialogContent>
        <CustomStepper />
        <br />

        <ProjectStep />
        <LayerStep />
        <UserGroupStep />
      </DialogContent>
      <DialogActions>
        <Actions />
      </DialogActions>
    </Dialog>
  )
}

function CustomStepper() {
  const { activeStep } = useCreateProjectContext()

  return (
    <Stepper activeStep={toNumber(activeStep, 1) - 1}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {}
        const labelProps: {
          optional?: React.ReactNode
        } = {}

        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

function Actions() {
  const { activeStep, back, next, setClose, loading } =
    useCreateProjectContext()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <Button
        disabled={loading}
        onClick={activeStep === 1 ? setClose : back}
        variant="outlined"
      >
        {activeStep === 1 ? translate('close') : translate('back')}
      </Button>
      <LoadingButton onClick={next} variant="contained" loading={loading}>
        {activeStep === 3 ? translate('create') : translate('next')}
      </LoadingButton>
    </Box>
  )
}
