import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AIEmail() {
  return (
    <>
      <WriteEmail />
      <ReplyToEmail />
      <WritePromotionalEmail />
      <WriteReactivationEmail />
      <ThankYouEmail />
      <WriteAbandonedCartEmail />
      <WriteWelcomeEmail />
      <WriteEducationalNewsletter />
    </>
  )
}

function WriteEmail() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    talkingPoints: ''
  })
  const isDisabled = !payload.talkingPoints || !!loading

  const functionCode = 'email_write_an_email'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      talkingPoints: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.talkingPoints}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            talkingPoints: event.target.value
          }))
        }
        placeholder={translate('talkingPoints')}
      />
    </AIFunctionCard>
  )
}

function ReplyToEmail() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    emailToReplyTo: '',
    yourTalkingPoints: ''
  })
  const isDisabled =
    !payload.emailToReplyTo || !payload.yourTalkingPoints || !!loading

  const functionCode = 'email_reply_to_email'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      emailToReplyTo: '',
      yourTalkingPoints: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.emailToReplyTo}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            emailToReplyTo: event.target.value
          }))
        }
        placeholder={translate('emailToReplyTo')}
      />
      <TextField
        disabled={loading}
        value={payload.yourTalkingPoints}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            yourTalkingPoints: event.target.value
          }))
        }
        placeholder={translate('yourTalkingPoints')}
      />
    </AIFunctionCard>
  )
}

function WritePromotionalEmail() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    productService: '',
    targetAudience: '',
    uniqueSellingPoints: '',
    promotionDetails: ''
  })
  const isDisabled =
    !payload.productService ||
    !payload.targetAudience ||
    !payload.uniqueSellingPoints ||
    !payload.promotionDetails ||
    !!loading

  const functionCode = 'email_write_promotional_email'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      productService: '',
      targetAudience: '',
      uniqueSellingPoints: '',
      promotionDetails: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.productService}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            productService: event.target.value
          }))
        }
        placeholder={translate('productService')}
      />
      <TextField
        disabled={loading}
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
      <TextField
        disabled={loading}
        value={payload.uniqueSellingPoints}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            uniqueSellingPoints: event.target.value
          }))
        }
        placeholder={translate('uniqueSellingPoints')}
      />
      <TextField
        disabled={loading}
        value={payload.promotionDetails}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            promotionDetails: event.target.value
          }))
        }
        placeholder={translate('promotionDetails')}
      />
    </AIFunctionCard>
  )
}

function WriteReactivationEmail() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    productServices: '',
    targetAudience: '',
    reasonsForCanceling: '',
    CTA: ''
  })
  const isDisabled =
    !payload.productServices ||
    !payload.targetAudience ||
    !payload.reasonsForCanceling ||
    !payload.CTA ||
    !!loading

  const functionCode = 'email_write_reactivation_email'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      productServices: '',
      targetAudience: '',
      reasonsForCanceling: '',
      CTA: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.productServices}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            productServices: event.target.value
          }))
        }
        placeholder={translate('productServices')}
      />
      <TextField
        disabled={loading}
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
      <TextField
        disabled={loading}
        value={payload.reasonsForCanceling}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            reasonsForCanceling: event.target.value
          }))
        }
        placeholder={translate('reasonsForCanceling')}
      />
      <TextField
        disabled={loading}
        value={payload.CTA}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            CTA: event.target.value
          }))
        }
        placeholder={translate('CTA')}
      />
    </AIFunctionCard>
  )
}

function ThankYouEmail() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    talkingPoints: ''
  })
  const isDisabled = !payload.talkingPoints || !!loading

  const functionCode = 'email_thank_you_email'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      talkingPoints: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.talkingPoints}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            talkingPoints: event.target.value
          }))
        }
        placeholder={translate('talkingPoints')}
      />
    </AIFunctionCard>
  )
}

function WriteAbandonedCartEmail() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    productService: '',
    targetAudience: '',
    incentive: '',
    uniqueSellingPoints: ''
  })
  const isDisabled =
    !payload.productService ||
    !payload.targetAudience ||
    !payload.incentive ||
    !payload.uniqueSellingPoints ||
    !!loading

  const functionCode = 'email_write_abandoned_cart_email'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      productService: '',
      targetAudience: '',
      incentive: '',
      uniqueSellingPoints: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.productService}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            productService: event.target.value
          }))
        }
        placeholder={translate('productService')}
      />
      <TextField
        disabled={loading}
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
      <TextField
        disabled={loading}
        value={payload.incentive}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            incentive: event.target.value
          }))
        }
        placeholder={translate('incentive')}
      />
      <TextField
        disabled={loading}
        value={payload.uniqueSellingPoints}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            uniqueSellingPoints: event.target.value
          }))
        }
        placeholder={translate('uniqueSellingPoints')}
      />
    </AIFunctionCard>
  )
}

function WriteWelcomeEmail() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    productService: '',
    targetAudience: '',
    goal: ''
  })
  const isDisabled =
    !payload.productService ||
    !payload.targetAudience ||
    !payload.goal ||
    !!loading

  const functionCode = 'email_write_welcome_email'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      productService: '',
      targetAudience: '',
      goal: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.productService}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            productService: event.target.value
          }))
        }
        placeholder={translate('productService')}
      />
      <TextField
        disabled={loading}
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
      <TextField
        disabled={loading}
        value={payload.goal}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            goal: event.target.value
          }))
        }
        placeholder={translate('goal')}
      />
    </AIFunctionCard>
  )
}

function WriteEducationalNewsletter() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    targetAudience: '',
    desiredFormat: '',
    topics: ''
  })
  const isDisabled =
    !payload.targetAudience ||
    !payload.desiredFormat ||
    !payload.topics ||
    !!loading

  const functionCode = 'email_write_educational_newsletter'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      targetAudience: '',
      desiredFormat: '',
      topics: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
      <TextField
        disabled={loading}
        value={payload.desiredFormat}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            desiredFormat: event.target.value
          }))
        }
        placeholder={translate('desiredFormat')}
      />
      <TextField
        disabled={loading}
        value={payload.topics}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            topics: event.target.value
          }))
        }
        placeholder={translate('topics')}
      />
    </AIFunctionCard>
  )
}
