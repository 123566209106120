import { Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useMemo, useState } from 'react'

import StatisticProjectSelect from '../../../components/statistic_project_select'
import { TotalToDateSwitch } from '../../../components/total_date_switch'
import { useStatisticContext } from '../../../contexts/statistic'
import { IStatisticGroupStatuses } from '../../../core/api_request_responses/statistic.models'
import { toArray, toNumber } from '../../../core/helpers'
import translate from '../../../hooks/use_localization'

interface Row {
  id: number
  name: string
  openTickets: number
  inProgressTickets: number
  doneTickets: number
  feedbackTickets: number
  rejectedTickets: number
  progressRate: number
  activationRate: number
  total: number
}

export default function GroupStatuses() {
  const { selectedTab, projects, groups } = useStatisticContext()
  const [isTotal, setIsTotal] = useState(true)

  const [selectedProject, setSelectedProject] = useState<
    string | undefined | null
  >(translate('all'))

  function toggleIsTotal() {
    setIsTotal((old) => !old)
  }

  const data: Row[] = useMemo(() => {
    return toArray(groups).map((group) => {
      let activation: IStatisticGroupStatuses = group.allTime.total
      const targetProject = toArray(projects).find(
        ({ name }) => name === selectedProject
      )
      if (targetProject) {
        if (isTotal) {
          activation = group.allTime.activations[targetProject.id]
        } else {
          activation = group.toDate.activations[targetProject.id]
        }
      } else {
        if (isTotal) {
          activation = group.allTime.total
        } else {
          activation = group.toDate.total
        }
      }

      return {
        id: group.id,
        name: group.name,
        openTickets: activation.openTickets,
        inProgressTickets: activation.inProgressTickets,
        doneTickets: activation.doneTickets,
        feedbackTickets: activation.feedbackTickets,
        rejectedTickets: activation.rejectedTickets,
        progressRate: activation.progressRate,
        activationRate: activation.activationRate,
        total: activation.total
      }
    })
  }, [isTotal, groups, selectedProject])

  if (selectedTab !== 'group' || !projects || !groups) {
    return <></>
  }

  return (
    <>
      <TotalToDateSwitch isTotal={isTotal} toggle={toggleIsTotal} />
      <StatisticProjectSelect
        projects={toArray(projects)}
        selected_project={selectedProject}
        set_selected_project={setSelectedProject}
      />
      <TableView loading={!groups} data={data} />
    </>
  )
}

function TableView({ loading, data }: { loading: boolean; data: Row[] }) {
  return (
    <DataGrid
      autoHeight={true}
      loading={loading}
      rows={toArray(data)}
      columns={columns}
      pageSize={30}
      rowHeight={40}
      sx={{ mt: 3 }}
      rowsPerPageOptions={[3]}
      disableSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'activationRate', sort: 'desc' }]
        }
      }}
    />
  )
}

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: translate('drawer_groups'),
    width: 400
  },
  {
    field: 'openTickets',
    headerName: translate('status_open')
  },
  {
    field: 'inProgressTickets',
    headerName: translate('status_working_on')
  },
  {
    field: 'doneTickets',
    headerName: translate('status_completed')
  },
  {
    field: 'feedbackTickets',
    headerName: translate('status_feedback')
  },
  {
    field: 'rejectedTickets',
    headerName: translate('status_rejected')
  },
  {
    field: 'total',
    headerName: translate('total'),
    type: 'number'
  },
  {
    field: 'progressRate',
    headerName: translate('compilation_rate'),
    renderCell: ({ value }) => (
      <Typography> {`%${toNumber(value)}`}</Typography>
    ),
    type: 'number',
    minWidth: 150
  },
  {
    field: 'activationRate',
    headerName: translate('active_rate'),
    type: 'number',
    minWidth: 210
  }
]
