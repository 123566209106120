import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useTicketsContext } from '../../contexts/tickets'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketChangePriorityRequest } from '../../core/api_request_responses/ticket.models'
import { toArray, toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { put } from '../../utils/api'

export default function TicketPriority() {
  const { ticket, changeTicket } = useTicketDetailContext()
  const { priorities } = useTicketsContext()

  const criticalId = toNumber(
    toArray(priorities).find((p) => p.code === 'critical')?.id,
    2
  )
  const normalId = toNumber(
    toArray(priorities).find((p) => p.code === 'normal')?.id,
    1
  )
  const isCritical = toNumber(ticket?.priority.id) === criticalId

  async function handle_click_change_ticket_priority(priorityId: number) {
    if (!ticket || ticket.priority.id === priorityId || !changeTicket) return

    const oldPriority = ticket.priority
    const targetPriority = toArray(priorities).find(
      (priority) => priority.id === priorityId
    )

    if (!targetPriority) {
      return
    }

    changeTicket({
      ...ticket,
      priority: targetPriority
    })

    put<ITicketChangePriorityRequest>(`/ticket/${ticket.id}/change-priority`, {
      priorityId
    }).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({ ...ticket, priority: oldPriority })
        alert('update_error')
      }
    })
  }

  if (!ticket) {
    return null
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={isCritical}
            onClick={async () =>
              await handle_click_change_ticket_priority(
                isCritical ? normalId : criticalId
              )
            }
          />
        }
        label={translate('critical')}
      />
    </FormGroup>
  )
}
