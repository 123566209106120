import { toArray } from '../core/helpers'

export function decodeHtml(html: string): string {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export function removeDuplicates<T>(inputArray: T[]): T[] {
  const uniques = new Set<number>()
  const uniquesArray: T[] = []

  toArray(inputArray).forEach((item: any) => {
    const { id } = item as unknown as { id: number }
    if (!uniques.has(id)) {
      uniques.add(id)
      uniquesArray.push(item as unknown as T)
    }
  })

  return uniquesArray
}
