import { AddLocationAlt, RoomOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import GoogleMapReact, { Coords } from 'google-map-react'
import { useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketUpdateCoordinates } from '../../core/api_request_responses/ticket.models'
import translate from '../../hooks/use_localization'
import { put } from '../../utils/api'

const GOOGLE_MAPS_API_KEY = 'AIzaSyBLE4Mlq2msh4IwFByNEtk7_YZKWzk_mqM'

const AnyReactComponent = ({
  text,
  lat,
  lng
}: {
  text: string
  lat: number
  lng: number
}) => (
  <Box>
    <RoomOutlined fontSize="large" color="error" />
  </Box>
)

export default function TicketCoordinatesAdd() {
  const { ticket, changeTicket } = useTicketDetailContext()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCoordinates, setSelectedCoordinates] = useState<Coords>({
    lat: 41.0082,
    lng: 28.9784
  })
  function setClose() {
    setIsOpen(false)
  }

  function setOpen() {
    setIsOpen(true)
  }

  async function save() {
    if (!ticket || !changeTicket) {
      return
    }
    changeTicket({
      ...ticket,
      lat: selectedCoordinates.lat,
      long: selectedCoordinates.lng
    })
    put<ITicketUpdateCoordinates, undefined>(
      `/ticket/${ticket.id}/update-coordinates`,
      {
        lat: selectedCoordinates.lat,
        long: selectedCoordinates.lng
      }
    ).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({
          ...ticket,
          lat: 0,
          long: 0
        })
        alert('update_error')
      }
    })

    setClose()
  }
  if (!!ticket?.lat && !!ticket?.long) {
    return null
  }

  return (
    <>
      <IconButton onClick={setOpen}>
        <AddLocationAlt color="success" />
        <Typography>{translate('add_location')}</Typography>
      </IconButton>
      <Dialog open={isOpen} onClose={setClose} fullWidth={true}>
        <DialogTitle>{translate('add_location')}</DialogTitle>
        <DialogContent>
          <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GOOGLE_MAPS_API_KEY
              }}
              defaultZoom={14}
              defaultCenter={{
                lat: 41.0082,
                lng: 28.9784
              }}
              onClick={(a) => {
                setSelectedCoordinates({ lat: a.lat, lng: a.lng })
              }}
            >
              <AnyReactComponent
                lat={selectedCoordinates.lat}
                lng={selectedCoordinates.lng}
                text={''}
              />
            </GoogleMapReact>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={setClose}>{translate('close')}</Button>
          <LoadingButton onClick={save}>{translate('add')}</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
