import { TagResponse } from '../core/models/tag.response'
import { decodeHtml } from '../utils'

export class Tag {
  id: number = 0
  name: string = ''
  color: string = ''
  projectId: number = 0
}

export function createTagFromTagResponse(tagResponse: TagResponse): Tag {
  const instance = new Tag()
  instance.id = tagResponse.id
  instance.name = decodeHtml(tagResponse.name)
  instance.color = tagResponse.color
  instance.projectId = tagResponse.projectId
  return instance
}
