import { Stack } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import translate from '../hooks/use_localization'
import { Layer } from '../models'

export default function LayerSelect({
  layers,
  value,
  onListItemClick,
  disabled,
  variant
}: {
  layers: Layer[]
  value?: number
  onListItemClick: (layerId: number) => void
  disabled?: boolean
  variant: 'outlined' | 'standard'
}) {
  return (
    <TextField
      value={value}
      label={translate('ticket_layer')}
      fullWidth={true}
      required={true}
      select
      size="small"
      variant={variant}
    >
      {layers.map((layer) => (
        <ListItemButton
          component={'button'}
          value={layer.id}
          key={layer.id}
          sx={{
            width: '100%'
          }}
          disabled={!!disabled}
          onClick={() => onListItemClick(layer.id)}
        >
          <Stack direction={'row'} display={'flex'} alignItems={'center'}>
            <Typography sx={{ p: 2, maxWidth: 150 }}>{layer.name}</Typography>
          </Stack>
        </ListItemButton>
      ))}
    </TextField>
  )
}
