import { Button, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import If from '../../../components/if'
import { IPublicRecoveryPasswordCodeRequest } from '../../../core/api_request_responses/public.models'
import { toString } from '../../../core/helpers'
import NewPasswordForm from '../../../forms/new_password'
import translate from '../../../hooks/use_localization'
import AuthLayout from '../../../layouts/auth_layout'
import { post } from '../../../utils/api'

export default function RecoveryPasswordCodePage() {
  const code = useParams<{ code: string }>()
  const navigate = useNavigate()
  const api_message = useRef<
    { error?: boolean; message?: string } | undefined
  >()
  const [loading, set_loading] = useState(false)

  function handle_click_go_login() {
    navigate('/public/login')
  }

  async function handle_submit(password: string) {
    set_loading(true)
    const response = await post<IPublicRecoveryPasswordCodeRequest, undefined>(
      `/public/recovery-password/${toString(code?.code)}`,
      {
        password
      }
    )
    if (response.statusCode === 200) {
      api_message.current = {
        error: false,
        message: translate('new_password_success')
      }
    } else {
      api_message.current = {
        error: true,
        message: translate(response?.errorCode)
      }
    }
    set_loading(false)
  }

  return (
    <AuthLayout>
      <Stack gap={3}>
        <Typography component="h1" variant="h5">
          {translate('recovery_password_title')}
        </Typography>
        <NewPasswordForm
          handle_submit={handle_submit}
          loading={loading}
          api_message={api_message.current}
        />
        <If condition={!!api_message && !api_message.current?.error}>
          <Button
            disabled={loading}
            onClick={handle_click_go_login}
            fullWidth
            variant="outlined"
          >
            {translate('recovery_password_go_login')}
          </Button>
        </If>
      </Stack>
    </AuthLayout>
  )
}
