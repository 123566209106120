import Masonry from '@mui/lab/Masonry'
import { Avatar, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'
import Card from '../../components/card'
import GroupCard from '../../components/group_card'
import ProjectCard from '../../components/project_card'
import {
  ProfileUserContextProvider,
  useProfileUserContext
} from '../../contexts/profile/user'
import { toArray, toNumber, toString } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import Layout from '../../layouts/page_layout'
import { Group, Project } from '../../models'

export default function UserProfilePage() {
  const { id } = useParams<{ id: string }>()
  return (
    <ProfileUserContextProvider id={toNumber(id)}>
      <Layout>
        <Stack
          direction={{
            xs: 'column',
            md: 'row'
          }}
          gap={2}
        >
          <UserAvatar />
          <UserInfo />
        </Stack>
        <UserGroups />
        <UserProjects />
      </Layout>
    </ProfileUserContextProvider>
  )
}

function UserInfo() {
  const { user } = useProfileUserContext()
  if (!user) {
    return <></>
  }
  return (
    <Card>
      <Typography> {user.name}</Typography>
      <br />
      <Typography>{user.email}</Typography>
    </Card>
  )
}

function UserAvatar() {
  const { user } = useProfileUserContext()
  if (!user) {
    return <></>
  }
  return (
    <Card>
      <Avatar
        alt={toString(user.name)}
        src={toString(user.profilePicture)}
        sx={{ width: 256, height: 256 }}
      />
    </Card>
  )
}

function UserGroups() {
  const { user } = useProfileUserContext()
  if (!user) {
    return <></>
  }
  return (
    <Box>
      <Card>
        <Typography variant={'h5'}>
          {translate('user_included_groups')}
        </Typography>
      </Card>
      <br />
      <Masonry
        columns={{
          sm: 1,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 6
        }}
        spacing={2}
      >
        {toArray(user.groups).map((group: Group) => (
          <GroupCard group={group} key={group.id} />
        ))}
      </Masonry>
    </Box>
  )
}

function UserProjects() {
  const { user } = useProfileUserContext()
  if (!user) {
    return <></>
  }
  return (
    <Box>
      <Card>
        <Typography variant={'h5'}>
          {translate('user_included_projects')}
        </Typography>
      </Card>
      <br />
      <Masonry
        columns={{
          sm: 1,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 6
        }}
        spacing={2}
      >
        {toArray(user.projects).map((project: Project) => (
          <ProjectCard project={project} key={project.id} />
        ))}
      </Masonry>
    </Box>
  )
}
