import { Card, MenuItem, Switch, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Stack } from '@mui/system'
import { useEffect, useMemo } from 'react'

import KanbanView from '../components/kanban'
import TicketDetail from '../components/ticket_detail'
import TicketFilter from '../components/ticket_filter'
import { TicketList } from '../components/ticket_list'
import { TicketsContextProvider, useTicketsContext } from '../contexts/tickets'
import { TicketDetailContextProvider } from '../contexts/ticket_detail'
import { toNumber } from '../core/helpers'
import translate from '../hooks/use_localization'
import Layout from '../layouts/page_layout'

export default function TicketsPage() {
  return (
    <TicketsContextProvider>
      <Layout>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ViewSelect />
          <Box sx={{ display: 'flex', mt: 2 }}>
            <TicketFilter />
            <TicketList />
            <KanbanView />
          </Box>
        </Box>
      </Layout>
      <TicketDetailArea />
    </TicketsContextProvider>
  )
}

function TicketDetailArea() {
  const { selectedTicketId, refCode, handle_click_title, changeTicket } =
    useTicketsContext()
  if (!selectedTicketId) {
    return null
  }
  return (
    <TicketDetailContextProvider
      ticketId={selectedTicketId}
      onClose={() => handle_click_title?.()}
      onChangeTicket={(ticket) => changeTicket?.(ticket)}
      refCode={refCode}
    >
      <TicketDetail />
    </TicketDetailContextProvider>
  )
}

function ViewSelect() {
  const { view, setView } = useTicketsContext()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Card sx={{ p: 1 }}>
        <Stack direction={'row-reverse'} gap={1} alignItems={'center'}>
          <Typography>{translate('list_view')}</Typography>
          <Switch
            color={'primary'}
            checked={view === 'list'}
            onChange={() => setView?.(view === 'list' ? 'kanban' : 'list')}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography>{translate('table_view')}</Typography>
        </Stack>
      </Card>
      <FilterPageSelect />
    </Box>
  )
}

function FilterPageSelect() {
  const { ticketFilter, setTicketFilter, tickets, totalPage, limit } =
    useTicketsContext()

  const options = useMemo(() => {
    const result: { label: string; value: number }[] = []
    if (totalPage === 1 || !totalPage || !limit) {
      return [{ label: translate('all'), value: 1 }]
    }

    for (let i = 1; i <= totalPage; i++) {
      result.push({ label: `${(i - 1) * limit}-${i * limit}`, value: i })
    }

    return result
  }, [totalPage, limit])

  useEffect(() => {
    if (
      ticketFilter &&
      totalPage &&
      setTicketFilter &&
      ticketFilter?.page > totalPage
    ) {
      setTicketFilter((prev) => ({ ...prev, page: 1 }))
    }
  }, [ticketFilter, totalPage])

  return (
    <Card>
      <TextField
        onChange={(event) => {
          setTicketFilter?.((prev) => ({
            ...prev,
            page: toNumber(event.target.value)
          }))
        }}
        value={toNumber(ticketFilter?.page)}
        disabled={!tickets}
        select
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Card>
  )
}
