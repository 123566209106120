import { GroupOutlined, Warning } from '@mui/icons-material'
import { Avatar, Link, MenuItem, Skeleton, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useMemo } from 'react'
import { toArray, toNumber, toString } from '../../../core/helpers'
import useTicketCardContext from '../../../hooks/context_hooks/ticket/use_ticket_card_context'
import { useProject } from '../../../hooks/detail_hooks/use_project'
import { Group, User } from '../../../models'
import TagCard from '../../tag_card'

export function Detail() {
  return (
    <Stack
      sx={{
        width: 180,
        maxWidth: 180,
        minWidth: 180,
        pt: 1
      }}
      rowGap={1}
    >
      <Title />
      <AssigneeName />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <ProjectName />
        <Priority />
      </Box>
      <Tags />
    </Stack>
  )
}

function Title() {
  const { ticket, handle_click_title } = useTicketCardContext()

  if (!ticket) {
    return (
      <Box width={'100%'}>
        <Skeleton animation={'wave'} width={'90%'} />
        <Skeleton animation={'wave'} width={'90%%'} />
      </Box>
    )
  }

  return (
    <Link
      underline={'hover'}
      sx={{ cursor: 'pointer' }}
      fontWeight={'bold'}
      onClick={handle_click_title}
    >
      {ticket?.name}
    </Link>
  )
}

function AssigneeName() {
  const { ticket, onChangeAssignee } = useTicketCardContext()
  const { project } = useProject(toNumber(ticket?.project?.id), {
    users: 'true',
    groups: 'true'
  })

  const users = toArray(
    toArray(project?.layers).find(({ id }) => id === ticket?.layer.id)?.users
  )

  const groups = toArray(
    toArray(project?.layers).find(({ id }) => id === ticket?.layer.id)?.groups
  )

  const assignList: {
    isGroup: boolean
    url?: string
    id: string
    name: string
  }[] = useMemo(() => {
    const userList = users.map((user) => ({
      isGroup: false,
      url: user?.profilePicture,
      id: `user_${user.id}`,
      name: user.name
    }))

    const groupList = groups.map((group) => ({
      isGroup: true,
      id: `group_${group.id}`,
      name: group.name
    }))

    return [...userList, ...groupList]
  }, [ticket?.project?.id, project])

  function handleChangeAssigned(event: React.ChangeEvent<HTMLInputElement>) {
    if (!ticket) {
      return
    }
    const value = event.target.value
    const assignedType = value.startsWith('group_') ? 'group' : 'user'
    const id = toNumber(value.replace('group_', '').replace('user_', ''))
    const assignee = assignList.find((assign) => assign.id === value)
    if (!assignee) {
      return
    }

    if (ticket.assignedType === assignedType && ticket.assigned.id === id) {
      return
    }

    const newAssignedType: 'user' | 'group' = assignedType
    let newAssigned: User | Group | undefined
    if (newAssignedType === 'group') {
      newAssigned = users.find((group) => group.id === id)
    } else {
      newAssigned = groups.find((user) => user.id === id)
    }

    if (!newAssigned || !onChangeAssignee) {
      alert('update_error')
      return
    }
    onChangeAssignee(newAssignedType, newAssigned)
  }

  if (!ticket) {
    return (
      <Box width={'90%%'}>
        <Skeleton animation={'wave'} width={'90%%'} />
      </Box>
    )
  }

  const assignedId = `${ticket.assignedType}_${ticket.assigned.id}`

  if (!project) {
    return (
      <TextField
        disabled={true}
        variant={'standard'}
        value={assignedId}
        select={true}
      >
        {toArray([
          {
            isGroup: ticket.assignedType === 'group',
            id: ticket.assigned.id,
            name: ticket.assigned.name,
            url: (ticket.assigned as unknown as User)?.profilePicture
          }
        ]).map((assign) => (
          <MenuItem key={assign.id} value={assign.id}>
            <Stack direction={'row'} alignItems={'center'}>
              {assign.isGroup ? (
                <GroupOutlined />
              ) : (
                <Avatar
                  alt={toString(assign.name)}
                  src={toString(assign.url)}
                  sx={{ width: 24, height: 24 }}
                />
              )}
              <Typography sx={{ ml: 1 }}>{assign.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </TextField>
    )
  }

  return (
    <TextField
      onChange={handleChangeAssigned}
      variant={'standard'}
      value={assignedId}
      disabled={!onChangeAssignee}
      select={true}
    >
      {toArray(assignList).map((assign) => (
        <MenuItem key={assign.id} value={assign.id}>
          <Stack direction={'row'} alignItems={'center'}>
            {assign.isGroup ? (
              <GroupOutlined />
            ) : (
              <Avatar
                alt={toString(assign.name)}
                src={toString(assign.url)}
                sx={{ width: 24, height: 24 }}
              />
            )}
            <Typography sx={{ ml: 1 }}>{assign.name}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  )
}

function ProjectName() {
  const { ticket } = useTicketCardContext()

  if (!ticket) {
    return <></>
  }
  return <Typography variant="caption">{ticket?.project?.name}</Typography>
}

function Priority() {
  const { ticket } = useTicketCardContext()

  if (!ticket || ticket.priority.code === 'normal') {
    return <></>
  }
  return <Warning color="warning" />
}

function Tags() {
  const { ticket } = useTicketCardContext()

  if (!ticket) {
    return <></>
  }

  return (
    <Box display={'flex'} gap={1} flexWrap={'wrap'} flexDirection={'row'}>
      {toArray(ticket?.tags)
        .slice(0, 4)
        .map((tag) => (
          <TagCard tag={tag} key={tag.id} variant="small" />
        ))}
    </Box>
  )
}
