import { CircularProgress } from '@mui/material'
import { createContext, useContext } from 'react'
import {
  IMeChangePasswordRequest,
  IMeUpdateRequest
} from '../../core/api_request_responses/me.models'
import { IUploadFileResponse } from '../../core/api_request_responses/upload_file.models'
import { IUserCreateRequest } from '../../core/api_request_responses/user.models'
import { CreateResponse } from '../../core/models/api.response'
import useMe from '../../hooks/detail_hooks/use_me'
import translate from '../../hooks/use_localization'

import { User, WebPayment } from '../../models'
import { post, postFormData, put } from '../../utils/api'

interface ProfileMeContextProps {
  user?: User
  translate?: (key: string) => string
  sendImage?: (formData: FormData) => Promise<boolean>
  createUser?: (payload: IUserCreateRequest) => Promise<string>
  changePassword?: (payload: IMeChangePasswordRequest) => Promise<string>
  orders?: WebPayment[]
}

export const ProfileMeContext = createContext<ProfileMeContextProps>({
  translate
})

export function ProfileMeContextProvider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}) {
  const { user, mutate, orders } = useMe({ groups: 'true', projects: 'true' })

  async function sendImage(formData: FormData): Promise<boolean> {
    if (!user) {
      return false
    }

    const { payload, statusCode } = await postFormData<IUploadFileResponse>(
      '/file',
      formData
    )

    if (statusCode !== 200 || !payload) {
      return false
    }
    const updateResponse = await put<IMeUpdateRequest>('/me', {
      firstName: user.firstName,
      lastName: user.lastName,
      profilePicture: payload.url
    })
    if (updateResponse.statusCode !== 200) {
      return false
    }
    await mutate()
    if (window) {
      window.localStorage.setItem('photoUrl', payload.url)
    }
    return true
  }

  async function createUser(payload: IUserCreateRequest): Promise<string> {
    const { statusCode, errorCode } = await post<
      IUserCreateRequest,
      CreateResponse
    >('/user', payload)
    if (statusCode !== 200) {
      return translate(errorCode)
    }
    await mutate()
    return translate('invite_success')
  }

  async function changePassword(
    payload: IMeChangePasswordRequest
  ): Promise<string> {
    const { statusCode, errorCode } = await post<
      IMeChangePasswordRequest,
      undefined
    >('/me/change-password', payload)
    if (statusCode !== 200) {
      return translate(errorCode)
    }
    await mutate()
    return translate('change_password_success')
  }

  return (
    <ProfileMeContext.Provider
      value={{
        user,
        translate,
        sendImage,
        createUser,
        changePassword,
        orders
      }}
    >
      {user ? children : <CircularProgress />}
    </ProfileMeContext.Provider>
  )
}

export default function useProfileMeContext() {
  const context = useContext(ProfileMeContext)
  if (!context) {
    return { translate }
  }
  return context
}
