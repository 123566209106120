import { Button, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IPublicRecoveryPasswordRequest } from '../../../core/api_request_responses/public.models'
import RecoveryPasswordForm from '../../../forms/recovery_password'
import translate from '../../../hooks/use_localization'
import AuthLayout from '../../../layouts/auth_layout'
import { post } from '../../../utils/api'

export default function RecoveryPasswordPage() {
  const navigate = useNavigate()
  const api_message = useRef<
    { error?: boolean; message?: string } | undefined
  >()
  const [loading, set_loading] = useState(false)

  function handle_click_go_login() {
    navigate('/public/login')
  }

  async function handle_submit(email: string) {
    set_loading(true)
    const response = await post<IPublicRecoveryPasswordRequest, undefined>(
      '/public/recovery-password',
      { email }
    )
    if (response.statusCode === 200) {
      api_message.current = {
        error: false,
        message: translate('recovery_password_success')
      }
    } else {
      api_message.current = {
        error: true,
        message: translate(response?.errorCode)
      }
    }
    set_loading(false)
  }

  return (
    <AuthLayout>
      <Stack gap={3}>
        <Typography component="h1" variant="h5">
          {translate('recovery_password_title')}
        </Typography>
        <RecoveryPasswordForm
          handle_submit={handle_submit}
          loading={loading}
          api_message={api_message.current}
        />
        <Button
          disabled={loading}
          fullWidth
          onClick={handle_click_go_login}
          variant="outlined"
        >
          {translate('recovery_password_go_login')}
        </Button>
      </Stack>
    </AuthLayout>
  )
}
