import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  IPublicSignUpCodeRequest,
  IPublicSignUpCodeResponse
} from '../../../core/api_request_responses/public.models'
import { sleep, toString } from '../../../core/helpers'
import NewPasswordForm from '../../../forms/new_password'
import translate from '../../../hooks/use_localization'
import AuthLayout from '../../../layouts/auth_layout'
import { get, post } from '../../../utils/api'

export default function SignUpCodePage() {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()

  const api_message = useRef<
    { error?: boolean; message?: string } | undefined
  >()
  const [loading, set_loading] = useState(false)

  async function handle_submit(password: string) {
    set_loading(true)
    const response = await post<IPublicSignUpCodeRequest, undefined>(
      `/public/sign-up/${toString(code)}`,
      {
        password
      }
    )
    if (response.statusCode === 200) {
      api_message.current = {
        error: false,
        message: translate('sign_up_success')
      }
      await sleep(3000)
      navigate('/public/login')
    } else {
      api_message.current = {
        error: true,
        message: translate(response?.errorCode)
      }
    }
    set_loading(false)
  }

  return (
    <AuthLayout>
      <Stack gap={3}>
        <Typography component="h1" variant="h5">
          {translate('sign_up_title')}
        </Typography>
        {code ? <UserInfo code={code} /> : <></>}
        <NewPasswordForm
          handle_submit={handle_submit}
          loading={loading}
          api_message={api_message.current}
        />
      </Stack>
    </AuthLayout>
  )
}

function UserInfo({ code }: { code?: string }) {
  const [user, setUser] = useState<IPublicSignUpCodeResponse | undefined>(
    undefined
  )

  useEffect(() => {
    get<IPublicSignUpCodeResponse>(`/public/sign-up/${toString(code)}`).then(
      (response) => {
        if (response.statusCode === 200 && !!response?.payload) {
          setUser(response.payload)
        }
      }
    )
  }, [code])

  return (
    <Stack>
      <Typography>
        {user?.firstName} {user?.lastName}
      </Typography>
    </Stack>
  )
}
