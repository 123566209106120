import { Skeleton, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import useTicketCardContext from '../../../hooks/context_hooks/ticket/use_ticket_card_context'
import translate from '../../../hooks/use_localization'
import { status_width } from './ticket_card'

export function StatusBar() {
  const { ticket } = useTicketCardContext()

  if (!ticket) {
    return (
      <Box
        sx={{
          width: status_width,
          position: 'absolute',
          height: '100%',
          left: 0,
          top: 0
        }}
      >
        <Skeleton
          variant="rectangular"
          width={status_width}
          height={'100%'}
          animation={'wave'}
        />
      </Box>
    )
  }

  return (
    <Tooltip title={translate(ticket.status)} arrow placement="left">
      <Box
        sx={{
          background: ticket.status.color,
          width: status_width,
          position: 'absolute',
          height: '100%',
          left: 0,
          top: 0
        }}
      />
    </Tooltip>
  )
}
