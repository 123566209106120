import { FileResponse } from '../core/models/file.response'
import { decodeHtml } from '../utils'
import { User, createUserFromUserResponse } from './user.model'

export class File {
  id: number = 0
  mimeType: string = ''
  filename: string = ''
  url: string = ''
  createdBy: User = new User()
}

export function createFileFromFileResponse(response: FileResponse): File {
  const instance = new File()
  instance.id = response.id
  instance.mimeType = response.mimeType
  instance.filename = decodeHtml(response.filename)
  instance.url = response.url
  instance.createdBy = createUserFromUserResponse(response.createdBy)
  return instance
}
