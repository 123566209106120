import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import If from '../components/if'
import { IPublicSignUpWithRefCodeRequest } from '../core/api_request_responses/public.models'
import translate from '../hooks/use_localization'

interface LoginFormProps {
  handle_submit: (payload: IPublicSignUpWithRefCodeRequest) => void
  loading: boolean
  api_message?: {
    error?: boolean
    message?: string
  }
}

interface IFormInput {
  firstName: string
  lastName: string
  email: string
  password: string
  password_confirmation: string
}

export default function UserRefSignUpForm({
  handle_submit,
  loading,
  api_message
}: LoginFormProps) {
  const navigate = useNavigate()
  const schema = useMemo(
    () =>
      yup
        .object({
          firstName: yup.string().required(translate('is_required')),
          lastName: yup.string().required(translate('is_required')),
          email: yup.string().email().required(translate('is_required')),
          password: yup.string().required(translate('is_required')),
          password_confirmation: yup
            .string()
            .oneOf(
              [yup.ref('password'), null],
              translate('password_confirmation_not_match')
            )
        })
        .required(),
    [translate]
  )

  const { handleSubmit, control } = useForm<IFormInput>({
    resolver: yupResolver(schema)
  })

  function handle_click_save_password() {
    handleSubmit((form) => handle_submit(form))()
  }

  function handle_click_login() {
    navigate('/public/login')
  }

  return (
    <>
      <If condition={!!api_message}>
        <Typography color={api_message?.error ? 'error' : 'success'}>
          {api_message?.message}
        </Typography>
      </If>
      <Controller
        name={'email'}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <TextField
            disabled={loading}
            type="email"
            fullWidth
            required
            onChange={onChange}
            value={value}
            label={translate('email')}
            error={!!errors.email}
          />
        )}
      />
      <Controller
        name={'firstName'}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <TextField
            disabled={loading}
            fullWidth
            required
            onChange={onChange}
            value={value}
            label={translate('firstName')}
            error={!!errors.firstName}
          />
        )}
      />
      <Controller
        name={'lastName'}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <TextField
            disabled={loading}
            fullWidth
            required
            onChange={onChange}
            value={value}
            label={translate('lastName')}
            error={!!errors.lastName}
          />
        )}
      />
      <Controller
        name={'password'}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <TextField
            disabled={loading}
            type="password"
            autoComplete="new-password"
            fullWidth
            required
            onChange={onChange}
            value={value}
            label={translate('password')}
            error={!!errors.password}
          />
        )}
      />
      <Controller
        name={'password_confirmation'}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <TextField
            disabled={loading}
            type="password"
            autoComplete="new-password"
            fullWidth
            required
            onChange={onChange}
            value={value}
            label={translate('password_confirmation')}
            error={!!errors.password_confirmation}
          />
        )}
      />

      <LoadingButton
        loading={loading}
        onClick={handle_click_save_password}
        fullWidth
        variant="contained"
      >
        {translate('sign_up_title')}
      </LoadingButton>

      <Button
        disabled={loading}
        onClick={handle_click_login}
        fullWidth
        variant="outlined"
      >
        {translate('login_sign_in')}
      </Button>
    </>
  )
}
