import { convertISO8601StringToDate } from '../core/helpers'
import { NotificationResponse } from '../core/models/notification.response'
import { decodeHtml } from '../utils'

export class Notification {
  id: number = 0
  tr: string = ''
  en: string = ''
  contentId: number = 0
  contentType: string = ''
  createdAt?: Date = new Date()
  unRead: boolean = true
}

export function createNotificationFromNotificationResponse(
  notificationResponse: NotificationResponse
): Notification {
  const instance = new Notification()
  instance.id = notificationResponse.id
  instance.tr = decodeHtml(notificationResponse.tr)
  instance.en = decodeHtml(notificationResponse.en)
  instance.contentId = notificationResponse.contentId
  instance.contentType = notificationResponse.contentType
  instance.createdAt = convertISO8601StringToDate(
    notificationResponse.createdAt
  )
  instance.unRead = !!notificationResponse.unRead
  return instance
}
