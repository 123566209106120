import { CircularProgress } from '@mui/material'
import { createContext, useContext } from 'react'
import { IGroupCreateRequest } from '../core/api_request_responses/group.models'
import { IMeLeaveGroupRequest } from '../core/api_request_responses/me.models'
import { IProjectCreateRequest } from '../core/api_request_responses/project.models'
import { IUserCreateRequest } from '../core/api_request_responses/user.models'
import { CreateResponse } from '../core/models/api.response'
import useMe from '../hooks/detail_hooks/use_me'
import { useUsers } from '../hooks/list_hooks/use_users'
import translate from '../hooks/use_localization'

import { User } from '../models'
import { post } from '../utils/api'

interface ProjectGroupsContextProps {
  user?: User
  users?: User[]
  translate?: (key: string) => string
  createGroup?: (payload: IGroupCreateRequest) => Promise<boolean>
  leaveGroup?: (payload: IMeLeaveGroupRequest) => Promise<boolean>
  createProject?: (payload: IProjectCreateRequest) => Promise<boolean>
  createUser?: (payload: IUserCreateRequest) => Promise<string>
  mutate?: () => Promise<void>
}

export const ProjectGroupsContext = createContext<ProjectGroupsContextProps>({
  translate
})

export function ProjectGroupsContextProvider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}) {
  const { user, mutate } = useMe({ groups: 'true', projects: 'true' })

  const { users } = useUsers()

  async function createGroup(payload: IGroupCreateRequest): Promise<boolean> {
    const { statusCode } = await post<IGroupCreateRequest, CreateResponse>(
      '/group',
      payload
    )
    if (statusCode !== 200) {
      return false
    }
    await mutate()
    return true
  }

  async function createProject(
    payload: IProjectCreateRequest
  ): Promise<boolean> {
    const { statusCode } = await post<IProjectCreateRequest, CreateResponse>(
      '/project',
      payload
    )
    if (statusCode !== 200) {
      return false
    }
    await mutate()
    return true
  }

  async function createUser(payload: IUserCreateRequest): Promise<string> {
    const { statusCode, errorCode } = await post<
      IUserCreateRequest,
      CreateResponse
    >('/user', payload)
    if (statusCode !== 200) {
      return translate(errorCode)
    }
    await mutate()
    return 'Kullanıcı başarıyla davet edildi'
  }

  async function leaveGroup(payload: IMeLeaveGroupRequest): Promise<boolean> {
    const { statusCode } = await post<IMeLeaveGroupRequest, undefined>(
      '/me/leave-group',
      payload
    )
    if (statusCode !== 200) {
      return false
    }
    await mutate()
    return true
  }

  return (
    <ProjectGroupsContext.Provider
      value={{
        user,
        translate,
        mutate: async () => {
          await mutate()
        },
        users,
        createGroup,
        leaveGroup,
        createProject,
        createUser
      }}
    >
      {user ? children : <CircularProgress />}
    </ProjectGroupsContext.Provider>
  )
}

export default function useProjectGroupsContext() {
  const context = useContext(ProjectGroupsContext)
  if (!context) {
    return { translate }
  }
  return context
}
