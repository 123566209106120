import { AddOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import { useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketAddCheckItemRequest } from '../../core/api_request_responses/ticket.models'
import { toArray, toNumber } from '../../core/helpers'
import { CreateResponse } from '../../core/models/api.response'
import translate from '../../hooks/use_localization'
import { CheckItem } from '../../models'
import { post } from '../../utils/api'
import { CheckItemList } from '../checkitem_list'
import TicketCollapsibleRow from './row'

export default function TicketCheckList() {
  const { ticket, changeTicket } = useTicketDetailContext()

  if (!ticket) {
    return null
  }

  return (
    <TicketCollapsibleRow
      title={translate('checkitems')}
      badge={toArray(ticket?.checkItems).length}
      AddComponent={() => (
        <AddCheckItemDialog
          onCreatedEnd={async (checkItem) => {
            if (!checkItem || !changeTicket || !ticket) {
              alert('update_error')
              return
            }
            changeTicket({
              ...ticket,
              checkItems: toArray(ticket?.checkItems).concat(checkItem)
            })
          }}
        />
      )}
    >
      <Box>
        <CheckItemList
          ticketId={ticket?.id}
          checkItemList={ticket?.checkItems}
          onChange={(newCheckItem) => {
            const newList = toArray(ticket?.checkItems).map((item) =>
              item.id === newCheckItem.id ? newCheckItem : item
            )
            changeTicket?.({ ...ticket, checkItems: newList })
          }}
          onDelete={(itemID) => {
            const newList = toArray(ticket?.checkItems).filter(
              (item) => item.id !== itemID
            )
            changeTicket?.({ ...ticket, checkItems: newList })
          }}
        />
      </Box>
    </TicketCollapsibleRow>
  )
}

function AddCheckItemDialog({
  onCreatedEnd
}: {
  onCreatedEnd: (checkItem?: CheckItem) => Promise<void>
}) {
  const { ticket } = useTicketDetailContext()

  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<ITicketAddCheckItemRequest>>(
    {}
  )

  function set_close() {
    if (loading) {
      return
    }
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function addCheckItem(
    payload: ITicketAddCheckItemRequest
  ): Promise<number> {
    const response = await post<ITicketAddCheckItemRequest, CreateResponse>(
      `/ticket/${toNumber(ticket?.id)}/add-check-item`,
      payload
    )
    if (response.statusCode !== 200) {
      return 0
    }
    return toNumber(response?.payload?.id)
  }

  async function save() {
    if (!payload?.name) {
      return
    }
    setLoading(true)
    const newId = await addCheckItem?.(
      payload as unknown as ITicketAddCheckItemRequest
    )
    if (!newId) {
      await onCreatedEnd()
    } else {
      const newCheckItem = new CheckItem()
      newCheckItem.id = newId
      newCheckItem.name = payload.name
      newCheckItem.isDone = false
      onCreatedEnd(newCheckItem)
    }

    set_close()
    setLoading(false)
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      name: event.target.value
    })
  }

  return (
    <>
      <LoadingButton
        sx={{ alignSelf: 'center' }}
        loading={loading}
        onClick={set_open}
      >
        {!loading ? <AddOutlined color="success" /> : <></>}
      </LoadingButton>
      <Dialog
        open={is_open}
        onClose={set_close}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle>{translate('add_checkitem')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={payload?.name}
            required={true}
            label={translate('name')}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.name || payload?.name?.trim().length === 0}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('add')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
