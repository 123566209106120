import useSWR from 'swr'
import {
  IUserRequest,
  IUserResponse
} from '../../core/api_request_responses/user.models'
import { generateQueryString } from '../../core/helpers'
import { createUserFromUserResponse } from '../../models/user.model'

export function useUser(id: number, props?: IUserRequest) {
  const { data, mutate } = useSWR<IUserResponse>(
    generateQueryString(`/user/${id}`, props)
  )

  return {
    user: data?.user ? createUserFromUserResponse(data?.user) : undefined,
    mutate
  }
}
