import { AddOutlined, DeleteOutline } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack
} from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useState } from 'react'
import CollapsibleRow from '../components/collapsible_row'
import CreateProjectDialog from '../components/create_project_dialog'
import DialogProvider from '../components/dialog_provider'
import GroupCard from '../components/group_card'
import ProjectCard from '../components/project_card'
import useProjectGroupsContext, {
  ProjectGroupsContextProvider
} from '../contexts/project_groups'
import { IGroupCreateRequest } from '../core/api_request_responses/group.models'
import { IMeLeaveGroupRequest } from '../core/api_request_responses/me.models'
import { toArray, toNumber } from '../core/helpers'
import translate from '../hooks/use_localization'
import Layout from '../layouts/page_layout'
import { Group, Project, User } from '../models'

export default function ProjectGroupsPage() {
  return (
    <ProjectGroupsContextProvider>
      <Layout>
        <UserGroups />
        <UserProjects />
      </Layout>
    </ProjectGroupsContextProvider>
  )
}

function UserGroups() {
  const { user } = useProjectGroupsContext()
  if (!user) {
    return <></>
  }

  return (
    <CollapsibleRow
      title={translate('your_groups')}
      DeleteComponent={() => <LeaveGroupDialog />}
      AddComponent={() => <CreateGroupDialog />}
      badge={toArray(user.groups).length}
    >
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {toArray(user.groups).map((group: Group) => (
          <GroupCard group={group} key={group.id} />
        ))}
      </Box>
    </CollapsibleRow>
  )
}

function CreateGroupDialog() {
  const { user, users, createGroup } = useProjectGroupsContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<IGroupCreateRequest>>({})

  function set_close() {
    if (loading) {
      return
    }
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.name || !payload?.managerId) {
      return
    }
    setLoading(true)
    await createGroup?.(payload as unknown as IGroupCreateRequest)
    set_close()
    setLoading(false)
  }

  function handleChangeManagerId(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      managerId: toNumber(event.target.value)
    })
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      name: event.target.value
    })
  }

  if (!user || !users) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={set_open}>
        <AddOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('create_group')}</DialogTitle>
        <DialogContent>
          <Stack mt={2} rowGap={4}>
            <TextField
              label={translate('group_name')}
              fullWidth
              value={payload?.name}
              onChange={handleChangeName}
            />

            <TextField
              label={translate('manager')}
              fullWidth
              value={payload?.managerId}
              select
              onChange={handleChangeManagerId}
            >
              {toArray(users).map((user: User) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.managerId || !payload?.name}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('create')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function LeaveGroupDialog() {
  const { user, leaveGroup } = useProjectGroupsContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<IMeLeaveGroupRequest>>({})

  function set_close() {
    if (loading) {
      return
    }
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.groupId) {
      return
    }
    setLoading(true)
    await leaveGroup?.(payload as unknown as IMeLeaveGroupRequest)
    set_close()
    setLoading(false)
  }

  function handleChangeGroupId(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      groupId: toNumber(event.target.value)
    })
  }

  if (!user) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={set_open}>
        <DeleteOutline color={'error'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('leave_group')}</DialogTitle>
        <DialogContent>
          <Stack mt={2} rowGap={4}>
            <Typography variant={'caption'}>
              {translate('leave_group_hint')}
            </Typography>
            <TextField
              label={translate('group_name')}
              fullWidth
              value={payload?.groupId}
              select
              onChange={handleChangeGroupId}
            >
              {toArray(user?.groups)
                .filter((group) => group.manager.id !== user.id)
                .map((group: Group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.groupId}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('leave_group')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function UserProjects() {
  const { user, mutate } = useProjectGroupsContext()
  if (!user) {
    return <></>
  }

  return (
    <CollapsibleRow
      title={translate('your_projects')}
      AddComponent={() => (
        <CreateProjectDialog
          mutate={async () => {
            await mutate?.()
          }}
        />
      )}
      badge={toArray(user.projects).length}
    >
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {toArray(user.projects).map((project: Project) => (
          <ProjectCard project={project} key={project.id} />
        ))}
      </Box>
    </CollapsibleRow>
  )
}
