import React from 'react'

export default function If(props: {
  condition: unknown
  children: JSX.Element | React.ReactElement
}) {
  if (!!props.condition) {
    return props.children
  }
  return null
}
