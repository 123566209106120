import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { ICheckItemResponseListResponse } from '../../core/api_request_responses/check_item.models'
import { generateQueryString, toArray } from '../../core/helpers'
import {
  CheckItem,
  createCheckItemFromCheckItemResponse
} from '../../models/check_item.model'

export function useCheckItemList() {
  const [checkItems, setCheckItems] = useState<CheckItem[] | undefined>()
  const { data, mutate } = useSWR<ICheckItemResponseListResponse>(
    generateQueryString('/check-item'),
    { refreshInterval: 1000 * 10 }
  )

  useEffect(() => {
    if (data?.checkItems) {
      setCheckItems(
        toArray(data?.checkItems).map((checkItem) =>
          createCheckItemFromCheckItemResponse(checkItem)
        )
      )
    }
  }, [data])

  async function changeCheckItem(checkItem: CheckItem) {
    setCheckItems((prev) =>
      toArray(prev).map((c) => (c.id === checkItem.id ? checkItem : c))
    )
  }

  return {
    checkItems,
    setCheckItems,
    mutate,
    changeCheckItem
  }
}
