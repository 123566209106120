import { Box, Stack, Typography } from '@mui/material'
import Card from '../../components/card'
import { useAIContext } from '../../contexts/ai'
import { toArray } from '../../core/helpers'
import translate from '../../hooks/use_localization'

export default function AISelect() {
  const { AI_TITLES, selectedFunctionCode } = useAIContext()

  if (selectedFunctionCode !== 'select') {
    return <></>
  }

  return (
    <>
      <Stack gap={4}>
        {AI_TITLES.map((group, index) => (
          <Box key={index}>
            <Typography variant="h4">{translate(group.title)}</Typography>
            <Stack direction={'row'} gap={2} flexWrap={'wrap'}>
              {toArray(group.functions).map(({ name, code }) => (
                <AICard name={name} code={code} key={code} />
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
    </>
  )
}

function AICard({
  name,
  code
}: {
  code: string
  name: { tr: string; en: string }
}) {
  const { setSelectedFunctionCode } = useAIContext()

  return (
    <Box
      sx={{ maxWidth: '25rem', flexWrap: 'wrap', cursor: 'pointer' }}
      onClick={() => {
        setSelectedFunctionCode?.(code)
      }}
    >
      <Card>
        <Typography>{translate(name)}</Typography>
      </Card>
    </Box>
  )
}
