import { CircularProgress } from '@mui/material'
import * as React from 'react'
import { createContext, useContext, useEffect } from 'react'
import {
  IGroupAddUserRequest,
  IGroupDeleteUserRequest,
  IGroupUpdateRequest
} from '../core/api_request_responses/group.models'
import { toArray } from '../core/helpers'
import { useGroup } from '../hooks/detail_hooks/use_group'
import useMe from '../hooks/detail_hooks/use_me'
import { useUsers } from '../hooks/list_hooks/use_users'
import translate from '../hooks/use_localization'
import { Group, User } from '../models'
import { post, put } from '../utils/api'

interface GroupDetailContextProps {
  group?: Group
  addUser?: (userId: number) => Promise<string | undefined>
  deleteUser?: (userId: number) => Promise<string | undefined>
  isManager?: boolean
  update?: (payload: IGroupUpdateRequest) => Promise<boolean>
  users?: User[]
  translate: (key?: string) => string
}

export const GroupDetailContext = createContext<GroupDetailContextProps>({
  translate
})

export function GroupDetailContextProvider({
  id,
  children
}: {
  id: number
  children: JSX.Element | JSX.Element[]
}): JSX.Element {
  const [isManager, setIsManager] = React.useState(false)
  const { group, mutate } = useGroup(id, {
    projects: 'true',
    users: 'true'
  })

  const { users } = useUsers()
  const { user } = useMe()

  useEffect(() => {
    if (group && user) {
      setIsManager(group.manager.id === user.id)
    }
  }, [group, user])

  async function addUser(userId: number): Promise<string | undefined> {
    const response = await post<IGroupAddUserRequest, void>(
      `/group/${id}/add-user`,
      {
        userId
      }
    )
    if (response.statusCode === 200) {
      await mutate()
      return undefined
    }

    return translate(response?.errorCode)
  }

  async function deleteUser(userId: number): Promise<string | undefined> {
    const response = await post<IGroupDeleteUserRequest, void>(
      `/group/${id}/delete-user`,
      {
        userId
      }
    )
    if (response.statusCode === 200) {
      await mutate()
      return undefined
    }

    return translate(response?.errorCode)
  }

  async function update(payload: IGroupUpdateRequest): Promise<boolean> {
    const response = await put<IGroupUpdateRequest, void>(
      `/group/${id}`,
      payload
    )
    if (response.statusCode === 200) {
      await mutate()
      return true
    }

    return false
  }

  const user_ids = toArray(group?.users).map((u) => u.id)

  return (
    <GroupDetailContext.Provider
      value={{
        group,
        translate,
        addUser,
        deleteUser,
        isManager,
        users: toArray(users).filter((user) => !user_ids.includes(user.id)),
        update
      }}
    >
      {group ? children : <CircularProgress />}
    </GroupDetailContext.Provider>
  )
}

export function useGroupDetailContext() {
  const context = useContext(GroupDetailContext)
  if (!context) {
    return {
      translate
    }
  }
  return context
}
