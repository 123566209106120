import { createContext, useContext, useState } from 'react'
import { IProjectCreateRequest } from '../core/api_request_responses/project.models'
import { convertDateStringToISOString } from '../core/helpers'
import { CreateResponse } from '../core/models/api.response'
import useGroups from '../hooks/list_hooks/use_groups'
import { useUsers } from '../hooks/list_hooks/use_users'
import translate from '../hooks/use_localization'
import { Group, User } from '../models'
import { post } from '../utils/api'

interface CreateProjectContextProps {
  activeStep?: number
  translate: (key?: string) => string
  next?: () => void
  back?: () => void
  step1IsValid?: () => boolean
  step2IsValid?: () => boolean
  isOpen?: boolean
  setClose?: () => void
  setOpen?: () => void
  users?: User[]
  groups?: Group[]
  payload?: IProjectCreateRequest
  setPayload?: React.Dispatch<React.SetStateAction<IProjectCreateRequest>>
  loading?: boolean
  createProject?: () => Promise<void>
}

export const CreateProjectContext = createContext<CreateProjectContextProps>({
  translate
})

export function CreateProjectContextProvider({
  children,
  mutate
}: {
  children: JSX.Element | JSX.Element[]
  mutate: () => Promise<void>
}): JSX.Element {
  const [activeStep, setActiveStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<IProjectCreateRequest>({
    name: '',
    managerId: 0,
    layers: []
  })
  const [isOpen, setIsOpen] = useState(false)

  const { users } = useUsers()
  const { groups } = useGroups()

  function setClose() {
    if (loading) {
      return
    }
    setPayload({
      name: '',
      managerId: 0,
      layers: []
    })
    setIsOpen(false)
  }

  function setOpen() {
    setIsOpen(true)
  }

  function next() {
    if (activeStep === 1 && step1Check(payload)) {
      setActiveStep(2)
    }
    if (activeStep === 2 && step2Check(payload)) {
      setActiveStep(3)
    }
    if (activeStep === 3 && step1Check(payload) && step2Check(payload)) {
      save().then()
    }
  }

  function back() {
    setActiveStep(Math.max(activeStep - 1, 1))
  }

  async function save() {
    if (!step1Check(payload) || !step2Check(payload)) {
      return
    }
    setLoading(true)

    if (payload?.eventEndDate) {
      payload.eventEndDate = convertDateStringToISOString(payload.eventEndDate)
    } else {
      payload.eventEndDate = undefined
    }

    if (payload?.eventStartDate) {
      payload.eventStartDate = convertDateStringToISOString(
        payload.eventStartDate
      )
    } else {
      payload.eventStartDate = undefined
    }

    if (payload?.startDate) {
      payload.startDate = convertDateStringToISOString(payload.startDate)
    } else {
      payload.startDate = undefined
    }

    if (payload?.endDate) {
      payload.endDate = convertDateStringToISOString(payload.endDate)
    } else {
      payload.endDate = undefined
    }
    const { statusCode } = await post<IProjectCreateRequest, CreateResponse>(
      '/project',
      payload
    )
    await mutate()

    setLoading(false)
    if (statusCode === 200) {
      setClose()
    }
  }

  return (
    <CreateProjectContext.Provider
      value={{
        activeStep,
        translate,
        next,
        back,
        step1IsValid: () => step1Check(payload),
        step2IsValid: () => step2Check(payload),
        isOpen,
        setClose,
        setOpen,
        users,
        groups,
        payload,
        setPayload,
        loading
      }}
    >
      {children}
    </CreateProjectContext.Provider>
  )
}

export function useCreateProjectContext(): CreateProjectContextProps {
  const context = useContext(CreateProjectContext)
  if (!context) {
    return {
      translate
    }
  }
  return context
}

function step1Check(payload: IProjectCreateRequest): boolean {
  if (!payload?.name || payload.name.trim().length === 0) {
    return false
  }
  if (!payload?.managerId) {
    return false
  }
  return true
}
function step2Check(payload: IProjectCreateRequest): boolean {
  return step1Check(payload) && payload.layers.length > 0
}
