import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { toArray } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { TicketAction } from '../../models'
import TicketCollapsibleRow from './row'

export default function TicketActions() {
  const { ticket } = useTicketDetailContext()

  if (!ticket) {
    return null
  }

  return (
    <TicketCollapsibleRow
      title={translate('actions')}
      badge={toArray(ticket?.actions).length}
    >
      <Box>
        {toArray(ticket?.actions).map((action) => (
          <ActionCard action={action} key={action.id} />
        ))}
      </Box>
    </TicketCollapsibleRow>
  )
}

function ActionCard({ action }: { action: TicketAction }) {
  return (
    <>
      <Stack
        gap={1}
        direction="row"
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Typography sx={{ display: 'inline' }}>{translate(action)}</Typography>
        <Typography variant={'caption'}>
          {action?.createdAt.toLocaleString() || ''}
        </Typography>
      </Stack>
      <hr />
    </>
  )
}
