import useSWR from 'swr'
import {
  IUserListRequest,
  IUserListResponse
} from '../../core/api_request_responses/user.models'
import { generateQueryString, toArray } from '../../core/helpers'
import { createUserFromUserResponse } from '../../models/user.model'

export function useUsers(props?: IUserListRequest) {
  const { data } = useSWR<IUserListResponse>(
    generateQueryString('/user', props)
  )

  return {
    users: data?.users
      ? toArray(data?.users).map((user) => createUserFromUserResponse(user))
      : undefined
  }
}
