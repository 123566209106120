import { DoneAll, NotificationsRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Badge,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'

import {
  NotificationContextProvider,
  useNotificationContext
} from '../contexts/notification'
import { toArray, toNumber } from '../core/helpers'
import translate from '../hooks/use_localization'
import { Notification } from '../models'
import Card from './card'
import If from './if'

export default function Notifications() {
  return (
    <NotificationContextProvider>
      <>
        <NotificationButton />
        <NotificationDialog />
      </>
    </NotificationContextProvider>
  )
}

function NotificationButton() {
  const { unread_count, setOpen } = useNotificationContext()
  return (
    <>
      <Tooltip title={translate('notifications')}>
        <IconButton color={'inherit'}>
          <Badge badgeContent={toNumber(unread_count)} color="error">
            <NotificationsRounded color={'inherit'} onClick={setOpen} />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  )
}

function NotificationDialog() {
  const {
    isOpen,
    setClose,
    loading,
    mark_all_read,
    allNotifications,
    unReadNotifications,
    ticketNotifications,
    mentionNotifications,
    onClickCard,
    tab,
    handleChange
  } = useNotificationContext()

  return (
    <Dialog open={!!isOpen} onClose={setClose}>
      <>
        <DialogTitle>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={`${translate('all')} ${
                      toArray(allNotifications).filter(({ unRead }) => !!unRead)
                        .length
                        ? `( ${
                            toArray(allNotifications).filter(
                              ({ unRead }) => !!unRead
                            ).length
                          } )`
                        : ''
                    }`}
                    value={0}
                  />
                  <Tab
                    label={`${translate('unreaded')} ${
                      toArray(unReadNotifications).filter(
                        ({ unRead }) => !!unRead
                      ).length
                        ? `( ${
                            toArray(unReadNotifications).filter(
                              ({ unRead }) => !!unRead
                            ).length
                          } )`
                        : ''
                    }`}
                    value={1}
                  />
                  <Tab
                    label={`${translate('tickets')} ${
                      toArray(ticketNotifications).filter(
                        ({ unRead }) => !!unRead
                      ).length
                        ? `( ${
                            toArray(ticketNotifications).filter(
                              ({ unRead }) => !!unRead
                            ).length
                          } )`
                        : ''
                    }`}
                    value={2}
                  />
                  <Tab
                    label={`${translate('mentions')} ${
                      toArray(mentionNotifications).filter(
                        ({ unRead }) => !!unRead
                      ).length
                        ? `( ${
                            toArray(mentionNotifications).filter(
                              ({ unRead }) => !!unRead
                            ).length
                          } )`
                        : ''
                    }`}
                    value={3}
                  />
                </Tabs>
              </Box>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <ListArea value={0} list={toArray(allNotifications)} />
          <ListArea value={1} list={toArray(unReadNotifications)} />
          <ListArea value={2} list={toArray(ticketNotifications)} />
          <ListArea value={3} list={toArray(mentionNotifications)} />
        </DialogContent>
      </>
    </Dialog>
  )
}

function ListArea({ value, list }: { value: number; list: Notification[] }) {
  const { tab, loading, onClickCard, mark_all_read } = useNotificationContext()

  if (tab !== value) {
    return null
  }
  return (
    <>
      <If condition={value === 0 || value === 1}>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Tooltip title={translate('mark_all_read')}>
            <LoadingButton
              variant={'outlined'}
              loading={loading}
              onClick={mark_all_read}
              color={'inherit'}
            >
              {loading ? <></> : <DoneAll color={'inherit'} />}
            </LoadingButton>
          </Tooltip>
          <Box sx={{ my: 2 }}></Box>
        </Box>
      </If>
      <Stack direction={'column'} gap={1} sx={{ minHeight: '30vh' }}>
        {toArray(list).map((notification) => (
          <Card
            aria-disabled={loading}
            sx={{
              py: 2,
              px: 1,
              cursor: 'pointer',
              background: notification.unRead ? '#f5f5f5' : 'white'
            }}
            key={notification.id}
            onClick={loading ? () => {} : onClickCard?.(notification)}
          >
            <Typography>{translate(notification)}</Typography>
            <Typography variant={'caption'}>
              {(notification.createdAt ?? new Date()).toLocaleString()}
            </Typography>
          </Card>
        ))}
      </Stack>
    </>
  )
}
