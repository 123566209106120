import { CircularProgress } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { removeExternalId } from '../onesignal'
import { post } from '../utils/api'

export default function LogoutPage() {
  const navigate = useNavigate()

  useEffect(() => {
    window.localStorage.clear()
    removeExternalId().then()
    post('/public/logout', { temp: 'temp' }).finally(() => {
      navigate('/public/login')
    })
  }, [navigate])

  return <CircularProgress />
}
