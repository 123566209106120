import useSWR from 'swr'
import { ITagListResponse } from '../../core/api_request_responses/tag.models'
import { generateQueryString, toArray } from '../../core/helpers'
import { TagResponse } from '../../core/models/tag.response'
import { createTagFromTagResponse } from '../../models/tag.model'

export default function useTags() {
  const { data, mutate } = useSWR<ITagListResponse>(
    generateQueryString('/tag'),
    { refreshInterval: 0 }
  )
  return {
    tags: data?.tags
      ? toArray(data?.tags).map((tag: TagResponse) =>
          createTagFromTagResponse(tag)
        )
      : undefined,
    mutate
  }
}
