import { Box } from '@mui/material'
import { AIContextProvider } from '../../contexts/ai'
import Layout from '../../layouts/page_layout'
import AIDesign from './design'
import AIEmail from './email'
import AIEngineer from './engineer'
import AIHistory from './history'
import AIIt from './it'
import AILimit from './limit'
import AIMarketing from './marketing'
import AIProduct from './product'
import AIProject from './project'
import AISales from './sales'
import AISelect from './select'
import AISeo from './seo'
import AISettings from './settings'
import AISocial from './social'

export default function AIPage() {
  return (
    <AIContextProvider>
      <Layout>
        <Box sx={{ flex: 4 }}>
          <AISelect />
          <AIProject />
          <AISales />
          <AISocial />
          <AISeo />
          <AIMarketing />
          <AIEngineer />
          <AIProduct />
          <AIDesign />
          <AIEmail />
          <AIIt />
          <AILimit />
          <AIHistory />
          <AISettings />
        </Box>
      </Layout>
    </AIContextProvider>
  )
}
