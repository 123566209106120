import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AISales() {
  return (
    <>
      <CreateElevatorPitch />
      <WriteCustomerEmail />
    </>
  )
}

function WriteCustomerEmail() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    customerName: '',
    emailPurpose: '',
    talkingPoints: ''
  })

  const isDisabled =
    !payload?.customerName ||
    !payload.emailPurpose ||
    !payload.talkingPoints ||
    !!loading

  async function send() {
    await sendQuestionTemplate?.({
      questionTemplate: 'sales_write_customer_email',
      payload
    })

    setPayload({
      customerName: '',
      emailPurpose: '',
      talkingPoints: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={send}
      code="sales_write_customer_email"
    >
      <TextField
        disabled={loading}
        value={payload.customerName}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            customerName: event.target.value
          }))
        }
        placeholder={translate('customerName')}
      />
      <TextField
        disabled={loading}
        multiline
        value={payload.emailPurpose}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            emailPurpose: event.target.value
          }))
        }
        placeholder={translate('emailPurpose')}
      />
      <TextField
        disabled={loading}
        multiline
        value={payload.talkingPoints}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            talkingPoints: event.target.value
          }))
        }
        placeholder={translate('talkingPoints')}
      />
    </AIFunctionCard>
  )
}

function CreateElevatorPitch() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    productService: '',
    companyName: '',
    productServicesBenefits: ''
  })
  const isDisabled =
    !payload?.productService ||
    !payload.companyName ||
    !payload?.productServicesBenefits ||
    !!loading

  async function send() {
    await sendQuestionTemplate?.({
      questionTemplate: 'sales_create_elevator_pitch',
      payload
    })
    setPayload({
      productService: '',
      companyName: '',
      productServicesBenefits: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={send}
      code="sales_create_elevator_pitch"
    >
      <TextField
        disabled={loading}
        value={payload.productService}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            productService: event.target.value
          }))
        }
        placeholder={translate('productService')}
      />
      <TextField
        disabled={loading}
        value={payload.companyName}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            companyName: event.target.value
          }))
        }
        placeholder={translate('companyName')}
      />
      <TextField
        disabled={loading}
        multiline
        value={payload.productServicesBenefits}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            productServicesBenefits: event.target.value
          }))
        }
        placeholder={translate('productServicesBenefits')}
      />
    </AIFunctionCard>
  )
}
