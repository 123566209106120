import moment from 'moment'
import * as qs from 'query-string'

export function generateQueryString<T = qs.StringifiableRecord | Object>(
  url: string,
  props?: T
): string | null {
  if (!props) {
    return url
  }
  return qs.stringifyUrl({
    url,
    query: props as unknown as qs.StringifiableRecord
  })
}

export function parseQueryString(url: string) {
  return qs.parse(url)
}

export function parseUrl(url: string) {
  return qs.parseUrl(url)
}

export function toArray<T>(value?: T[] | null): T[] {
  if (!value) {
    return [] as T[]
  }

  if (!Array.isArray(value)) {
    return []
  }

  return value
}

export function toString(value?: unknown): string {
  if (typeof value === 'number' && Number.isSafeInteger(value)) {
    return value.toString()
  }
  if (!value) {
    return ''
  }

  return String(value)
}

export function toNumber(
  value?: string | number | null,
  defaultValue: number = 0
): number {
  if (!value) {
    return defaultValue
  }

  if (typeof value === 'number') {
    return value
  }

  if (Number.isNaN(parseInt(value, 10))) {
    return defaultValue
  }

  if (Number.isSafeInteger(parseInt(value, 10))) {
    return parseInt(value, 10)
  }
  return defaultValue
}

export function getStringOrNull(value?: string): string {
  if (!value) {
    return 'NULL'
  }

  if (value.length === 0) {
    return 'NULL'
  }

  return `'${value}'`
}

export function getNumberOrNull(value?: number): string {
  if (!value) {
    return 'NULL'
  }

  if (Number.isNaN(value) || !Number.isSafeInteger(value)) {
    return 'NULL'
  }

  return `${value}`
}

export function toBoolean(value?: string | undefined | null | number): boolean {
  if (!value) {
    return false
  }

  if (typeof value === 'string' && value.trim().length === 0) {
    return false
  }

  if (value === '""') {
    return false
  }

  if (value === 'false') {
    return false
  }

  return true
}

export function toIdMap<T>(data: T[]): Map<number, T | undefined> {
  // @ts-expect-error
  return new Map([...data.map((d) => [d.id, d])])
}

export function convertISO8601ToString(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${
    date.getDate() + 1
  } ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.000`
}

export function convertISO8601StringToDate(str: string): Date | undefined {
  if (str.trim().length === 0) {
    return undefined
  }
  const date = moment(str)
  if (date.isValid()) {
    return date.toDate()
  }

  return undefined
}

export function convertDateStringToISOString(str: string): string | undefined {
  const date = moment(str)
  if (date.isValid()) {
    return date.toDate().toISOString()
  }

  return undefined
}

export function getPercent(value: number, total: number): number {
  if (total === 0) {
    return 0
  }
  return Math.floor((value / total) * 100)
}

export function bcryptHashNodeToPhp(hash: string): string {
  return hash.replace('$2a$', '$2y$')
}

export function bcryptHashPhpToNode(hash: string): string {
  return hash.replace('$2y$', '$2a$')
}

export async function sleep(ms: number): Promise<void> {
  return await new Promise((resolve) => setTimeout(resolve, ms))
}

export function getDate(str?: string | Date): Date {
  if (!str) {
    return new Date()
  }
  if (typeof str === 'string') {
    try {
      return new Date(str)
    } catch (err) {
      // console.log('get date err', err)
    }
  }

  if (str instanceof Date) {
    return str
  }

  return new Date()
}

export function getRandomItem<T>(arr: T[]): T {
  return arr[(Math.random() * arr.length) | 0]
}
