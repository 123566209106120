import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AIMarketing() {
  return (
    <>
      <CreateCampaignIdeas />
      <WriteSEOArticleBrief />
      <SummarizeProductFeedback />
    </>
  )
}

function CreateCampaignIdeas() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    productService: '',
    targetAudience: '',
    campaignGoals: ''
  })
  const isDisabled =
    !payload.productService ||
    !payload.targetAudience ||
    !payload.campaignGoals ||
    !!loading

  const functionCode = 'marketing_create_campaign_ideas'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      productService: '',
      targetAudience: '',
      campaignGoals: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.productService}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            productService: event.target.value
          }))
        }
        placeholder={translate('productService')}
      />
      <TextField
        disabled={loading}
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
      <TextField
        disabled={loading}
        value={payload.campaignGoals}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            campaignGoals: event.target.value
          }))
        }
        placeholder={translate('campaignGoals')}
      />
    </AIFunctionCard>
  )
}

function WriteSEOArticleBrief() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    targetAudience: '',
    mainKeyword: '',
    contentGoal: '',
    secondaryKeyword: ''
  })
  const isDisabled =
    !payload.targetAudience ||
    !payload.mainKeyword ||
    !payload.contentGoal ||
    !payload.secondaryKeyword ||
    !!loading

  const functionCode = 'marketing_write_seo_article_brief'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      targetAudience: '',
      mainKeyword: '',
      contentGoal: '',
      secondaryKeyword: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
      <TextField
        disabled={loading}
        value={payload.mainKeyword}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            mainKeyword: event.target.value
          }))
        }
        placeholder={translate('mainKeyword')}
      />
      <TextField
        disabled={loading}
        value={payload.secondaryKeyword}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            secondaryKeyword: event.target.value
          }))
        }
        placeholder={translate('secondaryKeyword')}
      />
      <TextField
        disabled={loading}
        value={payload.contentGoal}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            contentGoal: event.target.value
          }))
        }
        placeholder={translate('contentGoal')}
      />
    </AIFunctionCard>
  )
}

function SummarizeProductFeedback() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    productDescription: '',
    feedbackData: ''
  })
  const isDisabled =
    !payload.productDescription || !payload.feedbackData || !!loading

  const functionCode = 'marketing_summarize_product_feedback'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      productDescription: '',
      feedbackData: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.productDescription}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            productDescription: event.target.value
          }))
        }
        placeholder={translate('productDescription')}
      />
      <TextField
        disabled={loading}
        value={payload.feedbackData}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            feedbackData: event.target.value
          }))
        }
        placeholder={translate('feedbackData')}
      />
    </AIFunctionCard>
  )
}
