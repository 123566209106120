import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AIEngineer() {
  return (
    <>
      <WriteTechnicalSpecificationDocument />
      <CreateTestPlan />
      <BugReport />
    </>
  )
}

function WriteTechnicalSpecificationDocument() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    businessRequirements: '',
    systemInvolved: '',
    bestPractices: ''
  })
  const isDisabled =
    !payload.businessRequirements ||
    !payload.systemInvolved ||
    !payload.bestPractices ||
    !!loading

  const functionCode = 'engineering_write_technical_specification_document'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      businessRequirements: '',
      systemInvolved: '',
      bestPractices: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.businessRequirements}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            businessRequirements: event.target.value
          }))
        }
        placeholder={translate('businessRequirements')}
      />
      <TextField
        disabled={loading}
        value={payload.systemInvolved}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            systemInvolved: event.target.value
          }))
        }
        placeholder={translate('systemInvolved')}
      />
      <TextField
        disabled={loading}
        value={payload.bestPractices}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            bestPractices: event.target.value
          }))
        }
        placeholder={translate('bestPractices')}
      />
    </AIFunctionCard>
  )
}

function CreateTestPlan() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    businessRequirements: '',
    systemInvolved: '',
    technicalDesign: '',
    outputCategory: '',
    testingType: ''
  })
  const isDisabled =
    !payload.businessRequirements ||
    !payload.systemInvolved ||
    !payload.technicalDesign ||
    !payload.outputCategory ||
    !payload.testingType ||
    !!loading

  const functionCode = 'engineering_create_test_plan'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      businessRequirements: '',
      systemInvolved: '',
      technicalDesign: '',
      outputCategory: '',
      testingType: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.businessRequirements}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            businessRequirements: event.target.value
          }))
        }
        placeholder={translate('businessRequirements')}
      />
      <TextField
        disabled={loading}
        value={payload.systemInvolved}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            systemInvolved: event.target.value
          }))
        }
        placeholder={translate('systemInvolved')}
      />
      <TextField
        disabled={loading}
        value={payload.technicalDesign}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            technicalDesign: event.target.value
          }))
        }
        placeholder={translate('technicalDesign')}
      />
      <TextField
        disabled={loading}
        value={payload.outputCategory}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            outputCategory: event.target.value
          }))
        }
        placeholder={translate('outputCategory')}
      />
      <TextField
        disabled={loading}
        value={payload.testingType}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            testingType: event.target.value
          }))
        }
        placeholder={translate('testingType')}
      />
    </AIFunctionCard>
  )
}

function BugReport() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    yourContext: ''
  })
  const isDisabled = !payload.yourContext || !!loading

  const functionCode = 'engineering_bug_report'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      yourContext: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.yourContext}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            yourContext: event.target.value
          }))
        }
        placeholder={translate('yourContext')}
      />
    </AIFunctionCard>
  )
}
