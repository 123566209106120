import useSWR from 'swr'
import {
  IGroupRequest,
  IGroupResponse
} from '../../core/api_request_responses/group.models'
import { generateQueryString } from '../../core/helpers'
import { createGroupFromGroupResponse } from '../../models/group.model'

export function useGroup(id: number, props?: IGroupRequest) {
  const { data, mutate } = useSWR<IGroupResponse>(
    generateQueryString(`/group/${id}`, props)
  )

  return {
    group: data?.group ? createGroupFromGroupResponse(data?.group) : undefined,
    mutate
  }
}
