import useSWR from 'swr'
import {
  IProjectRequest,
  IProjectResponse
} from '../../core/api_request_responses/project.models'
import { generateQueryString } from '../../core/helpers'
import { createProjectFromProjectResponse } from '../../models/project.model'

export function useProject(id: number, props: IProjectRequest) {
  const { data, mutate } = useSWR<IProjectResponse>(
    generateQueryString(`/project/${id}`, props)
  )

  return {
    project: data?.project
      ? createProjectFromProjectResponse(data?.project)
      : undefined,
    mutate
  }
}
