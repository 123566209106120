import { RemoveOutlined } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTicketCardContext from '../../../hooks/context_hooks/ticket/use_ticket_card_context'
import moment from '../../../utils/moment'
import { status_width } from './ticket_card'

export function Time() {
  const { ticket } = useTicketCardContext()
  if (!ticket) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          left: status_width + 5,
          width: 50,
          minWidth: 50,
          maxWidth: 50,
          ml: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Skeleton animation={'wave'} width={30} />
        <Skeleton animation={'wave'} width={30} />
      </Box>
    )
  }

  if (!ticket?.endDate) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          left: status_width + 55,
          ml: 1,
          width: 50,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <RemoveOutlined />
      </Box>
    )
  }

  const locale = window.localStorage.getItem('lang')

  let end_date

  if (locale === 'tr') {
    moment.updateLocale('tr', {
      months:
        'Ocak_Şubat_Mart_Nisan_Mayıs_Haziran_Temmuz_Ağustos_Eylül_Ekim_Kasım_Aralık'.split(
          '_'
        ),
      monthsShort: 'Oca_Şub_Mar_Nis_May_Haz_Tem_Ağu_Eyl_Eki_Kas_Ara'.split('_'),
      weekdays: 'Pazar_Pazartesi_Salı_Çarşamba_Perşembe_Cuma_Cumartesi'.split(
        '_'
      ),
      weekdaysShort: 'Paz_Pts_Sal_Çar_Per_Cum_Cts'.split('_'),
      weekdaysMin: 'Pz_Pt_Sa_Ça_Pe_Cu_Ct'.split('_'),
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD.MM.YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm'
      },
      calendar: {
        sameDay: '[bugün saat] LT',
        nextDay: '[yarın saat] LT',
        nextWeek: '[gelecek] dddd [saat] LT',
        lastDay: '[dün] LT',
        lastWeek: '[geçen] dddd [saat] LT',
        sameElse: 'L'
      },
      relativeTime: {
        future: '%s sonra',
        past: '%s önce',
        s: 'birkaç saniye',
        ss: '%d saniye',
        m: 'bir dakika',
        mm: '%d dakika',
        h: 'bir saat',
        hh: '%d saat',
        d: 'bir gün',
        dd: '%d gün',
        M: 'bir ay',
        MM: '%d ay',
        y: 'bir yıl',
        yy: '%d yıl'
      },

      week: {
        dow: 1,
        doy: 7
      }
    })
    moment.locale('tr')
    end_date = moment(ticket?.endDate)
  } else {
    moment.locale('en')
    end_date = moment(ticket?.endDate)
  }
  const is_overdue =
    !end_date.isSameOrAfter(moment()) && ticket?.status?.code !== 'done'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        left: status_width + 55,
        ml: 1,
        width: 50,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography color={is_overdue ? 'error' : 'inherit'} fontWeight="bold">
        {end_date.date().toString()}
      </Typography>
      <Typography color={is_overdue ? 'error' : 'inherit'}>
        {end_date.format('MMM').toUpperCase()}
      </Typography>
    </Box>
  )
}
