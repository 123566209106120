import { createContext, useContext, useState } from 'react'
import { ICheckItemCreateRequest } from '../core/api_request_responses/check_item.models'
import { ERROR_CODES } from '../core/error_codes'
import { CreateResponse } from '../core/models/api.response'
import { useCheckItemList } from '../hooks/list_hooks/use_check_item_list'
import translate from '../hooks/use_localization'
import { CheckItem, Status } from '../models'
import { post } from '../utils/api'

interface PersonalAreaContextProps {
  mutate?: () => Promise<void>
  saveCheckItem?: (
    payload: ICheckItemCreateRequest
  ) => Promise<string | undefined>
  translate: (key?: string) => string
  checkItems?: CheckItem[]
  view?: 'list' | 'kanban'
  setView?: (view: 'list' | 'kanban') => void
  isCreateValid?: (payload?: ICheckItemCreateRequest) => boolean
  statuses: Status[]
  changeCheckItem?: (checkItem: CheckItem) => Promise<void>
}

const defaultProps: PersonalAreaContextProps = {
  translate,
  statuses: [
    { id: 1, code: 'open', tr: 'Açık', en: 'Open', color: '#795daa' },
    { id: 3, code: 'done', tr: 'Tamamlandı', en: 'Done', color: '#64cdac' }
  ]
}

export const PersonalAreaContext =
  createContext<PersonalAreaContextProps>(defaultProps)

export function PersonalAreaContextProvider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}): JSX.Element {
  const [view, setView] = useState<'list' | 'kanban'>('list')

  const { checkItems, mutate, changeCheckItem } = useCheckItemList()

  async function saveCheckItem(
    payload?: ICheckItemCreateRequest
  ): Promise<string | undefined> {
    if (!isCreateValid(payload)) {
      return ERROR_CODES.RECORD_CANNOT_CREATE
    }
    const response = await post<ICheckItemCreateRequest, CreateResponse>(
      '/check-item',
      payload as ICheckItemCreateRequest
    )
    if (response?.statusCode === 200) {
      await mutate()
      return undefined
    }
    return translate(response?.errorCode)
  }

  return (
    <PersonalAreaContext.Provider
      value={{
        ...defaultProps,
        checkItems,
        mutate: async () => {
          await Promise.all([mutate()])
        },
        saveCheckItem,
        view,
        setView,
        changeCheckItem
      }}
    >
      {children}
    </PersonalAreaContext.Provider>
  )
}

export function usePersonalAreaContext(): PersonalAreaContextProps {
  const context = useContext(PersonalAreaContext)
  if (!context) {
    return defaultProps
  }
  return context
}

function isCreateValid(item?: ICheckItemCreateRequest) {
  if (!item) {
    return false
  }

  if (!item?.name || item.name.trim().length === 0) {
    return false
  }

  return true
}
