import { RestartAlt } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'
import { useMemo, useRef, useState } from 'react'

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PieController,
  Title,
  Tooltip
} from 'chart.js/auto'
import { Bar, Chart, getElementAtEvent } from 'react-chartjs-2'
import AutoCompleteSelect from '../../../components/auto_complete_select'
import Card from '../../../components/card'
import If from '../../../components/if'
import TableTotal from '../../../components/ticket_list/table_total'
import { TotalToDateSwitch } from '../../../components/total_date_switch'
import ViewSelect from '../../../components/view_select'
import { useStatisticContext } from '../../../contexts/statistic'
import {
  generateQueryString,
  getPercent,
  toArray,
  toNumber
} from '../../../core/helpers'
import { TicketFilterResponse } from '../../../core/models/ticket_filter.response'
import translate from '../../../hooks/use_localization'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PieController
)

interface Row {
  openTickets: number
  inProgressTickets: number
  doneTickets: number
  feedbackTickets: number
  rejectedTickets: number
  total: number
  progressRate: number
  id: number
  name: string
  criticalTickets: number
  criticalDoneTickets: number
}

export default function ProjectStatuses() {
  const { projects, selectedTab } = useStatisticContext()

  const [view, setView] = useState<'table' | 'bar' | 'pie'>('table')
  const [isTotal, setIsTotal] = useState(true)

  function toggleIsTotal() {
    setIsTotal((prev) => !prev)
  }

  const data: Row[] = useMemo(
    () =>
      isTotal
        ? defaultSort(
            toArray(projects).map((project) => ({
              id: project.id,
              name: project.name,
              ...project.toDate
            }))
          )
        : defaultSort(
            toArray(projects).map((project) => ({
              id: project.id,
              name: project.name,
              ...project.allTime
            }))
          ),
    [projects, isTotal]
  )

  const projectsTotal: Row = useMemo(() => {
    const total: Row = {
      id: -1,
      name: translate('all_projects'),
      openTickets: data.reduce(
        (total, { openTickets }) => openTickets + total,
        0
      ),
      inProgressTickets: data.reduce(
        (total, { inProgressTickets }) => inProgressTickets + total,
        0
      ),
      doneTickets: data.reduce(
        (total, { doneTickets }) => doneTickets + total,
        0
      ),
      feedbackTickets: data.reduce(
        (total, { feedbackTickets }) => feedbackTickets + total,
        0
      ),
      rejectedTickets: data.reduce(
        (total, { rejectedTickets }) => rejectedTickets + total,
        0
      ),
      criticalTickets: data.reduce(
        (total, { criticalTickets }) => criticalTickets + total,
        0
      ),
      criticalDoneTickets: data.reduce(
        (total, { criticalDoneTickets }) => criticalDoneTickets + total,
        0
      ),
      total: data.reduce((total, curr) => curr.total + total, 0),
      progressRate: 0
    }

    total.progressRate = getPercent(total.doneTickets, total.total)
    return total
  }, [projects])

  if (!projects || selectedTab !== 'project') {
    return <></>
  }

  if (view === 'bar') {
    return (
      <>
        <TotalToDateSwitch isTotal={isTotal} toggle={toggleIsTotal} />
        <ViewSelect view={view} setView={setView} />
        <BarView loading={!projects} data={[projectsTotal, ...data]} />
      </>
    )
  }

  if (view === 'pie') {
    return (
      <>
        <TotalToDateSwitch isTotal={isTotal} toggle={toggleIsTotal} />
        <ViewSelect view={view} setView={setView} />
        <PieView data={[projectsTotal, ...data]} isTotal={isTotal} />
      </>
    )
  }

  const { danger, success } = getSuccessList(data)

  return (
    <>
      <TotalToDateSwitch isTotal={isTotal} toggle={toggleIsTotal} />
      <ViewSelect view={view} setView={setView} />
      <TableTotal {...projectsTotal} isToDate={!isTotal} />
      <TableView
        loading={!projects}
        data={data}
        success_list={success}
        danger_list={danger}
      />
    </>
  )
}

function TableView({
  loading,
  data,
  success_list,
  danger_list
}: {
  success_list: number[]
  danger_list: number[]
  loading: boolean
  data: Row[]
}) {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: translate('drawer_projects'),
      width: 400
    },
    {
      field: 'openTickets',
      headerName: translate('status_open'),
      type: 'number'
    },
    {
      field: 'inProgressTickets',
      headerName: translate('status_working_on'),
      type: 'number'
    },
    {
      field: 'doneTickets',
      headerName: translate('status_completed'),
      type: 'number'
    },
    {
      field: 'feedbackTickets',
      headerName: translate('status_feedback'),
      type: 'number'
    },
    {
      field: 'rejectedTickets',
      headerName: translate('status_rejected'),
      type: 'number'
    },
    {
      field: 'total',
      headerName: translate('total'),
      type: 'number'
    },
    {
      field: 'progressRate',
      headerName: translate('success_rate'),
      type: 'number',
      renderCell: (row) => <Typography>{`%${toNumber(row.value)}`}</Typography>,
      width: 150
    },
    {
      field: 'criticalTickets',
      headerName: translate('criticalTickets'),
      type: 'number'
    },
    {
      field: 'criticalDoneTickets',
      headerName: translate('criticalDoneTickets'),
      type: 'number'
    }
  ]

  const [sortModel, setSortModel] = useState<GridSortModel>(default_sort_model)

  return (
    <Box
      sx={{
        '& .super-app-theme--success': {
          bgcolor: 'rgba(0, 255, 0, 0.1)'
        },

        '& .super-app-theme--danger': {
          bgcolor: 'rgba(255, 0, 0, 0.1)'
        },
        mt: 3
      }}
    >
      <If condition={!isDefaultSortModel(sortModel)}>
        <Box width={'100%'} display={'flex'} flexDirection={'row-reverse'}>
          <IconButton onClick={() => setSortModel(default_sort_model)}>
            <RestartAlt />
          </IconButton>
        </Box>
      </If>
      <DataGrid
        autoHeight={true}
        loading={loading}
        rows={data}
        onSortModelChange={(newSortModal) => setSortModel(newSortModal)}
        sortModel={default_sort_model}
        columns={columns}
        getRowClassName={(params) => {
          if (data.length < 8) {
            return ''
          }
          if (!isDefaultSortModel(sortModel)) {
            return ''
          }
          if (success_list.includes(params.row.id)) {
            return 'super-app-theme--success'
          }
          if (danger_list.includes(params.row.id)) {
            return 'super-app-theme--danger'
          }
          return ''
        }}
        pageSize={30}
        rowsPerPageOptions={[30]}
        disableSelectionOnClick
      />
    </Box>
  )
}

function BarView({ data }: { loading: boolean; data: Row[] }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      }
    }
  }

  const labels = toArray(data).map((item) => item.name)
  const _data = {
    labels,
    datasets: [
      {
        label: translate('status_open'),
        data: data.map(({ openTickets }) => openTickets),
        backgroundColor: '#795daa'
      },
      {
        label: translate('status_working_on'),
        data: data.map(({ inProgressTickets }) => inProgressTickets),
        backgroundColor: '#5ab4d6'
      },
      {
        label: translate('status_completed'),
        data: data.map(({ doneTickets }) => doneTickets),
        backgroundColor: '#64cdac'
      },
      {
        label: translate('status_feedback'),
        data: data.map(({ feedbackTickets }) => feedbackTickets),
        backgroundColor: '#e7a745'
      },
      {
        label: translate('status_rejected'),
        data: data.map(({ rejectedTickets }) => rejectedTickets),
        backgroundColor: '#ec5f55'
      },
      {
        label: translate('success_rate'),
        data: data.map(({ progressRate }) => progressRate),
        backgroundColor: 'rgba(211, 211, 211, 1)'
      },
      {
        label: translate('criticalTickets'),
        data: data.map(({ criticalTickets }) => criticalTickets),
        backgroundColor: 'red'
      },
      {
        label: translate('criticalDoneTickets'),
        data: data.map(({ criticalDoneTickets }) => criticalDoneTickets),
        backgroundColor: 'green'
      }
    ]
  }
  return <Bar options={options} data={_data} />
}

function PieView({ data, isTotal }: { data: Row[]; isTotal: boolean }) {
  const { translate } = useStatisticContext()
  const chartRef = useRef(null)
  const [selected_project_id, set_selected_project_id] = useState<
    string | null
  >(data[0].name)

  const selected_project = toArray(data).find(
    ({ name }) => name === selected_project_id
  )
  const _data = {
    labels: [
      translate('status_open'),
      translate('status_working_on'),
      translate('status_completed'),
      translate('status_feedback'),
      translate('status_rejected')
    ],
    datasets: [
      {
        data: [
          selected_project?.openTickets,
          selected_project?.inProgressTickets,
          selected_project?.doneTickets,
          selected_project?.feedbackTickets,
          selected_project?.rejectedTickets
        ],
        backgroundColor: [
          '#795daa',
          '#5ab4d6',
          '#64cdac',
          '#e7a745',
          '#ec5f55'
        ],
        borderWidth: 1
      }
    ]
  }

  const options: string[] = useMemo(
    () => toArray(data).map(({ name }) => name),
    [data]
  )

  let text = ''
  if (!isTotal) {
    text += translate('until_today') + ' '
  }
  const total =
    toNumber(selected_project?.openTickets) +
    toNumber(selected_project?.inProgressTickets) +
    toNumber(selected_project?.doneTickets) +
    toNumber(selected_project?.feedbackTickets) +
    toNumber(selected_project?.rejectedTickets)
  text += `${selected_project?.name ?? ''} ${translate(
    'sum'
  )} ${total} ticket. ${translate('completion_rate')} %${toNumber(
    selected_project?.progressRate
  )} `

  return (
    <Stack gap={3} sx={{ mt: 3 }}>
      <AutoCompleteSelect
        placeholder={translate('project')}
        value={selected_project?.name}
        options={options}
        onChange={(value) => value && set_selected_project_id(value)}
      />

      <Box
        display={'grid'}
        gridTemplateColumns={{
          xs: '1fr',
          md: '450px auto'
        }}
      >
        <Box sx={{ maxWidth: 430 }}>
          <Typography variant="h3">{selected_project?.name}</Typography>
          <Box sx={{ maxWidth: 400 }}>
            <Chart
              ref={chartRef}
              type="pie"
              data={_data}
              onClick={(event) => {
                if (!chartRef.current) {
                  return
                }
                const filter = new TicketFilterResponse()
                if (toNumber(selected_project?.id) > 0) {
                  filter.projectIds = [toNumber(selected_project?.id)]
                }

                const element = getElementAtEvent(chartRef.current, event)
                const index = toNumber(element?.[0]?.index)
                switch (index) {
                  case 0:
                    filter.statusFilters = ['open']
                    break
                  case 1:
                    filter.statusFilters = ['inprogress']
                    break
                  case 2:
                    filter.statusFilters = ['completed']
                    break
                  case 3:
                    filter.statusFilters = ['feedback']
                    break
                  case 4:
                    filter.statusFilters = ['rejected']
                    break
                }

                const local = 'https://app.theticketapp.co'
                window
                  .open(
                    generateQueryString(local + '/tickets', filter) ??
                      local + '/tickets',
                    '_blank'
                  )
                  ?.focus()
              }}
            />
          </Box>
        </Box>
        <Box>
          <Card sx={{ mb: 3 }}>
            <Typography>{text}</Typography>
            <Box
              component={'ul'}
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr'
              }}
            >
              <li>
                <b>{selected_project?.openTickets}</b> {translate('open')}
              </li>
              <li>
                <b>{selected_project?.inProgressTickets}</b>{' '}
                {translate('in_progress')}
              </li>
              <li>
                <b>{selected_project?.doneTickets}</b> {translate('done')}
              </li>
              <li>
                <b>{selected_project?.feedbackTickets}</b>{' '}
                {translate('feedback')}
              </li>
              <li>
                <b>{selected_project?.rejectedTickets}</b>{' '}
                {translate('rejected')}
              </li>
            </Box>
          </Card>
        </Box>
      </Box>
    </Stack>
  )
}

const default_sort_model: GridSortModel = [
  {
    field: 'progressRate',
    sort: 'desc'
  },
  {
    field: 'total',
    sort: 'desc'
  }
]

function isDefaultSortModel(sortModel: GridSortModel) {
  return sortModel.every(
    (item, index) =>
      item.field === default_sort_model[index].field &&
      item.sort === default_sort_model[index].sort
  )
}

function getSuccessList(projects: Row[]): {
  success: number[]
  danger: number[]
} {
  if (!projects) {
    return { success: [], danger: [] }
  }

  const sorted_list = defaultSort(projects)

  return {
    success: sorted_list
      .slice(0, 3)
      .map(({ id }) => id)
      .filter(Boolean),
    danger: sorted_list
      .slice(sorted_list.length - 3)
      .map(({ id }) => id)
      .filter(Boolean)
  }
}

function defaultSort(rows: Row[]) {
  return rows.slice(0).sort((a, b) => {
    const aRate = a.progressRate
    const bRate = b.progressRate

    const aTotal = a.total
    const bTotal = b.total

    if (aRate !== bRate) {
      return bRate - aRate
    }

    if (aTotal !== bTotal) {
      return bTotal - aTotal
    }

    return b.id - a.id
  })
}
