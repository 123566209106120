import { CheckOutlined, RestartAltOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/system'
import { useRef, useState } from 'react'
import { ICheckItemUpdateRequest } from '../../core/api_request_responses/check_item.models'
import {
  ITicketDeleteCheckItemRequest,
  ITicketToggleCheckItemRequest,
  ITicketUpdateCheckItemRequest
} from '../../core/api_request_responses/ticket.models'
import { toArray, toString } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { CheckItem } from '../../models'
import { put } from '../../utils/api'
import DialogProvider from '../dialog_provider'
import If from '../if'

export function CheckItemList({
  ticketId,
  checkItemList,
  mutate,
  onChange,
  onDelete
}: {
  checkItemList?: CheckItem[]
  mutate?: () => Promise<void>
  ticketId?: number
  onChange?: (newCheckItem: CheckItem) => void
  onDelete?: (itemId: number) => void
}) {
  const [selectedItem, setSelectedItem] = useState<CheckItem | undefined>()
  const [loading, setLoading] = useState(0)
  function setClose() {
    if (loading) {
      return
    }
    setSelectedItem(undefined)
  }

  async function toggle(checkItem: CheckItem) {
    setLoading(checkItem.id)
    if (ticketId) {
      await put<ITicketToggleCheckItemRequest, undefined>(
        `/ticket/${ticketId}/toggle-check-item`,
        { checkItemId: checkItem.id }
      )
    } else {
      await put(`/check-item/${checkItem.id}/toggle`, { temp: 'temp' })
    }
    await mutate?.()
    onChange?.({ ...checkItem, isDone: !checkItem.isDone })
    setLoading(0)
  }

  if (!checkItemList) {
    return null
  }

  if (checkItemList.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Typography>{translate('no_check_item')}</Typography>
      </Box>
    )
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('order')}</TableCell>
              <TableCell>{translate('status')}</TableCell>
              <TableCell width={'100%'}>{translate('checkitem')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toArray(checkItemList)
              .sort((a) => (a.isDone ? 1 : -1))
              .map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    opacity: row.isDone ? 0.5 : 1,
                    '&:last-child td, &:last-child th': {
                      border: 0
                    }
                  }}
                >
                  <TableCell width={'2rem'} component="th">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th">
                    <If condition={!row.isDone}>
                      <LoadingButton
                        loading={loading === row.id}
                        disabled={!!loading && loading !== row.id}
                        onClick={async () => await toggle(row)}
                      >
                        {loading ? <></> : <CheckOutlined color={'success'} />}
                      </LoadingButton>
                    </If>
                    <If condition={row.isDone}>
                      <LoadingButton
                        loading={loading === row.id}
                        disabled={!!loading && loading !== row.id}
                        onClick={async () => await toggle(row)}
                      >
                        {loading ? (
                          <></>
                        ) : (
                          <RestartAltOutlined color={'success'} />
                        )}
                      </LoadingButton>
                    </If>
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ cursor: loading ? 'inherit' : 'pointer' }}
                    width={'100%'}
                    onClick={() => !loading && setSelectedItem(row)}
                  >
                    {row.name}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedItem ? (
        <CheckItemEdit
          item={selectedItem}
          mutate={mutate}
          isOpen={!!selectedItem}
          setClose={setClose}
          ticketId={ticketId}
          onChange={onChange}
          onDelete={onDelete}
        />
      ) : (
        <></>
      )}
    </>
  )
}

function CheckItemEdit({
  item,
  mutate,
  isOpen,
  setClose,
  ticketId,
  onChange,
  onDelete
}: {
  item: CheckItem
  mutate?: () => Promise<void>
  isOpen: boolean
  setClose: () => void
  ticketId?: number
  onChange?: (newCheckItem: CheckItem) => void
  onDelete?: (itemId: number) => void
}) {
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<
    Partial<ITicketUpdateCheckItemRequest | ICheckItemUpdateRequest>
  >({ name: item.name, checkItemId: item.id })
  const apiError = useRef<string | undefined>()

  function set_close() {
    if (loading) {
      return
    }
    apiError.current = undefined
    setPayload({
      name: item.name,
      checkItemId: item.id
    })
    setClose()
  }

  function checkPayload(): boolean {
    if (!payload?.name || payload.name.trim().length === 0) {
      return false
    }

    return true
  }

  async function save() {
    if (!checkPayload()) {
      return
    }

    setLoading(true)
    let statusCode = 0
    if (ticketId) {
      const response = await put<ITicketUpdateCheckItemRequest, undefined>(
        `/ticket/${ticketId}/update-check-item`,
        payload as unknown as ITicketUpdateCheckItemRequest
      )
      statusCode = response.statusCode
    } else {
      const response = await put<ICheckItemUpdateRequest, undefined>(
        `/check-item/${item.id}`,
        payload as unknown as ICheckItemUpdateRequest
      )
      statusCode = response.statusCode
    }
    await mutate?.()
    if (statusCode === 200) {
      onChange?.({ ...item, name: toString(payload?.name) })
      setLoading(false)
      set_close()
    }
  }

  async function deleteItem() {
    setLoading(true)
    let statusCode = 0

    if (ticketId) {
      const response = await put<ITicketDeleteCheckItemRequest, undefined>(
        `/ticket/${ticketId}/delete-check-item`,
        {
          checkItemId: item.id
        }
      )
      statusCode = response.statusCode
    } else {
      const response = await put(`/check-item/${item.id}/delete`, {
        temp: 'temp'
      })
      statusCode = response.statusCode
    }

    await mutate?.()

    if (statusCode === 200) {
      onDelete?.(item.id)
      setLoading(false)
      set_close()
    }
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({ ...payload, name: event.target.value })
  }

  return (
    <>
      <DialogProvider open={isOpen} set_close={set_close}>
        <DialogTitle>{translate('checkitem_title')}</DialogTitle>
        <DialogContent>
          <If condition={!!apiError.current}>
            <Typography>{apiError.current}</Typography>
          </If>
          <Stack
            direction={{
              xs: 'column',
              md: 'row'
            }}
            columnGap={2}
          >
            <Stack mt={2} gap={4}>
              <TextField
                disabled={loading}
                label={translate('name')}
                required={true}
                fullWidth
                value={payload?.name}
                onChange={handleChangeName}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            onClick={deleteItem}
            variant={'outlined'}
            color="error"
          >
            {translate('_delete')}
          </LoadingButton>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!checkPayload()}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('update')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}
