import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import * as XLSX from 'xlsx'

import { AddOutlined, EditOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack
} from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import Card from '../../components/card'
import DialogProvider from '../../components/dialog_provider'
import GroupCard from '../../components/group_card'
import If from '../../components/if'
import LayerCard from '../../components/layer_card'
import TagCard from '../../components/tag_card'
import UserCard from '../../components/user_card'
import {
  ProjectDetailContextProvider,
  useProjectDetailContext
} from '../../contexts/project_detail'
import {
  IProjectAddGroupRequest,
  IProjectAddLayerRequest,
  IProjectAddUserRequest,
  IProjectGetExcelResponse,
  IProjectUpdateLayerRequest,
  IProjectUpdateRequest,
  IProjectUploadFileResponse
} from '../../core/api_request_responses/project.models'
import {
  ITagCreateRequest,
  ITagUpdateRequest
} from '../../core/api_request_responses/tag.models'
import {
  convertDateStringToISOString,
  toArray,
  toNumber,
  toString
} from '../../core/helpers'
import translate from '../../hooks/use_localization'
import Layout from '../../layouts/page_layout'
import { Group, Layer, Tag, User } from '../../models'
import { get, postFormData } from '../../utils/api'

import { HexColorPicker } from 'react-colorful'
import CollapsibleRow from '../../components/collapsible_row'
import { removeDuplicates } from '../../utils'

export default function ProjectPage() {
  const { id } = useParams<{ id: string }>()
  return (
    <ProjectDetailContextProvider id={toNumber(id)}>
      <Layout>
        <ProjectInfo />
        <ProjectTags />
        <ProjectLayers />
        <ProjectGroups />
        <ProjectUsers />
      </Layout>
    </ProjectDetailContextProvider>
  )
}

function ProjectInfo() {
  const { project } = useProjectDetailContext()
  if (!project) {
    return <></>
  }
  return (
    <Card>
      <Typography>{project.name}</Typography>
      <br />
      <Typography>{project.manager.name}</Typography>
      <br />
      <If condition={!project.isPrivate}>
        <>
          <Typography>
            {translate('start_date')} :{' '}
            {project?.startDate?.toLocaleDateString() ??
              translate('not_specified')}
          </Typography>
          <br />
        </>
      </If>
      <If condition={!project.isPrivate}>
        <>
          <Typography>
            {translate('end_date')} :{' '}
            {project?.endDate?.toLocaleDateString() ??
              translate('not_specified')}
          </Typography>
          <br />
        </>
      </If>
      <If condition={!project.isPrivate}>
        <>
          <Typography>
            {translate('event_start_date')} :
            {project?.eventStartDate?.toLocaleDateString() ??
              translate('not_specified')}
          </Typography>
          <br />
        </>
      </If>
      <If condition={!project.isPrivate}>
        <>
          <Typography>
            {translate('event_end_date')} :{' '}
            {project?.eventEndDate?.toLocaleDateString() ??
              translate('not_specified')}
          </Typography>
          <br />
        </>
      </If>

      <UploadTicketDialog />
      <EditProjectDialog />
      <ExcelReportDialog />
    </Card>
  )
}

function UploadTicketDialog() {
  const { project, isManager } = useProjectDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const apiMessage = useRef<string>()

  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const onClickHandler = () => {
    fileInputRef.current?.click()
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length || loading) {
      return
    }

    const formData = new FormData()

    Array.from(event.target.files).forEach((file) => {
      formData.append('file', file)
    })

    setLoading(true)
    postFormData<IProjectUploadFileResponse>(
      `/project/${toNumber(project?.id)}/upload-file`,
      formData
    )
      .then((response) => {
        formRef.current?.reset()
        if (response.statusCode === 200) {
          if (response?.payload?.isSuccess) {
            apiMessage.current = translate('ticket_upload_success')
            return
          } else {
            apiMessage.current = `${translate(
              'ticket_upload_error1'
            )} : ${toArray(response?.payload?.users).join(', ')},  ${translate(
              'ticket_upload_error2'
            )}  : ${toArray(response?.payload?.groups).join(', ')}`
          }
          return
        }

        apiMessage.current = translate(response?.errorCode)
      })
      .finally(() => setLoading(false))
  }

  function set_close() {
    if (loading) {
      return
    }

    apiMessage.current = undefined

    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  if (!project || !isManager) {
    return <></>
  }
  return (
    <>
      <Button onClick={set_open} variant={'outlined'}>
        {translate('ticket_upload')}
      </Button>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle> {translate('ticket_upload')}</DialogTitle>
        <DialogContent>
          <Stack mt={2} rowGap={4}>
            <If condition={apiMessage.current}>
              <Alert
                severity={
                  apiMessage.current === translate('ticket_upload_success')
                    ? 'success'
                    : 'error'
                }
              >
                {apiMessage.current}
              </Alert>
            </If>

            <Button
              variant={'outlined'}
              href={
                'https://ticketdev.s3.eu-central-1.amazonaws.com/aktarim.xlsx'
              }
              target={'_blank'}
            >
              {translate('example_file')}
            </Button>

            <form ref={formRef}>
              <br />
              <LoadingButton
                sx={{ alignSelf: 'center' }}
                variant={'contained'}
                loading={loading}
                onClick={onClickHandler}
              >
                {translate('select_upload_file')}
              </LoadingButton>
              <input
                multiple={false}
                disabled={loading}
                name={'file'}
                onChange={onChangeHandler}
                ref={fileInputRef}
                accept={
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                style={{ display: 'none' }}
                type="file"
              />
            </form>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function EditProjectDialog() {
  const { project, updateProject, isManager } = useProjectDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<IProjectUpdateRequest>>({})

  useEffect(() => {
    if (project && !payload?.name) {
      setPayload({
        managerId: project.manager.id,
        name: project.name,
        startDate: project?.startDate
          ? moment(project.startDate).format('YYYY-MM-DD')
          : undefined,
        endDate: project?.endDate
          ? moment(project.endDate).format('YYYY-MM-DD')
          : undefined,
        eventStartDate: project?.eventStartDate
          ? moment(project.eventStartDate).format('YYYY-MM-DD')
          : undefined,
        eventEndDate: project?.eventEndDate
          ? moment(project.eventEndDate).format('YYYY-MM-DD')
          : undefined
      })
    }
  }, [project, is_open])

  function checkPayload(): boolean {
    if (!payload?.name || payload.name.trim().length === 0) {
      return false
    }
    if (!payload?.managerId) {
      return false
    }
    return true
  }

  function set_close() {
    if (loading) {
      return
    }
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.name || !payload?.managerId) {
      return
    }
    setLoading(true)
    if (payload?.eventEndDate) {
      payload.eventEndDate = convertDateStringToISOString(payload.eventEndDate)
    } else {
      payload.eventEndDate = undefined
    }

    if (payload?.eventStartDate) {
      payload.eventStartDate = convertDateStringToISOString(
        payload.eventStartDate
      )
    } else {
      payload.eventStartDate = undefined
    }

    if (payload?.startDate) {
      payload.startDate = convertDateStringToISOString(payload.startDate)
    } else {
      payload.startDate = undefined
    }

    if (payload?.endDate) {
      payload.endDate = convertDateStringToISOString(payload.endDate)
    } else {
      payload.endDate = undefined
    }

    await updateProject?.(payload as unknown as IProjectUpdateRequest)
    set_close()
    setLoading(false)
  }

  function handleChangeManagerId(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      managerId: toNumber(event.target.value)
    })
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      name: event.target.value
    })
  }

  function handleChangeStartDate(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      startDate: event.target.value
    })
  }

  function handleChangeEndDate(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      endDate: event.target.value
    })
  }

  function handleChangeEventStartDate(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setPayload({
      ...payload,
      eventStartDate: event.target.value
    })
  }

  function handleChangeEventEndDate(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setPayload({
      ...payload,
      eventEndDate: event.target.value
    })
  }

  if (!project || !isManager || project.isPrivate) {
    return <></>
  }

  const users = removeDuplicates(
    toArray(project.layers.flatMap((layer) => layer.users))
  ).filter(Boolean) as User[]

  return (
    <>
      <IconButton onClick={set_open}>
        <EditOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('update_project')}</DialogTitle>
        <DialogContent>
          <Stack mt={2} rowGap={4}>
            <TextField
              label={translate('project_name')}
              required={true}
              fullWidth
              value={payload?.name}
              onChange={handleChangeName}
            />

            <TextField
              label={translate('manager')}
              required={true}
              fullWidth
              value={payload?.managerId}
              select
              onChange={handleChangeManagerId}
            >
              {users.map((user: User) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label={translate('start_date')}
              type="date"
              value={payload?.startDate}
              onChange={handleChangeStartDate}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
            />

            <TextField
              label={translate('end_date')}
              type="date"
              value={payload?.endDate}
              onChange={handleChangeEndDate}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
            />

            <TextField
              label={translate('event_start_date')}
              type="date"
              value={payload?.eventStartDate}
              onChange={handleChangeEventStartDate}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
            />

            <TextField
              label={translate('event_end_date')}
              type="date"
              value={payload?.eventEndDate}
              onChange={handleChangeEventEndDate}
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!checkPayload()}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('update')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function ProjectTags() {
  const { project } = useProjectDetailContext()
  if (!project) {
    return <></>
  }

  return (
    <CollapsibleRow
      title={translate('project_tags')}
      AddComponent={() => <AddTagDialog />}
      EditComponent={() => <UpdateTagDialog />}
      badge={toArray(project.tags).length}
    >
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {toArray(project.tags).map((tag: Tag) => (
          <TagCard tag={tag} key={tag.id} />
        ))}
      </Box>
    </CollapsibleRow>
  )
}

function AddTagDialog() {
  const { project, create_tag } = useProjectDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<ITagCreateRequest>>({})

  function set_close() {
    if (loading) {
      return
    }
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.name || payload.name.trim().length === 0 || !payload?.color) {
      return
    }
    setLoading(true)
    await create_tag?.(payload as ITagCreateRequest)
    set_close()
    setLoading(false)
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      name: event.target.value
    })
  }

  if (!project) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={set_open}>
        <AddOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('add_tag')}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 3 }}
            fullWidth
            value={payload?.name}
            label={translate('tag_name')}
            onChange={handleChangeName}
          />
          <br />
          <Typography> {translate('tag_color')}</Typography>
          <br />
          <HexColorPicker
            color={payload?.color}
            onChange={(color) => setPayload((prev) => ({ ...prev, color }))}
          />

          <br />
          <TagCard tag={{ ...new Tag(), ...payload }} />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.name}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('add')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function UpdateTagDialog() {
  const { project, update_tag } = useProjectDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<
    Partial<ITagUpdateRequest & { id: number }>
  >({ id: 0 })

  function set_close() {
    if (loading) {
      return
    }
    setPayload({ id: 0 })
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.name || !payload?.color || payload.name.trim().length === 0) {
      return
    }
    setLoading(true)
    await update_tag?.(toNumber(payload?.id), payload as ITagUpdateRequest)
    set_close()
    setLoading(false)
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      name: event.target.value
    })
  }

  function handleChangeTag(event: React.ChangeEvent<HTMLInputElement>) {
    const targetId = toNumber(event.target.value)
    const targetTag = toArray(project?.tags).find((tag) => tag.id === targetId)
    if (!targetTag) {
      setPayload({ id: 0 })
    } else {
      setPayload({
        id: targetTag.id,
        name: targetTag.name,
        color: targetTag.color
      })
    }
  }

  if (!project) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={set_open}>
        <EditOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('edit_tag')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={payload?.id}
            select
            onChange={handleChangeTag}
          >
            {toArray(project?.tags).map((tag: Tag) => (
              <MenuItem key={tag.id} value={tag.id}>
                {tag.name}
              </MenuItem>
            ))}
          </TextField>

          <br />

          <TextField
            sx={{ mt: 3 }}
            fullWidth
            value={payload?.name}
            label={translate('tag_name')}
            onChange={handleChangeName}
          />

          <br />
          <Typography>{translate('tag_color')}</Typography>
          <br />
          <HexColorPicker
            color={payload?.color}
            onChange={(color) => setPayload((prev) => ({ ...prev, color }))}
          />

          <br />
          <TagCard tag={{ ...new Tag(), ...payload }} />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.name || !payload.color}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('save')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function ProjectLayers() {
  const { project } = useProjectDetailContext()
  if (!project) {
    return <></>
  }

  return (
    <CollapsibleRow
      title={translate('layers')}
      AddComponent={() => <AddLayerDialog />}
      EditComponent={() => <UpdateLayerDialog />}
      badge={toArray(project.layers).length}
    >
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {toArray(project.layers).map((layer: Layer) => (
          <LayerCard layer={layer} key={layer.id} />
        ))}
      </Box>
    </CollapsibleRow>
  )
}

function AddLayerDialog() {
  const { project, isManager, create_layer } = useProjectDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<IProjectAddLayerRequest>>({})

  function set_close() {
    if (loading) {
      return
    }
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.name || payload.name.trim().length === 0) {
      return
    }
    setLoading(true)
    await create_layer?.(payload as IProjectAddLayerRequest)
    set_close()
    setLoading(false)
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      name: event.target.value
    })
  }

  if (!project || !isManager) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={set_open}>
        <AddOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('add_layer')}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 3 }}
            fullWidth
            value={payload?.name}
            label={translate('name')}
            onChange={handleChangeName}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.name}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('save')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function UpdateLayerDialog() {
  const { project, isManager, update_layer } = useProjectDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<IProjectUpdateLayerRequest>>(
    {}
  )

  function set_close() {
    if (loading) {
      return
    }
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (
      !payload?.name ||
      !payload?.layerId ||
      payload.name.trim().length === 0
    ) {
      return
    }
    setLoading(true)
    await update_layer?.(payload as IProjectUpdateLayerRequest)
    set_close()
    setLoading(false)
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      name: event.target.value
    })
  }

  function handleChangeLayer(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      layerId: toNumber(event.target.value)
    })
  }

  if (!project || !isManager) {
    return <></>
  }
  return (
    <>
      <IconButton onClick={set_open}>
        <EditOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('edit_layer')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={payload?.layerId}
            select
            onChange={handleChangeLayer}
          >
            {toArray(project?.layers).map((layer: Layer) => (
              <MenuItem key={layer.id} value={layer.id}>
                {layer.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            sx={{ mt: 3 }}
            fullWidth
            value={payload?.name}
            label={translate('name')}
            onChange={handleChangeName}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.name || !payload.layerId}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('save')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function ProjectGroups() {
  const { project } = useProjectDetailContext()
  if (!project) {
    return <></>
  }

  const groups = toArray(
    removeDuplicates(project.layers.flatMap((layer) => layer.groups))
  ).filter(Boolean) as Group[]

  return (
    <CollapsibleRow
      title={translate('project_groups')}
      AddComponent={() => <AddGroupDialog />}
      badge={groups.length}
    >
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {groups.map((group: Group) => (
          <GroupCard group={group} key={group.id} />
        ))}
      </Box>
    </CollapsibleRow>
  )
}

function AddGroupDialog() {
  const { project, isManager, add_group_to_project, groups } =
    useProjectDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<IProjectAddGroupRequest>>({})

  function set_close() {
    if (loading) {
      return
    }
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.groupId || !payload?.layerId) {
      return
    }
    setLoading(true)
    await add_group_to_project?.(payload as unknown as IProjectAddGroupRequest)
    set_close()
    setLoading(false)
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      groupId: toNumber(event.target.value)
    })
  }

  function handleChangeLayer(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      layerId: toNumber(event.target.value)
    })
  }

  if (!project || !isManager || project.isPrivate) {
    return <></>
  }

  const oldList = toArray(project?.layers)
    .find((layer) => layer.id === payload?.layerId)
    ?.groups?.map(({ id }) => id)
    .filter(Boolean)
  const newGroups = toArray(groups).filter(
    (group) => !toArray(oldList)?.includes(group.id)
  )

  return (
    <>
      <IconButton onClick={set_open}>
        <AddOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('add_group_project')}</DialogTitle>
        <DialogContent>
          <TextField
            label={translate('layer')}
            fullWidth
            value={payload?.layerId}
            select
            onChange={handleChangeLayer}
          >
            {toArray(project?.layers).map((layer: Layer) => (
              <MenuItem key={layer.id} value={layer.id}>
                {layer.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={!payload?.layerId}
            label={translate('group')}
            sx={{ mt: 2 }}
            fullWidth
            value={payload?.groupId}
            select
            onChange={handleChange}
          >
            {newGroups.map((group: Group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.groupId || !payload?.layerId}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('add')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function ProjectUsers() {
  const { project } = useProjectDetailContext()
  if (!project) {
    return <></>
  }

  const managerId = project.manager.id

  const users = removeDuplicates(
    toArray(project.layers.flatMap((layer) => layer.users))
  ).filter(Boolean) as User[]

  return (
    <CollapsibleRow
      title={translate('users')}
      AddComponent={() => <AddUserDialog />}
      badge={users.length}
    >
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {users.map((user: User) => (
          <UserCard
            user={user}
            key={user.id}
            additionalText={
              user.id === managerId ? translate('manager') : undefined
            }
          />
        ))}
      </Box>
    </CollapsibleRow>
  )
}

function AddUserDialog() {
  const { project, isManager, add_user_to_project, users } =
    useProjectDetailContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<IProjectAddUserRequest>>({})

  function set_close() {
    if (loading) {
      return
    }
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.userId) {
      return
    }
    setLoading(true)
    await add_user_to_project?.(payload as unknown as IProjectAddUserRequest)
    set_close()
    setLoading(false)
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      userId: toNumber(event.target.value)
    })
  }

  function handleChangeLayer(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      layerId: toNumber(event.target.value)
    })
  }

  if (!project || !isManager || project.isPrivate) {
    return <></>
  }

  const oldList = toArray(project?.layers)
    .find((layer) => layer.id === payload?.layerId)
    ?.users?.map(({ id }) => id)
    .filter(Boolean)
  const newUsers = toArray(users).filter(
    (user) => !toArray(oldList)?.includes(user.id)
  )

  return (
    <>
      <IconButton onClick={set_open}>
        <AddOutlined color={'success'} />
      </IconButton>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('add_user_to_project')}</DialogTitle>
        <DialogContent>
          <TextField
            label={translate('layer')}
            fullWidth
            value={payload?.layerId}
            select
            onChange={handleChangeLayer}
          >
            {toArray(project?.layers).map((layer: Layer) => (
              <MenuItem key={layer.id} value={layer.id}>
                {layer.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            disabled={!payload?.layerId}
            fullWidth
            sx={{ mt: 2 }}
            label={translate('user')}
            value={payload?.userId}
            select
            onChange={handleChange}
          >
            {newUsers.map((user: User) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!payload?.userId || !payload?.layerId}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('add')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function ExcelReportDialog() {
  const { project, isManager } = useProjectDetailContext()
  const [loading, setLoading] = useState(false)

  async function downloadExcelReport() {
    if (!project) {
      return
    }
    setLoading(true)
    const response = await get<IProjectGetExcelResponse>(
      `/project/${project.id}/excel`
    )
    if (response.statusCode !== 200 || !response?.payload) {
      alert('Excel donusumunde hata')
      return
    }

    const tickets = toArray(response?.payload?.tickets).map((ticket) => ({
      id: toString(ticket.id),
      name: ticket.name,
      layer: ticket.layer.name,
      assigned: ticket.assigned.name,
      status: translate(ticket.status),
      priority: translate(ticket.priority),
      tags: toArray(ticket.tags)
        .map(({ name }) => name)
        .join(', '),
      createdBy: ticket.createdBy.name,
      long: toString(ticket?.long),
      lat: toString(ticket?.long),
      description: toString(ticket?.description),
      startDate: toString(ticket?.startDate),
      endDate: toString(ticket?.endDate)
    }))

    tickets.unshift({
      id: 'ID',
      name: translate({ tr: 'İsim', en: 'Name' }),
      layer: translate({ tr: 'Katman', en: 'Layer' }),
      assigned: translate({ tr: 'Atanan', en: 'Assigned' }),
      status: translate({ tr: 'Durum', en: 'Status' }),
      priority: translate({ tr: 'Öncelik', en: 'Priority' }),
      tags: translate({ tr: 'Etiketler', en: 'Tags' }),
      createdBy: translate({ tr: 'Oluşturan', en: 'Created By' }),
      long: 'Longitude',
      lat: 'Latitude',
      description: translate({ tr: 'Açıklama', en: 'Description' }),
      startDate: translate({ tr: 'Başlangıç Zamanı', en: 'Start Date' }),
      endDate: translate({ tr: 'Bitiş Zamanı', en: 'End Date' })
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(tickets, {
      skipHeader: true
    })

    // Workbook'a sayfayı ekleyin
    XLSX.utils.book_append_sheet(workbook, worksheet, project.name)

    // Excel dosyasını oluşturun ve indirin
    XLSX.writeFile(workbook, project.name + '.xlsx')
    setLoading(false)
  }

  if (!project || !isManager) {
    return <></>
  }
  return (
    <>
      <LoadingButton
        onClick={downloadExcelReport}
        variant={'outlined'}
        loading={loading}
      >
        Excel Rapor
      </LoadingButton>
    </>
  )
}
