import { Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Tag } from '../models'

export default function TagCard({
  tag,
  variant
}: {
  tag: Tag
  variant?: 'small' | 'high'
}) {
  return (
    <Card
      sx={{
        backgroundColor: tag?.color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: variant === 'small' ? 0.5 : 1,
        minHeight: variant === 'small' ? '1rem' : '2rem',
        maxWidth: variant === 'small' ? '5rem' : '10rem',
        minWidth: variant === 'small' ? '1rem' : '3rem'
      }}
    >
      <Typography
        textAlign={'center'}
        fontWeight={'500'}
        variant={variant === 'small' ? 'caption' : 'body1'}
        color={'white'}
      >
        {tag.name}
      </Typography>
    </Card>
  )
}
