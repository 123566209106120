import { createContext, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { generateQueryString, toArray, toNumber } from '../core/helpers'
import { useNotifications } from '../hooks/list_hooks/use_notifications'
import translate from '../hooks/use_localization'
import { Notification } from '../models'
import { post } from '../utils/api'

interface NotificationContextProps {
  tab?: number
  translate: (key?: string) => string
  isOpen?: boolean
  setClose?: () => void
  setOpen?: () => void
  onClickCard?: (notification: Notification) => () => Promise<void>
  handleChange?: (event: React.SyntheticEvent, newValue: number) => void
  loading?: boolean
  setLoading?: (newValue: boolean) => void
  unread_count?: number
  mutate?: () => Promise<void>
  mark_all_read?: () => Promise<void>
  allNotifications?: Notification[]
  unReadNotifications?: Notification[]
  ticketNotifications?: Notification[]
  mentionNotifications?: Notification[]
}

export const NotificationContext = createContext<NotificationContextProps>({
  translate
})

export function NotificationContextProvider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}): JSX.Element {
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { notifications, mutate } = useNotifications()

  async function mark_all_read() {
    setLoading(true)
    await post('/notification', {
      temp: 'temp'
    })
    await mutate()
    setLoading(false)
  }

  function setClose() {
    setIsOpen(false)
  }

  function setOpen() {
    setIsOpen(true)
  }

  const list: Notification[] = useMemo(() => {
    return toArray(notifications).sort(sortingFunction)
  }, [notifications])

  const allNotifications: Notification[] = useMemo(() => toArray(list), [list])

  const unReadNotifications: Notification[] = useMemo(
    () => toArray(list).filter(({ unRead }) => unRead),
    [list]
  )

  const ticketNotifications = useMemo(
    () => toArray(list).filter(({ contentType }) => contentType === 'ticket'),
    [list]
  )

  const mentionNotifications = useMemo(
    () => toArray(list).filter(({ contentType }) => contentType === 'comment'),
    [list]
  )

  function handle_click_card(notification: Notification) {
    return async () => {
      await post(`/notification/${toNumber(notification.id)}`, {
        temp: 'temp'
      })
      navigate(
        generateQueryString('/tickets', { ticketId: notification.contentId }) ??
          '/tickets'
      )
      setClose()
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        translate,
        isOpen,
        setClose,
        setOpen,
        handleChange,
        tab: value,
        loading,
        setLoading,
        unread_count: unReadNotifications.length,
        allNotifications,
        unReadNotifications,
        ticketNotifications,
        mentionNotifications,
        onClickCard: handle_click_card,
        mutate: async () => {
          await mutate()
        },
        mark_all_read
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotificationContext(): NotificationContextProps {
  const context = useContext(NotificationContext)
  if (!context) {
    return {
      translate
    }
  }
  return context
}

function sortingFunction(a: Notification, b: Notification) {
  if (a.unRead && !b.unRead) {
    return -1
  }
  if (!a.unRead && b.unRead) {
    return 1
  }

  if (!a?.createdAt || !b?.createdAt) {
    return 0
  }

  return toNumber(b.createdAt.valueOf()) - toNumber(a.createdAt.valueOf())
}
