import { Paper, Tab, Tabs } from '@mui/material'
import { createContext, useContext, useEffect, useState } from 'react'
import {
  IStatisticGroupStatuses,
  IStatisticResponse,
  IStatisticTicketStatuses,
  IStatisticUserActivation
} from '../core/api_request_responses/statistic.models'
import {
  convertDateStringToISOString,
  generateQueryString,
  toArray
} from '../core/helpers'
import useStatuses from '../hooks/list_hooks/use_statuses'
import translate from '../hooks/use_localization'
import Layout from '../layouts/page_layout'
import { Group, Project, Status, User } from '../models'
import { createGroupFromGroupResponse } from '../models/group.model'
import { createProjectFromProjectResponse } from '../models/project.model'
import { createUserFromUserResponse } from '../models/user.model'
import { get } from '../utils/api'

type tabs = 'rollup' | 'project' | 'individual' | 'group' | 'forecast'

interface StatisticContextProps {
  translate: (key?: string) => string
  projects?: (Project & {
    toDate: IStatisticTicketStatuses
    allTime: IStatisticTicketStatuses
  })[]
  users?: (User & {
    activations: Record<string, IStatisticUserActivation>
    activationTotal: IStatisticUserActivation
  })[]
  groups?: (Group & {
    toDate: {
      activations: Record<string, IStatisticGroupStatuses>
      total: IStatisticGroupStatuses
    }
    allTime: {
      activations: Record<string, IStatisticGroupStatuses>
      total: IStatisticGroupStatuses
    }
  })[]
  statuses?: Status[]
  selectedTab?: tabs
  mutate?: (dates?: {
    startDate: string
    endDate: string
  }) => Promise<IStatisticResponse | undefined>
  forecast?: {
    users: (User & {
      activation: Record<string, Record<string, number>> // projectId, hafta hafta kaydı
      realized: Record<string, Record<string, number>> // projectId, hafta hafta kaydı
    })[]
    groups: (Group & {
      activation: Record<string, Record<string, number>> // projectId, hafta hafta kaydı
      realized: Record<string, Record<string, number>> // projectId, hafta hafta kaydı
    })[]
  }
}

export const StatisticContext = createContext<StatisticContextProps>({
  translate,
  selectedTab: 'rollup'
})

export function StatisticContextProvider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}): JSX.Element {
  const [statistic, setStatistic] = useState<IStatisticResponse>()
  const { statuses } = useStatuses()
  const [selectedTab, setSelectedTab] = useState<tabs>('rollup')

  useEffect(() => {
    mutate().then((response) => response && setStatistic(response))
  }, [])

  async function mutate(dates?: { startDate: string; endDate: string }) {
    const response = await get<IStatisticResponse>(
      generateQueryString('/statistic', {
        startDate: convertDateStringToISOString(
          dates?.startDate ?? '01/01/2000 '
        ),
        endDate: convertDateStringToISOString(dates?.endDate ?? '01/01/2999 ')
      }) ?? '/statistic'
    )
    if (response.statusCode === 200 && response?.payload) {
      return response.payload
    }
  }

  function handle_change_tab(_: React.SyntheticEvent, new_value: tabs) {
    setSelectedTab(new_value)
  }

  return (
    <StatisticContext.Provider
      value={{
        translate,
        projects: toArray(statistic?.projects).map((project) => ({
          ...createProjectFromProjectResponse(project),
          allTime: project.allTime,
          toDate: project.toDate
        })),
        users: toArray(statistic?.users).map((user) => ({
          ...createUserFromUserResponse(user),
          activations: user.activations,
          activationTotal: user.activationTotal
        })),
        groups: toArray(statistic?.groups).map((group) => ({
          ...createGroupFromGroupResponse(group),
          toDate: group.toDate,
          allTime: group.allTime
        })),
        statuses,
        selectedTab,
        mutate,
        forecast: {
          users: toArray(statistic?.forecast?.users).map((user) => ({
            ...createUserFromUserResponse(user),
            activation: user.activation,
            realized: user.realized
          })),
          groups: toArray(statistic?.forecast?.groups).map((group) => ({
            ...createGroupFromGroupResponse(group),
            activation: group.activation,
            realized: group.realized
          }))
        }
      }}
    >
      <Layout>
        <>
          <TabWrapper>
            <Tabs
              value={selectedTab}
              onChange={handle_change_tab}
              allowScrollButtonsMobile={true}
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label={translate('tab_rollup')} value={'rollup'} />
              <Tab
                label={translate('tab_project_leadership')}
                value={'project'}
              />
              <Tab
                label={translate('tab_individual_leadership')}
                value={'individual'}
              />
              <Tab label={translate('tab_group_leadership')} value={'group'} />
              <Tab label={translate('tab_forecast')} value={'forecast'} />
            </Tabs>
          </TabWrapper>
          <Paper
            sx={{
              p: 3,
              mt: 3
            }}
          >
            {children}
          </Paper>
        </>
      </Layout>
    </StatisticContext.Provider>
  )
}

export function useStatisticContext(): StatisticContextProps {
  const context = useContext(StatisticContext)
  if (!context) {
    return {
      translate
    }
  }
  return context
}

function TabWrapper(props: { children: React.ReactNode }) {
  return (
    <Paper
      sx={{
        p: 2
      }}
    >
      {props.children}
    </Paper>
  )
}
