import { Autocomplete, Chip, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useMemo } from 'react'
import { checkedIcon, icon } from '.'
import { useTicketsContext } from '../../contexts/tickets'
import { toArray } from '../../core/helpers'
import { statusFilterOptions } from '../../core/models/ticket_filter.response'

export default function FilterStatusSelect() {
  const { ticketFilter, setTicketFilter, translate, tickets, view } =
    useTicketsContext()

  const options: { value: statusFilterOptions; label: string }[] = useMemo(
    () => [
      {
        value: 'open',
        label: translate?.('filters_open') ?? ''
      },
      {
        value: 'inprogress',
        label: translate?.('filters_working_on') ?? ''
      },
      {
        value: 'completed',
        label: translate?.('filters_completed') ?? ''
      },
      {
        value: 'feedback',
        label: translate?.('filters_feedback') ?? ''
      },
      {
        value: 'rejected',
        label: translate?.('filters_rejected') ?? ''
      }
    ],
    []
  )

  const selectedOptions = toArray(
    options.filter((option) =>
      toArray(ticketFilter?.statusFilters).includes(option.value)
    )
  )

  if (!translate || !tickets) {
    return <></>
  }

  return (
    <Autocomplete
      multiple
      autoComplete={true}
      limitTags={2}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      size={'small'}
      renderTags={(value, getTagProps) => {
        return value.map((v, index) => (
          <Chip
            variant="outlined"
            label={v.label}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      onChange={(a, b) => {
        if (b) {
          if (b.length === 0) {
            return
          }

          setTicketFilter?.((prev) => ({
            ...prev,
            statusFilters: Array.from(new Set(b.map((v) => v.value)))
          }))
        }
      }}
      value={selectedOptions}
      renderInput={(params) => (
        <TextField {...params} label={translate('status')} fullWidth={true} />
      )}
    />
  )
}
