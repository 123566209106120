import Dialog from '@mui/material/Dialog'

export default function DialogProvider({
  open,
  set_close,
  children
}: {
  open: boolean
  set_close: () => void
  children: JSX.Element | JSX.Element[]
}) {
  return (
    <Dialog open={open} onClose={set_close}>
      {children}
    </Dialog>
  )
}
