import { AddOutlined, DeleteOutline } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import { useState } from 'react'
import { useCreateTicketContext } from '../../contexts/create_ticket'
import { toArray } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import TicketCollapsibleRow from '../ticket_detail/row'

export default function NewTicketCheckList() {
  const { checkItems, setCheckItems } = useCreateTicketContext()

  return (
    <TicketCollapsibleRow
      title={translate('checkitems')}
      badge={toArray(toArray(checkItems)).length}
      AddComponent={() => (
        <AddCheckItemDialog
          onCreatedEnd={(checkItem) => {
            setCheckItems?.((prev) => [...prev, checkItem])
          }}
        />
      )}
    >
      <TableContainer component={Paper}>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('order')}</TableCell>
              <TableCell width={'100%'}>{translate('checkitem')}</TableCell>
              <TableCell width={'100%'}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toArray(checkItems).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0
                  }
                }}
              >
                <TableCell width={'2rem'} component="th">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row" width={'100%'}>
                  {row}
                </TableCell>
                <TableCell component="th">
                  <DeleteOutline
                    color="error"
                    onClick={() =>
                      setCheckItems?.((prev) =>
                        prev.filter((_, i) => i !== index)
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TicketCollapsibleRow>
  )
}

function AddCheckItemDialog({
  onCreatedEnd
}: {
  onCreatedEnd: (checkItem: string) => void
}) {
  const [text, setText] = useState('')

  const [is_open, set_is_open] = useState(false)
  function set_close() {
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  function save() {
    if (!text || text.trim().length === 0) {
      return
    }

    onCreatedEnd(text)
    set_close()
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setText(event.target.value)
  }

  return (
    <>
      <LoadingButton sx={{ alignSelf: 'center' }} onClick={set_open}>
        <AddOutlined color="success" />
      </LoadingButton>
      <Dialog
        open={is_open}
        onClose={set_close}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle>{translate('add_checkitem')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={text}
            required={true}
            label={translate('name')}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!text || text.trim().length === 0}
            onClick={save}
            variant={'contained'}
          >
            {translate('add')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
