import { useMemo } from 'react'
import { useStatisticContext } from '../../../contexts/statistic'
import { getDate, toArray, toNumber } from '../../../core/helpers'

import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Gantt, Task, ViewMode } from 'gantt-task-react'
import 'gantt-task-react/dist/index.css'
import Card from '../../../components/card'
import If from '../../../components/if'
import translate from '../../../hooks/use_localization'

const column_width = 90
const yearEndDate = new Date(`${new Date().getFullYear()}-12-31`)
const targetDate = new Date()
if (targetDate.getDate() >= 28 && targetDate.getDate() <= 31) {
  targetDate.setDate(30)
}
let start = column_width
const this_month = targetDate.getMonth()

for (let i = 0; i < this_month; i++) {
  start += column_width
}
for (let i = 1; i <= targetDate.getDate(); i++) {
  start += column_width / 30
}

export default function RoadMap() {
  const { projects, selectedTab } = useStatisticContext()

  const list: Task[] = useMemo(() => {
    return toArray(projects)
      .map((project) => ({
        id: toNumber(project?.id).toString(),
        name: project?.name || '',
        type: 'project',
        start: getStartDate(project?.startDate ?? project.createdAt),
        end: getEndDate(project?.endDate ?? yearEndDate),
        progress: project.allTime.progressRate,
        styles: {
          backgroundColor: '#0000D1',
          progressColor: '#188bbb'
        }
      }))
      .sort((a, b) => a.start.getTime() - b.start.getTime()) as Task[]
  }, [projects])

  if (
    !projects ||
    projects.length === 0 ||
    (!!selectedTab && selectedTab !== 'rollup')
  ) {
    return <></>
  }

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: column_width * 14,
        overflowX: 'hidden'
      }}
    >
      <Gantt
        viewDate={new Date()}
        tasks={list}
        viewMode={ViewMode.Month}
        listCellWidth={''}
        locale={translate({ tr: 'tr-TR', en: 'en-US' })}
        columnWidth={column_width}
        TooltipContent={({ task }) => {
          const project = toArray(projects).find(
            (project) => toNumber(project?.id) === toNumber(task?.id)
          )
          if (!project || task.type === 'milestone') {
            return (
              <Card>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {task?.start?.toLocaleDateString()}
                </Box>
              </Card>
            )
          }
          if (!project) {
            return <></>
          }

          return (
            <Card sx={{ zIndex: 4 }}>
              <Box sx={{ backgroundColor: '#1b31ed', color: 'white', p: 1 }}>
                {project?.name}
              </Box>
              <Divider />
              <ul>
                <li>
                  <b>{translate('done_total_tickets')}:</b>{' '}
                  {project.allTime.doneTickets} / {project.allTime.total}{' '}
                </li>
                <li>
                  <b>{translate('action_rate')} :</b>{' '}
                  {translate({
                    tr: `%${task.progress}`,
                    en: `${task.progress}%`
                  })}
                </li>
                <li>
                  <b>{translate('first_ticket')} :</b>{' '}
                  {getDate(
                    project.startDate ?? project.createdAt
                  ).toLocaleDateString()}
                </li>
                <If condition={project.endDate}>
                  <li>
                    <b>{translate('last_ticket')}:</b>{' '}
                    {getDate(project.endDate).toLocaleDateString()}
                  </li>
                </If>

                <If
                  condition={
                    !!project?.eventEndDate || !!project?.eventStartDate
                  }
                >
                  <li>
                    <b>{translate('event_date')} :</b>
                    <If condition={!!project?.eventStartDate}>
                      <Typography component={'span'}>
                        {getDate(project.eventStartDate).toLocaleDateString()}
                      </Typography>
                    </If>
                    <If
                      condition={
                        !!project?.eventStartDate && !!project?.eventEndDate
                      }
                    >
                      <Typography component={'span'}> - </Typography>
                    </If>
                    <If condition={!!project?.eventEndDate}>
                      <Typography component={'span'}>
                        {getDate(project.eventEndDate).toLocaleDateString()}
                      </Typography>
                    </If>
                  </li>
                </If>
                <li>
                  <b>{translate('roadMap.criticalTicketStatus')}:</b>{' '}
                  {project.allTime.criticalTickets} /{' '}
                  {project.allTime.criticalDoneTickets}
                </li>
              </ul>
            </Card>
          )
        }}
        rowHeight={35}
      />
      <Tooltip
        title={`${translate('today')} : ${new Date().toLocaleDateString()}`}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: `${start}px`,
            height: '90%',
            borderColor: 'rgba(211, 211, 211, 1)',
            borderWidth: 1,
            borderStyle: 'dashed',
            zIndex: 2
          }}
        />
      </Tooltip>
      {Array.from({ length: 14 }).map((_, i) => (
        <Box
          key={i}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: `${column_width / 2 + i * column_width}px`,
            height: '90%',
            borderColor: 'rgba(211, 211, 211, 0.3)',
            borderWidth: 1,
            borderStyle: 'dashed',
            zIndex: 0
          }}
        />
      ))}
    </Box>
  )
}

function getEndDate(date: Date): Date {
  if (date.getFullYear() !== new Date().getFullYear()) {
    return new Date(new Date().getFullYear(), 11, 31)
  }
  return date
}

function getStartDate(date: Date): Date {
  if (date.getFullYear() !== new Date().getFullYear()) {
    return new Date(new Date().getFullYear(), 0, 1)
  }
  return date
}
