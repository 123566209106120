import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketChangeAssigneeRequest } from '../../core/api_request_responses/ticket.models'
import { toArray, toNumber } from '../../core/helpers'
import { useProject } from '../../hooks/detail_hooks/use_project'
import { Group, User } from '../../models'
import { put } from '../../utils/api'
import AssigneeSelect from '../assignee_select'

export default function TicketAssignee() {
  const { ticket, changeTicket } = useTicketDetailContext()

  const { project } = useProject(toNumber(ticket?.project?.id), {
    users: 'true',
    groups: 'true'
  })

  const users = toArray(
    toArray(project?.layers).find(({ id }) => id === ticket?.layer.id)?.users
  )

  const groups = toArray(
    toArray(project?.layers).find(({ id }) => id === ticket?.layer.id)?.groups
  )

  async function handle_click_change({
    assignedId,
    assignedType
  }: {
    assignedId: number
    assignedType: 'group' | 'user'
  }) {
    if (
      !ticket ||
      (ticket.assigned.id === assignedId &&
        ticket.assignedType === assignedType) ||
      !changeTicket
    )
      return

    const oldAssign = ticket.assigned
    const oldAssignedType = ticket.assignedType

    let newAssign: User | Group | undefined
    if (assignedType === 'user') {
      newAssign = users.find(({ id }) => id === assignedId)
    } else {
      newAssign = groups.find(({ id }) => id === assignedId)
    }

    if (!!newAssign) {
      changeTicket({
        ...ticket,
        assigned: newAssign ?? new User(),
        assignedType
      })

      put<ITicketChangeAssigneeRequest>(
        `/ticket/${ticket.id}/change-assignee`,
        { assignedId, assignedType }
      ).then((response) => {
        if (response.statusCode !== 200) {
          changeTicket({
            ...ticket,
            assigned: oldAssign,
            assignedType: oldAssignedType
          })
          alert('update_error')
        }
      })
    }
  }

  if (!ticket) {
    return null
  }

  return (
    <AssigneeSelect
      selectedType={ticket.assignedType}
      selectedValue={ticket.assigned.id}
      users={users}
      groups={groups}
      onListItemClick={handle_click_change}
    />
  )
}
