import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ITicketAddCheckItemRequest,
  ITicketCreateRequest,
  ITicketPostSimilarRequest
} from '../core/api_request_responses/ticket.models'
import { generateQueryString, toNumber } from '../core/helpers'
import { CreateResponse } from '../core/models/api.response'
import usePriorities from '../hooks/list_hooks/use_priorities'
import { useProjects } from '../hooks/list_hooks/use_projects'
import useStatuses from '../hooks/list_hooks/use_statuses'
import translate from '../hooks/use_localization'
import useQuery from '../hooks/use_query'
import { Priority, Project, Status } from '../models'
import { post, postFormData } from '../utils/api'

interface CreateTicketContextProps {
  statuses?: Status[]
  createTicket?: (payload: ITicketCreateRequest) => Promise<string | undefined>
  projects?: Project[]
  priorities?: Priority[]
  translate: (key?: string) => string
  files?: File[]
  setFiles?: React.Dispatch<React.SetStateAction<File[]>>
  checkItems?: string[]
  setCheckItems?: React.Dispatch<React.SetStateAction<string[]>>
  setClose?: () => void
  setOpen?: () => void
  isOpen?: boolean
  getHelp?: (
    ticket: Partial<ITicketCreateRequest>,
    onSuccess: (payload: Partial<ITicketCreateRequest>) => void
  ) => Promise<boolean>
}

export const CreateTicketContext = createContext<CreateTicketContextProps>({
  translate
})

export function CreateTicketContextProvider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}): JSX.Element {
  const [files, setFiles] = useState<File[]>([])
  const [checkItems, setCheckItems] = useState<string[]>([])
  const [isOpen, setIsOpen] = useState(false)

  function setClose() {
    setCheckItems([])
    setFiles([])
    setIsOpen(false)
  }

  function setOpen() {
    setIsOpen(true)
  }

  const { statuses } = useStatuses()
  const { projects } = useProjects()
  const { priorities } = usePriorities()
  const query = useQuery()
  const navigate = useNavigate()

  async function createTicket(
    payload: ITicketCreateRequest
  ): Promise<string | undefined> {
    const response = await post<ITicketCreateRequest, CreateResponse>(
      '/ticket',
      payload
    )
    if (response?.statusCode === 200) {
      if (files.length > 0) {
        for await (const file of files) {
          const formData = new FormData()
          formData.append('file', file)
          await postFormData(
            `/ticket/${toNumber(response.payload?.id)}/upload-file`,
            formData
          )
        }
        setFiles([])
      }

      if (checkItems.length > 0) {
        for await (const item of checkItems) {
          await post<ITicketAddCheckItemRequest, CreateResponse>(
            `/ticket/${toNumber(response.payload?.id)}/add-check-item`,
            { name: item }
          )
        }
        setCheckItems([])
      }
      navigate(
        generateQueryString('/tickets', {
          ticketId: toNumber(response.payload?.id)
        }) ?? '/tickets',
        { replace: true }
      )
      setClose()
      return undefined
    }
    return translate(response?.errorCode)
  }

  useEffect(() => {
    const isCreateTicket = query.get('createTicket')
    if (isCreateTicket === 'true') {
      setOpen()
    }
  }, [query])

  async function getHelp(
    ticket: Partial<ITicketCreateRequest>,
    onSuccess: (payload: Partial<ITicketCreateRequest>) => void
  ) {
    if (!ticket?.layerId || !ticket?.name || !ticket?.projectId) {
      return false
    }

    const response = await post<
      ITicketPostSimilarRequest,
      Partial<ITicketCreateRequest>
    >('/ticket/post-similar', {
      layerId: toNumber(ticket?.layerId),
      projectId: toNumber(ticket?.projectId),
      name: ticket?.name
    })

    if (response?.payload && response.statusCode === 200) {
      onSuccess(response.payload)
      return true
    }

    return false
  }

  return (
    <CreateTicketContext.Provider
      value={{
        statuses,
        createTicket,
        projects,
        priorities,
        translate,
        files,
        setFiles,
        checkItems,
        setCheckItems,
        isOpen,
        setClose,
        setOpen,
        getHelp
      }}
    >
      {children}
    </CreateTicketContext.Provider>
  )
}

export function useCreateTicketContext(): CreateTicketContextProps {
  const context = useContext(CreateTicketContext)
  if (!context) {
    return {
      translate
    }
  }
  return context
}
