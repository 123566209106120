import { CSSProperties } from 'react'
import { useParams } from 'react-router-dom'

export default function PaymentPage() {
  const { code } = useParams<{ code: string }>()

  if (!code) {
    return <></>
  }

  const iframeStyle: CSSProperties = { width: '100%', height: '100vh' }

  return (
    <>
      <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>
      <iframe
        src={`https://www.paytr.com/odeme/guvenli/${code}`}
        id="paytriframe"
        frameBorder="0"
        scrolling="no"
        style={iframeStyle}
      ></iframe>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <script>iFrameResize({},'#paytriframe');</script>
    </>
  )
}
