import { Box, ListItemButton, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTicketsContext } from '../../contexts/tickets'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketChangeStatusRequest } from '../../core/api_request_responses/ticket.models'
import { toArray } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { put } from '../../utils/api'

export default function TicketStatus() {
  const { ticket, changeTicket } = useTicketDetailContext()
  const { statuses } = useTicketsContext()

  async function handle_click_change_ticket_status(statusId: number) {
    if (!ticket || ticket.status.id === statusId || !changeTicket) return

    const oldStatus = ticket.status
    const targetStatus = toArray(statuses).find(
      (status) => status.id === statusId
    )

    if (!targetStatus) {
      return
    }

    changeTicket({
      ...ticket,
      status: targetStatus
    })

    put<ITicketChangeStatusRequest>(`/ticket/${ticket.id}/change-status`, {
      statusId
    }).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({ ...ticket, status: oldStatus })
        alert('update_error')
      }
    })
  }

  if (!ticket) {
    return null
  }

  return (
    <TextField
      value={ticket?.status.id}
      label={translate('status')}
      fullWidth={true}
      required={true}
      select
      variant="standard"
      size="small"
    >
      {toArray(statuses).map((status) => (
        <ListItemButton
          component={'button'}
          value={status.id}
          key={status.id}
          sx={{
            width: '100%'
          }}
          disabled={status.id === ticket?.status.id}
          onClick={async () =>
            await handle_click_change_ticket_status(status.id)
          }
        >
          <Stack direction={'row'} display={'flex'} alignItems={'center'}>
            <Box
              sx={{
                width: 24,
                height: 24,
                borderRadius: 12,
                background: status.color
              }}
            />
            <Typography sx={{ p: 2, maxWidth: 150 }}>
              {translate(status)}
            </Typography>
          </Stack>
        </ListItemButton>
      ))}
    </TextField>
  )
}
