import { MenuItem, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { useCreateProjectContext } from '../../contexts/create_project'
import { toArray, toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { User } from '../../models'

export default function ProjectStep() {
  const { activeStep, users, payload, setPayload, createProject } =
    useCreateProjectContext()

  if (activeStep !== 1) {
    return null
  }

  function handleChangeManagerId(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload?.((prev) => ({
      ...prev,
      managerId: toNumber(event.target.value)
    }))
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload?.((prev) => ({
      ...prev,
      name: event.target.value
    }))
  }

  function handleChangeStartDate(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload?.((prev) => ({
      ...prev,
      startDate: event.target.value
    }))
  }

  function handleChangeEndDate(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload?.((prev) => ({
      ...prev,
      endDate: event.target.value
    }))
  }

  function handleChangeEventStartDate(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setPayload?.((prev) => ({
      ...prev,
      eventStartDate: event.target.value
    }))
  }

  function handleChangeEventEndDate(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setPayload?.((prev) => ({
      ...prev,
      eventEndDate: event.target.value
    }))
  }

  return (
    <Stack mt={2} rowGap={4}>
      <TextField
        label={translate('project_name')}
        required={true}
        fullWidth
        value={payload?.name}
        onChange={handleChangeName}
      />

      <TextField
        label={translate('manager')}
        required={true}
        fullWidth
        value={payload?.managerId}
        select
        onChange={handleChangeManagerId}
      >
        {toArray(users).map((user: User) => (
          <MenuItem key={user.id} value={user.id}>
            {user.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label={translate('start_date')}
        type="date"
        value={payload?.startDate}
        onChange={handleChangeStartDate}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
      />

      <TextField
        label={translate('end_date')}
        type="date"
        value={payload?.endDate}
        onChange={handleChangeEndDate}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
      />

      <TextField
        label={translate('event_start_date')}
        type="date"
        value={payload?.eventStartDate}
        onChange={handleChangeEventStartDate}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
      />

      <TextField
        label={translate('event_end_date')}
        type="date"
        value={payload?.eventEndDate}
        onChange={handleChangeEventEndDate}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Stack>
  )
}
