import { Box, Typography } from '@mui/material'
import { ColumnProps } from '..'
import List from './list'

export const grid = 8
export const borderRadius = 2

const Column = ({
  id,
  index,
  title,
  items,
  color,
  onChangeAssignee,
  handle_click_title
}: ColumnProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        maxHeight: '80vh',
        height: '80vh',
        background: 'white',
        flexDirection: 'column',
        px: 2,
        py: 1,
        alignItems: 'center',
        borderRadius
      }}
    >
      <Typography
        variant="h5"
        fontWeight={'bold'}
        sx={{
          color,
          userSelect: 'none',
          position: 'relative',
          borderBottom: 1
        }}
      >
        {title}
      </Typography>

      <List
        listId={`${id}`}
        listType="ITEMS"
        items={items}
        onChangeAssignee={onChangeAssignee}
        handle_click_title={handle_click_title}
      />
    </Box>
  )
}

export default Column
