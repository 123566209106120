import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { IStatisticTicketStatuses } from '../../core/api_request_responses/statistic.models'
import translate from '../../hooks/use_localization'
import Card from '../card'

export default function TableTotal({
  total,
  progressRate,
  isToDate,
  openTickets,
  inProgressTickets,
  doneTickets,
  rejectedTickets,
  feedbackTickets,
  criticalDoneTickets,
  criticalTickets
}: {
  isToDate: boolean
} & IStatisticTicketStatuses) {
  let text = ''
  if (isToDate) {
    text += translate('until_today') + ' '
  }

  text += `${translate('sum')} ${total} ticket. ${translate(
    'completion_rate'
  )} %${progressRate}`
  text = text.charAt(0).toUpperCase() + text.slice(1)
  return (
    <Card sx={{ mb: 3 }}>
      <Typography>{text}</Typography>
      <Box
        component={'ul'}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr'
        }}
      >
        <li>
          <b>{openTickets}</b> {translate('open')}
        </li>
        <li>
          <b>{inProgressTickets}</b> {translate('in_progress')}
        </li>
        <li>
          <b>{doneTickets}</b> {translate('done')}
        </li>
        <li>
          <b>{feedbackTickets}</b> {translate('feedback')}
        </li>
        <li>
          <b>{rejectedTickets}</b> {translate('rejected')}
        </li>
        <li>
          <b>
            {criticalTickets} / {criticalDoneTickets}
          </b>{' '}
          {translate('roadMap.criticalTicketStatus')}
        </li>
      </Box>
    </Card>
  )
}
