import { Stack } from '@mui/material'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  IPublicSignUpRequest,
  IPublicSignUpResponse
} from '../../../core/api_request_responses/public.models'
import UserRefSignUpForm from '../../../forms/user_ref_sign_up_form'
import translate from '../../../hooks/use_localization'
import AuthLayout from '../../../layouts/auth_layout'
import runOneSignal from '../../../onesignal'
import { post } from '../../../utils/api'

export default function SignUpPublicPage() {
  const navigate = useNavigate()

  const api_message = useRef<
    { error?: boolean; message?: string } | undefined
  >()
  const [loading, set_loading] = useState(false)

  async function handle_submit(payload: IPublicSignUpRequest) {
    set_loading(true)
    const response = await post<IPublicSignUpRequest, IPublicSignUpResponse>(
      '/public/sign-up',
      payload
    )
    console.log('response', response)
    if (response.statusCode === 200 && response?.payload?.user) {
      api_message.current = {
        error: false,
        message: translate('sign_up_success')
      }
      window.localStorage.setItem('userId', `${response?.payload?.user.id}`)
      window.localStorage.setItem('name', response?.payload?.user.name)
      window.localStorage.setItem(
        'photoUrl',
        response?.payload?.user?.profilePicture ?? ''
      )
      await runOneSignal(response?.payload?.user.id)
      navigate('/')
    } else {
      api_message.current = {
        error: true,
        message: translate(response?.errorCode)
      }
    }
    set_loading(false)
  }

  return (
    <AuthLayout>
      <Stack gap={3}>
        <UserRefSignUpForm
          handle_submit={handle_submit}
          loading={loading}
          api_message={api_message.current}
        />
      </Stack>
    </AuthLayout>
  )
}
