import { CheckOutlined, RestartAlt } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { useRef, useState } from 'react'
import {
  ITicketDeleteCheckItemRequest,
  ITicketUpdateCheckItemRequest
} from '../core/api_request_responses/ticket.models'
import { toString } from '../core/helpers'
import translate from '../hooks/use_localization'
import { CheckItem } from '../models'
import { put } from '../utils/api'
import DialogProvider from './dialog_provider'
import If from './if'

export default function CheckItemRow({
  item,
  mutate,
  hideAction
}: {
  item: CheckItem
  mutate?: () => Promise<void>
  hideAction?: boolean
}) {
  const [loading, setLoading] = useState(false)

  async function toggle() {
    setLoading(true)
    await put(`/check-item/${item.id}/toggle`, { temp: 'temp' })
    await mutate?.()
    setLoading(false)
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        width: 280,
        maxWidth: 280,
        minWidth: 280,
        position: 'relative',
        overflow: 'hidden',
        p: 1,
        mt: 3,
        minHeight: 120,
        opacity: item?.isDone ? 0.5 : 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: 200,
          maxWidth: 200,
          minWidth: 200
        }}
      >
        <CheckItemEdit item={item} mutate={mutate} />
        <Stack direction={'row'} alignItems={'center'}>
          <Avatar
            alt={toString(item.createdBy.name)}
            src={toString(item.createdBy?.profilePicture)}
            sx={{ width: 24, height: 24 }}
          />
          <Typography sx={{ ml: 1 }}>{item.createdBy.name}</Typography>
        </Stack>
      </Box>

      <If condition={!item.isDone && !hideAction}>
        <LoadingButton loading={loading} onClick={toggle}>
          {loading ? <></> : <CheckOutlined color={'success'} />}
        </LoadingButton>
      </If>
      <If condition={item.isDone && !hideAction}>
        <LoadingButton loading={loading} onClick={toggle}>
          {loading ? <></> : <RestartAlt color={'success'} />}
        </LoadingButton>
      </If>
    </Paper>
  )
}

function CheckItemEdit({
  item,
  mutate
}: {
  item: CheckItem
  mutate?: () => Promise<void>
}) {
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<
    Partial<ITicketUpdateCheckItemRequest>
  >({ name: item.name, checkItemId: item.id })
  const apiError = useRef<string | undefined>()

  function set_close() {
    if (loading) {
      return
    }
    apiError.current = undefined
    setPayload({
      name: item.name,
      checkItemId: item.id
    })
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  function checkPayload(): boolean {
    if (!payload?.name || payload.name.trim().length === 0) {
      return false
    }

    return true
  }

  async function save() {
    if (!checkPayload()) {
      return
    }

    setLoading(true)
    const response = await put<ITicketUpdateCheckItemRequest, undefined>(
      `/check-item/${item.id}`,
      payload as unknown as ITicketUpdateCheckItemRequest
    )
    await mutate?.()
    if (!!response && response.statusCode === 200) {
      setLoading(false)
      set_close()
    }
  }

  async function deleteItem() {
    setLoading(true)
    const response = await put<ITicketDeleteCheckItemRequest, undefined>(
      `/check-item/${item.id}/delete`,
      {
        checkItemId: item.id
      }
    )
    await mutate?.()

    if (!!response) {
      setLoading(false)
      set_close()
    }
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({ ...payload, name: event.target.value })
  }

  return (
    <>
      <Box
        onClick={set_open}
        sx={{ p: 0, textAlign: 'left', cursor: 'pointer' }}
      >
        <Typography variant={'h6'} sx={{ textDecoration: 'underline' }}>
          {item.name}
        </Typography>
      </Box>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle>{translate('checkitem_title')}</DialogTitle>
        <DialogContent>
          <If condition={!!apiError.current}>
            <Typography>{apiError.current}</Typography>
          </If>
          <Stack
            direction={{
              xs: 'column',
              md: 'row'
            }}
            columnGap={2}
          >
            <Stack mt={2} gap={4}>
              <TextField
                label={translate('name')}
                required={true}
                fullWidth
                value={payload?.name}
                onChange={handleChangeName}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            onClick={deleteItem}
            variant={'outlined'}
            color="error"
          >
            {translate('_delete')}
          </LoadingButton>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!checkPayload()}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('update')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}
