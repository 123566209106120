import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Button, Card, Divider } from '@mui/material'
import { Stack } from '@mui/system'
import { useTicketsContext } from '../../contexts/tickets'
import { TicketFilterResponse } from '../../core/models/ticket_filter.response'
import translate from '../../hooks/use_localization'
import FilterAssignSelect from './filter_assign_select'
import FilterLayerSelect from './filter_layer_select'
import FilterPrioritySelect from './filter_priority_select'
import FilterStatusSelect from './filter_status_select'
import FilterTagSelect from './filter_tag_select'
import FilterTimeSelect from './filter_time_select'
import FilterProjectSelect from './project_select'

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
export const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function TicketFilter() {
  const { resetFilter, saveFilter, tickets, ticketFilter } = useTicketsContext()
  return (
    <Card
      sx={{
        width: 310,
        maxWidth: 310,
        minWidth: 310,
        height: '100%'
      }}
    >
      <Stack rowGap={2} sx={{ px: 1, py: 3 }}>
        <FilterProjectSelect />
        <FilterLayerSelect />
        <Divider />
        <FilterAssignSelect />
        <FilterStatusSelect />
        <FilterPrioritySelect />
        <FilterTimeSelect />
        <FilterTagSelect />
        <Divider />
        <Button
          disabled={!tickets || !saveFilter || disableCheck(ticketFilter)}
          variant={'contained'}
          onClick={saveFilter}
        >
          {translate('submit')}
        </Button>
        <Button
          disabled={!tickets || !resetFilter}
          variant={'outlined'}
          onClick={resetFilter}
        >
          {translate('reset')}
        </Button>
      </Stack>
    </Card>
  )
}

function disableCheck(ticketFilter?: TicketFilterResponse): boolean {
  if (!ticketFilter) {
    return true
  }

  if (ticketFilter.assignFilter === 'custom' && !ticketFilter.customAssignId) {
    return true
  }

  if (
    ticketFilter.timeFilter === 'custom' &&
    (!ticketFilter?.customStartDate || !ticketFilter?.customEndDate)
  ) {
    return true
  }

  return false
}

// function FilterPageSelect() {
//   const { ticketFilter, setTicketFilter, tickets, totalPage, limit } =
//     useTicketsContext()

//   const options = useMemo(() => {
//     const result: { label: string; value: number }[] = []
//     if (totalPage === 1 || !totalPage || !limit) {
//       return [{ label: 'Hepsi', value: 1 }]
//     }

//     for (let i = 1; i <= totalPage; i++) {
//       result.push({ label: `${(i - 1) * limit}-${i * limit}`, value: i })
//     }

//     return result
//   }, [totalPage, limit])

//   useEffect(() => {
//     if (
//       ticketFilter &&
//       totalPage &&
//       setTicketFilter &&
//       ticketFilter?.page > totalPage
//     ) {
//       setTicketFilter((prev) => ({ ...prev, page: 1 }))
//     }
//   }, [ticketFilter, totalPage])

//   return (
//     <Box>
//       <Typography variant="h5">Sayfa Seçimi</Typography>
//       <TextField
//         onChange={(event) => {
//           setTicketFilter?.((prev) => ({
//             ...prev,
//             page: toNumber(event.target.value)
//           }))
//         }}
//         value={toNumber(ticketFilter?.page)}
//         fullWidth={true}
//         disabled={!tickets}
//         select
//       >
//         {options.map((option) => (
//           <MenuItem key={option.value} value={option.value}>
//             {option.label}
//           </MenuItem>
//         ))}
//       </TextField>
//     </Box>
//   )
// }

// function ViewSelect() {
//   const { view, setView } = useTicketsContext()

//   return (
//     <Stack direction={'row-reverse'} gap={1} alignItems={'center'} p={1}>
//       <Typography>Liste Görünümü</Typography>
//       <Switch
//         color={'primary'}
//         checked={view === 'list'}
//         onChange={() => setView?.(view === 'list' ? 'kanban' : 'list')}
//         inputProps={{ 'aria-label': 'controlled' }}
//       />
//       <Typography>Tablo Görünümü</Typography>
//     </Stack>
//   )
// }
