import { useMemo } from 'react'
import { toArray } from '../core/helpers'
import translate from '../hooks/use_localization'
import { Project } from '../models'
import AutoCompleteSelect from './auto_complete_select'

export default function StatisticProjectSelect({
  selected_project,
  set_selected_project,
  projects
}: {
  selected_project?: string | null
  set_selected_project?: (project?: string | null) => void
  projects: Project[]
}) {
  const options: string[] = useMemo(
    () =>
      [translate('all')].concat(
        toArray(projects)
          .sort(project_title_sort)
          .map(({ name }) => name ?? '')
      ),
    [projects]
  )
  if (!set_selected_project) {
    return <></>
  }
  return (
    <AutoCompleteSelect
      options={options}
      value={selected_project}
      onChange={set_selected_project}
      placeholder={translate('project')}
    />
  )
}

export function project_title_sort(a: Project, b: Project): number {
  const secondary = 'TUR ISTANBUL'
  if (
    (a?.name || '').toLocaleUpperCase('tr-TR') ===
    'Istanbul Marathon'.toLocaleUpperCase('tr-TR')
  ) {
    return -1
  }
  if (
    (b?.name || '').toLocaleUpperCase('tr-TR') ===
    'Istanbul Marathon'.toLocaleUpperCase('tr-TR')
  ) {
    return 1
  }
  if (
    (a?.name || '').toLocaleUpperCase('tr-TR') ===
    secondary.toLocaleUpperCase('tr-TR')
  ) {
    return -1
  }
  if (
    (b?.name || '').toLocaleUpperCase('tr-TR') ===
    secondary.toLocaleUpperCase('tr-TR')
  ) {
    return 1
  }

  if ((a?.name || '') < (b?.name || '')) {
    return -1
  }
  if ((a?.name || '') > (b?.name || '')) {
    return 1
  }
  return 0
}
