import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketChangeLayerRequest } from '../../core/api_request_responses/ticket.models'
import { toArray } from '../../core/helpers'
import { put } from '../../utils/api'
import LayerSelect from '../layer_select'

export default function TicketLayer() {
  const { ticket, changeTicket } = useTicketDetailContext()

  if (!ticket || !changeTicket) {
    return null
  }

  async function change(newId: number) {
    const oldLayer = ticket?.layer
    const oldId = oldLayer?.id
    const newLayer = toArray(ticket?.project.layers).find(
      (layer) => layer.id === newId
    )
    if (!ticket || !changeTicket || !oldId || !newLayer || newId === oldId) {
      return
    }

    changeTicket({
      ...ticket,
      layer: newLayer
    })
    put<ITicketChangeLayerRequest>(`/ticket/${ticket.id}/change-layer`, {
      layerId: newId
    }).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({
          ...ticket,
          layer: oldLayer
        })
        alert('update_error')
      }
    })
  }

  return (
    <LayerSelect
      variant="standard"
      layers={toArray(ticket.project.layers)}
      value={ticket.layer.id}
      onListItemClick={async (layerId) => await change(layerId)}
    />
  )
}
