import { PriorityResponse } from '../core/models/priority.response'

export class Priority {
  id: number = 0
  code: string = ''
  tr: string = ''
  en: string = ''
}

export function createPriorityFromPriorityResponse(
  priorityResponse: PriorityResponse
): Priority {
  const instance = new Priority()
  instance.id = priorityResponse.id
  instance.code = priorityResponse.code
  instance.tr = priorityResponse.tr
  instance.en = priorityResponse.en
  return instance
}
