import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useRef, useState } from 'react'
import { AvatarUpload } from '../../components/avatar_upload'
import Card from '../../components/card'
import DialogProvider from '../../components/dialog_provider'
import If from '../../components/if'
import useProfileMeContext, {
  ProfileMeContextProvider
} from '../../contexts/profile/me'
import { IMeChangePasswordRequest } from '../../core/api_request_responses/me.models'
import { IUserCreateRequest } from '../../core/api_request_responses/user.models'
import { toArray, toNumber } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import Layout from '../../layouts/page_layout'
import { post } from '../../utils/api'

export default function ProfilePage() {
  return (
    <ProfileMeContextProvider>
      <Layout>
        <Stack
          direction={{
            xs: 'column',
            md: 'row'
          }}
          gap={2}
        >
          <UserAvatar />
          <UserInfo />
          <OrderInfo />
        </Stack>
      </Layout>
    </ProfileMeContextProvider>
  )
}

function UserInfo() {
  const { user } = useProfileMeContext()
  if (!user) {
    return <></>
  }
  return (
    <Card>
      <Typography>{user.name}</Typography>
      <br />
      <Typography>{user.email}</Typography>
      <br />
      <CreateUserDialog />
      <br />
      <br />
      <ChangePasswordDialog />
      <br />
      <br />
      <TempPayment />
    </Card>
  )
}

function OrderInfo() {
  const { orders } = useProfileMeContext()
  if (!orders) {
    return <></>
  }
  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Miktar</TableCell>
              <TableCell>Zaman</TableCell>
              <TableCell>Durum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toArray(orders)
              .sort((a, b) => b.id - a.id)
              .map((order) => (
                <TableRow
                  key={order.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {order.paymentAmount}
                  </TableCell>
                  <TableCell>{order.createdAt.toLocaleString()}</TableCell>
                  <TableCell>
                    {order.isDone
                      ? 'Tamamlandi'
                      : order.isRejected
                      ? 'Reddedildi'
                      : 'İslem Yapilmadi'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

function CreateUserDialog() {
  const { createUser } = useProfileMeContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<IUserCreateRequest>>({})
  const apiError = useRef<string | undefined>()

  function set_close() {
    if (loading) {
      return
    }
    apiError.current = undefined
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (!payload?.firstName || !payload?.lastName || !payload?.email) {
      return
    }
    setLoading(true)
    const response = await createUser?.(
      payload as unknown as IUserCreateRequest
    )
    apiError.current = response
    setLoading(false)
  }

  function handleChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      firstName: event.target.value
    })
  }

  function handleChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      lastName: event.target.value
    })
  }

  function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      email: event.target.value
    })
  }

  return (
    <>
      <Button variant={'outlined'} onClick={set_open}>
        {translate('invite_user')}
      </Button>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle> {translate('invite_user')}</DialogTitle>
        <DialogContent>
          <Stack mt={2} rowGap={4}>
            <If condition={toNumber(apiError.current?.length) > 3}>
              <Typography>{apiError.current}</Typography>
            </If>
            <TextField
              label={translate('firstname')}
              fullWidth
              value={payload?.firstName}
              onChange={handleChangeFirstName}
            />
            <TextField
              label={translate('lastname')}
              fullWidth
              value={payload?.lastName}
              onChange={handleChangeLastName}
            />
            <TextField
              type={'email'}
              label={translate('email')}
              fullWidth
              value={payload?.email}
              onChange={handleChangeEmail}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={
              !payload?.email || !payload?.firstName || !payload?.lastName
            }
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('invite')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function ChangePasswordDialog() {
  const { changePassword } = useProfileMeContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<
    IMeChangePasswordRequest & { confirmPassword: string }
  >({ oldPassword: '', newPassword: '', confirmPassword: '' })
  const apiError = useRef<string | undefined>()

  function set_close() {
    if (loading) {
      return
    }
    apiError.current = undefined
    setPayload({ oldPassword: '', newPassword: '', confirmPassword: '' })
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  async function save() {
    if (
      !payload?.oldPassword ||
      !payload?.newPassword ||
      !payload?.confirmPassword
    ) {
      return
    }
    setLoading(true)
    const response = await changePassword?.(payload)
    apiError.current = response
    setLoading(false)
  }

  function handleChangeOldPassword(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      oldPassword: event.target.value
    })
  }

  function handleChangeNewPassword(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      newPassword: event.target.value
    })
  }

  function handleChangeConfirmPassword(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setPayload({
      ...payload,
      confirmPassword: event.target.value
    })
  }

  return (
    <>
      <Button variant={'outlined'} onClick={set_open}>
        {translate('change_password')}
      </Button>
      <DialogProvider open={is_open} set_close={set_close}>
        <DialogTitle> {translate('change_password')}</DialogTitle>
        <DialogContent>
          <Stack mt={2} rowGap={4}>
            <If condition={toNumber(apiError.current?.length) > 3}>
              <Typography>{apiError.current}</Typography>
            </If>
            <TextField
              type={'password'}
              label={translate('old_password')}
              fullWidth
              value={payload.oldPassword}
              onChange={handleChangeOldPassword}
            />
            <TextField
              type={'password'}
              label={translate('new_password')}
              fullWidth
              value={payload.newPassword}
              onChange={handleChangeNewPassword}
            />
            <TextField
              type={'password'}
              label={translate('confirm_password')}
              fullWidth
              value={payload.confirmPassword}
              onChange={handleChangeConfirmPassword}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={
              !payload?.oldPassword ||
              !payload?.newPassword ||
              !payload?.confirmPassword
            }
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('change_password')}
          </LoadingButton>
        </DialogActions>
      </DialogProvider>
    </>
  )
}

function UserAvatar() {
  const { user, sendImage } = useProfileMeContext()
  if (!user || !sendImage) {
    return <></>
  }
  return (
    <AvatarUpload
      name={user.name}
      url={user?.profilePicture}
      sendImage={sendImage}
    />
  )
}

function TempPayment() {
  const [loading, setLoading] = useState(false)
  async function pay() {
    setLoading(true)
    const response = await post<Object, string>('/me/payToken', {})
    setLoading(false)
    if (response?.payload && response?.payload?.length > 0) {
      window.open(
        'https//app.theticketapp.co/pay/' + response?.payload,
        '_blank'
      )
    }
  }
  return (
    <LoadingButton loading={loading} onClick={pay}>
      pay
    </LoadingButton>
  )
}
