import { AddLink, AddOutlined, PictureAsPdfOutlined } from '@mui/icons-material'
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material'
import { useState } from 'react'
import Lottie from 'react-lottie'
import ReactMarkdown from 'react-markdown'
import Card from '../../components/card'
import DialogProvider from '../../components/dialog_provider'
import If from '../../components/if'
import { TicketCard } from '../../components/ticket_list/sub_components/ticket_card'
import { useAIContext } from '../../contexts/ai'
import {
  IAIAnswerActionRequest,
  IAIAnswerActionResponse
} from '../../core/api_request_responses/ai.models'
import { generateQueryString, toArray, toNumber } from '../../core/helpers'
import { useTickets } from '../../hooks/list_hooks/use_tickets'
import translate from '../../hooks/use_localization'
import ticketLoading from '../../lotties/the_ticket_loading.json'
import { Ticket } from '../../models'
import { AIAnswer } from '../../models/ai_answer.model'
import { post } from '../../utils/api'
import style from './markdown-styles.module.css'

export default function AIHistory() {
  const { tickets } = useTickets()
  const { history, loading, selectedFunctionCode } = useAIContext()
  const answers = toArray(history)
    .filter((answer) => answer?.functionCode === selectedFunctionCode)
    .sort((a, b) => b.id - a.id)

  return (
    <Stack gap={4}>
      <AILoadingCard loading={!!loading} selectedCode={selectedFunctionCode} />
      {toArray(answers).map((answer) => (
        <AIAnswerCard key={answer.id} answer={answer} ticketList={tickets} />
      ))}
    </Stack>
  )
}

function AIAnswerCard({
  answer,
  ticketList
}: {
  answer: AIAnswer
  ticketList?: Ticket[]
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  function setClose() {
    if (loading) {
      return
    }
    setIsOpen(false)
  }

  function setOpen() {
    setIsOpen(true)
  }

  async function link(ticketId: number) {
    setLoading(true)
    const response = await post<IAIAnswerActionRequest, undefined>(
      `/ai/answer/action/${answer.id}`,
      {
        lang: translate({ en: 'en', tr: 'tr' }) as unknown as 'en' | 'tr',
        action: 'convert-to-ticket',
        ticketId: toNumber(ticketId)
      }
    )

    setLoading(false)
    if (response.statusCode === 200) {
      const local = 'https://app.theticketapp.co'
      window
        .open(
          generateQueryString(local + '/tickets', {
            ticketId: toNumber(ticketId)
          }) ?? local + '/tickets',
          '_blank'
        )
        ?.focus()
      setIsOpen(false)
    } else {
      alert(translate({ en: 'Error', tr: 'Ticket Dosya linkleme hatası' }))
    }
  }

  async function convertToTicket() {
    setLoading(true)
    const response = await post<
      IAIAnswerActionRequest,
      IAIAnswerActionResponse
    >(`/ai/answer/action/${answer.id}`, {
      lang: translate({ en: 'en', tr: 'tr' }) as unknown as 'en' | 'tr',
      action: 'convert-to-ticket'
    })

    setLoading(false)
    if (response.statusCode === 200 && response?.payload) {
      const local = 'https://app.theticketapp.co'
      window
        .open(
          generateQueryString(local + '/tickets', {
            createTicket: true,
            ...response?.payload?.partialTicket
          }) ?? local + '/tickets',
          '_blank'
        )
        ?.focus()
      setIsOpen(false)
    } else {
      alert(translate({ en: 'Error', tr: 'Ticket Dosya linkleme hatası' }))
    }
  }
  return (
    <Card>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
      >
        <Tooltip title={translate('view_as_pdf')}>
          <IconButton href={translate(answer.url)} target="_blank">
            <PictureAsPdfOutlined />
          </IconButton>
        </Tooltip>
        <Tooltip title="Link to Ticket">
          <IconButton onClick={setOpen}>
            <AddLink />
          </IconButton>
        </Tooltip>
        <If
          condition={[
            'marketing_summarize_product_feedback',
            'project_create_scope_document',
            'engineering_write_technical_specification_document'
          ].includes(answer.functionCode)}
        >
          <Tooltip title={'Convert to Ticket'}>
            <IconButton onClick={convertToTicket} disabled={loading}>
              <AddOutlined />
            </IconButton>
          </Tooltip>
        </If>
      </Stack>
      <ReactMarkdown className={style.reactMarkDown}>
        {translate(answer)}
      </ReactMarkdown>
      <DialogProvider open={isOpen} set_close={setClose}>
        <DialogTitle>{translate('select_ticket_for_linking')}</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Stack>
              {toArray(ticketList).map((ticket) => (
                <TicketCard
                  key={ticket.id}
                  ticket={ticket}
                  handle_click_title={(ticketId) => {
                    void link(toNumber(ticketId))
                  }}
                />
              ))}
            </Stack>
          )}
        </DialogContent>
      </DialogProvider>
    </Card>
  )
}

function AILoadingCard({
  loading,
  selectedCode
}: {
  loading: boolean
  selectedCode?: string
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ticketLoading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  if (loading && selectedCode !== 'settings_file_management') {
    return (
      <Card>
        <Lottie options={defaultOptions} height={400} width={400} />
      </Card>
    )
  }
  return <></>
}
