import { Switch, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import translate from '../hooks/use_localization'

export function TotalToDateSwitch({
  isTotal,
  toggle
}: {
  isTotal: boolean
  toggle: () => void
}) {
  const handleChange = () => {
    toggle()
  }

  return (
    <Stack direction={'row-reverse'} gap={1} alignItems={'center'} p={1}>
      <Typography>{translate('all')}</Typography>
      <Switch
        color={'primary'}
        checked={isTotal}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography>{translate('until_today')}</Typography>
    </Stack>
  )
}
