import { TaskOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Stack,
  Switch
} from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useRef, useState } from 'react'
import { CheckItemList } from '../components/checkitem_list'
import CheckItemKanban from '../components/check_item_kanban'
import If from '../components/if'
import {
  PersonalAreaContextProvider,
  usePersonalAreaContext
} from '../contexts/personal_area'
import { ICheckItemCreateRequest } from '../core/api_request_responses/check_item.models'
import translate from '../hooks/use_localization'
import Layout from '../layouts/page_layout'

export default function PersonalAreaPage() {
  return (
    <PersonalAreaContextProvider>
      <Layout>
        <Box
          sx={{
            display: 'column'
          }}
        >
          <ViewSelect />

          <Box sx={{ ml: 3 }}>
            <CheckItemKanban />
            <TaskList />
          </Box>
        </Box>
        <CreateCheckItem />
      </Layout>
    </PersonalAreaContextProvider>
  )
}

function TaskList() {
  const { mutate, checkItems, view } = usePersonalAreaContext()
  if (view !== 'list') {
    return null
  }
  return (
    <CheckItemList
      checkItemList={checkItems}
      mutate={async () => {
        await mutate?.()
      }}
    />
  )
}

function CreateCheckItem() {
  const { saveCheckItem } = usePersonalAreaContext()
  const [is_open, set_is_open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<Partial<ICheckItemCreateRequest>>({})
  const apiError = useRef<string | undefined>()

  function set_close() {
    if (loading) {
      return
    }
    apiError.current = undefined
    setPayload({})
    set_is_open(false)
  }

  function set_open() {
    set_is_open(true)
  }

  function checkPayload(): boolean {
    if (!payload?.name || payload.name.trim().length === 0) {
      return false
    }

    return true
  }

  async function save() {
    if (!checkPayload()) {
      return
    }

    setLoading(true)
    const response = await saveCheckItem?.(
      payload as unknown as ICheckItemCreateRequest
    )
    if (response) {
      apiError.current = response
    } else {
      set_close()
    }
    setLoading(false)
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({ ...payload, name: event.target.value })
  }

  return (
    <>
      <Fab
        variant="extended"
        size="medium"
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24
        }}
        aria-label="add"
        onClick={set_open}
      >
        <TaskOutlined color="secondary" sx={{ mr: 1 }} />
        {translate('create_checkitem')}
      </Fab>
      <Dialog
        open={is_open}
        onClose={set_close}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle> {translate('create_checkitem')}</DialogTitle>
        <DialogContent>
          <If condition={!!apiError.current}>
            <Typography>{apiError.current}</Typography>
          </If>
          <Stack
            direction={{
              xs: 'column',
              md: 'row'
            }}
            mt={2}
            columnGap={2}
          >
            <TextField
              label={translate('checkitem')}
              required={true}
              fullWidth
              value={payload?.name}
              onChange={handleChangeName}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={set_close} variant={'outlined'}>
            {translate('close')}
          </Button>
          <LoadingButton
            disabled={!checkPayload()}
            loading={loading}
            onClick={save}
            variant={'contained'}
          >
            {translate('create')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

function ViewSelect() {
  const { view, setView } = usePersonalAreaContext()

  return (
    <Stack direction={'row-reverse'} gap={1} alignItems={'center'} p={1}>
      <Typography>{translate('list_view')}</Typography>
      <Switch
        color={'primary'}
        checked={view === 'list'}
        onChange={() => setView?.(view === 'list' ? 'kanban' : 'list')}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography>{translate('table_view')}</Typography>
    </Stack>
  )
}
