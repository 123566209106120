import { Box } from '@mui/material'
import { DraggableProvided } from 'react-beautiful-dnd'
import { CheckItem } from '../../../models'
import CheckItemRow from '../../check_item_card'

export default function CheckItemContainer(props: {
  item: CheckItem
  isDragging: boolean
  index: number
  provided: DraggableProvided
}) {
  const { item, isDragging, provided, index } = props

  return (
    <Box
      sx={{ cursor: 'grap!important' }}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
      data-is-dragging={isDragging}
      data-index={index}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1
        }}
      >
        <CheckItemRow item={item} hideAction={true} />
      </Box>
    </Box>
  )
}
