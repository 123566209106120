import { RoomOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import GoogleMapReact, { Coords } from 'google-map-react'
import { useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketUpdateCoordinates } from '../../core/api_request_responses/ticket.models'
import translate from '../../hooks/use_localization'
import { put } from '../../utils/api'

const GOOGLE_MAPS_API_KEY = 'AIzaSyBLE4Mlq2msh4IwFByNEtk7_YZKWzk_mqM'

export default function TicketCoordinates() {
  const { ticket } = useTicketDetailContext()

  if (!ticket?.lat || !ticket?.long) {
    return null
  }

  return (
    <div style={{ height: '20vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBLE4Mlq2msh4IwFByNEtk7_YZKWzk_mqM' }}
        defaultCenter={{
          lat: ticket.lat,
          lng: ticket.long
        }}
        defaultZoom={15}
      >
        <CustomPoint lat={ticket.lat} lng={ticket.long} />
      </GoogleMapReact>
    </div>
  )
}

function CustomPoint({ lat, lng }: { lat: number; lng: number }) {
  const { ticket, changeTicket } = useTicketDetailContext()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCoordinates, setSelectedCoordinates] = useState<Coords>({
    lat,
    lng
  })
  function setClose() {
    setIsOpen(false)
  }

  function setOpen() {
    setIsOpen(true)
  }

  async function save() {
    if (!ticket || !changeTicket) {
      return
    }

    if (!isChanged({ lat, lng }, selectedCoordinates)) {
      setClose()
    }
    const oldLat = ticket?.lat
    const oldLong = ticket?.long
    changeTicket({
      ...ticket,
      lat: selectedCoordinates.lat,
      long: selectedCoordinates.lng
    })
    put<ITicketUpdateCoordinates, undefined>(
      `/ticket/${ticket.id}/update-coordinates`,
      {
        lat: selectedCoordinates.lat,
        long: selectedCoordinates.lng
      }
    ).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({
          ...ticket,
          lat: oldLat,
          long: oldLong
        })
        alert('update_error')
      }
    })
    setClose()
  }

  async function _delete() {
    if (!ticket || !changeTicket) {
      return
    }

    const oldLat = ticket?.lat
    const oldLong = ticket?.long
    changeTicket({
      ...ticket,
      lat: 0,
      long: 0
    })

    put<ITicketUpdateCoordinates, undefined>(
      `/ticket/${ticket.id}/update-coordinates`,
      {
        lat: 0,
        long: 0
      }
    ).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({
          ...ticket,
          lat: oldLat,
          long: oldLong
        })
        alert('update_error')
      }
    })
    setClose()
  }

  return (
    <Box>
      <IconButton onClick={setOpen}>
        <AnyReactComponent lat={lat} lng={lng} color="error" text="" />
      </IconButton>
      <Dialog open={isOpen} onClose={setClose} fullWidth={true}>
        <DialogTitle>{translate('edit_location')}</DialogTitle>
        <DialogContent>
          <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GOOGLE_MAPS_API_KEY
              }}
              defaultZoom={15}
              defaultCenter={{
                lat,
                lng
              }}
              onClick={(a) => {
                setSelectedCoordinates({ lat: a.lat, lng: a.lng })
              }}
            >
              <AnyReactComponent
                lat={lat}
                lng={lng}
                color="error"
                text={
                  isChanged({ lat, lng }, selectedCoordinates)
                    ? translate('old')
                    : ''
                }
              />
              {isChanged({ lat, lng }, selectedCoordinates) ? (
                <AnyReactComponent
                  text={translate('_new')}
                  lat={selectedCoordinates.lat}
                  lng={selectedCoordinates.lng}
                  color="success"
                />
              ) : null}
            </GoogleMapReact>
          </div>
        </DialogContent>
        <DialogActions>
          <Box display={'flex'} width="100%" justifyContent={'space-between'}>
            <LoadingButton onClick={_delete} color="error" variant="outlined">
              {translate('_delete')}
            </LoadingButton>
            <Box columnGap={2}>
              <Button onClick={setClose}>{translate('close')}</Button>
              <LoadingButton variant="contained" onClick={save}>
                {translate('save')}
              </LoadingButton>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

function isChanged(old: Coords, _new: Coords): boolean {
  if (old.lat !== _new.lat) {
    return true
  }
  if (old.lng !== _new.lng) {
    return true
  }
  return false
}

const AnyReactComponent = ({
  color,
  text,
  lat,
  lng
}: {
  color: 'error' | 'success'
  lat: number
  lng: number
  text: string
}) => (
  <Box>
    <RoomOutlined fontSize="large" color={color} />
    <Typography>{text}</Typography>
  </Box>
)
