import { Avatar, Button, Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { toString } from '../core/helpers'
import translate from '../hooks/use_localization'
import { User } from '../models'
import If from './if'

export default function UserCard({
  user,
  additionalText
}: {
  user: User
  additionalText?: string
}) {
  return (
    <Card
      sx={{
        p: 1,
        width: 280,
        maxWidth: 280,
        minWidth: 280
      }}
    >
      <Button
        sx={{ p: 0, pt: 1, alignItems: 'center' }}
        variant={'text'}
        href={`/profile/${user.id}`}
      >
        <Avatar
          sx={{ width: 32, height: 32, mr: 1 }}
          alt={toString(user.name)}
          src={toString(user?.profilePicture)}
        />
        <Typography fontWeight={'bold'}>{user.name}</Typography>
      </Button>
      <Stack
        direction={'row'}
        alignItems={'flex-end'}
        mt={3}
        justifyContent={'space-between'}
      >
        <Typography variant={'caption'}>{translate('user')}</Typography>
        <If condition={!!additionalText}>
          <Typography fontWeight={'bold'} variant={'caption'}>
            {additionalText}
          </Typography>
        </If>
      </Stack>
    </Card>
  )
}
