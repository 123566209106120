import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AIProduct() {
  return (
    <>
      <GenerateUserStory />
      <CreateGoToMarketPlan />
      <PrioritizeBugs />
    </>
  )
}

function GenerateUserStory() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    targetUser: '',
    feature: ''
  })
  const isDisabled = !payload.targetUser || !payload.feature || !!loading

  const functionCode = 'product_generate_user_story'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      targetUser: '',
      feature: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.targetUser}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetUser: event.target.value
          }))
        }
        placeholder={translate('targetUser')}
      />
      <TextField
        disabled={loading}
        value={payload.feature}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            feature: event.target.value
          }))
        }
        placeholder={translate('feature')}
      />
    </AIFunctionCard>
  )
}

function CreateGoToMarketPlan() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    product: '',
    targetAudience: ''
  })
  const isDisabled = !payload.product || !payload.targetAudience || !!loading

  const functionCode = 'product_create_go_to_market_plan'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      product: '',
      targetAudience: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.product}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            product: event.target.value
          }))
        }
        placeholder={translate('product')}
      />
      <TextField
        disabled={loading}
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
    </AIFunctionCard>
  )
}

function PrioritizeBugs() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    product: '',
    bugReportSource: '',
    bugReportData: ''
  })
  const isDisabled =
    !payload.product ||
    !payload.bugReportSource ||
    !payload.bugReportData ||
    !!loading

  const functionCode = 'product_prioritize_bugs'

  async function generateCampaignIdeas() {
    await sendQuestionTemplate?.({
      questionTemplate: functionCode,
      payload
    })
    setPayload({
      product: '',
      bugReportSource: '',
      bugReportData: ''
    })
  }

  return (
    <AIFunctionCard
      isDisabled={isDisabled}
      onSend={generateCampaignIdeas}
      code={functionCode}
    >
      <TextField
        disabled={loading}
        value={payload.product}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            product: event.target.value
          }))
        }
        placeholder={translate('product')}
      />
      <TextField
        disabled={loading}
        value={payload.bugReportSource}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            bugReportSource: event.target.value
          }))
        }
        placeholder={translate('bugReportSource')}
      />
      <TextField
        disabled={loading}
        value={payload.bugReportData}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            bugReportData: event.target.value
          }))
        }
        placeholder={translate('bugReportData')}
      />
    </AIFunctionCard>
  )
}
