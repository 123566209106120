import { LoadingButton } from '@mui/lab'
import { Avatar, Stack } from '@mui/material'
import { useRef, useState } from 'react'
import { toString } from '../core/helpers'
import translate from '../hooks/use_localization'
import Card from './card'

export function AvatarUpload({
  url,
  name,
  sendImage
}: {
  url?: string
  name: string
  sendImage: (formData: FormData) => Promise<boolean>
}) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null)
  const [loading, set_loading] = useState(false)

  const onClickHandler = () => {
    fileInputRef.current?.click()
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length || loading) {
      return
    }

    const formData = new FormData()

    Array.from(event.target.files).forEach((file) => {
      formData.append('file', file)
    })

    set_loading(true)
    sendImage(formData)
      .then(() => formRef.current?.reset())
      .finally(() => set_loading(false))
  }

  return (
    <Card>
      <Stack sx={{ alignItems: 'center' }}>
        <Avatar
          alt={toString(name)}
          src={toString(url)}
          sx={{ width: 256, height: 256 }}
        />

        <form ref={formRef}>
          <br />
          <LoadingButton
            sx={{ alignSelf: 'center' }}
            variant={'contained'}
            loading={loading}
            onClick={onClickHandler}
          >
            {translate('uploadAvatar')}
          </LoadingButton>
          <input
            multiple={false}
            disabled={loading}
            name={'file'}
            onChange={onChangeHandler}
            ref={fileInputRef}
            accept={'image/*'}
            style={{ display: 'none' }}
            type="file"
          />
        </form>
      </Stack>
    </Card>
  )
}
