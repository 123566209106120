import { Typography } from '@mui/material'
import { useTicketDetailContext } from '../../contexts/ticket_detail'

export default function TicketProject() {
  const { ticket } = useTicketDetailContext()
  if (!ticket) {
    return null
  }

  return <Typography variant="h6">{ticket?.project?.name}</Typography>
}
