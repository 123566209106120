import { convertISO8601StringToDate } from '../core/helpers'
import { WebPaymentResponse } from '../core/models/web_payment.response'

export class WebPayment {
  id: number = 0
  createdAt: Date = new Date()
  updatedAt: Date = new Date()
  paymentAmount: number = 0
  isDone: boolean = false
  isRejected: boolean = false
}

export function createWebPaymentFromWebPaymentResponse(
  response: WebPaymentResponse
): WebPayment {
  const instance = new WebPayment()
  instance.id = response.id
  instance.createdAt =
    convertISO8601StringToDate(response.createdAt) ?? new Date()
  instance.updatedAt =
    convertISO8601StringToDate(response.updatedAt) ?? new Date()
  instance.paymentAmount = response.paymentAmount
  instance.isDone = response.isDone
  instance.isRejected = response.isRejected

  return instance
}
