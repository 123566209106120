import {
  AddOutlined,
  ListOutlined,
  Logout,
  StackedLineChartOutlined
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Dialog,
  Divider,
  Fab,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Stack } from '@mui/system'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AssigneeSelect from '../components/assignee_select'
import Card from '../components/card'
import NewTicketCheckList from '../components/create_ticket/check_list'
import NewTicketFileList from '../components/create_ticket/file_list'
import If from '../components/if'
import LayerSelect from '../components/layer_select'
import Notifications from '../components/notifications'
import PrioritySelect from '../components/priority_select'
import ProjectSelect from '../components/project_select'
import {
  CreateTicketContextProvider,
  useCreateTicketContext
} from '../contexts/create_ticket'
import {
  IProjectRequest,
  IProjectResponse
} from '../core/api_request_responses/project.models'
import { ITicketCreateRequest } from '../core/api_request_responses/ticket.models'
import {
  convertDateStringToISOString,
  generateQueryString,
  toArray,
  toNumber
} from '../core/helpers'
import translate from '../hooks/use_localization'
import useQuery from '../hooks/use_query'
import {
  Project,
  createProjectFromProjectResponse
} from '../models/project.model'
import { get } from '../utils/api'

export const app_bar_height = 64

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}))

export default function Layout(props: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: (theme) => theme.palette.grey[100]
      }}
    >
      <CustomAppBar />

      <Box
        sx={{
          maxWidth: '1920px',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Box
          component="main"
          sx={{
            paddingBottom: 4,
            height: '100vh',
            width: '98%',
            minWidth: '98%',
            maxWidth: '98%',
            overflow: 'auto'
          }}
        >
          <Box sx={{ height: 96 }} />
          {props.children}
        </Box>
        <CreateTicket />
      </Box>
    </Box>
  )
}

const selectedBoxSx = {
  color: 'white',
  background: 'rgba(255,255,255,0.2)',
  borderRadius: 1
}

const selectedTextSx = { fontWeight: 'bold', fontSize: 16 }

function CustomAppBar() {
  const name = window.localStorage?.getItem('name') as string
  const photo_url = window.localStorage?.getItem('photoUrl') as string
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function goTo(path: string) {
    navigate(path)
  }

  return (
    <AppBar position="fixed">
      <Box
        display={'flex'}
        px={3}
        justifyContent={'space-between'}
        sx={{ height: app_bar_height }}
      >
        <Box display={'flex'} alignItems={'center'}>
          <MenuItem
            onClick={() => goTo('/')}
            sx={pathname === '/' ? selectedBoxSx : {}}
          >
            <Typography sx={pathname === '/' ? selectedTextSx : {}}>
              {translate('indexpage')}
            </Typography>
          </MenuItem>
          <NavigationTab
            pathname={pathname}
            path={'/tickets'}
            text={translate('tickets')}
          />

          <NavigationTab
            pathname={pathname}
            path={'/personal-area'}
            text={translate('personalArea')}
          />
          <NavigationTab
            pathname={pathname}
            path={'/ai'}
            text={translate('assistant')}
          />
        </Box>

        <Stack direction={'row'} gap={1}>
          <Notifications />
          <Tooltip title="Profil">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar
                alt={name}
                src={
                  typeof photo_url === 'string' && photo_url.length > 0
                    ? photo_url
                    : undefined
                }
                sx={{ width: 32, height: 32 }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => goTo('/profile')}>
          <Avatar
            alt={name}
            src={
              typeof photo_url === 'string' && photo_url.length > 0
                ? photo_url
                : undefined
            }
            sx={{ width: 32, height: 32 }}
          />
          {name}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => goTo('/project-groups')}>
          <ListItemIcon>
            <ListOutlined fontSize="small" />
          </ListItemIcon>
          {translate('projectGroups')}
        </MenuItem>
        <MenuItem onClick={() => goTo('/statistic')}>
          <ListItemIcon>
            <StackedLineChartOutlined fontSize="small" />
          </ListItemIcon>
          {translate('statistic')}
        </MenuItem>
        <Divider />
        <LanguageSelect />

        <Divider />
        <MenuItem onClick={() => goTo('/logout')}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {translate('logout')}
        </MenuItem>
      </Menu>
    </AppBar>
  )
}

function LanguageSelect() {
  let locale = window.localStorage.getItem('lang') ?? 'en'

  if (locale !== 'tr' && locale !== 'en') {
    locale = 'tr'
  }

  const newLang = locale === 'tr' ? 'en' : 'tr'

  function changeLang() {
    window.localStorage.setItem('lang', newLang)
    window.location.reload()
  }

  return (
    <MenuItem onClick={changeLang} sx={{ columnGap: 1 }}>
      <ListItemIcon>
        <img
          src={`https://flagsapi.com/${
            newLang === 'tr' ? 'TR' : 'GB'
          }/shiny/24.png`}
        />
      </ListItemIcon>
      {newLang === 'tr' ? 'Türkçe' : 'English'}
    </MenuItem>
  )
}

function NavigationTab({
  text,
  pathname,
  path
}: {
  text: string
  pathname: string
  path: string
}) {
  const navigate = useNavigate()
  const isSelected = pathname.startsWith(path)

  return (
    <MenuItem
      onClick={() => navigate(path)}
      sx={isSelected ? selectedBoxSx : {}}
    >
      <Typography sx={isSelected ? selectedTextSx : {}}>{text}</Typography>
    </MenuItem>
  )
}

function CreateTicket() {
  const { pathname } = useLocation()

  if (
    ['/statistic', '/personal-area', '/ai'].some((p) => pathname.startsWith(p))
  ) {
    return null
  }

  return (
    <CreateTicketContextProvider>
      <CreateTicketButton />
    </CreateTicketContextProvider>
  )
}

function CreateTicketButton() {
  const { setClose, setOpen, isOpen } = useCreateTicketContext()
  return (
    <>
      <Fab
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          zIndex: 98
        }}
        onClick={setOpen}
        variant="extended"
        size="medium"
        color="primary"
        aria-label="add"
      >
        <AddOutlined sx={{ mr: 1 }} />
        {translate('createTicket')}
      </Fab>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={!!isOpen}
        onClose={setClose}
      >
        <CreateTicketArea />
      </Dialog>
    </>
  )
}

function CreateTicketArea() {
  const { createTicket, priorities, projects, setOpen } =
    useCreateTicketContext()
  const query = useQuery()

  const [loading, setLoading] = React.useState(false)
  const [payload, setPayload] = React.useState<Partial<ITicketCreateRequest>>({
    statusId: 1,
    priorityId: 1
  })
  const apiError = React.useRef<string | undefined>()

  const [selectedProject, setSelectedProject] = React.useState<
    Project | undefined
  >()

  async function getProject(id: number) {
    const url = generateQueryString<IProjectRequest>(
      `/project/${toNumber(id)}`,
      {
        users: 'true',
        groups: 'true'
      }
    )
    if (!url) {
      return
    }
    setLoading(true)
    const response = await get<IProjectResponse>(url)
    if (response.statusCode === 200 && response.payload?.project) {
      setSelectedProject(
        createProjectFromProjectResponse(response.payload.project)
      )
    }
    setLoading(false)
  }

  function checkPayload(): boolean {
    if (!payload?.name || payload.name.trim().length === 0) {
      return false
    }
    if (!payload?.statusId) {
      return false
    }
    if (!payload?.priorityId) {
      return false
    }
    if (!payload?.assignedId) {
      return false
    }
    if (!['user', 'group'].includes(payload?.assignedType ?? '')) {
      return false
    }
    if (!payload?.projectId) {
      return false
    }

    if (!payload?.layerId) {
      return false
    }

    return true
  }

  async function save() {
    if (!checkPayload()) {
      return
    }

    if (payload?.startDate) {
      payload.startDate = convertDateStringToISOString(payload.startDate)
    } else {
      payload.startDate = undefined
    }

    if (payload?.endDate) {
      payload.endDate = convertDateStringToISOString(payload.endDate)
    } else {
      payload.endDate = undefined
    }

    setLoading(true)
    const response = await createTicket?.(
      payload as unknown as ITicketCreateRequest
    )
    if (response) {
      apiError.current = response
    }
    setLoading(false)
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({ ...payload, name: event.target.value })
  }

  function handleChangePriorityId(priorityId: number) {
    setPayload({ ...payload, priorityId })
  }

  function handleChangeProjectId(projectId: number) {
    getProject(projectId).finally(() => setPayload({ ...payload, projectId }))
  }

  function handleChangeLayerId(layerId: number) {
    setPayload({ ...payload, layerId })
  }

  function handleChangeAssignment({
    assignedId,
    assignedType
  }: {
    assignedId: number
    assignedType: 'group' | 'user'
  }) {
    setPayload({ ...payload, assignedId, assignedType })
  }

  function handleChangeEndDate(event: React.ChangeEvent<HTMLInputElement>) {
    setPayload({
      ...payload,
      endDate: event.target.value
    })
  }

  const users = toArray(
    toArray(selectedProject?.layers).find(({ id }) => id === payload?.layerId)
      ?.users
  )

  const groups = toArray(
    toArray(selectedProject?.layers).find(({ id }) => id === payload?.layerId)
      ?.groups
  )

  React.useEffect(() => {
    console.log('query', query)
    const isCreateTicket = query.get('createTicket')
    if (isCreateTicket === 'true') {
      const endDate = query.get('endDate')
      const startDate = query.get('startDate')
      const ticketName = query.get('name') ?? ''
      const description = query.get('description') ?? ''
      setPayload((prev) => ({
        ...prev,
        name: ticketName,
        description,
        endDate: endDate ? convertDateStringToISOString(endDate) : undefined,
        startDate: startDate
          ? convertDateStringToISOString(startDate)
          : undefined
      }))
    }
  }, [query])

  return (
    <Card>
      <Typography variant="h5">{translate('createTicket')}</Typography>
      <If condition={!!apiError.current}>
        <Typography>{apiError.current}</Typography>
      </If>
      <Stack direction={'column'} rowGap={2}>
        <TextField
          label={translate('ticketName')}
          required={true}
          fullWidth
          value={payload?.name}
          onChange={handleChangeName}
        />

        <ProjectSelect
          projects={toArray(projects)}
          disabled={loading}
          onListItemClick={handleChangeProjectId}
          value={payload?.projectId}
        />
        <LayerSelect
          variant="outlined"
          layers={toArray(selectedProject?.layers)}
          onListItemClick={handleChangeLayerId}
          disabled={loading || !selectedProject}
          value={payload?.layerId}
        />
        <GetHelpButton payload={payload} setPayload={setPayload} />
        <Stack direction={'row'} gap={2} alignItems="center">
          <AssigneeSelect
            users={users}
            groups={groups}
            onListItemClick={handleChangeAssignment}
            selectedType={payload?.assignedType as 'user'}
            selectedValue={payload?.assignedId}
            disabled={loading || !selectedProject}
          />
          <PrioritySelect
            priorities={toArray(priorities)}
            value={payload?.priorityId}
            onListItemClick={handleChangePriorityId}
            disabled={loading}
          />
        </Stack>

        <TextField
          label={translate('end_date')}
          type="date"
          value={payload?.endDate}
          onChange={handleChangeEndDate}
          fullWidth={true}
          InputLabelProps={{
            shrink: true
          }}
        />
        <NewTicketFileList />
        <NewTicketCheckList />

        <LoadingButton
          disabled={!checkPayload()}
          loading={loading}
          onClick={save}
          variant={'contained'}
        >
          {translate('create')}
        </LoadingButton>
      </Stack>
    </Card>
  )
}

function GetHelpButton({
  payload,
  setPayload
}: {
  payload: Partial<ITicketCreateRequest>
  setPayload: React.Dispatch<
    React.SetStateAction<Partial<ITicketCreateRequest>>
  >
}) {
  const [loading, setLoading] = React.useState(false)
  const { getHelp } = useCreateTicketContext()

  const isDisabled =
    !getHelp || !payload?.projectId || !payload?.layerId || !payload?.name

  async function onClickGetHelp() {
    if (isDisabled) {
      return
    }

    setLoading(true)
    await getHelp?.(payload, (newPayload) =>
      setPayload((prev) => ({ ...prev, ...newPayload }))
    )
    setLoading(false)
  }

  return (
    <LoadingButton
      sx={{
        display: isDisabled ? 'none' : 'block'
      }}
      disabled={isDisabled}
      loading={loading}
      onClick={onClickGetHelp}
      variant={'text'}
    >
      Doldur
    </LoadingButton>
  )
}
