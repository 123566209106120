import { Autocomplete, Chip, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useMemo } from 'react'
import { checkedIcon, icon } from '.'
import { useTicketsContext } from '../../contexts/tickets'
import { toArray } from '../../core/helpers'

export default function FilterProjectSelect() {
  const { projects, ticketFilter, setTicketFilter, translate, tickets } =
    useTicketsContext()

  const project_options: { value: number; label: string }[] = useMemo(
    () => [
      {
        value: 0,
        label: translate?.('all') ?? ''
      },
      ...toArray(projects).map((p) => ({
        value: p.id,
        label: p.name
      }))
    ],
    [projects]
  )

  function isChecked(value: number): boolean {
    return toArray(ticketFilter?.projectIds).includes(value)
  }

  const selectedOptions = toArray(
    project_options.filter((option) => isChecked(option.value))
  )

  if (!translate || !tickets) {
    return <></>
  }

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      autoComplete={true}
      limitTags={2}
      options={project_options}
      fullWidth={true}
      getOptionLabel={(option) => option.label}
      size={'small'}
      renderTags={(value, getTagProps) => {
        return value.map((v, index) => (
          <Chip
            variant="outlined"
            label={v.label}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      onChange={(a, b) => {
        if (b) {
          if (b.length === 0) {
            setTicketFilter?.((prev) => ({
              ...prev,
              projectIds: [0]
            }))
            return
          }

          setTicketFilter?.((prev) => ({
            ...prev,
            projectIds: Array.from(
              new Set(b.map((a) => a.value).filter(Boolean))
            )
          }))
        }
      }}
      value={selectedOptions}
      renderInput={(params) => (
        <TextField {...params} label={translate('project')} fullWidth={true} />
      )}
    />
  )
}
