import { createTheme } from '@mui/material/styles'
import { trTR } from '@mui/x-data-grid'

// Create a theme instance.
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#1D0547'
      },
      secondary: {
        main: 'rgb(238,128,35)'
      },
      text: {
        primary: '#131315'
      }
    },
    spacing: 8,
    shape: {
      borderRadius: 4
    }
  },
  trTR
)

export default theme
