import {
  CheckOutlined,
  GroupOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  RemoveOutlined,
  RestartAltOutlined,
  Warning
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import Masonry from '@mui/lab/Masonry'
import {
  Avatar,
  Collapse,
  Divider,
  IconButton,
  ListItemButton,
  MenuItem,
  Paper,
  Popover,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/system'
import { useMemo, useState } from 'react'
import { useTicketsContext } from '../../contexts/tickets'
import {
  ITicketChangeAssigneeRequest,
  ITicketChangeStatusRequest,
  ITicketToggleCheckItemRequest
} from '../../core/api_request_responses/ticket.models'
import { toArray, toNumber, toString } from '../../core/helpers'
import { useProject } from '../../hooks/detail_hooks/use_project'
import translate from '../../hooks/use_localization'
import { CheckItem, Group, Status, Ticket, User } from '../../models'
import { put } from '../../utils/api'
import moment from '../../utils/moment'
import AssigneeSelect from '../assignee_select'
import If from '../if'
import TagCard from '../tag_card'
import { TicketCard } from './sub_components/ticket_card'

export function TicketList() {
  const { tickets, statuses, view, changeTicket, handle_click_title } =
    useTicketsContext()

  if (view !== 'list') {
    return null
  }

  if (!tickets || !statuses) {
    return (
      <Masonry
        sx={{
          ml: 1,
          width: {
            xs: 280 * 1,
            sm: 280 * 2,
            md: 280 * 3,
            lg: 280 * 3,
            xl: 280 * 5
          }
        }}
        columns={{
          xs: 1,
          md: 3,
          lg: 4
        }}
        spacing={2}
      >
        {Array.from({ length: 35 }).map((_, index) => (
          <TicketCard key={index} onChangeAssignee={() => {}} />
        ))}
      </Masonry>
    )
  }

  if (tickets.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Typography>{translate('no_ticket')}</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ width: '90%', ml: 2 }}>
      <TableContainer component={Paper}>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell width={'1rem'} />
              <TableCell>{translate('status')}</TableCell>
              <TableCell width={'100%'}>{translate('name')}</TableCell>
              <TableCell sx={{ maxWidth: '5rem' }}>
                {translate('ticket_assignee')}
              </TableCell>
              <TableCell>{translate('project')}</TableCell>
              <TableCell>{translate('tags')}</TableCell>
              <TableCell>{translate('end_date')}</TableCell>
              <TableCell>{translate('priority')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toArray(tickets).map((row) => (
              <Row
                key={row.id}
                row={row}
                changeTicket={changeTicket}
                handle_click_title={(ticket) =>
                  handle_click_title?.(ticket?.id)
                }
                statuses={statuses}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

function Row({
  row,
  handle_click_title,
  statuses,
  changeTicket
}: {
  row: Ticket
  handle_click_title: (ticket?: Ticket | undefined) => void
  setClose?: () => void
  changeTicket?: (ticket: Ticket) => void
  statuses: Status[]
}) {
  const [open, setOpen] = useState(false)

  async function toggle(checkItem: CheckItem) {
    const oldList = toArray(row?.checkItems).slice(0)
    const newList = toArray(row?.checkItems).map((ci) =>
      ci.id === checkItem.id ? { ...ci, isDone: !ci.isDone } : ci
    )
    if (!!changeTicket) {
      changeTicket({ ...row, checkItems: newList })
      put<ITicketToggleCheckItemRequest, undefined>(
        `/ticket/${row.id}/toggle-check-item`,
        { checkItemId: checkItem.id }
      ).then((response) => {
        changeTicket({ ...row, checkItems: oldList })
        if (response.statusCode !== 200) {
          alert('update_error')
        }
      })
    }
  }

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <If condition={!!toArray(row?.checkItems).length}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpOutlined />
              ) : (
                <KeyboardArrowDownOutlined />
              )}
            </IconButton>
          </If>
        </TableCell>
        <TableCell component="th" scope="row">
          <StatusSelect
            row={row}
            statuses={statuses}
            changeTicket={changeTicket}
          />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{ cursor: 'pointer' }}
          onClick={() => handle_click_title(row)}
        >
          {row.name}
        </TableCell>
        <TableCell sx={{ maxWidth: '19rem', minWidth: '19rem' }}>
          <TicketAssigneeSelect row={row} changeTicket={changeTicket} />
        </TableCell>
        <TableCell>{row.project.name}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {toArray(row?.tags)
              .slice(0, 2)
              .map((tag) => (
                <TagCard variant="small" tag={tag} key={tag.id} />
              ))}
          </Box>
        </TableCell>
        <TableCell>
          <TicketTime row={row} />
        </TableCell>
        <TableCell>
          <TicketPriority row={row} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell width={'2rem'} component="th">
                      {translate('order')}
                    </TableCell>
                    <TableCell>{translate('status')}</TableCell>
                    <TableCell>{translate('checkitem')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.checkItems
                    ?.sort((a) => (a.isDone ? 1 : -1))
                    .map((checkItem, index) => (
                      <TableRow
                        key={checkItem.id}
                        sx={{
                          opacity: checkItem.isDone ? 0.5 : 1,
                          '&:last-child td, &:last-child th': {
                            border: 0
                          }
                        }}
                      >
                        <TableCell width={'2rem'} component="th">
                          {index + 1}
                        </TableCell>
                        <TableCell component="th">
                          <If condition={!checkItem.isDone}>
                            <LoadingButton
                              onClick={async () => await toggle(checkItem)}
                            >
                              <CheckOutlined color={'success'} />
                            </LoadingButton>
                          </If>
                          <If condition={checkItem.isDone}>
                            <LoadingButton
                              onClick={async () => await toggle(checkItem)}
                            >
                              <RestartAltOutlined color={'success'} />
                            </LoadingButton>
                          </If>
                        </TableCell>

                        <TableCell component="th" scope="row" width={'100%'}>
                          {checkItem.name}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function StatusSelect({
  row: ticket,
  statuses,
  changeTicket
}: {
  row: Ticket
  statuses: Status[]
  changeTicket?: (ticket: Ticket) => void
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const status_list = useMemo(
    () =>
      toArray(statuses).filter((status) => status?.id !== ticket?.status.id),
    [statuses, ticket?.status?.id]
  )

  async function handle_click_change_ticket_status(statusId: number) {
    const oldStatus = ticket.status
    const targetStatus = toArray(statuses).find(({ id }) => id === statusId)
    if (!targetStatus || !changeTicket) {
      return
    }
    changeTicket({ ...ticket, status: targetStatus })
    put<ITicketChangeStatusRequest>(`/ticket/${ticket.id}/change-status`, {
      statusId
    }).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({ ...ticket, status: oldStatus })
        alert('Ticket durum güncellenemedi')
      }
    })

    setAnchorEl(null)
  }

  if (!ticket) {
    return <Skeleton animation={'wave'} width={30} height={30} />
  }

  return (
    <Box
      sx={{
        maxWidth: 30,
        width: 30,
        minWidth: 30
      }}
    >
      <Tooltip title={translate(ticket.status)}>
        <Box
          sx={{
            maxWidth: 30,
            width: 30,
            minWidth: 30
          }}
        >
          <If condition={!!statuses}>
            <IconButton onClick={handleClick}>
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  background: ticket.status.color
                }}
              />
            </IconButton>
          </If>
        </Box>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          sx={{
            px: 2
          }}
        >
          <Stack direction={'row'} display={'flex'} alignItems={'center'}>
            <Box
              sx={{
                background: ticket.status?.color,
                width: 15,
                height: 15,
                borderRadius: 15
              }}
            />
            <Typography sx={{ p: 2, maxWidth: 150 }}>
              {translate(ticket.status)}
            </Typography>
          </Stack>
          <Divider />
          {status_list.map((status) => (
            <ListItemButton
              component={'button'}
              key={status.id}
              sx={{
                width: '100%'
              }}
              onClick={async () =>
                await handle_click_change_ticket_status(status.id)
              }
            >
              <Stack direction={'row'} display={'flex'} alignItems={'center'}>
                <Box
                  sx={{
                    background: status?.color,
                    width: 15,
                    height: 15,
                    borderRadius: 15
                  }}
                />
                <Typography sx={{ p: 2, maxWidth: 150 }}>
                  {translate(status)}
                </Typography>
              </Stack>
            </ListItemButton>
          ))}
        </Box>
      </Popover>
    </Box>
  )
}

function TicketAssigneeSelect({
  row: ticket,
  changeTicket
}: {
  row: Ticket
  changeTicket?: (ticket: Ticket) => void
}) {
  const { project } = useProject(toNumber(ticket?.project?.id), {
    users: 'true',
    groups: 'true'
  })

  const users = toArray(
    toArray(project?.layers).find(({ id }) => id === ticket?.layer.id)?.users
  )

  const groups = toArray(
    toArray(project?.layers).find(({ id }) => id === ticket?.layer.id)?.groups
  )

  async function handle_click_change({
    assignedId,
    assignedType
  }: {
    assignedId: number
    assignedType: 'group' | 'user'
  }) {
    const oldAssign = ticket.assigned
    const oldAssignedType = ticket.assignedType

    let newAssign: User | Group | undefined
    if (assignedType === 'user') {
      newAssign = users.find(({ id }) => id === assignedId)
    } else {
      newAssign = groups.find(({ id }) => id === assignedId)
    }

    if (!!newAssign && changeTicket) {
      changeTicket({ ...ticket, assigned: newAssign, assignedType })
      await put<ITicketChangeAssigneeRequest>(
        `/ticket/${ticket.id}/change-assignee`,
        { assignedId, assignedType }
      ).then((response) => {
        if (response.statusCode !== 200) {
          changeTicket({
            ...ticket,
            assigned: oldAssign,
            assignedType: oldAssignedType
          })
          alert(translate('update_error'))
        }
      })
    }
  }

  if (!project) {
    return (
      <TextField
        required={true}
        disabled={true}
        fullWidth
        label={translate('ticket_assignee')}
        value={ticket.assigned.id}
        select={true}
      >
        {toArray([
          {
            id: ticket.assigned.id,
            isGroup: ticket.assignedType === 'group',
            name: ticket.assigned.name,
            url: (ticket.assigned as unknown as User)?.profilePicture
          }
        ]).map((assign) => (
          <MenuItem key={assign.id} value={assign.id}>
            <Stack direction={'row'} alignItems={'center'}>
              {assign.isGroup ? (
                <GroupOutlined />
              ) : (
                <Avatar
                  alt={toString(assign.name)}
                  src={toString(assign.url)}
                  sx={{ width: 24, height: 24 }}
                />
              )}
              <Typography sx={{ ml: 1 }}>{assign.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </TextField>
    )
  }

  return (
    <AssigneeSelect
      selectedType={ticket.assignedType}
      selectedValue={ticket.assigned.id}
      users={users}
      groups={groups}
      onListItemClick={handle_click_change}
    />
  )
}

function TicketTime({ row }: { row: Ticket }) {
  if (!row?.endDate) {
    return <RemoveOutlined />
  }
  const locale = window.localStorage.getItem('lang')

  let end_date

  if (locale === 'tr') {
    moment.updateLocale('tr', {
      months:
        'Ocak_Şubat_Mart_Nisan_Mayıs_Haziran_Temmuz_Ağustos_Eylül_Ekim_Kasım_Aralık'.split(
          '_'
        ),
      monthsShort: 'Oca_Şub_Mar_Nis_May_Haz_Tem_Ağu_Eyl_Eki_Kas_Ara'.split('_'),
      weekdays: 'Pazar_Pazartesi_Salı_Çarşamba_Perşembe_Cuma_Cumartesi'.split(
        '_'
      ),
      weekdaysShort: 'Paz_Pts_Sal_Çar_Per_Cum_Cts'.split('_'),
      weekdaysMin: 'Pz_Pt_Sa_Ça_Pe_Cu_Ct'.split('_'),
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD.MM.YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm'
      },
      calendar: {
        sameDay: '[bugün saat] LT',
        nextDay: '[yarın saat] LT',
        nextWeek: '[gelecek] dddd [saat] LT',
        lastDay: '[dün] LT',
        lastWeek: '[geçen] dddd [saat] LT',
        sameElse: 'L'
      },
      relativeTime: {
        future: '%s sonra',
        past: '%s önce',
        s: 'birkaç saniye',
        ss: '%d saniye',
        m: 'bir dakika',
        mm: '%d dakika',
        h: 'bir saat',
        hh: '%d saat',
        d: 'bir gün',
        dd: '%d gün',
        M: 'bir ay',
        MM: '%d ay',
        y: 'bir yıl',
        yy: '%d yıl'
      },

      week: {
        dow: 1,
        doy: 7
      }
    })
    moment.locale('tr')
    end_date = moment(row?.endDate)
  } else {
    moment.locale('en')
    end_date = moment(row?.endDate)
  }

  const is_overdue =
    !end_date.isSameOrAfter(moment()) && row?.status?.code !== 'done'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography color={is_overdue ? 'error' : 'inherit'} fontWeight="bold">
        {end_date.date().toString()}
      </Typography>
      <Typography color={is_overdue ? 'error' : 'inherit'}>
        {end_date.format('MMM').toUpperCase()}
      </Typography>
    </Box>
  )
}

function TicketPriority({ row }: { row: Ticket }) {
  if (row?.priority.code === 'normal') {
    return <></>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Tooltip title={translate('this_ticket_is_critical')}>
        <Warning color="warning" />
      </Tooltip>
    </Box>
  )
}
