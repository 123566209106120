import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { ITicketChangeStartDateRequest } from '../../core/api_request_responses/ticket.models'
import {
  convertDateStringToISOString,
  convertISO8601StringToDate
} from '../../core/helpers'
import translate from '../../hooks/use_localization'
import { put } from '../../utils/api'
import moment from '../../utils/moment'

export default function TicketStartDate() {
  const { ticket, changeTicket } = useTicketDetailContext()
  const [value, setValue] = useState(
    ticket?.startDate
      ? moment(ticket?.startDate).format('YYYY-MM-DD')
      : undefined
  )

  useEffect(() => {
    if (ticket) {
      setValue(
        ticket?.startDate
          ? moment(ticket.startDate).format('YYYY-MM-DD')
          : undefined
      )
    }
  }, [ticket])

  async function change(newValue: string) {
    if (!ticket || !changeTicket) {
      return
    }
    const rawOldValue = ticket?.startDate
    const oldValue = ticket?.startDate
      ? moment(ticket.startDate).format('YYYY-MM-DD')
      : undefined
    const formattedNewValue =
      newValue && newValue.length > 0
        ? convertDateStringToISOString(newValue)
        : undefined

    if (oldValue === newValue) {
      return
    }
    changeTicket({
      ...ticket,
      startDate: formattedNewValue
        ? convertISO8601StringToDate(formattedNewValue)
        : undefined
    })
    put<ITicketChangeStartDateRequest>(
      `/ticket/${ticket.id}/change-start-date`,
      { startDate: formattedNewValue }
    ).then((response) => {
      if (response.statusCode !== 200) {
        changeTicket({ ...ticket, startDate: rawOldValue })
        alert('update_error')
      }
    })
  }

  if (!ticket) {
    return null
  }

  return (
    <TextField
      label={translate('start_date')}
      type="date"
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onBlur={async (event) => await change(event.target.value)}
      fullWidth={true}
      InputLabelProps={{
        shrink: true
      }}
    />
  )
}
