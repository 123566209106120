import useSWR from 'swr'
import { IStatusListResponse } from '../../core/api_request_responses/status.models'
import { generateQueryString, toArray } from '../../core/helpers'
import { StatusResponse } from '../../core/models/status.response'
import { createStatusFromStatusResponse } from '../../models/status.model'

export default function useStatuses() {
  const { data, mutate } = useSWR<IStatusListResponse>(
    generateQueryString('/status'),
    { refreshInterval: 0 }
  )
  return {
    statuses: data?.statuses
      ? toArray(data?.statuses).map((status: StatusResponse) =>
          createStatusFromStatusResponse(status)
        )
      : undefined,
    mutate
  }
}
