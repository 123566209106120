import { LoadingButton } from '@mui/lab'
import { Stack, Switch } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import translate from '../hooks/use_localization'
import If from './if'

interface IStatisticTimeSwitchProps {
  onClickSet: ({
    endDate,
    startDate
  }: {
    endDate: string
    startDate: string
  }) => Promise<void>
  isTotal: boolean
  toggleIsTotal: () => void
}
export function StatisticTimeSwitch({
  onClickSet,
  isTotal,
  toggleIsTotal
}: IStatisticTimeSwitchProps) {
  const [loading, set_loading] = useState(false)
  const [start_date, set_start_date] = useState(
    `${new Date().getFullYear()}-01-01`
  )
  const [end_date, set_end_date] = useState(
    `${new Date().getFullYear() + 1}-01-01`
  )

  function handleStartChange(event: React.ChangeEvent<HTMLInputElement>) {
    set_start_date(event.target.value)
  }

  function handleEndChange(event: React.ChangeEvent<HTMLInputElement>) {
    set_end_date(event.target.value)
  }

  return (
    <Stack
      direction={'row'}
      gap={1}
      alignItems={'center'}
      justifyContent={'space-between'}
      p={1}
    >
      <Box>
        <If condition={!isTotal}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <TextField
              id="date"
              label={translate('start_date')}
              type="date"
              onChange={handleStartChange}
              value={start_date}
              disabled={loading}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="date"
              label={translate('end_date')}
              type="date"
              onChange={handleEndChange}
              value={end_date}
              disabled={loading}
              InputLabelProps={{
                shrink: true
              }}
            />
            <LoadingButton
              loading={loading}
              variant={'outlined'}
              disabled={!start_date || !end_date}
              onClick={async () => {
                set_loading(true)
                await onClickSet({
                  startDate: `${start_date} 00:00:00`,
                  endDate: `${end_date} 23:59:59`
                })
                set_loading(false)
              }}
            >
              <Typography variant={'body2'}>{translate('update')}</Typography>
            </LoadingButton>
          </Stack>
        </If>
      </Box>
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        <Typography>{translate('custom')}</Typography>
        <Switch
          disabled={loading}
          color={'primary'}
          checked={isTotal}
          onChange={toggleIsTotal}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography>{translate('all')}</Typography>
      </Stack>
    </Stack>
  )
}
