import { StatusResponse } from '../core/models/status.response'

export class Status {
  id: number = 0
  code: string = ''
  tr: string = ''
  en: string = ''
  color: string = ''
}

export function createStatusFromStatusResponse(
  statusResponse: StatusResponse
): Status {
  const instance = new Status()
  instance.id = statusResponse.id
  instance.code = statusResponse.code
  instance.tr = statusResponse.tr
  instance.en = statusResponse.en
  instance.color = statusResponse.color
  return instance
}
