import useSWR from 'swr'
import {
  IMeRequest,
  IMeResponse
} from '../../core/api_request_responses/me.models'
import { generateQueryString, toArray } from '../../core/helpers'
import { createUserFromUserResponse } from '../../models/user.model'
import { createWebPaymentFromWebPaymentResponse } from '../../models/web_payment.model'

export default function useMe(props?: IMeRequest) {
  const { data, mutate } = useSWR<IMeResponse>(
    generateQueryString('/me', props)
  )

  return {
    user: data?.user ? createUserFromUserResponse(data?.user) : undefined,
    orders: data?.orders
      ? toArray(data?.orders).map(createWebPaymentFromWebPaymentResponse)
      : [],
    mutate
  }
}
