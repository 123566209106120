import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AIProject() {
  return (
    <>
      <CreateProjectScopeDocument />
      <CreateProjectSubtasks />
    </>
  )
}

function CreateProjectScopeDocument() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    projectDescription: ''
  })
  const isDisabled = !payload?.projectDescription || !!loading

  async function send() {
    await sendQuestionTemplate?.({
      questionTemplate: 'project_create_scope_document',
      payload
    })
    setPayload({
      projectDescription: ''
    })
  }

  return (
    <AIFunctionCard
      code="project_create_scope_document"
      onSend={send}
      isDisabled={isDisabled}
    >
      <TextField
        multiline
        value={payload.projectDescription}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            projectDescription: event.target.value
          }))
        }
        disabled={loading}
        placeholder={translate('projectDescription')}
      />
    </AIFunctionCard>
  )
}

function CreateProjectSubtasks() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    mainTask: '',
    talkingPoints: ''
  })
  const isDisabled = !payload?.mainTask || !payload.talkingPoints || !!loading

  async function send() {
    await sendQuestionTemplate?.({
      questionTemplate: 'project_create_subtasks',
      payload
    })
    setPayload({
      mainTask: '',
      talkingPoints: ''
    })
  }

  return (
    <AIFunctionCard
      code="project_create_subtasks"
      onSend={send}
      isDisabled={isDisabled}
    >
      <TextField
        value={payload.mainTask}
        required
        disabled={loading}
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            mainTask: event.target.value
          }))
        }
        placeholder={translate('mainTask')}
      />
      <TextField
        multiline
        value={payload.talkingPoints}
        required
        disabled={loading}
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            talkingPoints: event.target.value
          }))
        }
        placeholder={translate('talkingPoints')}
      />
    </AIFunctionCard>
  )
}
