import { toArray } from '../core/helpers'
import { LayerResponse } from '../core/models/layer.response'
import { decodeHtml } from '../utils'
import { Group, createGroupFromGroupResponse } from './group.model'
import { User, createUserFromUserResponse } from './user.model'

export class Layer {
  id: number = 0
  name: string = ''
  projectId: number = 0
  users?: User[]
  groups?: Group[]
}

export function createLayerFromLayerResponse(
  layerResponse: LayerResponse
): Layer {
  const instance = new Layer()
  instance.id = layerResponse.id
  instance.name = decodeHtml(layerResponse.name)
  instance.projectId = layerResponse.projectId
  instance.users = toArray(layerResponse?.users).map(createUserFromUserResponse)
  instance.groups = toArray(layerResponse?.groups).map(
    createGroupFromGroupResponse
  )
  return instance
}
