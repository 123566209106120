import { TextField } from '@mui/material'
import { useState } from 'react'
import AIFunctionCard from '../../components/ai_function_card'
import { useAIContext } from '../../contexts/ai'
import translate from '../../hooks/use_localization'

export default function AISocial() {
  return (
    <>
      <CreateContentCalendar />
      <WriteThread />
    </>
  )
}

function CreateContentCalendar() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    targetAudience: '',
    topics: '',
    postingFrequency: ''
  })

  const isDisabled =
    !payload?.targetAudience ||
    !payload.topics ||
    !payload.postingFrequency ||
    !!loading

  async function send() {
    await sendQuestionTemplate?.({
      questionTemplate: 'social_create_content_calendar',
      payload
    })

    setPayload({
      targetAudience: '',
      topics: '',
      postingFrequency: ''
    })
  }

  return (
    <AIFunctionCard
      code="social_create_content_calendar"
      onSend={send}
      isDisabled={isDisabled}
    >
      <TextField
        disabled={loading}
        multiline
        value={payload.targetAudience}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            targetAudience: event.target.value
          }))
        }
        placeholder={translate('targetAudience')}
      />
      <TextField
        disabled={loading}
        multiline
        value={payload.topics}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            topics: event.target.value
          }))
        }
        placeholder={translate('topics')}
      />
      <TextField
        disabled={loading}
        value={payload.postingFrequency}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            postingFrequency: event.target.value
          }))
        }
        placeholder={translate('postingFrequency')}
      />
    </AIFunctionCard>
  )
}
function WriteThread() {
  const { sendQuestionTemplate, loading } = useAIContext()
  const [payload, setPayload] = useState({
    talkingPoints: ''
  })

  const isDisabled = !payload?.talkingPoints || !!loading

  async function send() {
    await sendQuestionTemplate?.({
      questionTemplate: 'social_write_thread',
      payload
    })

    setPayload({
      talkingPoints: ''
    })
  }

  return (
    <AIFunctionCard
      code="social_write_thread"
      onSend={send}
      isDisabled={isDisabled}
    >
      <TextField
        disabled={loading}
        multiline
        value={payload.talkingPoints}
        required
        onChange={(event) =>
          setPayload((prev) => ({
            ...prev,
            talkingPoints: event.target.value
          }))
        }
        placeholder={translate('talkingPoints')}
      />
    </AIFunctionCard>
  )
}
