import useSWR from 'swr'
import { IProjectListResponse } from '../../core/api_request_responses/project.models'
import { toArray } from '../../core/helpers'
import { createProjectFromProjectResponse } from '../../models/project.model'

export function useProjects() {
  const { data, mutate } = useSWR<IProjectListResponse>('/project', {
    refreshInterval: 0
  })

  return {
    projects: data?.projects
      ? toArray(data?.projects).map((project) =>
          createProjectFromProjectResponse(project)
        )
      : undefined,
    mutate
  }
}
