import { ArrowBack, SettingsOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { useAIContext } from '../../contexts/ai'
import { toArray } from '../../core/helpers'
import translate from '../../hooks/use_localization'
import Card from '../card'
import DialogProvider from '../dialog_provider'

export default function AIFunctionCard({
  children,
  onSend,
  isDisabled,
  code
}: {
  children: React.ReactElement | React.ReactElement[]
  onSend: () => Promise<void>
  isDisabled: boolean
  code: string
}) {
  const { selectedFunctionCode, AI_TITLES, setSelectedFunctionCode } =
    useAIContext()
  const [loading, setLoading] = useState(false)

  const title =
    AI_TITLES.map(({ functions }) => functions)
      .flat()
      .find(({ code }) => code === selectedFunctionCode)?.name ?? ''

  async function send() {
    setLoading(true)
    await onSend()
    setLoading(false)
  }

  function onClickBack() {
    setSelectedFunctionCode?.('select')
  }

  if (selectedFunctionCode !== code) {
    return <></>
  }

  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            direction: 'row',
            flex: 1,
            alignItems: 'center'
          }}
        >
          <IconButton onClick={onClickBack}>
            <ArrowBack />
          </IconButton>

          <Typography variant="h4" sx={{ marginLeft: 2 }}>
            {translate(title)}
          </Typography>
        </Box>

        <FileList />
      </Box>
      <br />
      <Stack gap={2}>
        {children}
        <LoadingButton onClick={send} loading={loading} disabled={isDisabled}>
          {translate('send')}
        </LoadingButton>
      </Stack>
    </Card>
  )
}

function FileList() {
  const { files, selectedFiles, setSelectedFiles } = useAIContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  function onClickFileSelect() {
    setDialogOpen(true)
  }

  return (
    <>
      <IconButton onClick={onClickFileSelect}>
        <SettingsOutlined />
      </IconButton>
      <DialogProvider set_close={() => setDialogOpen(false)} open={dialogOpen}>
        <DialogTitle>{translate('file_select')}</DialogTitle>
        <DialogContent>
          <FormGroup sx={{ gap: 2 }}>
            {toArray(files).map((file) => (
              <FormControlLabel
                key={file.id}
                control={
                  <Checkbox
                    checked={toArray(selectedFiles)?.includes(file.id)}
                    onClick={() => {
                      const isChecked = !selectedFiles?.includes(file.id)
                      if (isChecked) {
                        setSelectedFiles?.((prev) =>
                          Array.from(new Set([...toArray(prev), file.id]))
                        )
                      } else {
                        setSelectedFiles?.((prev) =>
                          toArray(prev).filter((id) => id !== file.id)
                        )
                      }
                    }}
                  />
                }
                label={file.filename}
              />
            ))}
          </FormGroup>
        </DialogContent>
      </DialogProvider>
    </>
  )
}
