import { convertISO8601StringToDate } from '../core/helpers'
import { TicketCommentResponse } from '../core/models/ticket_comment.response'
import { decodeHtml } from '../utils'
import { createUserFromUserResponse, User } from './user.model'

export class Comment {
  id: number = 0
  createdBy: User = new User()
  createdAt: Date = new Date()
  text: string = ''
}

export function createCommentFromCommentResponse(
  response: TicketCommentResponse
): Comment {
  const instance = new Comment()
  instance.id = response.id
  instance.createdBy = createUserFromUserResponse(response.createdBy)
  instance.createdAt =
    convertISO8601StringToDate(response.createdAt) ?? new Date()
  instance.text = decodeHtml(response.text)
  return instance
}
