import { Avatar, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useTicketDetailContext } from '../../contexts/ticket_detail'
import { toString } from '../../core/helpers'
import translate from '../../hooks/use_localization'

export default function TicketOwner() {
  const { ticket } = useTicketDetailContext()
  if (!ticket) {
    return null
  }

  return (
    <Stack>
      <Typography variant="caption">{translate('createdBy')} </Typography>
      <Box sx={{ my: 0.5 }}></Box>
      <Stack direction={'row'} alignItems={'center'}>
        <Avatar
          alt={toString(ticket?.createdBy?.name)}
          src={toString(ticket?.createdBy?.profilePicture)}
          sx={{ width: 24, height: 24 }}
        />

        <Typography sx={{ ml: 1 }}>{ticket?.createdBy?.name}</Typography>
      </Stack>
    </Stack>
  )
}
