import OneSignal from 'react-onesignal'
const isProd = (process.env.env ?? 'dev')?.toLocaleLowerCase() === 'production'

export default async function runOneSignal(externalUserId: number) {
  if (!externalUserId || !isProd) {
    return
  }

  await OneSignal.init({
    appId: 'c2e651f1-3def-4ea6-8abd-33d500dc289e',
    safari_web_id: 'web.onesignal.auto.115f8fcb-d4e8-44f7-85e0-0f5ff0e5093f'
  })
  await OneSignal.setExternalUserId(`${externalUserId}`)

  await OneSignal.showNativePrompt()
  await OneSignal.showSlidedownPrompt()
}

export async function removeExternalId() {
  if (!isProd) {
    return
  }
  OneSignal.removeExternalUserId()
}
