import { LoadingButton } from '@mui/lab'
import { DialogTitle } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import Lottie from 'react-lottie'
import translate from '../hooks/use_localization'
import assistantAnimation from '../lotties/assistant_team.json'
import { post } from '../utils/api'
import Card from './card'
import DialogProvider from './dialog_provider'

export default function AILimitModal({
  open,
  setClose,
  ticketId,
  code
}: {
  open: boolean
  setClose: () => void
  ticketId?: number
  code: string
}) {
  const [loading, setLoading] = useState(false)
  const [isSended, setIsSended] = useState(false)
  const handleClose = () => {
    setIsSended(false)
    setClose()
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: assistantAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  async function sendRequest() {
    setLoading(true)
    await post('/ai/token-request', { ticketId, code })
    setIsSended(true)
    setLoading(false)
  }

  return (
    <DialogProvider open={open} set_close={handleClose}>
      <Card>
        <DialogTitle>{isSended ? '' : translate('ai_limit_full')} </DialogTitle>
        {isSended ? (
          <>
            <Lottie options={defaultOptions} height={400} width={400} />
            <Typography>{translate('request_500_accept')}</Typography>
          </>
        ) : (
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={sendRequest}
          >
            {translate('ai_limit_request')}
          </LoadingButton>
        )}
      </Card>
    </DialogProvider>
  )
}
