import { convertISO8601StringToDate } from '../core/helpers'
import { AITicketAnswerResponse } from '../core/models/ai_ticket_answer.response'
import { decodeHtml } from '../utils'
import {
  AITicketQuestion,
  createAITicketQuestionFromAIQuestionResponse
} from './ai_ticket_question.model'
import { Ticket, createTicketFromTicketResponse } from './ticket.model'
import { User, createUserFromUserResponse } from './user.model'

export class AITicketAnswer {
  id: number = 0
  tr: string = ''
  en: string = ''
  createdBy: User = new User()
  ticket: Ticket = new Ticket()
  question: AITicketQuestion = new AITicketQuestion()
  createdAt?: Date = new Date()
  like: number = 0
  liked: boolean = false
}

export function createAITicketAnswerFromAIAnswerResponse(
  response: AITicketAnswerResponse
): AITicketAnswer {
  const instance = new AITicketAnswer()
  instance.id = response.id
  instance.tr = decodeHtml(response.tr)
  instance.en = response.en
  instance.createdBy = createUserFromUserResponse(response.createdBy)
  instance.question = createAITicketQuestionFromAIQuestionResponse(
    response.question
  )
  instance.ticket = createTicketFromTicketResponse(response.ticket)
  instance.createdAt = convertISO8601StringToDate(response.createdAt)
  instance.like = response.like
  instance.liked = response.liked
  return instance
}
