import axios, { AxiosError, AxiosResponse } from 'axios'
import queryString from 'query-string'
import { toNumber } from '../core/helpers'
import { ApiResponse } from '../core/models/api.response'

axios.defaults.withCredentials = true

const apiBase = process.env.REACT_APP_APIURL as string

export async function post<P, R>(
  url: string,
  body: P
): Promise<ApiResponse<R>> {
  url = apiBase + url
  try {
    const axiosInstance = axios.create({
      withCredentials: true
    })
    return (
      await axiosInstance.post<P, AxiosResponse<ApiResponse<R>>>(url, body)
    ).data
  } catch (error) {
    return errorHandler(error as AxiosError<ApiResponse<R>>)
  }
}

export async function postFormData<R>(
  url: string,
  formData: FormData
): Promise<ApiResponse<R>> {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    withCredentials: true
  }
  url = apiBase + url
  try {
    const axiosInstance = axios.create({
      withCredentials: true
    })
    return (
      await axiosInstance.post<FormData, AxiosResponse<ApiResponse<R>>>(
        url,
        formData,
        config
      )
    ).data
  } catch (error) {
    return errorHandler(error as AxiosError<ApiResponse<R>>)
  }
}

export async function put<P = unknown, R = undefined>(
  url: string,
  body: P
): Promise<ApiResponse<R>> {
  url = apiBase + url
  try {
    const axiosInstance = axios.create({
      withCredentials: true
    })
    return (
      await axiosInstance.put<P, AxiosResponse<ApiResponse<R>>>(url, body, {
        withCredentials: true
      })
    ).data
  } catch (error) {
    return errorHandler(error as AxiosError<ApiResponse<R>>)
  }
}

export async function get<R = undefined>(url: string): Promise<ApiResponse<R>> {
  const axiosInstance = axios.create({
    withCredentials: true
  })
  try {
    return (
      await axiosInstance.get<ApiResponse<R>>(apiBase + url, {
        withCredentials: true
      })
    ).data
  } catch (error) {
    return errorHandler(error as AxiosError<ApiResponse<R>>, url)
  }
}

function errorHandler<R>(
  error: AxiosError<ApiResponse<R>>,
  target?: string
): ApiResponse<R> {
  const response = error.response?.data
  if (toNumber(error?.response?.status) === 401) {
    if (window && document && window?.location && target) {
      if (target.startsWith('/me') || target.startsWith('/notification')) {
        window.location.href = '/public/login'
      } else if (target.startsWith('/ticket/')) {
        const { refCode, ticketId } = queryString.parse(target) as {
          refCode?: string
          ticketId?: string
        }

        if (refCode && ticketId) {
          window.location.href = `/public/sign-up-with-ref/${refCode}?ticketId=${ticketId}`
        } else if (ticketId) {
          window.location.href = `/public/login?ticketId=${ticketId}`
        } else {
          window.location.href = '/public/login'
        }
      }
    }
    return { statusCode: 401 }
  }
  if (response) {
    return response
  } else {
    return {
      statusCode: 400
    }
  }
}
