import { Box } from '@mui/material'
import { DraggableProvided } from 'react-beautiful-dnd'
import { Group, Ticket, User } from '../../../models'
import { TicketCard } from '../../ticket_list/sub_components/ticket_card'

export default function TicketItem(props: {
  item: Ticket
  isDragging: boolean
  index: number
  provided: DraggableProvided
  handle_click_title?: (ticketId?: number | undefined) => void
  onChangeAssignee: (
    ticket: Ticket,
    newAssignedType: 'user' | 'group',
    newAssigned: User | Group
  ) => void
}) {
  const {
    item,
    isDragging,
    provided,
    index,
    onChangeAssignee,
    handle_click_title
  } = props

  return (
    <Box
      sx={{ cursor: 'grap!important' }}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
      data-is-dragging={isDragging}
      data-index={index}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1
        }}
      >
        <TicketCard
          ticket={item}
          handle_click_title={handle_click_title}
          onChangeAssignee={(newAssignedType, newAssigned) =>
            onChangeAssignee(item, newAssignedType, newAssigned)
          }
        />
      </Box>
    </Box>
  )
}
