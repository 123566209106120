import { Box, Typography } from '@mui/material'
import { ColumnProps } from '..'
import List from './list'

export const grid = 8
export const borderRadius = 2

const Column = ({ id, code, title, items, color }: ColumnProps) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          maxHeight: '80vh',
          background: 'white',
          flexDirection: 'column',
          px: 2,
          py: 1,
          alignItems: 'center',
          borderRadius
        }}
      >
        <Typography
          variant="h5"
          fontWeight={'bold'}
          sx={{
            color,
            userSelect: 'none',
            position: 'relative',
            borderBottom: 1
          }}
        >
          {title}
        </Typography>

        <List listId={`${id}`} listType="ITEMS" items={items} />
      </Box>
    </Box>
  )
}

export default Column
