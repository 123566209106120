import { Button, Divider, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useCreateProjectContext } from '../../contexts/create_project'
import { toArray } from '../../core/helpers'
import translate from '../../hooks/use_localization'

export default function LayerStep() {
  const { activeStep, setPayload, payload } = useCreateProjectContext()
  if (activeStep !== 2) {
    return null
  }
  const [text, setText] = useState('')

  return (
    <Stack>
      <TextField
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
      <Button
        disabled={text?.trim().length === 0}
        onClick={() => {
          const newText = text.trim()
          if (newText.length === 0) {
            return
          }
          setPayload?.((prev) => ({
            ...prev,
            layers: toArray(prev.layers).concat({ name: newText })
          }))
          setText('')
        }}
      >
        {translate('add')}
      </Button>
      <Box sx={{ my: 1 }}>
        <Divider />
      </Box>
      <Typography>{translate('layers')}</Typography>
      <Box sx={{ my: 1 }}>
        <Divider />
      </Box>
      <Stack gap={2}>
        {toArray(payload?.layers).map((layer, index) => (
          <Typography key={index}>{layer.name}</Typography>
        ))}
      </Stack>
    </Stack>
  )
}
