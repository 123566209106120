import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { ReactNode } from 'react'

interface AuthLayoutProps {
  children: ReactNode
}

export default function AuthLayout(props: AuthLayoutProps) {
  return (
    <Box
      sx={{
        width: '100vw',
        minHeight: '100vh',
        flex: 1
      }}
    >
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          src={
            'https://theticketgecici.s3.eu-central-1.amazonaws.com/web_logo_1.png'
          }
          srcSet={
            'https://theticketgecici.s3.eu-central-1.amazonaws.com/web_logo_1.png'
          }
          loading="lazy"
        />
        <Box
          sx={{
            my: {
              xs: 3,
              sm: 5
            },
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  )
}

function Navbar() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        flex: 1,
        p: 3
      }}
    >
      <LanguageSelect />
    </Box>
  )
}

function LanguageSelect() {
  let locale = window.localStorage.getItem('lang') ?? 'en'

  if (locale !== 'tr' && locale !== 'en') {
    locale = 'tr'
  }

  const newLang = locale === 'tr' ? 'en' : 'tr'

  function changeLang() {
    window.localStorage.setItem('lang', newLang)
    window.location.reload()
  }

  return (
    <Box>
      <Button onClick={changeLang} variant="text">
        {newLang}
      </Button>
    </Box>
  )
}
