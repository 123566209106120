import { createContext } from 'react'
import { Group, Ticket, User } from '../../models'

interface TicketCardContextProps {
  ticket?: Ticket
  handle_click_title?: () => void
  onChangeAssignee?: (
    newAssignedType: 'user' | 'group',
    newAssigned: User | Group
  ) => void
}

export const TicketCardContext = createContext<TicketCardContextProps>({})

export function TicketCardContextProvider({
  ticket,
  handle_click_title,
  children,
  onChangeAssignee
}: {
  ticket?: Ticket
  children: JSX.Element | JSX.Element[]
  handle_click_title?: () => void
  onChangeAssignee?: (
    newAssignedType: 'user' | 'group',
    newAssigned: User | Group
  ) => void
}) {
  return (
    <TicketCardContext.Provider
      value={{
        ticket,
        handle_click_title,
        onChangeAssignee
      }}
    >
      {children}
    </TicketCardContext.Provider>
  )
}
