import { Stack } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import translate from '../hooks/use_localization'
import { Project } from '../models'

export default function ProjectSelect({
  projects,
  value,
  onListItemClick,
  disabled
}: {
  projects: Project[]
  value?: number
  onListItemClick: (projectId: number) => void
  disabled?: boolean
}) {
  return (
    <TextField
      value={value}
      label={translate('ticket_project')}
      fullWidth={true}
      required={true}
      select
    >
      {projects.map((project) => (
        <ListItemButton
          component={'button'}
          value={project.id}
          key={project.id}
          sx={{
            width: '100%'
          }}
          disabled={disabled}
          onClick={() => onListItemClick(project.id)}
        >
          <Stack direction={'row'} display={'flex'} alignItems={'center'}>
            <Typography sx={{ p: 2, maxWidth: 150 }}>{project.name}</Typography>
          </Stack>
        </ListItemButton>
      ))}
    </TextField>
  )
}
