import { Paper } from '@mui/material'
import { TicketCardContextProvider } from '../../../contexts/ticket/card'
import { Group, Ticket, User } from '../../../models'
import { Detail } from './detail'
import { StatusBar } from './status_bar'
import { Time } from './time'

export const status_width = 15

export function TicketCard({
  ticket,
  handle_click_title,
  onChangeAssignee
}: {
  ticket?: Ticket
  handle_click_title?: (ticketId?: number) => void
  onChangeAssignee?: (
    newAssignedType: 'user' | 'group',
    newAssigned: User | Group
  ) => void
}) {
  return (
    <TicketCardContextProvider
      ticket={ticket}
      handle_click_title={() => handle_click_title?.(ticket?.id)}
      onChangeAssignee={onChangeAssignee}
    >
      <Paper
        sx={{
          display: 'flex',
          width: 280,
          maxWidth: 280,
          minWidth: 280,
          position: 'relative',
          overflow: 'hidden',
          p: 1,
          mt: 3,
          minHeight: 100
        }}
      >
        <StatusBar />
        <Time />
        <Detail />
      </Paper>
    </TicketCardContextProvider>
  )
}
