import useSWR from 'swr'
import { INotificationListResponse } from '../../core/api_request_responses/notification.models'
import { toArray } from '../../core/helpers'
import { createNotificationFromNotificationResponse } from '../../models/notification.model'

export function useNotifications() {
  const { data, mutate } = useSWR<INotificationListResponse>('/notification')

  return {
    notifications: data?.notifications
      ? toArray(data?.notifications).map((notification) =>
          createNotificationFromNotificationResponse(notification)
        )
      : undefined,
    mutate
  }
}
