import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  IPublicSignUpWithRefCodeRequest,
  IPublicSignUpWithRefCodeResponse
} from '../../../core/api_request_responses/public.models'
import { toNumber, toString } from '../../../core/helpers'
import UserRefSignUpForm from '../../../forms/user_ref_sign_up_form'
import translate from '../../../hooks/use_localization'
import AuthLayout from '../../../layouts/auth_layout'
import runOneSignal from '../../../onesignal'
import { post } from '../../../utils/api'

export default function SignUpWithCodePage() {
  const navigate = useNavigate()
  const { code } = useParams<{ code: string }>()

  const api_message = useRef<
    { error?: boolean; message?: string } | undefined
  >()
  const [loading, set_loading] = useState(false)

  async function handle_submit(payload: IPublicSignUpWithRefCodeRequest) {
    set_loading(true)
    const response = await post<
      IPublicSignUpWithRefCodeRequest,
      IPublicSignUpWithRefCodeResponse
    >(`/public/sign-up-with-ref-code/${toString(code)}`, payload)
    if (response.statusCode === 200 && response?.payload?.ticketId) {
      api_message.current = {
        error: false,
        message: translate('sign_up_success')
      }
      window.localStorage.setItem('userId', `${response?.payload?.user.id}`)
      window.localStorage.setItem('name', response?.payload?.user.name)
      window.localStorage.setItem(
        'photoUrl',
        response?.payload?.user?.profilePicture ?? ''
      )
      await runOneSignal(response?.payload?.user.id)
      navigate(`/tickets?ticketId=${toNumber(response.payload?.ticketId)}`)
    } else {
      api_message.current = {
        error: true,
        message: translate(response?.errorCode)
      }
    }
    set_loading(false)
  }

  return (
    <AuthLayout>
      <Stack gap={3}>
        <Typography component="h1" variant="h5">
          {translate('sign_up_title')}
        </Typography>

        <UserRefSignUpForm
          handle_submit={handle_submit}
          loading={loading}
          api_message={api_message.current}
        />
      </Stack>
    </AuthLayout>
  )
}
